.SurveyInfoFormWrap {
  // display: flex;
  // width: 100%;

  > form {
    width: 100%;
  }

  .ant-form-item {
    flex-direction: column;

    .ant-form-item-label {
      margin: 1rem 0;
    }
  }

  .SettingsColumn {
    // flex: 0 0 270px;

    .SettingsBox {
      background-color: #fff;
      padding: 12px;
      margin: 20px 0px 0 40px;
      border-radius: 4px;

      &:first-of-type {
        margin-top: 0;
      }

      h3 {
        text-transform: capitalize !important;
        border-bottom: 3px solid #f5f7f7;
        min-height: 35px;
      }

      &__footer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        button:last-of-type {
          margin-left: auto;
        }
      }
    }
    .SettingsBox__footer {
      justify-content: flex-end;
      .apply {
        margin-left: 35px !important;
      }
      .save-draft {
        background-color: transparent;
        color: #000000;
        border: none;
      }
      .ArchiveSurvey {
        background-color: transparent;
        border-color: transparent;
        color: var(--primary-hover-color);
        &:hover,
        &:focus {
          color: var(--primary-hover-color);
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
  }
  .adminResultDetails {
    .adminResultCollapseTitle {
      &.required {
        &::after {
          content: '*';
          color: #eb3052;
          margin-left: 1rem;
        }
      }
    }
    .adminResultAnswerCount {
      margin-bottom: 0px;
      font-weight: 300;
    }
    .adminResultBirthdayList {
      .adminResultBirthdayItem {
        padding: 9px 10px;
        border-radius: 4px;
        background-color: #f3f3f3;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.SurveyInfoInputs {
  .SurveyInfoInputsItem {
    display: flex;
    flex-wrap: wrap;
    .dragSpace {
      width: 20px;
      background-color: #52a6ac;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
    }
    .contentSpace {
      flex: 1;
      padding: 16px 20px;
      background-color: #e5e5e5;
      border: 2px solid #e5e5e5;
      label {
        display: block;
      }
      .gear-icon {
        color: #4d9a9f;
      }
      .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__calendar-icon {
          z-index: 1;
          right: 0;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .ant-select {
        width: 100%;
      }
    }
    .ant-checkbox-wrapper {
      .ant-checkbox {
        top: 0px !important;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
    &.active {
      .contentSpace {
        border-color: #52a6ac;
      }
    }
  }
}
.dataFieldCard {
  background-color: #f5f7f7;
  border: 2px solid transparent;
  .ant-card-body {
    padding: 10px;
    .dataFieldCardTitle {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0px;
    }
    .dataFieldCardText {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 0px;
    }
  }
  &.active {
    border-color: #52a6ac;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}
.error-message {
  color: red;
}
@mobile-ruleset: {
  .SurveyInfoFormWrap {
    flex-direction: column-reverse;

    .SettingsColumn .SettingsBox {
      margin: 0 !important;
    }

    .SettingsColumn {
      flex: 0 0 100%;
      margin-bottom: 20px;

      .SettingsBox {
        margin-bottom: 20px !important;
      }
    }

    .AdminContentLangSwitcher__wrap {
      label {
        margin-right: 1em;
      }

      flex-direction: row;
      margin: 0.75em 0 0 0;
    }

    .AccessibilityBoxForm {
      .ant-row {
        display: flex !important;
        flex-direction: row !important;
      }
    }
  }
};

@media screen and (max-width: 767px) {
  @mobile-ruleset();
}

//When the content area is smaller than 768 (768 + 290)
@media only screen and (max-width: 1057px) {
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout {
    @mobile-ruleset();
  }
}

@media screen and (max-width: 460px) {
  .SurveyInfoFormWrap .AdminContentLangSwitcher__wrap {
    flex-direction: column;
  }
}

@desktop-ruleset: {
  #sticky-wrapper.enabled {
    position: fixed;
    top: 5em;
    overflow: auto;
    width: 270px;
    max-height: calc(~'100vh - 100px');
  }
};

@media screen and (min-width: 1057px) {
  @desktop-ruleset();
}

@media only screen and (min-width: 767px) {
  aside.ant-layout-sider-collapsed + i + section.ant-layout {
    @desktop-ruleset();
  }
}

// @media screen and (min-width: 1100px) {
//   #sticky-wrapper.enabled {
//     width: 360px;
//   }

//   .SurveyInfoFormWrap .SettingsColumn {
//     flex: 0 0 360px;
//   }
// }

// @media screen and (min-width: 1400px) {
//   #sticky-wrapper.enabled {
//     width: 450px;
//   }

//   .SurveyInfoFormWrap .SettingsColumn {
//     flex: 0 0 450px;
//   }
// }
