.ParticipationTypeOptions {
  display: flex;
  position: relative;
  margin-bottom: 1rem;
}
.ParticipationTypeOptions:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  width: 32px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
@media screen and (max-width: 400px) {
  .ParticipationTypeOptions {
    flex-direction: column;
  }
}
span.ParticipationTypeOptions__Option,
button.ParticipationTypeOptions__Option {
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
span.ParticipationTypeOptions__Option > button {
  border: none;
  width: 100%;
}
span.ParticipationTypeOptions__Option > button:hover {
  color: var(--link-hover-color);
}
span.ParticipationTypeOptions__Option > button:focus {
  color: var(--link-hover-color);
}
button.ParticipationTypeOptions__Option {
  outline: none;
}
button.ParticipationTypeOptions__Option:hover {
  border-color: var(--link-hover-color);
}
button.ParticipationTypeOptions__Option:focus {
  border-color: var(--link-hover-color);
}
.ParticipationTypeOptions__Option {
  cursor: pointer;
  border: 2px solid #DDDFDF;
  border-radius: 4px;
  transition: all 0.3s;
  margin-right: 6px;
}
.ParticipationTypeOptions__Option.ParticipationTypeOptions__Option--Selected {
  border: 2px var(--primary-color) solid;
}
.ParticipationTypeOptions__Option:last-child {
  margin-right: 0;
  opacity: 0;
}
.ParticipationTypeOptions__Option .ParticipationTypeOptions__Icon {
  font-size: 3.6rem;
  margin-top: 24px;
  margin-bottom: 1rem;
  color: var(--link-color);
}
.ParticipationTypeOptions__Option h3 {
  margin: 0;
}
.ParticipationTypeOptions__Option p {
  line-height: initial;
  text-align: center;
  margin-bottom: 30px;
}
.ParticipationTypeOptions__Empty {
  flex: 1;
}
