@import (reference) "../../assets/stylesheets/main";

.CreatePersonalizationForm {
  padding-top: @padding-lg;
  max-width: 700px;

  &__ContentLangSwitcher {
    display: flex;
    background-color: #D8FDFF;
    padding: 1.8rem;
    margin-bottom: 2.4rem;

    .fa-exclamation-circle {
      color: #5CAFB6;
      font-size: 2.4rem;
      margin-right: 1.8rem;
      margin-top: 0.8rem;
    }

    label {
      font-weight: bold;
    }

    .TextAreaMultiLangSelect {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 0;

      .ant-select-selection {
        background: none;
        border: none;
      }

      .ant-select-arrow {
        margin-top: -1rem;
      }
    }
  }

  &__Field {
    display: flex;

    >div {
      flex: 1;
    }
  }

  &__Submit {
    display: flex;
    justify-content: flex-start;

    .btn-submit {
      height: 40px;
      font-weight: 400;
    }
  }

  &__Dropdown {
    width: auto !important;
  }

  &__SubmitWrapper {
    display: flex;

    // Equivalent of flex-grow:2 but we don't have any div to have flex-grow:1 aside
    width: 100% * 2 / 3;

    > * {
      margin-right: 10px;
    }
  }

  &__CancelWrapper {
    .btn-draft {
      margin-right: 1rem;
    }
  }
}




