.SearchBar {
  background-color: #F5F7F7;
  padding-top: 3.4rem;
  padding-bottom: 3rem;
}
.SearchBar form {
  padding-left: 4rem;
  padding-right: 4rem;
}
.SearchBar.SearchBar--compact {
  background-color: transparent;
}
.SearchBar .ant-form-item {
  margin-bottom: 0;
}
.SearchBar ::placeholder {
  color: rgba(0, 0, 0, 0.45);
}
.SearchBar .extraContent {
  padding: 0 4rem;
}
.SearchBar__Divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.SearchBar__Divider .AdminTitleBar {
  padding-top: 0;
  padding-bottom: 0;
}
.SearchBar__Divider h1,
.SearchBar__Divider h2 {
  margin-bottom: 0;
}
.SearchBar__Divider > span a {
  margin-left: 20px;
}
.SearchBar__ToggleFilterBar {
  cursor: pointer;
}
.SearchBar__ToggleFilterBar.SearchBar__ToggleFilterBar--open {
  color: var(--primary-color);
}
.SearchBar__InputPrefixIcon {
  color: var(--primary-color);
}
.SearchBar__FilterBar {
  padding: 12px 12px 0 12px;
}
.SearchBar__FilterBar h2 {
  font-size: 18px;
  padding-bottom: 12px;
  border-bottom: 3px solid #CCCCCC;
}
.SearchBar__FilterBar .ant-calendar-picker-icon:after {
  color: var(--primary-color);
}
.SearchBar__FilterBar .ant-form-item-children {
  height: 100%;
  display: inline-block;
}
.SearchBar__FilterBar .ant-form-item-children:after {
  margin-top: -8px;
  top: unset !important;
  bottom: 10px;
}
.SearchBar__FilterBar .ant-calendar-picker-input {
  display: flex;
  justify-content: flex-start;
}
.SearchBar__FilterBar .ant-calendar-picker-input .ant-calendar-picker-icon {
  order: 1;
  position: relative;
  right: 0;
}
.SearchBar__FilterBar .ant-calendar-picker-input .ant-calendar-range-picker-separator {
  order: 3;
}
.SearchBar__FilterBar .ant-calendar-picker-input .ant-calendar-range-picker-input:first-of-type {
  order: 2;
}
.SearchBar__FilterBar .ant-calendar-picker-input .ant-calendar-range-picker-input:last-of-type {
  order: 4;
}
.SearchBar__FilterBar .ant-form-item {
  display: flex;
}
.SearchBar__FilterBar .ant-form-item .ant-form-item-control-wrapper {
  display: flex;
  align-items: center;
  flex: 3;
  line-height: normal;
}
.SearchBar__FilterBar .ant-form-item .ant-form-item-label {
  flex: 1.3;
  text-align: left;
  white-space: pre-wrap;
  line-height: normal;
  align-self: flex-start;
  margin-top: 1rem;
}
.SearchBar__FilterBar .ant-form-item .ant-form-item-control {
  width: 100%;
}
.SearchBar__FilterBarControls {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.SearchBar__FilterBarControls button {
  margin-left: 6px;
}
.SearchBar__FilterBarControls button i,
.SearchBar__FilterBarControls button svg {
  margin-left: 0.5em;
}
@media screen and (max-width: 700px) {
  .SearchBar__Divider h1 {
    font-size: 1.65em;
  }
  .AdminTitleBar--buttons {
    margin-top: 1.25em;
  }
  .AdminTitleBar--buttons .ant-btn {
    width: 2.75em;
    height: 2.75em !important;
    display: flex;
    align-items: center;
    padding: 0 !important;
    text-align: center;
    justify-content: center;
  }
  .AdminTitleBar--buttons svg {
    margin-left: 0;
  }
  .AdminTitleBar--buttons .ant-btn span:first-child {
    display: none;
  }
  .CsvReportButton svg {
    font-size: 1.5em;
    position: relative;
    top: 2px;
  }
}
@media only screen and (max-width: 990px) {
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .SearchBar__Divider h1 {
    font-size: 1.65em;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminTitleBar--buttons {
    margin-top: 1.25em;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminTitleBar--buttons .ant-btn {
    width: 2.75em;
    height: 2.75em !important;
    display: flex;
    align-items: center;
    padding: 0 !important;
    text-align: center;
    justify-content: center;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminTitleBar--buttons svg {
    margin-left: 0;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminTitleBar--buttons .ant-btn span:first-child {
    display: none;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CsvReportButton svg {
    font-size: 1.5em;
    position: relative;
    top: 2px;
  }
}
