.CampaignAdminPageForm {
  width: 100%;
}
.CampaignAdminPageForm .AvatarThumbnail__PrimaryContent {
  font-size: 0.8em;
  text-transform: uppercase;
}
.CampaignAdminPageForm .AvatarThumbnail__SecondaryContent {
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
}
.CampaignAdminPageForm__Approval > div {
  margin-top: 1em;
}
.CampaignAdminPageForm__Approval button:first-of-type {
  margin-right: 1em;
}
.CampaignAdminPageForm .MoneyField--campaign_goal .ant-form-item-children-icon {
  left: 120px;
}
