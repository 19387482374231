.PublicDonationQuote {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.PublicDonationQuote p {
  background-color: #F5F7F7;
  border-radius: 4px;
  border: 1px solid #DDDFDF;
  margin-bottom: 0;
  margin-left: 52px;
  padding: 8px;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}
.PublicDonationQuote .AvatarThumbnail__PrimaryContent {
  color: var(--primary-color);
  font-weight: bold;
}
.PublicDonationQuote .AvatarThumbnail__SecondaryContent {
  font-size: 14px;
  line-height: 15px;
  font-weight: bold;
}
.PublicDonationQuote--BigQuote {
  flex-direction: column-reverse;
}
.PublicDonationQuote--BigQuote p {
  background-color: #ECEDED;
  border-radius: 12px;
  border: 0;
  padding: 0 48px;
  margin: 0;
  height: 250px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Domine', serif;
  font-size: 18px;
  font-style: italic;
  text-align: center;
}
.PublicDonationQuote--BigQuote p:before,
.PublicDonationQuote--BigQuote p:after {
  color: #888888;
  font-size: 100px;
  font-family: Arial, serif;
  font-style: normal;
  position: absolute;
}
.PublicDonationQuote--BigQuote .PublicDonationQuote__Info {
  position: relative;
  padding: 12px 0 12px 65px;
}
.PublicDonationQuote--BigQuote .PublicDonationQuote__Arrow {
  position: absolute;
  left: 18px;
  top: 0;
  height: 33%;
  fill: #ECEDED;
}
.PublicDonationQuote--BigQuote.large p {
  font-size: 3.6rem;
}
.PublicDonationQuote--BigQuote.medium p {
  font-size: 2.4rem;
}
.PublicDonationQuote--BigQuote.small p {
  font-size: 1.8rem;
}
@media screen and (max-width: 520px) {
  .PublicDonationQuote--BigQuote p {
    padding: 0 20px !important;
  }
  .PublicDonationQuote--BigQuote.large p {
    font-size: 2.4rem;
  }
  .PublicDonationQuote--BigQuote.medium p {
    font-size: 1.8rem;
  }
  .PublicDonationQuote--BigQuote.small p {
    font-size: 1.4rem;
  }
}
