@import (reference) "../../assets/stylesheets/main";

.CreateConfirmationPanel {
  position: relative;
  padding-top: @padding-lg;
  max-width: 680px;

  svg.CreateConfirmationPanel__SuccessIcon {
    position: absolute;
    left: -56px - @padding-lg;
    font-size: 56px;
    color: var(--link-color);
    margin-right: 14px;
  }

  &--inviteBtn{
    background-color: #3b5998 !important;
    &:hover,
    &:focus {
      background-color: #3b5998 !important;
    }

    display: flex;
    align-items: center;

    svg {
      font-size: 1.5em;
    }
  }

  @media screen and (max-width: 580px) {
    max-width: inherit;
    margin-left: 0;

    svg.CreateConfirmationPanel__SuccessIcon {
      display: none;
    }
  }

}

.CreateConfirmationPanel__Divider {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  margin-bottom: @padding-lg;


  &--CTA {
    justify-content: flex-start !important;
    flex-wrap: wrap;
    margin-top: 78px;
    margin-left: -.5rem;
    margin-right: -.5rem;

    > a, button {
      margin: .5rem;
    }
  }
}

.CreateConfirmationPanel__DarkButton {
  .dark-btn();
}
