@import (reference) "../assets/stylesheets/main";
@import (reference) "./ResultTable";

.PaginationTop {
  display: flex;
  padding-top: @padding-md;
  padding-bottom: @padding-md - @table-border-spacing;

  > *,
  .ant-pagination-total-text {
    .inner-margin(20px);
  }

  .ant-pagination {
    display: flex;
    flex: 1;

    > :first-child {
      display: flex;
      flex: 1;
    }

    .ant-pagination-item {
      // No page buttons 1, 2, 3, etc. Impossible to tell Antd to NOT render them
      display: none;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      font-size: @font-size-sm;
      font-weight: bold;
      svg {
        width: .5em;
      }
    }
  }

  .ant-select-selection {
    .ant-select-arrow:before {
      color: rgba(0, 0, 0, 0.65);
      font-size: .8em;
    }
  }

  &__SelectOption {
    text-align: center;
  }

}

.PaginationTop__TotalInfo {
  display: flex;
  flex: 1;
  justify-content: space-between;

  > :first-child {
    font-weight: bold;
    font-size: 18px;
  }

  > :last-child {
    font-size: @font-size-sm;
  }
}
