.ContactPage {
  padding: 80px 0;
}
.ContactPage__Content {
  display: flex;
  flex-wrap: wrap;
  margin: -20px -30px;
}
.ContactPage__Map,
.ContactPage__Infos {
  margin: 20px 30px;
}
.ContactPage__Map {
  flex: 1 1 52%;
  background-color: white;
  height: 345px;
}
.ContactPage__Infos {
  flex: 1 0 33%;
  font-size: 18px;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}
.ContactPage__Infos h4 {
  font-weight: 600;
  margin-bottom: 4px;
}
.ContactPage__Infos p {
  line-height: 20px;
}
.ContactPage__Infos .postal-code {
  text-transform: uppercase;
}
.ContactPage__Infos ul {
  padding-left: 0;
  list-style-type: none;
}
.ContactPage__Phones li,
.ContactPage__Webs li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;
}
.ContactPage__Phones svg,
.ContactPage__Webs svg {
  margin-right: 10px;
  font-size: 24px;
}
.ContactPage__Phones a,
.ContactPage__Webs a {
  font-weight: 600;
  line-height: normal;
}
.ContactPage .PlatformSocialMedia ul {
  display: flex;
  margin: 0;
}
.ContactPage .PlatformSocialMedia ul li {
  margin-right: 10px;
}
.ContactPage .PlatformSocialMedia ul li:last-of-type {
  margin-right: 0;
}
.ContactPage .PlatformSocialMedia ul a {
  display: flex;
  width: 42px;
  height: 42px;
  border-radius: 4px;
}
.ContactPage .PlatformSocialMedia ul svg {
  color: #fff;
  margin: auto;
}
