.TextAreaField__CounterBarContainer {
  width: 100%;
  height: 5px;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
}
.TextAreaField__CounterBar {
  height: 100%;
  background-color: var(--primary-color);
}
.TextAreaField textarea.ant-input {
  margin-bottom: 0 !important;
  max-height: 200px !important;
}
.TextAreaField textarea.ant-input.radius2 {
  border-radius: 4px 4px 0 0;
}
.TextAreaField textarea.ant-input.radius3 {
  border-radius: 4px 4px 4px 0;
}
.TextAreaField textarea.ant-input.radius4 {
  border-radius: 4px 4px 4px 4px;
}
