.CampaignsTeamsPageForm{
  width: 100%;

  .AvatarThumbnail {
    &__PrimaryContent {
      font-size: .8em;
      text-transform: uppercase;
    }

    &__SecondaryContent {
      font-size: 14px;
      line-height: 15px;
      font-weight: 700;
    }
  }

  .Wysiwyg {
    &.smallQuote .quill.wysiwygStyle .ql-snow .ql-editor {
      font-size: 20px;
    }
    &.mediumQuote .quill.wysiwygStyle .ql-snow .ql-editor {
      font-size: 15px;
    }
    &.largeQuote .quill.wysiwygStyle .ql-snow .ql-editor {
      font-size: 10px;
    }
  }

  .quill.wysiwygStyle {

    .ql-snow .ql-editor {
      color: rgba(0,0,0,.65);
      font-family: Domine,serif;

      p {
        font-size: 1.6em;
        margin: 0.5em 0;
      }
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
      u {
        text-decoration: underline;
      }
      sub {
        font-size: 0.5em;
      }
      sup {
        font-size: 0.5em;
      }
    }
  }
}
