.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__MenuLink {
  width: 90% !important;max-width: 1200px;margin: auto;
}
@media screen and (max-width: 600px) {
  .ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__MenuLink {
    width: 95% !important;
  }
}
.ParticipantPublicPage .ParticipantPublicPage__Container {
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 24px;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu {
  flex: 1 0 320px;
  max-width: 392px;
  padding-right: 24px;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__Thermometer {
  margin: 24px 0;
  margin-top: 0;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__Thermometer .ParticipantPublicPage__Participant--dropdown {
  width: 100%;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__Thermometer .ParticipantPublicPage__Participant--dropdown .HelpText {
  display: block;
  text-align: left;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__Thermometer .PublicThermometer__Percentage {
  font-size: 8.2rem;
  font-weight: 600;
  line-height: normal;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__Thermometer .PublicThermometer__Detail {
  font-size: 2.6rem;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__MenuLink {
  line-height: 60px;
  border-radius: 4px;
  border-left: 5px solid transparent;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 5px;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__MenuLink svg {
  font-size: 28px;
  vertical-align: middle;
  margin-right: 24px;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__MenuLink:hover {
  background-color: #FFFFFF;
}
.ParticipantPublicPage .ParticipantPublicPage__Menu .ParticipantPublicPage__MenuLink--Active {
  background-color: #FFFFFF;
  border-left: 5px solid var(--link-color);
}
.ParticipantPublicPage .ParticipantPublicPage__Content {
  flex: 1 1 auto;
  min-width: 0;
}
.ParticipantPublicPage .ParticipantPublicPage__Content > div:first-of-type {
  margin-top: 0;
}
.ParticipantPublicPage__Breadcrumbs {
  font-size: 12px;
  color: #AEADAE;
}
.ParticipantPublicPage__Breadcrumbs ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 12px;
  padding-left: 0;
}
.ParticipantPublicPage__Breadcrumbs li:last-of-type {
  color: #424749;
}
.ParticipantPublicPage__Breadcrumbs a {
  color: #AEADAE;
  cursor: pointer;
}
.ParticipantPublicPage__Breadcrumbs a:hover {
  color: var(--primary-color);
}
.ParticipantPublicPage__Breadcrumbs svg {
  font-size: 10px;
  color: var(--secondary-color);
  margin: 0 6px;
}
