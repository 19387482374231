.CampaignsTeamsPage .team-name {
  float: left;
}
.CampaignsTeamsPage .btn-view-page {
  float: right;
}
.CampaignsTeamsPage .ant-tabs {
  clear: both;
}
.CampaignsTeamsPage .page-title {
  text-transform: uppercase;
  margin-bottom: 0;
}
.CampaignsTeamsPage h1 svg {
  margin-left: 1rem;
}
