.InformationFormContainer .AdditionalInformation {
  margin-top: 3em;
}
.InformationFormContainer .ProfileTypeCards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}
.InformationFormContainer .ProfileTypeCards .FundkyCard {
  margin: 1rem;
  min-width: 300px;
  width: 100%;
}
.InformationFormContainer .ProfileTypeCards .FundkyCard__Body p {
  line-height: normal;
  margin-bottom: 0;
}
.InformationFormContainer .ProfileTypeCards .empty-corporate-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.InformationFormContainer .ProfileTypeCards .empty-corporate-card p {
  margin-bottom: 0;
}
