.AdminFormWrap {
  display: flex;
  width: 100%;

  >form {
    width: 100%;
  }

  .ant-form-item {
    flex-direction: column;

    .ant-form-item-label {
      margin: 1rem 0;
    }
  }

  .SettingsColumn {
    flex: 0 0 270px;

    .SettingsBox {
      background-color: #fff;
      padding: 12px;
      margin: 20px 20px 0;
      border-radius: 4px;

      &:first-of-type {
        margin-top: 0;
      }

      h3 {
        text-transform: capitalize !important;
        border-bottom: 3px solid #f5f7f7;
        min-height: 35px;
      }

      &__footer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        button:last-of-type {
          margin-left: auto;
        }
      }
    }
  }
}


@mobile-ruleset: {
  .AdminFormWrap {
    flex-direction: column-reverse;

    .SettingsColumn .SettingsBox {
      margin: 0 !important;
    }

    .SettingsColumn {
      flex: 0 0 100%;
      margin-bottom: 20px;

      .SettingsBox {
        margin-bottom: 20px !important;
      }
    }

    .AdminContentLangSwitcher__wrap {
      label {
        margin-right: 1em;
      }

      flex-direction: row;
      margin:0.75em 0 0 0;
    }

    .AccessibilityBoxForm {
      .ant-row {
        display: flex !important;
        flex-direction: row !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  @mobile-ruleset();
}

//When the content area is smaller than 768 (768 + 290)
@media only screen and (max-width: 1057px) {
  aside:not(.ant-layout-sider-collapsed)+i+section.ant-layout {
    @mobile-ruleset();
  }
}

@media screen and (max-width: 460px) {
  .AdminFormWrap .AdminContentLangSwitcher__wrap {
    flex-direction: column;
  }
}


@desktop-ruleset: {
  #sticky-wrapper.enabled {
    position: fixed;
    top: 5em;
    overflow: auto;
    width: 270px;
    max-height: calc(~"100vh - 100px");
  }
}

@media screen and (min-width: 1057px) {
  @desktop-ruleset();
}

@media only screen and (min-width: 767px) {
  aside.ant-layout-sider-collapsed+i+section.ant-layout {
    @desktop-ruleset();
  }
}

@media screen and (min-width: 1100px) {
  #sticky-wrapper.enabled {
    width: 360px;
  }

  .AdminFormWrap .SettingsColumn {
    flex: 0 0 360px;
  }
}

@media screen and (min-width: 1400px) {
  #sticky-wrapper.enabled {
    width: 450px;
  }

  .AdminFormWrap .SettingsColumn {
    flex: 0 0 450px;
  }
}
