.CreatePaymentForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 24px;
}
.CreatePaymentForm__Form {
  margin-right: 4%;
  flex: 0 0 70%;
}
.CreatePaymentForm .WinkTooltip__Cc {
  font-size: 2.5em;
  line-height: 1em;
  white-space: nowrap;
}
.CreatePaymentForm .WinkTooltip__Cc svg {
  margin-right: 9px;
}
.CreatePaymentForm .CreatePaymentForm__Field {
  display: flex;
}
.CreatePaymentForm .CreatePaymentForm__Field > div {
  flex: 1;
}
.CreatePaymentForm .CreatePaymentForm__Field input {
  height: 56px;
}
.CreatePaymentForm .CreatePaymentForm__Field .ant-select-selection {
  height: 56px;
}
.CreatePaymentForm .CreatePaymentForm__Field .ant-select-selection__rendered {
  height: 56px;
}
.CreatePaymentForm .CreatePaymentForm__Field .ant-select-selection-selected-value {
  line-height: 56px;
}
.CreatePaymentForm .CreatePaymentForm__Field .ant-form-item-children {
  display: block;
}
.CreatePaymentForm .CreatePaymentForm__Field .ant-form-item-control {
  line-height: unset;
}
.CreatePaymentForm .CreatePaymentForm__Field.CreatePaymentForm__Field--cvv .ant-form-item-children {
  display: flex;
  align-items: center;
}
.CreatePaymentForm__Divider {
  display: flex;
  justify-content: space-between;
}
.CreatePaymentForm__Divider .CreatePaymentForm__Field {
  margin-left: 10px;
  margin-right: 10px;
}
.CreatePaymentForm__Divider .CreatePaymentForm__Field--full {
  width: 100%;
}
.CreatePaymentForm__Divider .CreatePaymentForm__Field--half {
  width: 50%;
}
.CreatePaymentForm__Divider .CreatePaymentForm__Field--third {
  width: calc(100% / 3);
  max-width: calc(100% / 3 - 10px);
}
.CreatePaymentForm__Divider .CreatePaymentForm__Field--twoThird {
  width: calc(100% / 3 * 2);
}
.CreatePaymentForm__Divider .CreatePaymentForm__Field--sixth {
  width: calc(100% / 6);
}
.CreatePaymentForm__Divider .CreatePaymentForm__Field:first-of-type {
  margin-left: 0;
}
.CreatePaymentForm__Divider .CreatePaymentForm__Field:last-of-type {
  margin-right: 0;
}
.CreatePaymentForm__Divider .CreatePaymentForm__Field .ant-calendar-picker {
  width: 100%;
}
.CreatePaymentForm .CreatePaymentForm__Field--Short > div {
  flex: initial;
}
.CreatePaymentForm__ExpirationFields {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
}
.CreatePaymentForm__ExpirationFields > span {
  display: block;
  min-width: 20px;
  text-align: center;
  margin-bottom: 24px;
  line-height: 56px;
}
.CreatePaymentForm .StripeElement {
  margin-bottom: 20px;
  padding: 18px 11px;
  background-color: #fff;
  height: 56px;
  max-height: 56px;
  border-radius: 4px;
  border: 1px solid #dddfdf;
}
@media screen and (max-width: 1230px) and (min-width: 801px) {
  .CreatePaymentForm .CardNumberDateCvv {
    flex-direction: column;
  }
  .CreatePaymentForm .CardNumberDateCvv > div {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 920px) and (min-width: 801px) {
  .CreatePaymentForm .ZipCodeCountry {
    flex-direction: column;
  }
  .CreatePaymentForm .ZipCodeCountry > div {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 800px) {
  .CreatePaymentForm {
    flex-direction: column-reverse;
  }
  .CreatePaymentForm__Form {
    margin-right: 0;
  }
  .CreatePaymentForm__HelpBox {
    margin-bottom: 3rem;
    margin-left: 0;
  }
}
@media screen and (max-width: 480px) {
  .CreatePaymentForm .CardNumberDateCvv {
    flex-direction: column;
  }
  .CreatePaymentForm .CardNumberDateCvv > div {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .CreatePaymentForm {
    padding-right: 1em;
  }
}
.CreatePaymentForm__SubmitWrapper {
  display: flex;
  justify-content: flex-start;
}
.CreatePaymentForm__SubmitWrapper .ant-btn {
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: 400;
}
.CreatePaymentForm__SubmitWrapper .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding: 0 15px;
}
.CreatePaymentForm__SubmitWrapper .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) i {
  display: none;
}
.CreatePaymentForm__SubmitWrapper .CircularProgressbar {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}
.CreatePaymentForm__SubmitWrapper .CircularProgressbar-trail {
  stroke: #ffadb4;
}
.CreatePaymentForm__SubmitWrapper .CircularProgressbar-path {
  stroke: #FFFFFF;
}
.CreatePaymentForm__SubmitWrapper .Back-btn {
  color: #fff;
  background-color: #5f697a;
  height: 40.5px;
  margin-right: 10px;
  transition: background-color 1s;
}
.CreatePaymentForm__SubmitWrapper .Back-btn:hover {
  border-color: unset;
  background-color: rgba(0, 0, 0, 0.65);
  transition: background-color 1s;
}
