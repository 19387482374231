@import (reference) "../../../../assets/stylesheets/main";
@import '~react-quill/dist/quill.core.css';
@import '~react-quill/dist/quill.snow.css';

.Wysiwyg {
  .quill {
    .ql-toolbar,
    &.ql-toolbar {
      background-color: #fff;
      border-radius: 4px 4px 0 0;
    }

    .ql-tooltip {
      left: 0 !important;
    }

    .ql-header {
      color: rgba(0,0,0,0.85);
    }

    .ql-container {
      background-color: #fff;
      border-radius: 0 0 4px 4px;
    }

    .ql-editor {
      font-family: @font-family;
      font-size: 1.6rem;
      min-height: 275px;
      max-height: 80vh;
      padding: 8px 12px 28px 12px;

      h1 { font-family: @font-family-title; }
      h2 { font-weight: bold; }
      h3 { text-transform: inherit; }

      ul li:not(.ql-direction-rtl) {
        padding-left: 1em;
      }
    }

    &.disabled {
      opacity: .5;
      &, * {
        cursor: not-allowed !important;
      }
      .ql-toolbar{
        display: none;
      }
      .ql-container {
        border-radius: 4px;
        border-top: 1px solid #ccc;
      }
    }

    .ql-video-wrapper {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
    }

    iframe.ql-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .ql-video-invalid {
      text-align: center;
      background: #f3704a;
      color: #fff;
      font-weight: bold;
      padding: 10px;
    }
  }

  .radius2 { border-radius: 4px 4px 0 0; }
  .radius3 { border-radius: 4px 4px 4px 0; }
  .radius4 { border-radius: 4px 4px 4px 4px; }

  &__CounterBarContainer {
    width: 100%;
    height: 5px;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    margin-top: -1px;
    margin-left: 1px;
  }

  &__CounterBar {
    height: 100%;
    background-color: var(--primary-color);
  }

  .ant-form-item-children-icon {
    top: 22px !important;
  }

  .ant-form-item-control:not(.has-success):not(.has-error):not(.has-feedback) {
    .ant-form-item-children-icon {
      display: none;
    }
  }
}

.ant-message {
  .ant-message-notice-content {
    padding: 0;
  }
  .ant-message-error {
    border: 1px solid #FFD4C8;
    background-color: #FFEFEB;
  }
  .ant-message-custom-content {
    text-align: left;
    border-radius: 4px;
    padding: 21px;
    color: #333333;
    font-family: Assistant;
    font-size: 18px;
    letter-spacing: 0;
    display: flex;
    .anticon {
      font-size: 24px;
      margin-right: 19px;
      display: inline-block;
    }
    > span {
      display: inline-block;
      line-height: 20px;
    }
    > span div {
      margin-top: 5px;
      max-width: 500px;
      color: #333333;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
    }
    .ant-message-error {
      border: 1px solid #FFD4C8;
      background-color: #FFEFEB;
    }
    .an-message-success {
      border: 1px solid #CFFCD8;
      background-color: #E9FFED;
    }
    .an-message-info {
      border: 1px solid #CFFCD8;
      background-color: #E9FFED;
    }
    .an-message-warning {
      border: 1px solid #FAEC8F;
      background-color: #FFF9CE;
    }
  }
}
