.ConfirmationPanel input,
.ConnectionForm__Field input,
.ConnectionForm__CompactField input {
  height: 55px;
}
.ConfirmationPanel .ant-btn,
.ConnectionForm__Field .ant-btn,
.ConnectionForm__CompactField .ant-btn {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}
.ConnectionForm__Divider {
  display: flex;
}
.ConnectionForm__Divider .ConnectionForm__Field {
  flex-grow: 1;
}
.ConnectionForm__Divider .ConnectionForm__Field:first-child {
  margin-right: 12px;
}
.ConnectionForm__Divider .ConnectionForm__Field:last-child {
  margin-left: 12px;
}
.ConnectionForm__HiddenField {
  display: none;
}
.ConnectionForm__CompactField .ant-form-item-control {
  line-height: 1;
}
.ConnectionForm__EndNote {
  text-align: center;
}
.ant-alert {
  margin-bottom: 24px;
}
.ConnectionForm h1 {
  font-size: 24px;
}
.ConnectionForm__Field--password {
  margin-bottom: 0;
}
.ConnectionForm__ForgotLink {
  text-align: right;
}
.ConnectionForm__EndNote {
  margin: 0;
}
.ConnectionForm .twitch-btn {
  display: block;
  background-color: #9146FF;
  color: #fff;
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.12rem;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 15px;
  outline: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ConnectionForm .twitch-btn:hover {
  background-color: #7313ff;
}
.ConnectionForm .twitch-btn img {
  margin-left: 1em;
}
