.AdminLayout {
  width: 100%;
  min-height: 100%;
  background: none;
}
.AdminLayout .ant-layout {
  overflow-x: initial !important;
  background: none;
}
.AdminLayout .iconButton {
  font-size: 1.2em;
  margin: 0 0.5em;
}
.AdminLayout .WinkNotification {
  background-color: #f4f7f7;
  padding: 4rem;
  margin-bottom: 0;
}
.AdminLayout .ant-layout-header {
  line-height: normal;
}
.AdminLayout .PlatformSwitcher label {
  display: block;
}
.AdminLayout .ant-btn:not(.ant-btn-link) {
  padding: 0.5em 1em;
  line-height: 1em;
  white-space: normal;
  height: auto;
}
.AdminLayout .ant-btn:not(.ant-btn-link).FundkyButton__Type--text {
  padding: 0.5em 0;
}
.AdminLayout .ant-layout-content {
  background: none;
  min-height: auto;
}
.AdminLayout .ant-layout-content .ant-tabs-bar {
  padding-left: 4rem;
  padding-right: 4rem;
  margin: 0;
  background-color: #f4f7f7;
}
.AdminLayout .ant-layout-content .AdminList,
.AdminLayout .ant-layout-content .ant-tabs-tabpane {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
  background-color: #eceded;
}
.AdminLayout .ant-layout-content .ant-tabs-tabpane .AdminList {
  padding: 0 !important;
}
.AdminLayout .ant-layout-content h3 {
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
}
.AdminLayout .ant-layout-content .AdminList .PaginationTop .ant-select-selection {
  height: 32px;
}
.AdminLayout .ant-layout-content .AdminList .PaginationTop .ant-select-selection .ant-select-selection__rendered {
  line-height: 30px;
}
.AdminLayout .ant-layout-content .AdminList .PaginationTop .ant-select-selection .ant-select-arrow {
  font-size: 14px;
  margin-top: -6px;
}
.AdminLayout .ant-layout-content .AdminList .buttons button {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
}
.AdminLayout .ant-layout-content .AdminList .buttons button:hover,
.AdminLayout .ant-layout-content .AdminList .buttons button:focus {
  background-color: #fff !important;
  border-color: #fff !important;
}
.AdminLayout .ant-layout-content .AdminPanel,
.AdminLayout .ant-layout-content .DashboardPage__Content {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
  background-color: #f4f7f7;
  padding-bottom: 3rem;
  height: 100%;
}
.AdminLayout .ant-layout-sider-children {
  overflow: hidden;
}
.AdminLayout .ant-collapse {
  background: none;
}
.AdminLayout .ant-collapse-item {
  background: #fff;
  border: none;
  margin-bottom: 0.5rem;
}
.AdminLayout .ant-collapse-item .ant-collapse-header {
  border-bottom: 0px solid #f5f7f7;
  padding: 1.5rem !important;
  font-weight: bold;
  padding: 0;
}
.AdminLayout .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  left: inherit !important;
  right: 1.6rem;
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: #eb3052;
}
.AdminLayout .ant-collapse-item .ant-collapse-content-active {
  margin-top: 1.5rem;
}
.AdminLayout .ant-collapse-item-active .ant-collapse-header {
  border-bottom: 3px solid #f5f7f7;
}
.AdminLayout .ant-collapse-item-active .ant-collapse-header .arrow {
  display: none !important;
}
.AdminLayout .CancelAndSaveButtons__wrap {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}
.AdminLayout .btn-cancel {
  color: #eb3052;
  margin-right: 1rem;
}
.AdminLayout .btn-submit-wrap {
  margin-bottom: 0 !important;
  padding-bottom: 0;
}
.AdminLayout .btn-submit-wrap .ant-form-item-control {
  line-height: inherit;
}
.AdminLayout .btn-submit {
  height: 3.2rem;
  font-weight: bold;
}
.AdminLayout .btn-submit svg {
  margin-left: 0.4rem;
}
.AdminLayout .ant-tabs-tabpane {
  padding: 5.4rem 2rem 3rem;
}
.AdminLayout .ant-input,
.AdminLayout .ant-input-number,
.AdminLayout .ant-input-number-input,
.AdminLayout .ant-time-picker-input,
.AdminLayout .ant-select-selection:not(.ant-select-selection--multiple) {
  height: 4.2rem;
  text-overflow: ellipsis;
}
.AdminLayout .ant-select-selection--multiple {
  min-height: 4.2rem;
  padding-top: 0.3rem;
}
.AdminLayout .ant-select-selection--multiple .ant-select-arrow {
  top: 50%;
}
.AdminLayout .ant-select-selection--multiple .ant-select-selection__rendered {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.AdminLayout .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice {
  margin: 0.2rem;
}
.AdminLayout .ant-select-selection__rendered {
  line-height: 4.2rem;
}
.AdminLayout .ant-form-item-children {
  display: block;
}
.AdminLayout label.small {
  font-size: 1.4rem;
}
.AdminLayout .fa-pencil-alt {
  margin-right: 0;
  color: #ea3152;
  cursor: pointer;
  font-size: 0.8em;
}
.AdminLayout .ant-form-horizontal .ant-form-item {
  display: flex;
}
.AdminLayout .ant-form-horizontal .ant-form-item .ant-form-item-label {
  flex: 1.3;
  text-align: left;
  white-space: pre-wrap;
  line-height: normal;
  align-self: flex-start;
  margin-top: 1rem;
}
.AdminLayout .ant-form-horizontal .ant-form-item .ant-form-item-control-wrapper {
  display: flex;
  align-items: center;
  flex: 3;
  line-height: normal;
}
.AdminLayout .ant-form-horizontal .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control {
  width: 100%;
}
.AdminLayout .ant-form-item-label label p {
  margin-bottom: 0;
}
.AdminLayout .ant-table-thead > tr > th .ant-table-header-column {
  display: block;
}
.AdminLayout .ant-form-explain,
.AdminLayout .ant-form-extra {
  margin-bottom: 0.8em !important;
  margin-top: 0.1em !important;
}
@media only screen and (max-width: 1200px) {
  .AdminLayout .ant-layout-content .AdminList,
  .AdminLayout .ant-layout-content .ant-tabs-tabpane,
  .AdminLayout .AdminTitleBar,
  .AdminLayout .SearchBar form,
  .AdminLayout .WinkNotification,
  .AdminLayout .ant-layout-content .DashboardPage__Content,
  .AdminLayout .ant-layout-content .AdminPanel,
  .AdminLayout .ant-layout-content .ant-tabs-bar,
  .AdminLayout .AdminBackButtonBar {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .AdminLayout .ant-row.ant-form-item:not(.SocialMediaField) {
    display: block !important;
  }
}
@media only screen and (max-width: 890px) {
  .AdminLayout aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ant-row.ant-form-item:not(.SocialMediaField) {
    display: block !important;
  }
}
@media only screen and (max-width: 400px) {
  .AdminLayout #mainmenu_trigger {
    padding-left: 0.4em;
    padding-right: 0.4em;
  }
  .AdminLayout .ant-layout-content .AdminList,
  .AdminLayout .ant-layout-content .ant-tabs-tabpane,
  .AdminLayout .AdminTitleBar,
  .AdminLayout .SearchBar form,
  .AdminLayout .WinkNotification,
  .AdminLayout .ant-layout-content .DashboardPage__Content,
  .AdminLayout .ant-layout-content .AdminPanel,
  .AdminLayout .ant-layout-content .ant-tabs-bar,
  .AdminLayout .AdminBackButtonBar {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
#WePay-tags {
  position: fixed !important;
}
.ant-btn-circle:hover,
.ant-btn-circle:focus {
  background: transparent !important;
}
.ant-btn-circle:hover svg,
.ant-btn-circle:focus svg {
  position: relative;
  z-index: 12;
}
.ant-btn-circle:hover:after,
.ant-btn-circle:focus:after {
  content: '';
  height: 35px;
  width: 35px;
  position: absolute;
  background: white;
  border-radius: 35px;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
