@import (reference) "../../assets/stylesheets/main";

@resultThumbnailLiMargin: 4.5px;

.SelectCampaignPanel {
  display: flex;
  flex-direction: column;

  .PaginationTop,
  .SearchBar {
    width: 75%;
    align-self: center;
  }

  .ResultThumbnailList {
    li {
      margin: @resultThumbnailLiMargin;
    }
  }

  .card-disabled {
    border: 2px solid #E5E5E5;
    background-color: #fff;
    opacity: .5;
    &:hover {
      cursor: not-allowed;
      border: 2px solid #E5E5E5 !important;
      background-color: #fff !important;;
    }
  }

  &__CreateCampaignCTA {
    color: #fff;
    margin: 20px auto;
  }

  @media screen and (max-width: 706px) {
    .SearchBar,
    .PaginationTop,
    .ResultThumbnailList {
      width: 100%;
    }

    .ResultThumbnailList li {
      width: calc(~'50% - @{resultThumbnailLiMargin}*2');
    }
  }

  @media screen and (max-width: 500px) {
    .PaginationTop,
    .ResultThumbnailList {
      width: 100%;
    }

    .ResultThumbnailList li {
      width: 100%;
    }
  }

  @media screen and (max-width: 380px) {
    .PaginationTop {
      .ant-pagination {
        flex-wrap: wrap;

        .ant-pagination-total-text {
          flex: initial;
          margin-right: 0;
          width: 100%;
        }

        .ant-pagination-prev {
          margin-right: auto;
        }

        .ant-pagination-next {
          margin-left: auto;
        }
      }
    }
  }

}
