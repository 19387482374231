.FundkyCTA {
  display: flex;
  flex: 1;
  max-width: 300px;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  margin: 0.6rem;
  padding: 24px;
  box-sizing: border-box;
}
.FundkyCTA__Title {
  margin: 12px 0;
  text-align: center;
}
.FundkyCTA__Description {
  text-align: center;
  margin-bottom: 24px;
}
