.PublicTeamPanel__Carousel {
  padding: 4px 44px;
}
.PublicTeamPanel__Carousel a {
  display: block;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}
.PublicTeamPanel__Carousel a:hover {
  background-color: #F5F7F7;
}
.PublicTeamPanel__Team .PublicTeamPanel__Divider svg {
  font-size: 28px;
  color: rgba(0, 0, 0, 0.65);
}
.PublicTeamPanel__Team:nth-child(1) .PublicTeamPanel__Information svg {
  color: #EDCE48;
}
.PublicTeamPanel__Team:nth-child(2) .PublicTeamPanel__Information svg {
  color: #BCC1CA;
}
.PublicTeamPanel__Team:nth-child(3) .PublicTeamPanel__Information svg {
  color: #9A7D48;
}
.PublicTeamPanel__Participant {
  padding: 12px;
  color: rgba(0, 0, 0, 0.65);
}
.PublicTeamPanel__Participant .AvatarThumbnail__PrimaryContent {
  font-size: 14px;
  line-height: 15px;
}
.PublicTeamPanel__Participant .AvatarThumbnail__PrimaryContent svg {
  font-size: 14px;
  color: var(--primary-color);
}
.PublicTeamPanel__Participant .AvatarThumbnail__SecondaryContent {
  font-weight: bold;
}
.PublicTeamPanel__Divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: #F5F7F7;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.85);
}
.PublicTeamPanel__Divider > svg {
  color: rgba(0, 0, 0, 0.25);
  font-size: 2em;
}
a.PublicTeamPanel__Divider:hover {
  background-color: #333;
  color: #fff;
}
a.PublicTeamPanel__Divider:hover > svg {
  color: rgba(255, 255, 255, 0.25);
}
a.PublicTeamPanel__Divider:hover .HelpText {
  color: #ccc;
}
div.PublicTeamPanel__Divider .PublicTeamPanel__Name span:first-child {
  position: relative;
  top: 2px;
}
.PublicTeamPanel__Information {
  display: flex;
  align-items: center;
  position: relative;
}
.PublicTeamPanel__Position {
  font-family: 'Domine', serif;
  font-size: 16px;
  line-height: 16px;
  margin-left: 10px;
  font-weight: 600;
}
.PublicTeamPanel__Name {
  display: flex;
  flex-direction: column;
  margin: 0 24px;
}
.PublicTeamPanel__Name > :first-child {
  line-height: 12px;
  font-size: 12px;
  margin-bottom: 2px;
}
.PublicTeamPanel__Name > :last-child {
  font-family: 'Domine', serif;
  font-size: 16px;
  line-height: 16px;
}
.PublicTeamPanel__Collected {
  font-size: 24px;
  line-height: 24px;
  color: var(--primary-color);
  font-weight: bold;
}
@media screen and (max-width: 420px) {
  .PublicTeamPanel__Team [class*="fa-trophy"],
  .PublicTeamPanel__Team .fa-arrow-right {
    font-size: 2rem;
  }
  .PublicTeamPanel__Team .PublicTeamPanel__Name {
    margin: 0 12px;
  }
  .PublicTeamPanel__Team .PublicTeamPanel__Collected {
    margin-right: 12px;
  }
  .PublicTeamPanel__Team .PublicTeamPanel__Carousel {
    padding: 4px 0;
  }
}
