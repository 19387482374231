.CurrentCampaignDropdown {
    display: flex !important;
    align-items: center;
    cursor: pointer;
    color: #fff;

    .ant-skeleton {
      opacity: .1;
    }

    .ant-skeleton-paragraph {
      margin-bottom: 1px;
    }

    .current-campaign-avatar-wrap {
      width: 50px;
      height: 50px;
      min-width: 50px;
      border-radius: 50%;
      margin-right: 1rem !important;
    }

    .current-campaign-avatar {
      margin: 0 !important;
      max-width: 100%;
      min-width: 50px;
      width: 50px;
      min-width: 50px;
      height: auto;
    }

    .current-campaign-text {
      overflow: hidden;

      strong {
        font-size: 1.8rem;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }

      .current-campaign-subtitle {
        color: #fff;
        text-transform: initial;
        font-size: 1.6rem;
        font-weight: 300;
        margin-bottom: 0;
      }
    }

    .fa-caret-down {
      margin-right: 0;
      margin-left: auto;
    }
}

.CurrentCampaignDropdown__Menu {
  max-width:375px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #DDDFDF;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 12px;

  a {
    color: rgba(0, 0, 0, 0.85);
    padding: 8px;
  }

  .CurrentCampaignDropdown__EntityLinks {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: auto;

    a {
      transition: background-color .2s;
    }

    a:hover {
      background-color: #DDDDDD;
    }
  }

  .CurrentCampaignDropdown__AdditionalLinks {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    border-top: 3px solid #F5F7F7;

    a {
      padding-left: 12px;
      line-height: 40px;
      transition: color .3s;

      &:hover {
        color: #eb3052;
      }
    }

    svg {
      margin-right: 10px;
    }
  }
}
