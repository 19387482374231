.DashboardPage {
  display: inline;

  &__Content {
    padding: 0 4rem 3rem;
    background-color: #F4F7F7;
  }
  &__Content_Actions {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;

    button{
      margin-bottom: 1rem;
    }
    button:not(:last-of-type) {
      margin-right: 1rem;
    }

    button a {
      color: #fff !important;
      font-weight: bold;
    }
  }

  .WinkNotification {
    padding-bottom: 0 !important;
  }
}
