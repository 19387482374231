@import (reference) "../../assets/stylesheets/main";

.CreateInformationForm {
  padding-top: @padding-lg;
  max-width: 680px;

  .TitleField {
    margin: 0;
  }

  &__Divider {
    margin-left: -10px;
    margin-right: -10px;

    .CreateInformationForm__Field {
      flex: 1;
      margin: 0 10px;
    }
  }

  &__Field {
    display: flex;
    margin: 0;

    > div {
      flex: 1;
    }

    .ant-select-selection {
      height: 56px;

      &__rendered {
        height: 56px;
      }

      &-selected-value {
        line-height: 56px;
      }
    }

    .ant-form-item-children {
      display: block;
    }

    input {
      height: 56px;
    }
  }

  .FundkyCard {
    margin-top: 2.7rem;
  }

  .WinkNotification__Inner_Content {
    p > p {
      margin-bottom: 0;
    }
    .WinkTooltip__CTA {
      margin-right: 1em;
      margin-top: 1em;
    }
  }

}

.CreateInformationForm__Checkboxes {

  .ant-form-item-control.has-feedback label > span:not(.ant-checkbox) {
    padding-right: 30px;
  }
  span.ant-form-item-children {
    display: block;
  }
}

.CreateInformationForm__SubmitWrapper {
  display: flex;
  justify-content: flex-start;
  padding-top: @padding-lg * 3;

  .ant-btn {
    height: 40px;
    font-weight: 400;
  }

  .Back-btn {
    color: #fff;
    background-color: #5f697a;
    height: 40.5px;
    margin-right: 10px;

    transition: background-color 1s;

    &:hover {
      border-color: unset;
      background-color: rgba(0, 0, 0, 0.65);
      transition: background-color 1s;
    }
  }
}

.FundkyModal--content {
  .AddressUnit,
  .CityProvince,
  .OrgNameTitle {
    display: block;
    margin-bottom: 0;

    > div {
      margin-bottom: 2.4rem;
    }
  }

  .ant-form-explain {
    position: absolute;
  }
}

@media screen and (max-width: 600px) {
  .CreateInformationForm{
    padding-right:1em;
  }
}

@media screen and (max-width: 360px) {
  .CreateInformationForm {
      &__Divider {
        margin-right:0;
        margin-left:0;
      }
    .OrgNameTitle,
    .FirstNameLastName,
    .ZipCodeCountry,
    .EmailLanguage {
      flex-direction: column;
      margin-bottom: 0;

      > div {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 2.4rem;
      }
    }
  }
}

