@import (reference) "../../assets/stylesheets/main";

.DonationPage {
  .DonationPage__Header {
    padding: @padding-lg 0;
    margin-bottom: 1.4rem;
    display: flex;
    align-items: center;
  }

  .ant-tabs {
    background-color: @body-background;
  }

  .ant-tabs-bar {
    background-color: @hq-admin-background-bar;
    
    margin-bottom: 0; // TODO Maybe we can do this globally

    i, svg {
      color: var(--link-color);
    }
  }

  .ant-tabs-tab {
    padding-left: 40px;
    padding-right: 40px;
  }

  .ant-tabs-tabpane{
    width:100vw;
  }

  &__ChangeRecipientBtn {
    font-size: 30px;
    color: var(--primary-color);
    margin-left: 30px;
    margin-top: 9px;

    svg {
      margin: 0;
    }

    &:hover {
      color: @primary-hover-color;
    }
  }

  [class*="CancelWrapper"] {
    margin-bottom: 80px;

    a, > span {
      color: var(--primary-color);
      font-size: .8em;
      font-weight: 600;
      letter-spacing: .12rem;
      text-transform: uppercase;
      cursor: pointer;

      .ant-popover-message-title {
        text-transform: initial;
      }
    }
  }

  @media screen and (max-width: 360px) {
    .TypeField > div > div > span {
      display: block;
      position: relative;
    }

    .ant-radio-group {
      display: flex;
      flex-direction: column;

      > label {
        margin-right: 0;
      }
    }
  }
}
