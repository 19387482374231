.RegisterDropdown_menu {
  .ant-dropdown-menu-item {
    cursor: default;
  }

  a[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .5;
  }
}