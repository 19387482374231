.SocialSharing {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 2.4rem 0;
}
.SocialSharing__Title {
  margin-bottom: 0.8rem;
  text-transform: uppercase;
}
.SocialSharing__Wrap {
  display: flex;
  justify-content: center;
}
.SocialSharing a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  color: #fff;
  width: 40px;
  height: 40px;
}
.SocialSharing a:first-of-type {
  border-radius: 4px 0 0 4px;
}
.SocialSharing a:last-of-type {
  border-radius: 0 4px 4px 0;
}
.SocialSharing__Copy {
  background-color: #63C1C6;
  transition: background-color 0.2s ease-in-out;
}
.SocialSharing__Copy:hover {
  background-color: #4db8be;
}
.SocialSharing .hiddenInput {
  position: absolute;
  left: -99999px;
}
.ant-message {
  z-index: 1060;
}
.WinkNotification .SocialSharing {
  align-items: flex-start;
}
