@import (reference) "../assets/stylesheets/main";
@import (reference) "../common/PublicCarousel";

@icon-size: @public-carousel-side-controls-width;

.PublicParticipantPanel__Carousel {
  padding: 0 @public-carousel-side-controls-width + @padding-md;

  ul.slider-list{
    min-width: 50%;
  }
}

.PublicParticipantPanel__Page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: auto;

  a {
    display: block;
    transition: background-color 0.3s ease;
    border-radius: 4px;

    &:hover {
      background-color: #F5F7F7;
    }
  }

  .PublicParticipantPanel__Participant {
    padding: @padding-sm;
    color: @text-color;

    .AvatarThumbnail__PrimaryContent {
      font-size: @font-size-xs;
      line-height: @font-size-xs + 1;

      svg {
        font-size: @font-size-xs;
        color: var(--primary-color);
      }
    }

    .AvatarThumbnail__SecondaryContent {
      font-weight: bold;
    }
  }

  .AvatarThumbnail__PrimaryContent {
    font-size: @font-size-xs;
    color: @text-color;
    line-height: @font-size-xs + 1;

    i, svg {
      font-size: @font-size-xs;
      color: var(--primary-color);
    }
  }

  .AvatarThumbnail__SecondaryContent {
    font-weight: bold;
    color: @text-color;
  }
}


.PublicParticipantPanel__Name {
  display: flex;
  flex-direction: column;
  margin: 0 @padding-lg;

  > :first-child {
    line-height: 12px;
    font-size: 12px;
  }

  > :last-child {
    color: @heading-color;
    font-family: @font-family-title;
    font-size: @font-size-base;
    line-height: @font-size-base;
  }
}

.PublicParticipantPanel__Collected {
  font-size: @font-size-lg;
  line-height: @font-size-lg;;
  color: var(--primary-color);
  font-weight: bold;
}
