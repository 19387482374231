@import "../abstracts/_mixins";
@import "../abstracts/_variables";
@import "../themes/_fundky";

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
}

h1 {
  font-family: @font-family-title;
  font-weight: bold;
}

.ant-btn {
  .button-font();
  font-size: 14px; // TODO PR Antd to add @btn-font-size-base ?


  &.ant-btn-primary:hover {
    // Antd makes the hover buttons lighter (@primary-5), we want them darker
    background-color: @primary-7;
    border-color: @primary-7;
  }
}

.Global__Ordinal {
  vertical-align: super;
  font-size: @font-size-xs;
}
