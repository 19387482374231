.StaticBreadcrumbs ul {
  margin: 0 0 20px;
  padding: 0;
  list-style-type: none;
}
.StaticBreadcrumbs li {
  display: inline;
  font-size: 12px;
}
.StaticBreadcrumbs li a {
  color: #AEADAE;
}
.StaticBreadcrumbs li svg {
  color: var(--link-color);
  margin: 0 7px;
}
.StaticBreadcrumbs li:last-of-type a {
  color: #424749;
}
