.PublicCarousel--SideControls {
  padding: 0 28px;
}
.PublicCarousel--SideControls .slider-control-centerleft a,
.PublicCarousel--SideControls .slider-control-centerright a {
  position: absolute;
  transform: translateY(-50%);
  font-size: 28px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}
.PublicCarousel--SideControls .slider-control-centerleft a.disabled,
.PublicCarousel--SideControls .slider-control-centerright a.disabled {
  color: #999999 !important;
  cursor: initial;
}
.PublicCarousel--SideControls .slider-control-centerleft a {
  left: -28px;
}
.PublicCarousel--SideControls .slider-control-centerright a {
  right: -28px;
}
.PublicCarousel .slider-list {
  display: flex !important;
}
.PublicCarousel .slider {
  outline: none;
}
.spinner-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem;
}
