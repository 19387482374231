.AdminFormWrap {
  display: flex;
  width: 100%;
}
.AdminFormWrap > form {
  width: 100%;
}
.AdminFormWrap .ant-form-item {
  flex-direction: column;
}
.AdminFormWrap .ant-form-item .ant-form-item-label {
  margin: 1rem 0;
}
.AdminFormWrap .SettingsColumn {
  flex: 0 0 270px;
}
.AdminFormWrap .SettingsColumn .SettingsBox {
  background-color: #fff;
  padding: 12px;
  margin: 20px 20px 0;
  border-radius: 4px;
}
.AdminFormWrap .SettingsColumn .SettingsBox:first-of-type {
  margin-top: 0;
}
.AdminFormWrap .SettingsColumn .SettingsBox h3 {
  text-transform: capitalize !important;
  border-bottom: 3px solid #f5f7f7;
  min-height: 35px;
}
.AdminFormWrap .SettingsColumn .SettingsBox__footer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.AdminFormWrap .SettingsColumn .SettingsBox__footer button:last-of-type {
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .AdminFormWrap {
    flex-direction: column-reverse;
  }
  .AdminFormWrap .SettingsColumn .SettingsBox {
    margin: 0 !important;
  }
  .AdminFormWrap .SettingsColumn {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
  .AdminFormWrap .SettingsColumn .SettingsBox {
    margin-bottom: 20px !important;
  }
  .AdminFormWrap .AdminContentLangSwitcher__wrap {
    flex-direction: row;
    margin: 0.75em 0 0 0;
  }
  .AdminFormWrap .AdminContentLangSwitcher__wrap label {
    margin-right: 1em;
  }
  .AdminFormWrap .AccessibilityBoxForm .ant-row {
    display: flex !important;
    flex-direction: row !important;
  }
}
@media only screen and (max-width: 1057px) {
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminFormWrap {
    flex-direction: column-reverse;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminFormWrap .SettingsColumn .SettingsBox {
    margin: 0 !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminFormWrap .SettingsColumn {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminFormWrap .SettingsColumn .SettingsBox {
    margin-bottom: 20px !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminFormWrap .AdminContentLangSwitcher__wrap {
    flex-direction: row;
    margin: 0.75em 0 0 0;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminFormWrap .AdminContentLangSwitcher__wrap label {
    margin-right: 1em;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .AdminFormWrap .AccessibilityBoxForm .ant-row {
    display: flex !important;
    flex-direction: row !important;
  }
}
@media screen and (max-width: 460px) {
  .AdminFormWrap .AdminContentLangSwitcher__wrap {
    flex-direction: column;
  }
}
@media screen and (min-width: 1057px) {
  #sticky-wrapper.enabled {
    position: fixed;
    top: 5em;
    overflow: auto;
    width: 270px;
    max-height: calc(100vh - 100px);
  }
}
@media only screen and (min-width: 767px) {
  aside.ant-layout-sider-collapsed + i + section.ant-layout #sticky-wrapper.enabled {
    position: fixed;
    top: 5em;
    overflow: auto;
    width: 270px;
    max-height: calc(100vh - 100px);
  }
}
@media screen and (min-width: 1100px) {
  #sticky-wrapper.enabled {
    width: 360px;
  }
  .AdminFormWrap .SettingsColumn {
    flex: 0 0 360px;
  }
}
@media screen and (min-width: 1400px) {
  #sticky-wrapper.enabled {
    width: 450px;
  }
  .AdminFormWrap .SettingsColumn {
    flex: 0 0 450px;
  }
}
