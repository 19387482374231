.HomePage h2 {
  font-size: 2em;
  font-family: 'Domine', serif;
  font-weight: bold;
}
.HomePage .HomePage__Carousel .slider {
  visibility: inherit !important;
}
.HomePage .HomePage__Carousel .slider,
.HomePage .HomePage__Carousel .slider-frame,
.HomePage .HomePage__Carousel .slider-list,
.HomePage .HomePage__Carousel .slider-slide {
  min-height: 1px;
  width: 100% !important;
}
.HomePage .HomePage__Carousel .slider-slide > img,
.HomePage .HomePage__Carousel .slider-slide > a,
.HomePage .HomePage__Carousel .slider-slide > a > img {
  width: 100%;
  display: block;
}
.HomePage .HomePage__Carousel .slider-slide img {
  min-width: 100% !important;
}
