@import (reference) "../../assets/stylesheets/main";
@import (reference) "../../common/PublicCarousel";

@icon-size: @public-carousel-side-controls-width;

.PublicTeamPanel__Carousel {
  padding: 4px @public-carousel-side-controls-width + @padding-md;

  a {
    display: block;
    transition: background-color 0.3s ease;
    border-radius: 4px;

    &:hover {
      background-color: #F5F7F7;
    }
  }
}

.PublicTeamPanel__Team .PublicTeamPanel__Divider svg {
    font-size: 28px;
    color: @text-color;
}

.PublicTeamPanel__Team:nth-child(1) .PublicTeamPanel__Information svg {
  color: @gold-color;
}

.PublicTeamPanel__Team:nth-child(2) .PublicTeamPanel__Information svg {
  color: @silver-color;
}

.PublicTeamPanel__Team:nth-child(3) .PublicTeamPanel__Information svg {
  color: @bronze-color;
}

.PublicTeamPanel__Participant {
  padding: @padding-sm;
  color: @text-color;

  .AvatarThumbnail__PrimaryContent {
    font-size: @font-size-xs;
    line-height: @font-size-xs + 1;

    svg {
      font-size: @font-size-xs;
      color: var(--primary-color);
    }
  }

  .AvatarThumbnail__SecondaryContent {
    font-weight: bold;
  }
}

.PublicTeamPanel__Divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: @padding-sm @padding-md;
  background-color: @layout-body-background;
  transition: background-color 0.3s ease;
  border-radius: @border-radius-base;
  color: rgba(0, 0, 0, 0.85);

  > svg {
    color: rgba(0, 0, 0, .25);
    font-size: 2em;
  }
}

a.PublicTeamPanel__Divider:hover {
  background-color: #333;
  > svg {
    color: rgba(255, 255, 255, .25);
  }

  color: #fff;

  .HelpText {
    color: #ccc;
  }
}

div.PublicTeamPanel__Divider .PublicTeamPanel__Name span:first-child{
  position: relative;
  top: 2px;
}

.PublicTeamPanel__Information {
  display: flex;
  align-items: center;
  position: relative;
}

.PublicTeamPanel__Position {
  font-family: @font-family-title;
  font-size: @font-size-base;
  line-height: @font-size-base;
  margin-left: 10px;
  font-weight: 600;
}

.PublicTeamPanel__Name {
  display: flex;
  flex-direction: column;
  margin: 0 @padding-lg;

  > :first-child {
    line-height: 12px;
    font-size: 12px;
    margin-bottom: 2px;
  }

  > :last-child {
    font-family: @font-family-title;
    font-size: @font-size-base;
    line-height: @font-size-base;
  }
}

.PublicTeamPanel__Collected {
  font-size: 24px;
  line-height: 24px;
  color: var(--primary-color);
  font-weight: bold;
}

@media screen and (max-width: 420px) {
  .PublicTeamPanel__Team {
    [class*="fa-trophy"],
    .fa-arrow-right {
      font-size: 2rem;
    }

    .PublicTeamPanel__Name {
      margin: 0 @padding-lg/2;
    }

    .PublicTeamPanel__Collected {
      margin-right: @padding-lg/2;
    }

    .PublicTeamPanel__Carousel {
      padding: 4px 0;
    }
  }
}
