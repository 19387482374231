.AdminBackButtonBar {
  padding: 3.4rem 4rem 0;
  background-color: #F4F7F7;

  .lnk-back {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    color: #828281;
    margin-bottom: 0;

    svg {
      color: #828281;
    }

    &:hover {
      color: #5CAFB5;
      svg {
        color: #5CAFB5;
      }
    }
  }
}
