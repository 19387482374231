@import "~antd/es/style/themes/default";

:root {
  --primary-color: #EB3052;

  --primary-color-darken: #D62D4C;
  --primary-hover-color: var(--primary-color-darken);

  --primary-shadow-color: rgba(235, 48, 82, .2);

  --secondary-color: #64BFC5;
  --default-color: var(--secondary-color);
  --link-color: var(--secondary-color);
  --input-hover-border-color: var(--secondary-color);
  --input-active-border-color: var(--secondary-color);
  --tabs-ink-bar-bg-color: var(--secondary-color);
  --tab-hover-color: var(--secondary-color);

  --secondary-color-darken: #5CAFB5;
  --default-hover-color: var(--secondary-color-darken);
  --info-color: var(--secondary-color-darken);
  --link-hover-color: var(--secondary-color-darken);

  --secondary-shadow-color: rgba(100, 191, 197, .2);
}

@primary-color: #EB3052;
@primary-hover-color: #D62D4C;
@default-color: #64BFC5;
@default-hover-color: #5CAFB5;
@cancel-color: #F5F7F7;
@cancel-hover-color: #E8E9E9;
@helper-color: #AB8DC2;
@helper-hover-color: #9A7EAF;

// FEEDBACK
@success-color: #64C579;
@warning-color: #E8CE1E;
@error-color: #F3704A;
@info-color: #5CAFB5;

// CTA
@link-color: #64BFC5;
@link-hover-color: #5CAFB5;
@link-active-color: #4D9A9F;

@primary-text-color: #333;
@secondary-text-color: #B2BEBE;
@tertiary-text-color: #C8D5D5;

@layout-header-background: #FFF;
@layout-body-background: #E9EAEB;

@input-background-color: #FFF;
@input-border-color: #DDDFDF;
@input-hover-border-color: #64BFC5;
@input-active-border-color: #64BFC5;

@body-background: #ECEDED;
@font-family: 'Assistant', sans-serif;
@font-size-base: 16px;
@font-size-sm: 14px;
@btn-font-size-lg: @font-size-sm;
@btn-font-size-sm: @font-size-sm;

@tabs-ink-bar-bg-color: @link-color;
@tab-hover-color: @link-color;

@admin-header-height: 78px;

@card-head-padding: 12px;
@card-inner-head-padding: 8px;
@card-padding-base: 16px;

@modal-mask-bg: rgba(0, 0, 0, 0.25);

@facebook-color: #3B5998;
@linkedin-color: #0077B5;
@twitter-color: #1DA1F2;
@pinterest-color: #BD081C;

@visa-color: #0157a2;
@mastercard-color: #0a3a82;
@amex-color: #007bc1;
@dicover-color: #f68121;

.cc-visa {
  color: @visa-color;
}

.cc-mastercard {
  color: @mastercard-color;
}

.cc-amex {
  color: @amex-color;
}

.cc-discover {
  color: @dicover-color;
}

.facebook-color {
  color: @facebook-color;
}

.linkedin-color {
  color: @linkedin-color;
}

.twitter-color {
  color: @twitter-color;
}

.pinterest-color {
  color: @pinterest-color;
}

.instagram-color {
  path {
    fill: url(#instagramGradient);
  }

  &--square {
    path {
      fill: url(#instagramGradient);
    }
  }

  &--icon {
    path {
      fill: white
    }
  }
}

.facebook-bg,
.linkedin-bg,
.twitter-bg {
  transition: background-color .2s ease-in-out;
}

.facebook-bg {
  background-color: @facebook-color;

  &:hover {
    background-color: darken(@facebook-color, 6%);
  }
}

.linkedin-bg {
  background-color: @linkedin-color;

  &:hover {
    background-color: darken(@linkedin-color, 6%);
  }
}

.twitter-bg {
  background-color: @twitter-color;

  &:hover {
    background-color: darken(@twitter-color, 6%);
  }
}

.pinterest-bg {
  background-color: @pinterest-color;
}

.instagram-bg {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.color-warning {
  color: @primary-color;
  color: var(--primary-color);
}

.fa-info-circle {
  color: #AC8EC3;
}

.ant-tooltip-inner {
  background-color: rgba(172, 142, 195, 0.85);
  white-space: pre-wrap;
  word-break: break-word;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight {
  border-top-color: rgba(172, 142, 195, 0.85);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: rgba(172, 142, 195, 0.85);
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  border-left-color: rgba(172, 142, 195, 0.85);
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  border-right-color: rgba(172, 142, 195, 0.85);
}

.ant-tooltip-arrow:before {
  background-color: rgba(172, 142, 195, 0.85);
}

main>section {
  min-height: 100%;
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 2000;
}

.overModal,
.ant-notification {
  z-index: 2001;
}

.overHeader {
  z-index: 1052;
}

// Texts coming from Quill WYSIWYG
.wysiwygStyle,
.wysiwygStyle .ql-snow .ql-editor {
  color: rgba(0, 0, 0, .85);

  h1,
  h2,
  h3 {
    font-family: Domine, serif;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: .5em;
  }

  h1 {
    font-size: 3.2rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  p {
    font-size: 1.6rem;
    margin-top: 0;
    margin-bottom: 1em;
  }

  a {
    color: @link-color;
    color: var(--link-color);
    text-decoration: none;
  }

  ol,
  ul {
    font-size: 1.6rem;
    margin-top: 0;
    margin-bottom: 1em;
  }

  li {
    display: list-item;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  sub {
    font-size: 1.2rem;
  }

  sup {
    font-size: 1.2rem;
  }

  .ql-size-small {
    font-size: 1.4rem;
  }

  .ql-size-large {
    font-size: 1.8rem;
  }

  .ql-size-huge {
    font-size: 2rem;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  iframe.ql-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    max-width: 100%;
  }
}

// Hide reCAPTCHA badge
.grecaptcha-badge {
  display: none !important;
  visibility: hidden !important; }
.grecaptcha-badge.show {
  display: block !important;
  visibility: visible !important;
  z-index: 2001;
}
