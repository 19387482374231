@import (reference) "../../assets/stylesheets/main";

@import '~perfect-scrollbar/css/perfect-scrollbar.css';


#navLogo {
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: 24px;
  margin-bottom: 24px;
  transform: translateX(-50%);
  z-index: 3;

  &:hover {
    background-color: inherit;
  }
}

.AdminSider+i+section.ant-layout {
  padding-left: 290px;
  transition: padding 0.2s;
  width: 100%;
}

.AdminSider.ant-layout-sider-collapsed+i+section.ant-layout {
  padding-left: 0px;
}

.AdminSider {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;

  z-index: 1040;

  .WrapSiders {
    min-height: 100%;

    >div {
      padding: 10rem 20px @padding-lg @padding-md;
      background-color: @hq-admin-color-bg;
      min-height: 100vh;

      // Zig zag right border in pure CSS
      &:after {
        position: fixed;
        top: 0;
        left: 289px;
        z-index: 2;
        background-color: @hq-admin-color-bg;
        -webkit-mask: url("../../assets/images/menu_border.svg");
        -moz-mask: url("../../assets/images/menu_border.svg");
        -o-mask: url("../../assets/images/menu_border.svg");
        mask: url("../../assets/images/menu_border.svg");
        background-repeat: repeat-y;
        background-position: right top;
        background-size: 14px 14px;
        content: "";
        width: 15px;
        height: 100%;
        -webkit-transition: left 0.2s;
        /* Safari prior 6.1 */
        transition: left 0.2s;
      }
    }

    .SettingsSider,
    .PluginsSider {
      background-color: #5280AB;

      &:after {
        background-color: #5280AB;
      }

      .AdminSider__GroupItem--active,
      a:hover {
        background-color: #4E7AA2 !important;
      }
    }
  }
}

.AdminLayout .ant-layout-sider-children {
  position: relative;
}

//perfect-scrollbar
.ps {
  height: 100vh;
  position: relative;
}

// Make the ZigZag follow the menu
.AdminSider.ant-layout-sider-collapsed {
  &+#mainmenu_trigger {
    left: 0;
  }

  .WrapSiders>div:after {
    left: -13px;
  }
}

.AdminSider .ant-layout-sider-children {
  .AdminSider__GroupTitle {
    color: #FFFFFF;
    padding-left: @padding-md;
    margin-top: @padding-xs;
    margin-bottom: @padding-md;
    display: block;
    text-transform: uppercase !important;

    .fa-long-arrow-left {
      margin-right: 1rem;
    }
  }

  .AdminSider__GroupItem {
    display: flex;
    align-items: center;
    background: transparent;
    margin-bottom: 1px;
    height: @button-xl;
    border: none;
    text-transform: initial;
    box-shadow: none;
    font-size: 1.6rem;
    letter-spacing: normal;
    text-align: left;

    &:hover {
      background-color: @hq-admin-color-hover;
    }

    &.AdminSider__GroupItem--active {
      background-color: @hq-admin-color-active  !important;
    }

    .svg-inline--fa {
      flex: 0 0 auto;
      font-size: 2.2rem;
      margin-right: @padding-lg;
    }
  }

  .SettingsSider,
  .PluginsSider {
    background-color: #5280AB;

    &:after {
      background-color: #5280AB;
    }

    .AdminSider__GroupItem--active,
    a:hover {
      background-color: #4E7AA2 !important;
    }
  }
}

.slide-enter {
  left:~"calc(-100% - @{padding-md})";
}

.slide-enter.slide-enter-active {
  left: 0;
  transition: all .5s;
}

.slide-enter-done {
  left: 0;
}

.slide-exit {
  left: 0;
}

.slide-exit.slide-exit-active {
  left:~"calc(-100% - @{padding-md})";
  transition: all .5s;
}

#mainmenu_trigger {
  position: fixed;
  left: 290px;
  padding: 0.7em;
  font-size: 24px;
  -webkit-transition: left 0.2s;
  /* Safari prior 6.1 */
  transition: left 0.2s;
  background: #fff;
  z-index: 2000;
}


.AdminSider.ant-layout-sider-collapsed+i+section.ant-layout {
  display: block !important;
  overflow: auto;
}


@media only screen and (min-width: 1200px) {
  #mainmenu_trigger {
    display: none;
  }

  .AdminSider {
    flex: 0 0 290px !important;
    max-width: 290px !important;
    min-width: 290px !important;
    width: 290px !important;
  }

  .AdminSider .WrapSiders>div:after {
    left: 290px;
  }

  .AdminSider+i+section.ant-layout {
    padding-left: 290px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .AdminSider:not(.ant-layout-sider-collapsed)+#mainmenu_trigger {
    left: 235px;
    color: #fff;
    background: none;
  }

  .AdminSider.ant-layout-sider-collapsed+i+section.ant-layout #headerFix,
  .AdminSider.ant-layout-sider-collapsed+i+section.ant-layout #headerFix .ant-affix {
    width: 100% !important;
  }

  .AdminSider .ant-layout-sider-children .AdminSider__GroupItem {
    height: 42px;
  }

  #navLogo {
    margin: 15px 0;
  }

  .AdminSider .WrapSiders>div {
    padding-top: 8rem;
  }

  .QuickLinks .QuickLinks__title {
    margin: 20px 0;
  }

  .QuickLinks a {
    font-size: 1.5rem !important;
    margin-bottom: .5em;
  }

}

//Put content under menu and disable scroll on content if menu is open
@media only screen and (max-width: 585px) {
  .AdminSider+#mainmenu_trigger+section.ant-layout {
    &:after {
      content: "";
      background: #000;
      opacity: 0;
      height: 0;
      width: 100vw;
      position: absolute;
      -webkit-transition: opacity 0.2s;
      /* Safari prior 6.1 */
      transition: opacity 0.2s;
    }
  }

  .AdminSider+i+section.ant-layout {
    padding-left: 0;
  }

  .AdminSider:not(.ant-layout-sider-collapsed)+#mainmenu_trigger+section.ant-layout {
    overflow: hidden !important;
    height: 100vh;
  }

  .AdminSider:not(.ant-layout-sider-collapsed)+#mainmenu_trigger+section.ant-layout:after {
    opacity: 0.3;
    height: 100vh;
    z-index: 1030;
  }
}

//Make menu smaller to fit on small screens
@media only screen and (max-width: 359px) {
  .AdminSider:not(.ant-layout-sider-collapsed) {
    flex: 0 0 265px !important;
    max-width: 265px !important;
    min-width: 265px !important;
    width: 265px !important;
  }

  .AdminSider .WrapSiders>div:after {
    left: 265px;
  }

  .AdminSider:not(.ant-layout-sider-collapsed)+#mainmenu_trigger {
    left: 224px;
  }
}

.AdminSider {

  /*ScrollBar Overwrite custom*/
  .ps__rail-y {
    padding: 0 !important;
  }

  .ps__rail-y:hover>.ps__thumb-y,
  .ps__rail-y:focus>.ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: darken(@hq-admin-color-bg, 5%);
  }

  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: transparent;
  }

  .ps__thumb-y {
    background-color: darken(@hq-admin-color-bg, 5%);
  }

  .ps__rail-y {
    margin-right: 3px;
  }

  .ps__rail-x {
    display: none !important;
  }
}
