@import (reference) '../assets/stylesheets/main.less';

.AdminLayout {
  width: 100%;
  min-height: 100%;
  background: none;

  .ant-layout {
    overflow-x: initial !important;
    background: none;
  }

  .iconButton {
    font-size: 1.2em;
    margin: 0 0.5em;
  }

  .WinkNotification {
    background-color: #f4f7f7;
    padding: 4rem;
    margin-bottom: 0;
  }

  .ant-layout-header {
    line-height: normal;
  }

  .PlatformSwitcher {
    label {
      display: block;
    }
  }

  .ant-btn:not(.ant-btn-link) {
    //responsive buttons
    padding: 0.5em 1em;
    line-height: 1em;
    white-space: normal;
    height: auto;

    &.FundkyButton__Type--text {
      padding: 0.5em 0;
    }
  }

  .ant-layout-content {
    background: none;
    min-height: auto;

    .ant-tabs-bar {
      padding-left: 4rem;
      padding-right: 4rem;
      margin: 0;
      background-color: #f4f7f7;
    }

    .AdminList,
    .ant-tabs-tabpane {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
      background-color: #eceded;
    }

    .ant-tabs-tabpane .AdminList {
      padding: 0 !important; //prevent having double the padding
    }

    h3 {
      font-size: 1.6rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .AdminList {
      .PaginationTop {
        .ant-select-selection {
          height: 32px;

          .ant-select-selection__rendered {
            line-height: 30px;
          }

          .ant-select-arrow {
            font-size: 14px;
            margin-top: -6px;
          }
        }
      }

      .buttons button {
        background-color: transparent !important;
        border-color: transparent !important;
        padding: 0 !important;

        &:hover,
        &:focus {
          background-color: #fff !important;
          border-color: #fff !important;
        }
      }
    }

    .AdminPanel,
    .DashboardPage__Content {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
      background-color: #f4f7f7;
      padding-bottom: 3rem;
      height: 100%;
    }
  }

  .ant-layout-sider-children {
    overflow: hidden;
  }

  .ant-collapse {
    background: none;
  }

  .ant-collapse-item {
    background: #fff;
    border: none;
    margin-bottom: 0.5rem;

    .ant-collapse-header {
      border-bottom: 0px solid #f5f7f7;
      padding: 1.5rem !important;
      font-weight: bold;
      padding: 0;

      .ant-collapse-arrow {
        left: inherit !important;
        right: 1.6rem;
        top: 50% !important;
        transform: translateY(-50%) !important;
        color: #eb3052;
      }
    }

    .ant-collapse-content-active {
      margin-top: 1.5rem;
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      border-bottom: 3px solid #f5f7f7;

      .arrow {
        display: none !important;
      }
    }
  }

  .CancelAndSaveButtons__wrap {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
  }

  .btn-cancel {
    color: #eb3052;
    margin-right: 1rem;
  }

  .btn-submit-wrap {
    margin-bottom: 0 !important;
    padding-bottom: 0;

    .ant-form-item-control {
      line-height: inherit;
    }
  }

  .btn-submit {
    height: 3.2rem;
    font-weight: bold;

    svg {
      margin-left: 0.4rem;
    }
  }

  .ant-tabs-tabpane {
    padding: 5.4rem 2rem 3rem;
  }

  .ant-input,
  .ant-input-number,
  .ant-input-number-input,
  .ant-time-picker-input,
  .ant-select-selection:not(.ant-select-selection--multiple) {
    height: 4.2rem;
    text-overflow: ellipsis;
  }

  .ant-select-selection--multiple {
    min-height: 4.2rem;
    padding-top: 0.3rem;

    .ant-select-arrow {
      top: 50%;
    }

    .ant-select-selection__rendered {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      .ant-select-selection__choice {
        margin: 0.2rem;
      }
    }
  }

  .ant-select-selection__rendered {
    line-height: 4.2rem;
  }

  .ant-form-item-children {
    display: block;
  }

  label {
    &.small {
      font-size: 1.4rem;
    }
  }

  .fa-pencil-alt {
    margin-right: 0;
    color: #ea3152;
    cursor: pointer;
    font-size: 0.8em;
  }

  .ant-form-horizontal {
    .ant-form-item {
      display: flex;

      .ant-form-item-label {
        flex: 1.3;
        text-align: left;
        white-space: pre-wrap;
        line-height: normal;
        align-self: flex-start;
        margin-top: 1rem;
      }

      .ant-form-item-control-wrapper {
        display: flex;
        align-items: center;
        flex: 3;
        line-height: normal;

        .ant-form-item-control {
          width: 100%;
        }
      }
    }
  }

  .ant-form-item-label label p {
    margin-bottom: 0;
  }

  .ant-table-thead>tr>th .ant-table-header-column {
    display: block;
  }

  .ant-form-explain,
  .ant-form-extra {
    margin-bottom: 0.8em !important;
    margin-top: 0.1em !important;
  }

  @media only screen and (max-width: 1200px) {

    .ant-layout-content .AdminList,
    .ant-layout-content .ant-tabs-tabpane,
    .AdminTitleBar,
    .SearchBar form,
    .WinkNotification,
    .ant-layout-content .DashboardPage__Content,
    .ant-layout-content .AdminPanel,
    .ant-layout-content .ant-tabs-bar,
    .AdminBackButtonBar {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
  }

  @mobile-ruleset: {
    .ant-row.ant-form-item:not(.SocialMediaField) {
      display: block !important;
    }
  }

  ;

  // When smaller then 600
  @media only screen and (max-width: 600px) {
    @mobile-ruleset();
  }

  //When the content area is smaller than 600 (menu open)
  @media only screen and (max-width: 890px) {
    aside:not(.ant-layout-sider-collapsed)+i+section.ant-layout {
      @mobile-ruleset();
    }
  }

  @media only screen and (max-width: 400px) {
    #mainmenu_trigger {
      padding-left: 0.4em;
      padding-right: 0.4em;
    }

    .ant-layout-content .AdminList,
    .ant-layout-content .ant-tabs-tabpane,
    .AdminTitleBar,
    .SearchBar form,
    .WinkNotification,
    .ant-layout-content .DashboardPage__Content,
    .ant-layout-content .AdminPanel,
    .ant-layout-content .ant-tabs-bar,
    .AdminBackButtonBar {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
}

#WePay-tags {
  position: fixed !important;
}

.ant-btn-circle:hover,
.ant-btn-circle:focus {
  background: transparent !important;

  svg {
    position: relative;
    z-index: 12;
  }

  &:after {
    content: '';
    height: 35px;
    width: 35px;
    position: absolute;
    background: white;
    border-radius: 35px;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}
