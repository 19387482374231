.CampaignAdminPageForm {
  width: 100%;

  .AvatarThumbnail {
    &__PrimaryContent {
      font-size: .8em;
      text-transform: uppercase;
    }

    &__SecondaryContent {
      font-size: 14px;
      line-height: 15px;
      font-weight: 700;
    }
  }

  &__Approval {
    > div {
      margin-top: 1em;
    }

    button:first-of-type {
      margin-right: 1em;
    }
  }

  .MoneyField--campaign_goal {
    .ant-form-item-children-icon {
      left: 120px;
    }
  }
}
