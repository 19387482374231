.TeamField {
  &__TeamIcon {
    margin-right: 10px;
    color: var(--link-color);
  }
}

form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow {
    right: 28px;
}
