@import (reference) "../assets/stylesheets/main";

.SearchBar {
  background-color: @hq-admin-background-bar;
  padding-top: 3.4rem;
  padding-bottom: 3rem;

  form {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  &.SearchBar--compact {
    background-color: transparent;
  }

  .ant-form-item {
    // Delete the space allocated to the validation message (no validation for search filters)
    margin-bottom: 0;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.45);
  }

  .extraContent{
    padding:0 4rem;
  }
}

.SearchBar__Divider {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // Reset the margin-bottom of the h1 and add it to the divider, so that vertical alignment is preserved
  margin-bottom: 1em;

  .AdminTitleBar {
    padding-top: 0;
    padding-bottom: 0;
  }

  h1, h2 {
    margin-bottom: 0;
  }

  > span a {
    margin-left: 20px;
  }
}

.SearchBar__ToggleFilterBar {
  cursor: pointer;

  &.SearchBar__ToggleFilterBar--open {
    color: var(--primary-color);
  }
}

.SearchBar__InputPrefixIcon {
  color: var(--primary-color);
}

.SearchBar__FilterBar {
  padding: @padding-sm @padding-sm 0 @padding-sm;

  h2 {
    font-size: 18px;
    padding-bottom: @padding-sm;
    border-bottom: 3px solid #CCCCCC;
  }

  .ant-calendar-picker-icon:after {
    color: var(--primary-color);
  }

  .ant-form-item-children {
    height: 100%;
    display: inline-block;
    &:after {
      margin-top: -8px;
      top: unset !important;
      bottom: 10px;
    }
  }

  .ant-calendar-picker-input {
    display: flex;
    justify-content: flex-start;

    .ant-calendar-picker-icon {
      order: 1;
      position: relative;
      right: 0;
    }

    .ant-calendar-range-picker-separator {
      order: 3;
    }

    .ant-calendar-range-picker-input {
      &:first-of-type {
        order: 2;
      }

      &:last-of-type {
        order: 4;
      }
    }

  }

  .ant-form-item {
    display: flex;

    .ant-form-item-control-wrapper {
      display: flex;
      align-items: center;
      flex: 3;
      line-height: normal;
    }
    .ant-form-item-label {
      flex: 1.3;
      text-align: left;
      white-space: pre-wrap;
      line-height: normal;
      align-self: flex-start;
      margin-top: 1rem;
    }
    .ant-form-item-control {
      width: 100%;
    }
  }

}

.SearchBar__FilterBarControls {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  button {
    margin-left: 6px;
    i, svg {
      margin-left: .5em;
    }
  }
}

@media screen and (max-width: 700px) {
  @mobile-ruleset();
}

//When the content area is smaller than 700 (700 + 290)
@media only screen and (max-width: 990px) {
  aside:not(.ant-layout-sider-collapsed)+i+section.ant-layout {
    @mobile-ruleset();
  }
}


@mobile-ruleset: {
  .SearchBar__Divider h1{
    font-size: 1.65em;
  }
  .AdminTitleBar--buttons{
    margin-top: 1.25em;
    .ant-btn{
      width: 2.75em;
      height: 2.75em !important;
      display: flex;
      align-items: center;
      padding: 0 !important;
      text-align: center;
      justify-content: center;
    }
  }
  .AdminTitleBar--buttons svg{
    margin-left:0;
  }
  .AdminTitleBar--buttons .ant-btn {
    span:first-child{
      display:none;
    }
  }
  .CsvReportButton svg{
    font-size: 1.5em;
    position: relative;
    top: 2px;
  }
}
