.TextField input.counterOn {
  padding-right: 30px;
}
.TextField .TextField_Counter {
  position: absolute;
  color: #BFBFBF;
  right: 0;
  top: 50%;
  line-height: 0;
  text-align: center;
  width: 35px;
}
.TextField input.counterOn {
  padding-right: 50px;
}
.TextField .has-feedback.has-error .TextField_Counter,
.TextField .has-feedback.has-success .TextField_Counter {
  right: 20px;
}
