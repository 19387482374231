.featuredCampaignItem {

  .FeatureCampaign__Tip {
    margin: 3rem 0 .3rem 0;
  }

  .Draggable_Wrap {
    display: flex;
    flex-direction: column;
  }

  .Draggable_Item {
    display: flex;
    align-items: center;
    height: 70px;
    background-color: #fff;
    margin: .5rem 0;
    padding: 1rem;
    border: 2px solid transparent;
    transition: border .2s ease-in-out;

    &:hover,
    &:active {
      border: 2px solid var(--link-color);
    }

    &--Order {
      font-weight: bold;
      margin-right: 1rem;
    }

    &--Banner {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 1rem;
      object-fit: cover; //doesn't work for IE 11
    }

    &--Title {
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      white-space: nowrap;
    }
  }

  .featureCampaigns_disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:after {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 100;
    }
  }
}
