.PublicTitleDropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.PublicTitleDropdown h1 {
  line-height: normal;
  margin-bottom: 0;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.PublicTitleDropdown i {
  font-size: 16px;
  vertical-align: middle;
}
.PublicTitleDropdown__Menu {
  display: flex;
  max-width: 375px;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #DDDFDF;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 12px;
}
.PublicTitleDropdown__Menu .HelpText {
  margin: 12px;
}
.PublicTitleDropdown__Menu a {
  color: rgba(0, 0, 0, 0.85);
  padding: 8px;
}
.PublicTitleDropdown__EntityLinks {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
}
.PublicTitleDropdown__EntityLinks .AvatarThumbnail__Content {
  font-family: 'Domine', serif;
  font-weight: bold;
}
.PublicTitleDropdown__EntityLinks a:hover {
  background-color: #DDDDDD;
}
.PublicTitleDropdown__EntityLinks img {
  border-radius: 100%;
  margin: 0 12px;
  height: 100%;
}
.PublicTitleDropdown__AdditionalLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
  border-top: 3px solid #F5F7F7;
}
.PublicTitleDropdown__AdditionalLinks a {
  padding-left: 12px;
  line-height: 40px;
  transition: color 0.3s;
}
.PublicTitleDropdown__AdditionalLinks a:hover {
  color: var(--primary-color);
}
.PublicTitleDropdown__AdditionalLinks svg {
  color: var(--link-color);
  margin-right: 10px;
}
