@import '~react-image-crop/dist/ReactCrop.css';
.UploadCrop__Field {
  display: flex;
  position: relative;
}
.UploadCrop__Field .ant-upload-select-picture-card {
  float: left;
  box-sizing: border-box;
  width: 272px;
  height: 128px;
  background-color: #fff;
  border: 2px solid #dddfdf;
  border-radius: 4px;
  transition: all 0.3s;
}
.UploadCrop__Field .UploadButton__Icon {
  font-size: 36px;
  color: var(--link-color);
}
.UploadCrop__Field .ant-upload-disabled .Upload__Preview {
  opacity: 0.5;
}
.ant-modal .UploadCrop__Field .ant-upload {
  width: 100%;
}
.Upload__Preview {
  width: 100%;
  height: auto;
}
.Upload__Error {
  color: #F3704A;
  margin-bottom: 0;
  line-height: 20px;
}
.ReactCrop {
  max-width: inherit;
  background-color: inherit;
}
