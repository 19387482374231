@import (reference) "../../assets/stylesheets/main";

.CreatePaymentForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: @padding-lg;

  &__Form {
    margin-right: 4%;
    flex: 0 0 70%;
  }

  .WinkTooltip__Cc {
    font-size: 2.5em;
    line-height: 1em;
    white-space: nowrap;
    svg {
      margin-right: 9px;
    }
  }

  .CreatePaymentForm__Field {
    display: flex;

    > div {
      flex: 1;
    }

    input {
      height: 56px;
    }

    .ant-select-selection {
      height: 56px;

      &__rendered {
        height: 56px;
      }

      &-selected-value {
        line-height: 56px;
      }
    }

    .ant-form-item-children {
      display: block;
    }

    .ant-form-item-control {
      line-height: unset;
    }

    &.CreatePaymentForm__Field--cvv {
      .ant-form-item-children {
        display: flex;
        align-items: center;
      }
    }
  }

  &__Divider {
    display: flex;
    justify-content: space-between;

    .CreatePaymentForm__Field {
      &--full {
        width: 100%;
      }

      &--half {
        width: 50%;
      }

      &--third {
        width: ~"calc(100% / 3)";
        max-width: ~"calc(100% / 3 - 10px)";
      }

      &--twoThird {
        width: ~"calc(100% / 3 * 2)";
      }

      &--sixth {
        width: ~"calc(100% / 6)";
      }

      margin-left: 10px;
      margin-right: 10px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }

  }

  .CreatePaymentForm__Field--Short > div {
    // For non full-screen fields: reset the flex: 1;
    flex: initial;
  }

  &__ExpirationFields {
    display: flex !important;
    justify-content: space-between;
    align-items: flex-start;

    > span {
      display: block;
      min-width: 20px;
      text-align: center;
      margin-bottom: 24px;
      line-height: 56px;
    }
  }

  .StripeElement {
    margin-bottom: 20px;
    padding: 18px 11px;
    background-color: #fff;
    height: 56px;
    max-height: 56px;
    border-radius: 4px;
    border: 1px solid #dddfdf;
  }

  @media screen and (max-width: 1230px) and (min-width: 801px) {
    .CardNumberDateCvv {
      flex-direction: column;

      > div {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 920px) and (min-width: 801px) {
    .ZipCodeCountry {
      flex-direction: column;

      > div {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;

    &__Form {
      margin-right: 0;
    }

    &__HelpBox {
      margin-bottom: 3rem;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 480px) {
    .CardNumberDateCvv {
      flex-direction: column;

      > div {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .CreatePaymentForm {
    padding-right:1em;
  }
}

.CreatePaymentForm__SubmitWrapper {
  display: flex;
  justify-content: flex-start;

  .ant-btn {
    display: flex;
    align-items: center;
    height: 40px;
    font-weight: 400;
  }

  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    // Custom circle progress bar, so we hide the default antd spinner (and reset its allocated space)
    padding: @btn-padding-base;

    i {
      display: none;
    }
  }

  .CircularProgressbar {
    height: @input-height-lg - @padding-xs;
    width: @input-height-lg - @padding-xs;
    margin-right: @padding-xs;
  }

  .CircularProgressbar-trail {
    stroke: @primary-3;
  }

  .CircularProgressbar-path {
    stroke: #FFFFFF;
  }

  .Back-btn {
    color: #fff;
    background-color: #5f697a;
    height: 40.5px;
    margin-right: 10px;

    transition: background-color 1s;

    &:hover {
      border-color: unset;
      background-color: rgba(0, 0, 0, 0.65);
      transition: background-color 1s;
    }
  }
}
