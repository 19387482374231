@import (reference) "../../assets/stylesheets/main";
@import (reference) "../../common/HelpText";

.CreateInformationForm {
  padding-top: @padding-lg;
  max-width: 680px;
  margin-bottom: 16px;

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  &__Divider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: @padding-lg;

    button {
      .secondary-btn();
    }

    &--title {
      justify-content: flex-start !important;
    }
  }

  .AvatarThumbnail__PrimaryContent {
    font-size: @font-size-lg;
    font-weight: bold;
    font-family: @font-family-title;
  }

  &__Field {
    display: flex;

    > div {
      flex: 1;
    }
  }

  &__Field--Short > div {
    // For non full-screen fields: reset the flex: 1;
    flex: initial;
  }

  &__Submit {
    display: flex;
    justify-content: flex-end;
  }

  &__ChangeCampaignBtn {
    font-size: 24px;
    color: var(--primary-color);
    margin-left: 30px;
    margin-top: 9px;
    align-self: center;

    &:hover {
      color: @primary-hover-color;
    }
  }

  &__SubmitWrapper {
    display: flex;
    justify-content: flex-end;
  }
}
