.AvatarThumbnail {
  display: flex;
  align-items: center;
}
.AvatarThumbnail .iconButton {
  cursor: pointer;
  margin: 0 0 0 0.5em !important;
}
.AvatarThumbnail .iconButton .fa-layers svg[data-icon="external-link-alt"] {
  font-size: 11.5px;
  top: 10px;
  left: 12px;
  background: #eceded;
  border: 1px solid #eceded;
}
.AvatarThumbnail__Avatar {
  background-color: var(--link-color);
  height: 40px;
  width: 40px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
  font-size: 18px;
  color: #FFFFFF;
  flex: 0 0 40px;
}
.AvatarThumbnail__Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  line-height: 17px;
}
.AvatarThumbnail--title .AvatarThumbnail__Avatar,
.AvatarThumbnail--title .AvatarThumbnail__Avatar img {
  height: 127px;
  width: 127px;
  flex: 0 0 127px;
}
.AvatarThumbnail--title .AvatarThumbnail__Content {
  flex-direction: column-reverse;
  margin-left: 20px;
}
.AvatarThumbnail--title .AvatarThumbnail__PrimaryContent {
  font-size: 2em !important;
  line-height: normal;
  font-family: 'Domine', serif;
}
.AvatarThumbnail--title .AvatarThumbnail__SecondaryContent {
  margin-bottom: -6px;
}
.AvatarThumbnail--title .AvatarThumbnail__Avatar {
  font-size: 80px;
}
.AvatarThumbnail__Popover .ant-popover-inner,
.AvatarThumbnail__Popover .ant-popover-arrow {
  background-color: #F5F7F7;
}
.AvatarThumbnail__Popover .ant-popover-inner-content {
  padding: 2rem;
}
.AvatarThumbnail__Popover__Content {
  display: flex;
  align-items: center;
}
.AvatarThumbnail__Popover__Content__Avatar {
  border-radius: 100%;
  margin-right: 2rem;
}
.AvatarThumbnail__Popover__Content__Avatar,
.AvatarThumbnail__Popover__Content__Avatar img {
  height: 100px;
  width: 100px;
  flex: 0 0 100px;
}
.AvatarThumbnail__Popover__Content__Name {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.6rem;
}
.AvatarThumbnail__Popover__Content__Name > span {
  margin-right: 0.4rem;
}
.AvatarThumbnail__Popover__Content__Name svg {
  color: var(--primary-color);
  font-size: 1.2rem;
}
.AvatarThumbnail__Popover__Content__Actions {
  margin: 0 -0.5rem;
}
.AvatarThumbnail__Popover__Content__Actions button {
  margin: 0 0.5rem;
}
