.SearchPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
}
.SearchPage .ant-tabs {
  width: 100%;
}
.SearchPage .ant-tabs .ant-tabs-bar {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: 0;
}
.SearchPage > h1,
.SearchPage > p {
  text-align: center;
}
.SearchPage .SearchBar {
  width: 75%;
}
.SearchPage .SearchBar input {
  height: 57px;
  font-size: 24px;
  padding-left: 38px !important;
}
.SearchPage .PaginationTop,
.SearchPage .ResultThumbnailList {
  width: 75%;
  margin: 0 auto;
}
.SearchPage .ResultThumbnailList li {
  margin: 4.5px;
}
.SearchPage .ResultThumbnailList .ant-card {
  border: 2px solid #E5E5E5;
}
.SearchPage .ResultThumbnailList .ant-card:hover {
  background-color: #fff;
  border: 2px solid var(--primary-color);
  color: rgba(0, 0, 0, 0.65);
}
.SearchPage .ResultThumbnailList .ant-card .ant-card-body {
  padding: 12px;
}
.SearchPage .ResultThumbnailList .ant-card .AvatarThumbnail__Avatar {
  height: 46px;
  width: 46px;
  flex: 0 0 46px;
}
.SearchPage .ResultThumbnailList .ant-card .AvatarThumbnail__Content {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.SearchPage .ResultThumbnailList .ant-card .AvatarThumbnail__PrimaryContent {
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
}
.SearchPage .card-disabled {
  border: 2px solid #E5E5E5;
  background-color: #fff;
  opacity: 0.5;
}
.SearchPage .card-disabled:hover {
  cursor: not-allowed;
  border: 2px solid #E5E5E5 !important;
  background-color: #fff !important;
}
@media screen and (max-width: 706px) {
  .SearchPage .PaginationTop,
  .SearchPage .ResultThumbnailList {
    width: 100%;
  }
  .SearchPage .ResultThumbnailList li {
    width: calc(50% - 4.5px*2);
  }
}
@media screen and (max-width: 500px) {
  .SearchPage .PaginationTop,
  .SearchPage .ResultThumbnailList {
    width: 100%;
  }
  .SearchPage .ResultThumbnailList {
    margin: 0 -4.5px;
  }
  .SearchPage .ResultThumbnailList li {
    width: 100%;
    flex: 1 0 auto;
  }
}
@media screen and (max-width: 380px) {
  .SearchPage .PaginationTop .ant-pagination {
    flex-wrap: wrap;
  }
  .SearchPage .PaginationTop .ant-pagination .ant-pagination-total-text {
    flex: initial;
    margin-right: 0;
    width: 100%;
  }
  .SearchPage .PaginationTop .ant-pagination .ant-pagination-prev {
    margin-right: auto;
  }
  .SearchPage .PaginationTop .ant-pagination .ant-pagination-next {
    margin-left: auto;
  }
}
