.FundkyButton {
  text-transform: uppercase;
  letter-spacing: 0.12rem;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  line-height: 1em;
}
.FundkyButton.ant-btn-loading {
  padding-left: 15px !important;
}
.FundkyButton svg.after {
  margin-left: 0.8rem;
}
.FundkyButton svg.before {
  margin-right: 0.8rem;
}
.FundkyButton__Size--large {
  height: 40px;
  padding: 0 15px;
}
.FundkyButton__Size--medium {
  height: 30px;
  padding: 0 10px;
}
.FundkyButton__Size--small {
  height: 20px;
  padding: 0 5px;
}
.FundkyButton__Type--primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}
.FundkyButton__Type--primary:hover,
.FundkyButton__Type--primary:focus {
  background-color: var(--primary-color-darken) !important;
  border-color: var(--primary-color-darken) !important;
  color: #fff !important;
}
.FundkyButton__Type--secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #fff;
}
.FundkyButton__Type--secondary:hover,
.FundkyButton__Type--secondary:focus {
  background-color: var(--secondary-color-darken) !important;
  border-color: var(--secondary-color-darken) !important;
  color: #fff !important;
}
.FundkyButton__Type--default {
  background-color: #3f4345;
  border-color: #3f4345;
  color: #fff;
}
.FundkyButton__Type--default:hover,
.FundkyButton__Type--default:focus {
  background-color: #000 !important;
  border-color: #000 !important;
  color: #fff !important;
}
.FundkyButton__Type--cancel {
  background-color: #f5f7f7;
  border-color: #f5f7f7;
  color: var(--primary-color);
}
.FundkyButton__Type--cancel.white {
  background-color: #fff;
  border-color: #fff;
  color: var(--primary-color);
}
.FundkyButton__Type--cancel:hover,
.FundkyButton__Type--cancel:focus {
  background-color: #e8e9e9 !important;
  border-color: #e8e9e9 !important;
  color: var(--primary-color) !important;
}
.FundkyButton__Type--disabled {
  background-color: #f5f7f7 !important;
  border-color: #f5f7f7 !important;
  color: #c8d5d5 !important;
}
.FundkyButton__Type--disabled:hover,
.FundkyButton__Type--disabled:focus {
  background-color: #f5f7f7 !important;
  border-color: #f5f7f7 !important;
  color: #c8d5d5 !important;
}
.FundkyButton__Type--text {
  text-transform: none;
  letter-spacing: normal;
  background-color: transparent !important;
  border-color: transparent !important;
  color: var(--secondary-color) !important;
  height: auto;
  font-size: 1em;
  padding: 0 !important;
  box-shadow: none;
}
.FundkyButton__Type--text:hover,
.FundkyButton__Type--text:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  color: var(--secondary-color-darken) !important;
}
.FundkyButton__Type--text-cancel {
  font-size: 0.8em;
}
.FundkyButton__Type--text-primary,
.FundkyButton__Type--text-cancel {
  text-transform: none;
  background-color: transparent !important;
  border-color: transparent !important;
  height: auto;
  font-size: 1em;
  padding: 0 !important;
  box-shadow: none;
  color: var(--primary-color) !important;
  letter-spacing: normal;
}
.FundkyButton__Type--text-primary:hover,
.FundkyButton__Type--text-cancel:hover,
.FundkyButton__Type--text-primary:focus,
.FundkyButton__Type--text-cancel:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  color: var(--primary-color-darken) !important;
}
.FundkyButton.FundkyButton__Type--text:disabled,
.FundkyButton.FundkyButton__Type--text-primary:disabled,
.FundkyButton.FundkyButton__Type--text-cancel:disabled {
  color: #c8d5d5 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
.FundkyButton.FundkyButton__Type--text:disabled:hover,
.FundkyButton.FundkyButton__Type--text-primary:disabled:hover,
.FundkyButton.FundkyButton__Type--text-cancel:disabled:hover,
.FundkyButton.FundkyButton__Type--text:disabled:focus,
.FundkyButton.FundkyButton__Type--text-primary:disabled:focus,
.FundkyButton.FundkyButton__Type--text-cancel:disabled:focus {
  color: #c8d5d5 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
.FundkyButton:disabled,
.FundkyButton:disabled:hover,
.FundkyButton:disabled:focus {
  color: rgba(0, 0, 0, 0.5) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-color: rgba(0, 0, 0, 0.05) !important;
}
.FundkyButton:disabled.onLightGrey,
.FundkyButton:disabled.onLightGrey:hover,
.FundkyButton:disabled.onLightGrey:focus {
  color: #b1bdbd !important;
  background-color: #e7e7e7 !important;
  border-color: #e7e7e7 !important;
}
a.FundkyButton__Size--large {
  line-height: 40px;
}
a.FundkyButton__Size--medium {
  line-height: 30px;
}
a.FundkyButton__Size--small {
  line-height: 20px;
}
