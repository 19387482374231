.ChangeRecipientModalContent--clickableAvatar {
  cursor: pointer;
}
.ChangeRecipientModalContent__AvatarThumbnail {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
}
.ChangeRecipientModalContent__AvatarThumbnail h1 {
  font-size: 24px;
  line-height: 30px;
}
.ChangeRecipientModalContent__AvatarThumbnail .AvatarThumbnail__Avatar,
.ChangeRecipientModalContent__AvatarThumbnail .AvatarThumbnail__Avatar img {
  height: 80px;
  width: 80px;
  flex: 0 0 80px;
  font-size: 40px;
}
.ChangeRecipientModalContent__AvatarThumbnail .AvatarThumbnail__Content {
  flex-direction: column-reverse;
  margin-left: 20px;
}
.ChangeRecipientModalContent__Or {
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 25px;
  margin: 30px 0 30px 0;
  font-weight: 700;
  text-align: center;
}
.ChangeRecipientModalContent--label {
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 25px;
  margin: 38px 0 20px 0;
  font-weight: 700;
}
.ChangeRecipientModalContent--select .ant-select-selection {
  height: 58px;
}
.ChangeRecipientModalContent--select .ant-select-selection__rendered {
  height: 100%;
}
.ChangeRecipientModalContent--select .ant-select-selection-selected-value {
  height: 100%;
  display: flex !important;
}
.ChangeRecipientModalContent__SearchNewButton {
  width: 100%;
  min-height: 60px;
  font-size: 18px;
}
