@import (reference) "../../assets/stylesheets/main";

.ParticipantPublicPage {
  .ParticipantPublicPage__Container {
    display: flex;
    position: relative;
    width: 100%;
    padding-bottom: 24px;
  }

  .ParticipantPublicPage__Menu {
    flex: 1 0 320px;
    max-width: 392px;
    padding-right: @padding-lg;

    .ParticipantPublicPage__Thermometer {
      margin: @padding-lg 0;
      margin-top: 0;

      .ParticipantPublicPage__Participant--dropdown {
        width: 100%;

        .HelpText {
          display: block;
          text-align: left;
        }
      }

      .PublicThermometer__Percentage {
        font-size: 8.2rem;
        font-weight: 600;
        line-height: normal;
      }

      .PublicThermometer__Detail {
        font-size: 2.6rem;
      }
    }

    .ParticipantPublicPage__MenuLink {
      &:extend(.FundkyWrap);
      line-height: 60px;
      border-radius: @border-radius-base;
      border-left: 5px solid transparent;
      color: @heading-color;
      margin-bottom: 5px;

      svg {
        font-size: 28px;
        vertical-align: middle;
        margin-right: @padding-lg;
      }

      &:hover {
        background-color: #FFFFFF;
      }
    }

    .ParticipantPublicPage__MenuLink--Active {
      background-color: #FFFFFF;
      border-left: 5px solid var(--link-color);
    }
  }

  .ParticipantPublicPage__Content {
    flex: 1 1 auto;
    min-width: 0;

    >div:first-of-type {
      margin-top: 0;
    }
  }

  &__Breadcrumbs {
    font-size: 12px;
    color: #AEADAE;

    ul {
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      margin-bottom: 12px;
      padding-left: 0;
    }

    li:last-of-type {
      color: #424749;
    }

    a {
      color: #AEADAE;
      cursor: pointer;

      &:hover {
        color: var(--primary-color);
      }
    }

    svg {
      font-size: 10px;
      color: var(--secondary-color);
      margin: 0 6px;
    }
  }
}
