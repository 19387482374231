@import (reference) "../../assets/stylesheets/main";
@import (css) url('https://fonts.googleapis.com/css?family=Galada');

.PublicFooter {
  // Reset the default Antd padding
  padding: 0;
  background-color: @layout-body-background;

  .PublicFooter--instagram {
    top: 3px;
  }

  &-home {
    .PublicFooter__PlatformSection {
      margin-top: 0 !important;
      border-top: 0 !important;
    }
  }

  &__PlatformSection {
    border-top: 3px solid #E8E9E9;
    padding: @padding-lg * 2 0;
  }

  .PlatformSocialMedia__PlatformSocialLinks ul {
    display: flex;
  }

  &__PlatformSection .ant-collapse {
    border: 0;
    background: none;
  }

  &__PlatformSection,
  &__PlatformSection .ant-collapse {
    display: flex;

    .ant-collapse-header {
      padding: 0;
      margin-bottom: .5em;
      display: inline-flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      line-height: 1.2em;
      padding-right: 0;
      margin-right: 0;

      .ant-collapse-arrow {
        position: static;
        transform: none;
        margin-left: 0.8em;
      }
    }

    .ant-collapse-content,
    .ant-collapse-item {
      background: none;
      border: 0;
    }

    .ant-collapse-content-box {
      padding: 0;
    }


    div,
    .ant-collapse-item {
      flex: 1;
      .inner-margin(@padding-lg)
    }

    h4,
    .ant-collapse-header {
      text-transform: uppercase;
      font-weight: bold;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    a {
      color: @text-color-secondary;

      &:hover {
        color: @text-color;
      }

    }
  }

  .PlatformSocialMedia__PlatformSocialLinks {
    li {
      display: inline;
      .inner-margin(@padding-sm)
    }

    svg {
      font-size: 2.8rem;
    }
  }

  &__WaveBorder {
    height: 7px;

    &:after {
      content: "";
      display: block;
      height: 8px;
      background-repeat: repeat;
      background-size: 16px 8px;
      background-image: url("../../assets/images/footer_border.svg");
    }

  }

  &__FundkySection {
    display: flex;
    flex-direction: column;
    background-color: #383D3F;
    color: @text-color-secondary-dark;
    padding: @padding-lg 0;
  }

  &__GetFundky {
    font-family: 'Galada';
    font-size: 36px;
    color: #d1d2d2;
    margin-left: 5px;
    line-height: 30px;
  }

  &__Divider {
    display: flex;
    flex: 1 0 auto;

    &:first-child {
      justify-content: space-between;
      padding-bottom: @padding-lg;
      border-bottom: 1px solid fade(#fff, 15%);
    }

    &:last-child {
      flex-wrap: wrap;
      padding-top: @padding-lg;
    }
  }

  &__Newsletter {
    display: flex;
    flex: 1;
    align-items: center;

    >* {
      margin-right: @padding-lg;
    }

    p {
      flex-basis: 300px;
      margin-bottom: 0;
    }

    form {
      flex-basis: 300px;
    }

    input {
      border: 0;
    }

    input:hover,
    input:focus {
      box-shadow: none;
    }

    svg {
      font-size: 24px;
      color: var(--link-color);
      cursor: pointer;
    }
  }

  &__FundkySocialLinks {
    display: flex;
    align-items: center;

    a {
      .inner-margin(@padding-sm);
      border: 2px solid @text-color-secondary-dark;
      border-radius: 100%;
      background-color: transparent;
      transition: all .3s;
      width: 30px !important;
      height: 30px !important;
      max-width: 30px !important;
      max-height: 30px !important;
    }

    i {
      padding: 6px;
      vertical-align: middle;
      color: @text-color-secondary-dark;
      transition: all .3s;
    }

    svg {
      vertical-align: middle;
      color: @text-color-secondary-dark;
      transition: all .3s;
      width: 26px !important;
      height: 26px;
    }

    a:hover {
      background-color: @text-color-secondary-dark;
      border-color: transparent;

      i {
        color: #383D3F;
      }

      svg {
        color: #383D3F;
      }
    }
  }

  &__LegalLinks,
  &__CopyrightContainer {
    display: flex;
    align-items: center;
  }

  &__LegalLinks {
    .inner-margin(@padding-lg);

    >* {
      font-size: @font-size-sm;
      .inner-margin(@padding-lg);
    }
  }

  &__CopyrightContainer {
    .inner-margin(@padding-lg);
  }

  &__PoweredContainer {
    margin-left: auto;
    position: relative;
  }

  &__PoweredTxt {
    position: absolute;
    display: block;
    top: 30px;
    left: 48px;
    font-size: 15px;
  }

  @media screen and (max-width: 992px) {
    .PublicFooter__FundkySection {
      .PublicFooter__Divider:last-of-type {

        .PublicFooter__LegalLinks,
        .PublicFooter__CopyrightContainer {
          margin-bottom: 2rem;
        }

        .PublicFooter__CopyrightContainer {
          margin-right: 0;
        }

        .PublicFooter__PoweredContainer {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type {
      .PublicFooter__LegalLinks {
        width: 100%;
        margin-right: 0;
      }

      .PublicFooter__CopyrightContainer {
        margin-bottom: 0;
      }

      .PublicFooter__PoweredContainer {
        width: auto;
        margin-left: auto;
      }
    }
  }

  @media screen and (max-width: 720px) {
    &__PlatformSection .ant-collapse {
      width: 100% !important;
    }

    &__PlatformSection,
    &__PlatformSection .ant-collapse {
      flex-wrap: wrap;

      >div,
      .ant-collapse-item {
        flex: inherit;
        width: calc(~'50% - @{padding-lg}');
      }

    }
  }

  @media screen and (max-width: 624px) {
    .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type {
      .PublicFooter__LegalLinks {
        flex-wrap: wrap;

        >a {
          width: calc(~'50% - @{padding-lg}');
        }
      }
    }
  }

  @media screen and (min-width: 567px) {
    .PublicFooter__PlatformSection .ant-collapse-header .ant-collapse-arrow {
      display: none;
    }

    .PublicFooter__PlatformSection .ant-collapse-content {
      display: block !important;
    }
  }

  @media screen and (max-width: 566px) {

    .PublicFooter__PlatformSection,
    .PublicFooter__PlatformSection .ant-collapse {
      flex-direction: column;
      text-align: center;
      padding-bottom: 0;

      .ant-collapse-header,
      h4 {
        text-align: center;
        font-size: 1.125em;
        margin-bottom: 0;
      }

      .ant-collapse-header {
        margin-left: 1.3em;
      }

      h4 {
        margin-bottom: 0.25em;
      }

      .PlatformSocialMedia__PlatformSocialLinks svg {
        font-size: 3rem;
      }

      .ant-collapse-content-box {
        margin-top: -0.5em;
      }

      .ant-collapse-content-box ul li {
        margin: 0.5em 0;
      }

      >div,
      .ant-collapse-item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1em !important;

        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }
      }
    }

    .PlatformSocialMedia__PlatformSocialLinks ul {
      justify-content: center;
    }


    .PublicFooter__FundkySection .PublicFooter__Divider:first-of-type {
      flex-direction: column;
      align-items: center;
      text-align: center;

      .PublicFooter__Newsletter {
        margin-bottom: 2rem;

        span {
          margin-right: 0;
        }
      }
    }


    .PublicFooter__FundkySocialLinks {
      a {
        width: 40px !important;
        height: 40px !important;
        max-width: 40px !important;
        max-height: 40px !important;
      }

      svg {
        height: 37px !important;
      }
    }

    .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type {
      flex-direction: column;

      .PublicFooter__LegalLinks {
        justify-content: center;

        >a {
          width: auto !important;
          margin: 0 0.5em;
          white-space: nowrap;
          font-size: 12px;


        }

        span {
          margin-bottom: 2rem;
          width: 100%;
          text-align: center;
        }
      }

      .PublicFooter__CopyrightContainer {
        justify-content: center;
        margin-bottom: 1rem;
      }

      .PublicFooter__PoweredContainer {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .PublicFooter__CopyrightContainer {
      font-size: 12px;
    }
  }

}
