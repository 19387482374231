@import (reference) "../../assets/stylesheets/main";

.DonationChoicePage {
  padding-top: @padding-lg * 2;
  padding-bottom: @padding-lg * 2;
  .FundkyWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > h1,
  > p {
    text-align: center;
  }

  > p {
    margin-bottom: @padding-lg * 2;
  }
}

.DonationChoicePage__CallToActions {
  display: flex;
  justify-content: center;
  width: 100%;

  > span {
    align-self: center;
    font-size: @font-size-lg;
    margin: @padding-lg;
    text-transform: uppercase;
  }
}

.DonationChoicePage__CallToAction {
  border: 1px solid @input-border-color;

  .FundkyCTA__Icon {
    color: var(--link-color);
  }
  
  &:not(&--Primary) a {
    .secondary-btn();
  }

  &--Primary .FundkyCTA__Icon {
    color: var(--primary-color);
  }
}

@media screen and (max-width: 460px) {
  .DonationChoicePage__CallToActions {
    flex-direction: column;
    align-items: center;
  }
}
