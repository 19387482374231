@import (reference) "../../assets/stylesheets/main";
@import "_ConnectionForm";

.RegistrationConfirmationPanel {
  p {
    font-size: 20px;
    text-align: center;
    margin-left: 60px;
    margin-right: 60px;
  }

  button {
    display: block;
    width: 100%;
  }
}

.RegistrationConfirmationPanel__TitleWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: @padding-lg;

  &--success {
    font-size: 48px;
    color: @success-color;
    margin-right: 14px;
  }

  &--error {
    font-size: 48px;
    color: @error-color;
    margin-right: 14px;
  }

  h1 {
    display: inline;
    margin-bottom: 0;
  }
}
