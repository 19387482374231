@bannerDisplayWidth: 260px;
@bannerDisplayHeight: 108px;
@bannerDisplayWrapperWidth: calc(@bannerDisplayWidth + 12px);
@bannerDisplayWrapperHeight: calc(@bannerDisplayHeight + 20px);

.CampaignAdminPage {
  > h1 {
    float: left;
  }

  .btn-view-page {
    float: right;
  }

  .ant-tabs {
    clear: both;
  }

  .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload-select-picture-card {
    width: @bannerDisplayWrapperWidth !important;
    height: @bannerDisplayWrapperHeight !important;

    .ant-upload-list-item-thumbnail img {
      width: @bannerDisplayWidth;
      height: @bannerDisplayHeight;
      object-fit: cover;
    }
  }

  &--banner {
    .UploadField__Upload {
      .ant-upload-list-picture-card .ant-upload-list-item,
      .ant-upload-select-picture-card {
        width: @bannerDisplayWrapperWidth;
        height: @bannerDisplayWrapperHeight;
      }
    }
  }
}