@import (reference) "../../assets/stylesheets/main";

.CampaignPublicPage {
  padding: @padding-lg 0;
  flex-direction: row;

  &__Menu {
    flex: 1 0 320px;
    max-width: 392px;
    padding-right: @padding-lg;

    .CampaignPublicPage__Menu {
      margin: 0 -1rem;
    }

    .CampaignPublicPage__TitleThermo,
    .CampaignPublicPage__MenuNavSocialSharing {
      margin: 0 1rem;
    }

    .CampaignPublicPage__TitleThermo {
      &>div {
        top: auto !important;
      }

      margin-bottom: 2.4rem;
    }


    .CampaignPublicPage__Thermometer {
      margin-top: 1.4rem;
    }

    .CampaignPublicPage__MenuNav {
      display: flex;
      flex-direction: column;
    }

    .CampaignPublicPage__MenuLink {
      display: flex;
      align-items: center;
      padding-right: @padding-lg;
      line-height: normal;
      height: 60px;
      border-radius: @border-radius-base;
      border-left: 5px solid transparent;
      color: @heading-color;
      margin-bottom: 5px;

      &:hover {
        background-color: #FFFFFF;
      }

      &[disabled] {
        opacity: .5;
      }
    }

    .CampaignPublicPage__MenuLinkIcon {
      font-size: 2.8rem;
      width: 38%;
      max-width: 84px;
      text-align: center;
    }

    .CampaignPublicPage__MenuLink--Active {
      background-color: #FFFFFF;
      border-left: 5px solid var(--link-color);
    }
  }

  .CampaignPublicPage__Content {
    flex: 1 1 auto;
    min-width: 0;

    .CampaignPublicPage__BannerHolder {
      position: relative;

      &:hover {
        .Btn__EditBanner {
          opacity: 1;
          pointer-events: auto;
        }
      }

      .CampaignPublicPage__Banner {
        width: 100%;
        height: auto;
        object-fit: cover;
        max-height: 377px;
      }

      .Btn__EditBanner {
        opacity: 0;
        pointer-events: none;
        transition: opacity .2s ease-in-out;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media screen and (max-width: 980px) {
      .PublicTeamPanel {
        .PublicPanel__Footer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}
