.SocialMediaField.empty svg {
  color: #B8B8B8;
}
.SocialMediaField.empty svg path {
  fill: #B8B8B8;
}
.SocialMediaField .ant-form-item-label {
  flex: none !important;
  width: 50px !important;
  text-align: center !important;
}
.SocialMediaField .ant-form-item-label svg {
  font-size: 24px;
  vertical-align: middle;
}
