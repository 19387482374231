@import (reference) '../../assets/stylesheets/main';

.FundkyButton {
  text-transform: uppercase;
  letter-spacing: 0.12rem;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  line-height: 1em;

  &.ant-btn-loading {
    padding-left: 15px !important;
  }

  svg.after {
    margin-left: 0.8rem;
  }

  svg.before {
    margin-right: 0.8rem;
  }

  &__Size--large {
    height: 40px;
    padding: 0 15px;
  }

  &__Size--medium {
    height: 30px;
    padding: 0 10px;
  }

  &__Size--small {
    height: 20px;
    padding: 0 5px;
  }

  &__Type--primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;

    &:hover,
    &:focus {
      background-color: var(--primary-color-darken) !important;
      border-color: var(--primary-color-darken) !important;
      color: #fff !important;
    }
  }

  &__Type--secondary {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #fff;

    &:hover,
    &:focus {
      background-color: var(--secondary-color-darken) !important;
      border-color: var(--secondary-color-darken) !important;
      color: #fff !important;
    }
  }

  &__Type--default {
    background-color: #3f4345;
    border-color: #3f4345;
    color: #fff;

    &:hover,
    &:focus {
      background-color: #000 !important;
      border-color: #000 !important;
      color: #fff !important;
    }
  }

  &__Type--cancel {
    background-color: #f5f7f7;
    border-color: #f5f7f7;
    color: var(--primary-color);

    &.white {
      background-color: #fff;
      border-color: #fff;
      color: var(--primary-color);
    }

    &:hover,
    &:focus {
      background-color: #e8e9e9 !important;
      border-color: #e8e9e9 !important;
      color: var(--primary-color) !important;
    }
  }

  &__Type--disabled {
    background-color: #f5f7f7 !important;
    border-color: #f5f7f7 !important;
    color: #c8d5d5 !important;

    &:hover,
    &:focus {
      background-color: #f5f7f7 !important;
      border-color: #f5f7f7 !important;
      color: #c8d5d5 !important;
    }
  }

  &__Type--text {
    text-transform: none;
    letter-spacing: normal;
    background-color: transparent !important;
    border-color: transparent !important;
    color: var(--secondary-color) !important;
    height: auto;
    font-size: 1em;
    padding: 0 !important;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--secondary-color-darken) !important;
    }

    &-cancel {
      font-size: 0.8em;
    }
  }

  &__Type--text-primary,
  &__Type--text-cancel {
    text-transform: none;
    background-color: transparent !important;
    border-color: transparent !important;
    height: auto;
    font-size: 1em;
    padding: 0 !important;
    box-shadow: none;
    color: var(--primary-color) !important;
    letter-spacing: normal;

    &:hover,
    &:focus {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--primary-color-darken) !important;
    }
  }

  &.FundkyButton__Type--text:disabled,
  &.FundkyButton__Type--text-primary:disabled,
  &.FundkyButton__Type--text-cancel:disabled {
    color: #c8d5d5 !important;
    background-color: transparent !important;
    border-color: transparent !important;

    &:hover,
    &:focus {
      color: #c8d5d5 !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }

  &:disabled {

    &,
    &:hover,
    &:focus {
      color: rgba(0, 0, 0, .5) !important;
      background-color: rgba(0, 0, 0, .05) !important;
      border-color: rgba(0, 0, 0, .05) !important;
    }

    &.onLightGrey {

      &,
      &:hover,
      &:focus {
        color: #b1bdbd !important;
        background-color: #e7e7e7 !important;
        border-color: #e7e7e7 !important;
      }
    }
  }
}

a.FundkyButton {
  &__Size--large {
    line-height: 40px;
  }

  &__Size--medium {
    line-height: 30px;
  }

  &__Size--small {
    line-height: 20px;
  }
}
