.TypeOptions {
  display: flex;
  position: relative;
}
.TypeOptions:after {
  position: absolute;
  top: 50%;
  right: -32px;
  margin-top: -10px;
  width: 32px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
@media screen and (max-width: 480px) {
  .TypeOptions {
    flex-direction: column;
  }
  .TypeOptions__Option {
    margin-right: 0 !important;
    margin-bottom: 6px;
    padding: 0 1rem;
  }
  .TypeOptions:after {
    right: -24px;
  }
}
.TypeOptions__Option {
  background-color: #fff;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border: 2px solid #DDDFDF;
  border-radius: 4px;
  transition: all 0.3s;
  margin-right: 6px;
}
.TypeOptions__Option:hover,
.TypeOptions__Option:focus {
  outline: none;
  border: 2px solid var(--primary-color) !important;
}
.TypeOptions__Option.TypeOptions__Option--Selected {
  border: 2px var(--primary-color) solid;
}
.TypeOptions__Option:last-child {
  margin-right: 0;
}
.TypeOptions__Option .TypeOptions__Icon {
  font-size: 36px;
  margin-top: 24px;
  color: var(--link-color);
}
.TypeOptions__Option h3 {
  margin: 10px 0;
  line-height: normal;
}
.TypeOptions__Option p {
  line-height: initial;
  text-align: center;
  flex: 1 1 33%;
}
.TypeOptions__Option p:last-of-type {
  margin-bottom: 30px;
}
