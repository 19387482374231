.TextField {
  input.counterOn {
    padding-right: 30px;
  }

  .TextField_Counter {
    position: absolute;
    color: #BFBFBF;
    right: 0;
    top: 50%;
    line-height: 0;
    text-align: center;
    width: 35px
  }

  input.counterOn {
    padding-right: 50px;
  }

  .has-feedback.has-error,
  .has-feedback.has-success {
    .TextField_Counter {
      right: 20px;
    }
  }
}
