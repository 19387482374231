.CustomRefinementList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0 -0.2rem;
}
.CustomRefinementList .CustomRefinementList__item {
  border-radius: 20px;
  padding: 0 0.6rem;
  margin: 0.2rem;
}
.CustomRefinementList .CustomRefinementList__item a {
  display: inline-block;
}
.CustomRefinementList .CustomRefinementList__item.selected {
  background-color: var(--primary-color);
}
.CustomRefinementList .CustomRefinementList__item.selected a {
  color: #fff;
}
