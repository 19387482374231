.PublicLayout .ant-form-item-children .CardCvvTooltip {
  position: absolute;
  left: 100%;
  right: auto !important;
}
.PublicLayout .ant-form-item-children .CardCvvTooltip + span.ant-form-item-children-icon {
  left: 100%;
  right: auto !important;
  margin-left: 1.1em;
}
