.RecipientModalContent--clickableAvatar {
  cursor: pointer;
}
.RecipientModalContent__AvatarThumbnail {
  background-color: #fff;
  padding: 12px;
  border-radius: 4px;
}
.RecipientModalContent__AvatarThumbnail h1 {
  font-size: 24px;
  line-height: 30px;
}
.RecipientModalContent__AvatarThumbnail .AvatarThumbnail__Avatar,
.RecipientModalContent__AvatarThumbnail .AvatarThumbnail__Avatar img {
  height: 80px;
  width: 80px;
  flex: 0 0 80px;
  font-size: 40px;
}
.RecipientModalContent__AvatarThumbnail .AvatarThumbnail__Content {
  flex-direction: column-reverse;
  margin-left: 20px;
}
.RecipientModalContent__Or {
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 25px;
  margin: 30px 0 30px 0;
  font-weight: 700;
  text-align: center;
}
.RecipientModalContent--label {
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 25px;
  margin: 38px 0 20px 0;
  font-weight: 700;
}
.RecipientModalContent--select .ant-select-selection {
  height: 58px;
}
.RecipientModalContent--select .ant-select-selection__rendered {
  height: 100%;
}
.RecipientModalContent--select .ant-select-selection-selected-value {
  height: 100%;
  display: flex !important;
}
.RecipientModalContent__SearchNewButton {
  width: 100%;
  min-height: 60px;
  font-size: 18px;
}
.RecipientModalContent--radio .ant-radio-button-wrapper {
  border-radius: 4px !important;
  width: 100%;
  height: auto;
  padding: 0;
  border: 4px solid #fff;
}
.RecipientModalContent--radio .ant-radio-button-wrapper:before {
  content: none;
}
.RecipientModalContent--radio .ant-radio-button-wrapper-checked {
  border-color: var(--secondary-color);
  box-shadow: none;
}
.RecipientModalContent--radio .ant-radio-button-wrapper:not(.organization-option) {
  margin-bottom: 12px;
}
.RecipientModalContent--radio .ant-radio-button-wrapper:not(.organization-option):last-of-type {
  margin-bottom: 0;
}
.RecipientModalContent--radio .ant-radio-button-wrapper > span:not(.ant-radio-button) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.RecipientModalContent--radio .ant-radio-button-wrapper > span:not(.ant-radio-button) .FundkyButton {
  font-size: 18px;
}
.RecipientModalContent--radio .ant-radio-button-wrapper > span:not(.ant-radio-button) .FundkyButton svg {
  margin: 16px;
}
.RecipientModalContent--radio .not-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}
.RecipientModalContent--radio .not-option h1 {
  margin: 0;
}
.RecipientModalContent--radio .not-option .FundkyButton {
  font-size: 18px;
}
.RecipientModalContent--radio .not-option .FundkyButton svg {
  margin: 16px;
}
