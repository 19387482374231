@import (reference) "../assets/stylesheets/main";

@avatar-thumbnail-size: 40px;
@avatar-thumbnail-inner-padding: @padding-sm;

.AvatarThumbnail {
  display: flex;
  align-items: center;

  .iconButton {
    cursor: pointer;
    margin:0 0 0 0.5em !important;

    .fa-layers svg[data-icon="external-link-alt"] {
      font-size: 11.5px;
      top: 10px;
      left: 12px;
      background: #eceded;
      border: 1px solid #eceded;
    }
  }
}

.AvatarThumbnail__Avatar {
  background-color: var(--link-color);
  height: @avatar-thumbnail-size;
  width: @avatar-thumbnail-size;
  margin-right: @avatar-thumbnail-inner-padding;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
  font-size: @font-size-lg;
  color: #FFFFFF;
  flex: 0 0 @avatar-thumbnail-size;
}

.AvatarThumbnail__Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: @font-size-base;
  line-height: @font-size-base + 1;
}

.AvatarThumbnail--title {
  .AvatarThumbnail__Avatar, .AvatarThumbnail__Avatar img {
    height: 127px;
    width: 127px;
    flex: 0 0 127px;
  }

  .AvatarThumbnail__Content {
    flex-direction: column-reverse;
    margin-left: 20px;
  }

  .AvatarThumbnail__PrimaryContent {
    font-size: 2em !important;
    line-height: normal;
    font-family: 'Domine', serif;
  }

  .AvatarThumbnail__SecondaryContent {
    margin-bottom: -6px;
  }

  .AvatarThumbnail__Avatar {
    font-size: 80px;
  }
}

.AvatarThumbnail__Popover {
  .ant-popover-inner,
  .ant-popover-arrow {
    background-color: #F5F7F7;
  }

  .ant-popover-inner-content {
    padding: 2rem;
  }

  &__Content {
    display: flex;
    align-items: center;

    &__Avatar {
      border-radius: 100%;
      margin-right: 2rem;
    }

    &__Avatar, &__Avatar img {
      height: 100px;
      width: 100px;
      flex: 0 0 100px;
    }

    &__Name {
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: .6rem;

      > span {
        margin-right: .4rem;
      }

      svg {
        color: var(--primary-color);
        font-size: 1.2rem;
      }
    }

    &__Actions {
      margin: 0 -.5rem;

      button {
        margin: 0 .5rem;
      }
    }
  }
}
