@import (reference) "../../assets/stylesheets/main";
@import (reference) "../../common/AvatarThumbnail";

.PublicCaptainQuote {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__Message {
    background-color: @body-background;
    border-radius: 12px;
    border: 0;
    padding: 0 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before,
    &:after {
      color: #888888;
      font-size: 100px;
      font-family: Arial, serif;
      font-style: normal;
      line-height: normal;
    }

    &:before {
      content: '\201C'; // Opening double quote character
      align-self: flex-start;
      margin-top: -10px;
    }

    &:after {
      content: '\201E'; // Closing double quote character
      align-self: flex-end;
    }
  }

  p {
    line-height: 1.2em;
    margin: 0;
    font-family: @font-family-title;
    text-align: center;
  }

  &__Info {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .no-style {
      color: inherit;
      cursor: pointer;
    }
  }

  &__Arrow {
    position: absolute;
    left: 18px;
    top: 0;
    height: 33%;
    fill: @body-background;
  }

  .AvatarThumbnail {
    padding: 8px 0;
  }

  .AvatarThumbnail__PrimaryContent {
    font-size: .8em;
    svg {
      color: var(--primary-color);
    }
  }

  .AvatarThumbnail__SecondaryContent {
    font-size: @font-size-sm;
    line-height: @font-size-sm + 1;
    font-weight: bold;
  }

  .AvatarThumbnail {
    justify-content: center;
  }
}

.PublicCaptainQuote__Popover {
  .ant-popover-inner,
  .ant-popover-arrow {
    background-color: #F5F7F7;
  }

  .ant-popover-inner-content {
    padding: 2rem;
  }

  &__Content {
    display: flex;
    align-items: center;

    &__Avatar {
      border-radius: 100%;
      margin-right: 2rem;
    }

    &__Avatar, &__Avatar img {
      height: 100px;
      width: 100px;
      flex: 0 0 100px;
    }

    &__Name {
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: .6rem;

      > span {
        margin-right: .4rem;
      }

      svg {
        color: var(--primary-color);
        font-size: 1.2rem;
      }
    }

    &__Actions {
      margin: 0 -.5rem;

      button {
        margin: 0 .5rem;
      }
    }
  }
}


// Texts coming from Quill WYSIWYG
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle {
  color: rgba(0,0,0,.65);
  font-family: Domine,serif;
  &.smallQuote {
    font-size: 20px;
  }
  &.mediumQuote {
    font-size: 15px;
  }
  &.largeQuote {
    font-size: 10px;
  }
  p {
    font-size: 1.6em;
    margin: 0.5em 0;
  }
  strong {
    font-weight: 700;
  }
  em {
    font-style: italic;
  }
  u {
    text-decoration: underline;
  }
  sub {
    font-size: 0.5em;
  }
  sup {
    font-size: 0.5em;
  }
}
