@import (reference) "../../assets/stylesheets/main";

.CreateConfirmationPanel {
  position: relative;
  padding-top: @padding-lg;
  max-width: 680px;

  svg.CreateConfirmationPanel__SuccessIcon {
    position: absolute;
    left: -56px - @padding-lg;
    font-size: 56px;
    color: var(--link-color);
    margin-right: 14px;
  }

  svg.CreateConfirmationPanel__ErrorIcon {
    position: absolute;
    left: -56px - @padding-lg;
    font-size: 56px;
    color: @error-color;
    margin-right: 14px;
  }

  svg.CreateConfirmationPanel__CcIcon {
    font-size: 33px;
    margin-right: 4px;
  }

  .TaxReceipt__ConfirmationMessage {
    display: block;
    margin-top: 2rem;
  }

  &__InformationBlocks {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
  }

  &__InformationBlock {
    min-width: 50px;
    flex: auto;
    margin: 0 1rem;

    &.TransactionInformation {
      width: 100%;

      tbody.ticket tr:first-of-type td {
        padding-top: 10px;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
    }

    ul {
      padding: 0;
      list-style-type: none;
    }

    li {
      display: flex;
      align-items: center;
    }

    label {
      display: block;
      font-weight: 600;
      min-width: 100px;
      padding-right: 1rem;
    }
  }

  &__NoTaxes {
    margin: 20px 0;
  }

  .WinkNotification {
    .wink-create-account {
      padding-bottom: 2.4rem;
    }
  }
}

.CreateConfirmationPanel__Divider {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  margin-bottom: @padding-lg;

  &--CTA {
    justify-content: flex-start !important;
    margin-top: 78px;

    > a, button {
      margin-right: 10px;
    }
  }
}

.CreateConfirmationPanel__DarkButton {
  .dark-btn();
}

.WinkTooltip {
  .SocialSharing {
    margin: 0 auto 1rem;
  }

  .wink-create-account {
    button {
      margin: 0 auto;
      display: block;
    }
    padding-bottom: 2.4rem;
  }
}

@media print {
  .PublicHeader__Divider,
  .DonationPage__Header,
  .ant-tabs-bar,
  .CreateConfirmationPanel__Divider--CTA,
  .WinkTooltip,
  .PublicFooter__PlatformSection,
  .PublicFooter__WaveBorder,
  .PublicFooter__FundkySection > .PublicFooter__Divider:first-of-type,
  .PublicFooter__PoweredContainer {
    display: none !important;
  }

  .PublicHeaderRef{
    position:static !important;
  }

  .PublicLayout {
    min-height: inherit !important;
  }

  .PublicFooter__FundkySection,
  .PublicFooter__FundkySection > .PublicFooter__Divider:last-of-type,
  .PublicFooter__LegalLinks {
    flex-direction: column;
    align-items: flex-start;
  }

  .PublicFooter__Divider:last-of-type {
    display: block;
    flex: inherit;
    flex-direction: inherit !important;
    align-items: inherit !important;
    flex-wrap: inherit;
    padding-top: 0;
  }

  .PublicFooter__LegalLinks {
    display: block;
    margin: 0;

    a {
      display: block;
      margin: 0;

      &:link:after {
        content: " (" attr(href) ") ";
        font-size: 90%;
      }
    }
  }
}
