@import (reference) "../assets/stylesheets/main";
@import (reference) "./AvatarThumbnail";

.PublicDonationQuote {
  width: 100%;
  display: flex;
  flex-direction: column;

  p {
    background-color: @layout-body-background;
    border-radius: @border-radius-base;
    border: 1px solid @input-border-color;
    margin-bottom: 0;
    margin-left: @avatar-thumbnail-inner-padding + @avatar-thumbnail-size;
    padding: @padding-xs;
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
  }

  .AvatarThumbnail__PrimaryContent {
    color: var(--primary-color);
    font-weight: bold;
  }

  .AvatarThumbnail__SecondaryContent {
    font-size: @font-size-sm;
    line-height: @font-size-sm + 1;
    font-weight: bold;
  }
}

.PublicDonationQuote--BigQuote {
  flex-direction: column-reverse;

  p {
    background-color: @body-background;
    border-radius: 12px;
    border: 0;
    padding: 0 @padding-lg * 2;
    margin: 0;
    height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: @font-family-title;
    font-size: @font-size-lg;
    font-style: italic;
    text-align: center;
  }

  p:before,
  p:after {
    color: #888888;
    font-size: 100px;
    font-family: Arial, serif;
    font-style: normal;
    position: absolute;
  }

  // p:before {
  //   content: '\201C'; // Opening double quote character
  //   top: -20px; // This character is not centered
  //   left: @padding-sm;
  // }

  // p:after {
  //   content: '\201E'; // Closing double quote character
  //   bottom: -10px; // The character is not centered (it doesn't even have the same space as the opening quote)
  //   right: @padding-sm;
  // }

  .PublicDonationQuote__Info {
    position: relative;
    padding: @padding-sm 0 @padding-sm 65px;
  }

  .PublicDonationQuote__Arrow {
    position: absolute;
    left: 18px;
    top: 0;
    height: 33%;
    fill: @body-background;
  }

  &.large p {
    font-size: 3.6rem;
  }

  &.medium p {
    font-size: 2.4rem;
  }

  &.small p {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 520px) {
    p {
      padding: 0 20px !important;
    }

    &.large p {
      font-size: 2.4rem;
    }

    &.medium p {
      font-size: 1.8rem;
    }

    &.small p {
      font-size: 1.4rem;
    }
  }
}
