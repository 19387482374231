.SelectCampaignPanel {
  display: flex;
  flex-direction: column;
}
.SelectCampaignPanel .PaginationTop,
.SelectCampaignPanel .SearchBar {
  width: 75%;
  align-self: center;
}
.SelectCampaignPanel .ResultThumbnailList li {
  margin: 4.5px;
}
.SelectCampaignPanel .card-disabled {
  border: 2px solid #E5E5E5;
  background-color: #fff;
  opacity: 0.5;
}
.SelectCampaignPanel .card-disabled:hover {
  cursor: not-allowed;
  border: 2px solid #E5E5E5 !important;
  background-color: #fff !important;
}
.SelectCampaignPanel__CreateCampaignCTA {
  color: #fff;
  margin: 20px auto;
}
@media screen and (max-width: 706px) {
  .SelectCampaignPanel .SearchBar,
  .SelectCampaignPanel .PaginationTop,
  .SelectCampaignPanel .ResultThumbnailList {
    width: 100%;
  }
  .SelectCampaignPanel .ResultThumbnailList li {
    width: calc(50% - 4.5px*2);
  }
}
@media screen and (max-width: 500px) {
  .SelectCampaignPanel .PaginationTop,
  .SelectCampaignPanel .ResultThumbnailList {
    width: 100%;
  }
  .SelectCampaignPanel .ResultThumbnailList li {
    width: 100%;
  }
}
@media screen and (max-width: 380px) {
  .SelectCampaignPanel .PaginationTop .ant-pagination {
    flex-wrap: wrap;
  }
  .SelectCampaignPanel .PaginationTop .ant-pagination .ant-pagination-total-text {
    flex: initial;
    margin-right: 0;
    width: 100%;
  }
  .SelectCampaignPanel .PaginationTop .ant-pagination .ant-pagination-prev {
    margin-right: auto;
  }
  .SelectCampaignPanel .PaginationTop .ant-pagination .ant-pagination-next {
    margin-left: auto;
  }
}
