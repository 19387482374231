@import (reference) "../../assets/stylesheets/main";

.StaticTitle {
    margin-bottom: 60px;

  h1{
    font-size: 60px;
    font-weight: bold;
    font-family: 'Domine';
    color: #333;
    line-height: 62px;

    @media screen and (max-width: 900px) {
      font-size: 50px;
      line-height: 52px;
    }

    @media screen and (max-width: 540px) {
      font-size: 40px;
      line-height: 42px;
    }
  }

  hr{
    margin: 30px 0 0 0;
    border: 0;
    background-color: #444545;
    width: 150px;
    height: 3px;
  }
}
