@import (reference) "../../assets/stylesheets/main";

.CreateDonationForm {
  padding-top: @padding-lg;
  max-width: 680px;

  #is .ant-tooltip-content p {
    margin: 0;
  }

  .CreateDonationForm__Field {
    display: flex;

    > div {
      flex: 1;
    }
  }

  .CreateDonationForm__Field--Short > div {
    // For non full-screen fields: reset the flex: 1;
    flex: initial;
  }

  .TaxReceipt__Checkbox {
    background: #d9d9d9;
    padding: 1em 2rem;
    border-radius: 4px;
  }

  .CreateDonationForm__Submit {
    display: flex;
    justify-content: flex-end;
  }

  textarea {
    resize: none;
  }

  .TextAreaField__Counter {
    text-align: right;
    font-size: .7em;
    display: block;
    width: 100%;
    line-height: normal;
    opacity: .5;
  }

}

.CreateDonationForm__SubmitWrapper {
  display: flex;
  justify-content: flex-start;

  .ant-btn {
    height: 40px;
    font-weight: 400;
  }
}

@media screen and (max-width: 600px) {
  .CreateDonationForm {
    padding-right:1em;
  }
}
