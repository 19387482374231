@import '~instantsearch.css/themes/algolia.css';

section.TransactionsFilterPage.ant-layout {
  background-color: #f5f7f7;
}

/* Algolia Overwrite */
.ais-Hits-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Results-list {
  margin: 0;
  padding: 0;
}

.ais-Hits-item,
.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Results-item {
  margin: 0;
  padding: 0;
}

.ais-Hits {
  padding-right: 4rem;
}

[class^='ais-'] {
  font-size: 1em;
  color: #545658;
}

.ais-InstantSearch__root .SearchBar__Divider h1{
	margin-top: 1em;
}

/* Search Zone */
.ais-SearchBox-form {
  button.ais-SearchBox-submit {
    svg path {
      fill: #eb3052;
      stroke: #eb3052;
      stroke-width: 2px;
    }

    margin-left: 2px;
  }
}

.betaMessage {
  margin: 0 4rem;
}

/* Filter Zone */
.halfFilter,
.columnFilter {
  display: flex;
  justify-content: space-between;
}

.algPanelHalf {
  flex: 0 0 48.5%;
}

.algPanelCol {
  flex: 0 0 100%;
}

.Alg_Ref_Cta {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;

  .ais-ClearRefinements {
    flex: 0 0 20%;
  }

  .ais-CurrentRefinements {
    flex: 0 0 79%;
  }

  .ais-ClearRefinements-button {
    background-color: #4a4a4a;

    &.ais-ClearRefinements-button--disabled {
      background-color: #ccc;
    }
  }
}

.ais-Panel {
  // background-color:#fff;
  margin-bottom: 1em;

  // padding:1em;
  // border:1px solid #ccc;
  h3 {
    margin: 1em 0 0;
    font-size: 1.3em;
  }

  .ais-Panel-header {
    margin: 0;
    padding: 0;
    border: 0;
    text-transform: none;
    font-weight: 500;
  }

  a {
    color: #eb3052;
  }
}

.Alg_SearchZone {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding: 0 4rem 1em;
  position: relative;

  input[type='search'] {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 2.55em;
    padding-left: 3em;
  }

  .btnAlg {
    position: absolute;
    top: 0.25em;
    z-index: 2;
    color: #eb3052;
    font-size: 1.3em;

    &.search {
      left: 2.5em;
    }

    &.filter {
      right: 2.5em;

      &.closed {
        color: #c8d5d5;
      }
    }
  }
}

.Alg_Container {
  display: flex;
  box-sizing: border-box;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  // padding:0 4rem 0 0;
}

.Alg_FilterZone {
  flex: 0 0 0;
  padding: 0 0rem;
  order: 2;
  display: none;
  transition: all 0.5s ease-out;
  flex-wrap: wrap;

  &__title-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.8rem;
    margin-bottom: 1rem;

    .Alg_FilterZone__btn-close {
      font-size: 2.4rem;
      line-height: normal;
      color: #CBCDCC;

      &:hover {
        color: darken(#CBCDCC, 10%);
      }
    }
  }

  h2 {
    font-size: 1.8rem;
    margin-right:  1rem;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 600;

    svg {
      cursor: pointer;
      color: var(--primary-color);
      margin-left: 1rem;
    }
  }

  hr {
    border: 0;
    background-color: none;
    border-top: 3px solid #ddd;
    width: 100%;
  }

  &.opened {
    display: flex;
    flex: 1 1 30%;
    padding: 0 4rem 0 1em;
    background-color: #e7e9e9;
    align-content: flex-start;
  }

  &-actions {
    display: flex;
    flex-wrap: wrap;
    margin: -0.4rem;

    button {
      margin: 0.4rem;
    }
  }
}

.ais-ToggleRefinement-checkbox {
  margin-right: 0.5em;
}

.Alg_Results {
  flex: 0 0 100%;
  order: 1;
  padding: 0 0 0 4rem;
  background-color: #ecedee;
  transition: all 0.5s ease-out;

  &.opened {
    flex: 0 0 63.5%;
    padding-right: 1em;

    .hitActions {
      padding: 1em 0;
    }

    .ais-Hits {
      padding: 0;
    }
  }
}

.ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
}

.ais-Hits-item {
  flex: 0 0 100%;
  margin-bottom: 1em;
  padding: 0;
  border: 0;

  .hit-message {
    word-break: break-all;
  }
}

/* Hierarchical menu widget */
.ais-HierarchicalMenu-list {
  li {
    padding: 0.5em;
    background-color: #fff;
    // &:nth-child(even){
    // 	background-color:#eee;
    // }
  }
}

.ais-HierarchicalMenu-count {
  background-color: #eb3153;
  color: #fff;
  float: right;
}

/* Range Input Widget */
.ais-RangeInput {
  .ais-RangeInput-submit {
    padding: 0.2em 0.5em;
    background-color: #eb3052;
    height: 2em;
    color: #fff;
  }
}

.ais-RangeInput-form {
  display: flex;
  justify-content: space-between;
}

.Alg_FilterZone input[type='number'] {
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 2em;
  flex: 1 1 auto;
  padding-left: 0.5em;
}

/* Refinement List Widget (checkboxes) */
.ais-RefinementList {
  box-sizing: border-box;
  margin-bottom: 1em;
}

.ais-RefinementList-list {
  li {
    padding: 0.5em;
    background-color: #fff;

    .ais-RefinementList-count {
      float: right;
      background-color: #eb3153;
      color: #fff;
    }
  }

  .ais-RefinementList-checkbox {
    margin-right: 5px;
  }
}

.AlgoliaDateRange,
.AlgoliaPriceRange {
  margin: -.4rem;

  .ant-calendar-picker,
  .ant-input-number {
    width: calc(50% - 0.8rem);
    margin: .4rem;
  }
}

.AlgoliaDateRange .fa-calendar-alt {
  color: var(--primary-color);
}

.AlgoliaMenuSelect {
  width: 100%;
}

/* Top Pager - Actions */
.hitActions {
  display: flex;
  justify-content: space-between;
  padding: 1em 4rem 1em 0;
}

.ais-Stats-text {
  font-weight: 600;
  font-size: 1.15em;
  color: #000;
}

.hitControls {
  flex: 0 0 75%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ais-SortBy {
  margin-right: 1em;
}

.algPageInfo {
  margin-right: 1em;
}

/* Result Tiles */
.donation-hit {
  padding: 1em;
  position: relative;
  color: #333;
  border-left: 6px solid #9cd6d9;
  border-radius: 5px;
  background-color: #fff;

  .hit-donator-name {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    font-weight: bold;
    background-color: #333;
    color: #fff;
    padding: 0.5em;
    font-size: 1em;
  }

  .hit-ref-area {
    font-weight: bold;
    padding: 0.5em;
    font-size: 0.9em;

    p {
      margin: 0;
    }
  }

  .hit-donator-reference {
    color: #eb3052;
  }

  .hitSubtitle {
    font-weight: bold;
    display: block;
  }

  .hit-donator-layout {
    display: flex;
    justify-content: space-between;
    font-size: 1em;
    width: 100%;

    .hit-donator-info {
      flex: 0 0 40%;
    }

    .hit-recipient-info {
      flex: 0 0 40%;
    }

    .hit-recipient-logo {
      flex: 0 0 20%;
    }

    .hit-receipt {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 1.5em;
    }

    .hit-cta {
      display: flex;
      justify-content: right;
      align-items: center;
      padding-left: 1rem;
    }
  }

  .btnEditDonation {
    font-size: 1.8rem;
    color: var(--primary-color);
    position: relative;
    border: none;
    background: none;
    letter-spacing: normal;
    line-height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    overflow: visible;
    padding: 2px 0 0;
  }

  .donation-id {
    font-weight: 600;
    font-size: 1.1em;
    margin-bottom: 0.5em;
  }

  .bottom-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    padding-top: 0.75em;
    border-top: 3px solid #f5f7f7;
  }

  .subLabels {
    font-weight: 600;
  }

  .hit-status {
    display: inline-block;
    background-color: #e8e9e9;
    color: #333;
    padding: 0.3em 1em;
  }
}

.dateRangeFormContainer {
  background-color: #fff;

  .algRangeInput {
    border: 0;
  }
}

/* Pagination */
.ais-Pagination {
  margin: 0 0 3em 0;
}

.ais-Pagination-list {
  a,
  span {
    padding: 0.11em 0.7em;
    color: #000;
    border-radius: 5px;

    &.ais-Pagination-link--selected {
      background-color: #eb3052;
      color: #fff;
      border-color: #eb3052;
    }
  }
}

.algPricerange form {
  background-color: #fff;

  input[type='number'] {
    border: 0;
  }
}

// #delete-wrap {
//   .ant-popover-buttons {
//     .ant-btn:first-of-type {
//       background-color:
//       color: var(--primary-color);
//     }

//     .ant-btn:last-of-type {
//       color: var(--secondary-color);
//     }
//   }
// }

// When smaller then 1400
@media only screen and (max-width: 1400px) {
  .ais-MenuSelect-select {
    width: 98%;
  }

  .Alg_FilterZone {
    .ais-RangeInput-form {
      flex-wrap: wrap;

      .ais-RangeInput-input {
        flex: 1 1 100%;
        margin: 1em;
        border: 1px solid #ccc;
      }

      .ais-RangeInput-submit {
        margin: 0.5em 1em 1em;
      }
    }

    .dateRangeFormContainer {
      flex-wrap: wrap;

      .algRangeInput {
        flex: 1 1 100%;
        margin: 1em;
        border: 1px solid #ccc;
      }
    }

    .ais-RangeInput-separator {
      margin: 0 1em;
    }
  }

  .AdminLayout .dateRangeFormContainer button.ant-btn {
    margin: 0.5em 1em 1em;
  }

  .Alg_Container {
    flex-wrap: wrap;
  }

  .Alg_FilterZone.opened {
    flex: 0 0 100%;
    order: 1;
    padding: 0 4rem;
  }

  .Alg_Results.opened {
    flex: 0 0 100%;
    order: 2;
    padding: 0 4rem;
  }
}

// When smaller then 1000
@media only screen and (max-width: 1100px) {
  .columnFilter,
  .halfFilter {
    flex-wrap: wrap;
  }

  .algPanelCol {
    flex: 0 0 100%;
  }
}

// When smaller then 1100
@media only screen and (max-width: 1100px) {
  span.wherenow {
    display: none;
  }

  .hitActions {
    flex-wrap: wrap;
  }

  .ais-Stats {
    flex: 0 0 100%;
  }

  .hitControls {
    flex: 0 0 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    .algPageInfo {
      order: 5;
      flex: 0 0 100%;
    }

    .ais-SortBy {
      order: 1;
      flex: 0 0 48%;
      margin: 0;
    }

    .ais-HitsPerPage {
      order: 2;
      flex: 0 0 48%;
      text-align: right;
    }

    span.cta {
      display: flex;
      justify-content: space-between;
      margin-top: 1em;
    }
  }
}

// When smaller then 1200
@media only screen and (max-width: 1200px) {
  .betaMessage {
    margin: 0 2rem;
  }
}

// When smaller then 800
@media only screen and (max-width: 1000px) {
  .donation-hit .hit-donator-layout {
    flex-wrap: wrap;
  }

  .donation-hit .hit-donator-layout .hit-donator-info,
  .donation-hit .hit-donator-layout .hit-recipient-info {
    flex: 0 0 95%;
  }

  .hit-recipient-info {
    margin: 1em 0;
  }

  .donation-hit .hit-donator-layout .hit-receipt {
    flex: 0 0 50%;
  }
}

// When smaller then 500
@media only screen and (max-width: 500px) {
  .Alg_SearchZone {
    padding: 0 1rem 1em;

    .btnAlg.search {
      left: 1em;
    }

    .btnAlg.filter {
      right: 1em;
    }
  }

  .Alg_Results {
    padding: 0 1rem;
  }
}
