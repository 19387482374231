.ConfirmationPanel input,
.ConnectionForm__Field input,
.ConnectionForm__CompactField input {
  height: 55px;
}
.ConfirmationPanel .ant-btn,
.ConnectionForm__Field .ant-btn,
.ConnectionForm__CompactField .ant-btn {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}
.ConnectionForm__Divider {
  display: flex;
}
.ConnectionForm__Divider .ConnectionForm__Field {
  flex-grow: 1;
}
.ConnectionForm__Divider .ConnectionForm__Field:first-child {
  margin-right: 12px;
}
.ConnectionForm__Divider .ConnectionForm__Field:last-child {
  margin-left: 12px;
}
.ConnectionForm__HiddenField {
  display: none;
}
.ConnectionForm__CompactField .ant-form-item-control {
  line-height: 1;
}
.ConnectionForm__EndNote {
  text-align: center;
}
.RegistrationConfirmationPanel p {
  font-size: 20px;
  text-align: center;
  margin-left: 60px;
  margin-right: 60px;
}
.RegistrationConfirmationPanel button {
  display: block;
  width: 100%;
}
.RegistrationConfirmationPanel__TitleWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.RegistrationConfirmationPanel__TitleWrapper--success {
  font-size: 48px;
  color: #64C579;
  margin-right: 14px;
}
.RegistrationConfirmationPanel__TitleWrapper--error {
  font-size: 48px;
  color: #F3704A;
  margin-right: 14px;
}
.RegistrationConfirmationPanel__TitleWrapper h1 {
  display: inline;
  margin-bottom: 0;
}
