@import (reference) "../../assets/stylesheets/main";
@import (reference) "../../common/HelpText";

.CreateInformationForm {
  padding-top: @padding-lg;
  max-width: 680px;

  .CreateInformationForm__Divider {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: @padding-lg;

    button {
      .secondary-btn();
    }

    .ant-select {
      display: inline;
    }
  }

  .AvatarThumbnail__PrimaryContent {
    font-size: @font-size-lg;
    font-weight: bold;
    font-family: @font-family-title;
  }

  .CreateInformationForm__Field {
    display: flex;

    >div {
      flex: 1;
    }
  }

  .CreateInformationForm__Field--Short>div {
    // For non full-screen fields: reset the flex: 1;
    flex: initial;
  }

  .CreateInformationForm__Submit {
    display: flex;
    justify-content: flex-end;
  }

}

.CreateInformationForm__SubmitWrapper {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 580px) {
  .AvatarThumbnail--title {
    .AvatarThumbnail__Avatar {
      height: 90px;
      width: 90px;
      flex: 0 0 90px;
      margin-right: 0;
    }

    .AvatarThumbnail__PrimaryContent {
      font-size: 2.5rem !important;
    }
  }
}
