@import (reference) "../../assets/stylesheets/main";

.ConfirmationPanel,
.ConnectionForm__Field,
.ConnectionForm__CompactField {
  // Antd "large" size is 40px, we need extra-large
  input {
    height: @button-xl;
  }

  .ant-btn {
    height: 50px;
    line-height: 50px;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.ConnectionForm__Divider {
  display: flex;

  .ConnectionForm__Field {
    flex-grow: 1;

    // The following aims at defining the same horizontal space between the first name and last
    // name as the vertical space betwwen the other fields
    &:first-child {
      margin-right: @form-item-margin-bottom / 2;
    }

    &:last-child {
      margin-left: @form-item-margin-bottom / 2;
    }
  }
}

.ConnectionForm__HiddenField {
  display: none;
}

.ConnectionForm__CompactField .ant-form-item-control {
  // Antd default line height is 40px, it gives a extra space making the narrow field (checkbox,
  // radio) too far from the others fields
  line-height: 1;
}

.ConnectionForm__EndNote {
  text-align: center;
  //margin-bottom: @form-item-margin-bottom;
}
