.PresentationSection {
  padding: 48px 0;
  background-color: #F5F7F7;
}
.PresentationSection .bg-amount {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  padding: 20px 0px;
  font-weight: 600;
  font-size: 23px;
}
.PresentationSection .bg-amount .title {
  margin-right: 20px;
  color: #fff;
  max-width: 100px;
  line-height: 1.1;
}
.PresentationSection .bg-amount .bg-white {
  color: #fff;
  font-weight: 600;
  margin: 0px 5px 0px 0px !important;
}
.PresentationSection .bg-white-gradiant {
  background-color: #fff;
  position: relative;
  margin-bottom: 0px;
  border-radius: 5px;
  width: 30px;
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.PresentationSection .bg-white-gradiant::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 12.24%);
}
.PresentationSection .ant-divider {
  margin-top: 24px;
  margin-bottom: 24px;
}
.PresentationSection h1 {
  min-width: 200px;
  word-wrap: break-word;
  hyphens: auto;
}
.PresentationSection h2 {
  text-align: center;
}
.PresentationSection__Information {
  display: flex;
}
.PresentationSection__Information h2 {
  text-align: left;
  font-size: 25px !important;
}
.PresentationSection__Information h3 {
  font-size: 20px;
  font-weight: bold;
  font-family: Domine, serif;
}
.PresentationSection__Information h4,
.PresentationSection__Information h5,
.PresentationSection__Information h6 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
}
.PresentationSection__Information h1 {
  flex: 1;
}
.PresentationSection__Information div {
  flex: 3;
  padding-left: 24px;
  padding-top: 8px;
}
.PresentationSection__CallToActions {
  display: flex;
  padding-top: 48px;
  justify-content: center;
}
.PresentationSection__CallToAction.FundkyCTA {
  justify-content: flex-start;
  width: 300px;
  min-height: 300px;
  padding: 40px;
}
.PresentationSection__CallToAction.FundkyCTA .ant-btn {
  margin-top: auto;
}
.PresentationSection__CallToAction.FundkyCTA h3,
.PresentationSection__CallToAction.FundkyCTA p {
  width: 100%;
}
.PresentationSection__CallToAction.FundkyCTA:not(.PresentationSection__CallToAction--Primary) > a,
.PresentationSection__CallToAction.FundkyCTA .RegisterDropdown {
  border-color: var(--link-color);
  background-color: var(--link-color);
  color: #FFFFFF;
}
.PresentationSection__CallToAction.FundkyCTA:not(.PresentationSection__CallToAction--Primary) > a:hover,
.PresentationSection__CallToAction.FundkyCTA .RegisterDropdown:hover {
  border-color: var(--link-hover-color);
  background-color: var(--link-hover-color);
  color: #FFFFFF;
}
.PresentationSection__CallToAction.FundkyCTA svg {
  color: var(--link-color);
}
.PresentationSection__CallToAction.FundkyCTA.PresentationSection__CallToAction--Primary svg {
  color: var(--primary-color);
}
@media screen and (max-width: 720px) {
  .PresentationSection__Information {
    flex-direction: column;
    align-items: center;
    overflow-wrap: break-word;
  }
  .PresentationSection__Information > h1 {
    min-width: inherit;
  }
  .PresentationSection__Information p {
    padding-left: 0;
  }
  .PresentationSection__Information > div {
    width: 100%;
    padding: 0;
  }
  .PresentationSection .ant-divider-horizontal {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6.4rem;
  }
  .PresentationSection__CallToActions {
    flex-direction: column;
    align-items: center;
  }
  .PresentationSection__CallToAction {
    width: 100%;
    min-height: 240px;
  }
}
