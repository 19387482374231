.AdminHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 0 4rem;

  .ant-menu-horizontal {
    border-bottom: none;
  }

  .ant-menu-horizontal>.ant-menu-item,
  .ant-menu-horizontal>.ant-menu-submenu {
    border-bottom: none;
    transition: none;
  }

  .ant-menu-submenu-title svg {
    margin-left: 2px;
    margin-right: 0;
    color: #EB3052;
  }

  // User menu (impossible to specify a class to the Dropdown Antd component)
  span:last-child {
    cursor: pointer;
    line-height: normal;
    align-self: center;
  }

  .locale {
    text-transform: uppercase;
  }
}

.ant-menu-sub .ant-menu-item>a:hover,
.ant-menu-sub .ant-menu-item:hover {
  color: var(--link-color);
}

.AdminHeader__PlatformSubMenuIcon {
  color: var(--secondary-color);
  margin-right: 16px;
}

.mobileOnly {
  display: flex;
  align-items: center;

  &>a {
    color: rgba(0, 0, 0, 0.65);
  }
}

@media only screen and (max-width: 585px) {

  #headerFix,
  #headerFix .ant-affix {
    width: 100% !important;
  }

  .AdminHeader {
    position: fixed;
    width: 100%;

    &+main {
      margin-top: 3em;
    }
  }
}


@media only screen and (min-width: 1023px) {
  .mobileOnly {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .AdminHeader {
    padding: 0 2rem 0 6rem;
  }

  .AdminSider:not(.ant-layout-sider-collapsed)+i+section.ant-layout .AdminHeader {
    padding: 0 0 0 2rem;
  }

}

@media only screen and (max-width: 1024px) {
  .desktopOnly {
    display: none;
  }

  .AdminHeader {
    padding-right: 0;

    .mobileOnly ul svg {
      font-size: 24px;
      position: relative;
      top: 4px;
    }
  }

  .ant-menu-submenu .ant-menu-submenu-title span svg {
    display: none;
  }
}
