.DashboardPage {
  display: inline;
}
.DashboardPage__Content {
  padding: 0 4rem 3rem;
  background-color: #F4F7F7;
}
.DashboardPage__Content_Actions {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.DashboardPage__Content_Actions button {
  margin-bottom: 1rem;
}
.DashboardPage__Content_Actions button:not(:last-of-type) {
  margin-right: 1rem;
}
.DashboardPage__Content_Actions button a {
  color: #fff !important;
  font-weight: bold;
}
.DashboardPage .WinkNotification {
  padding-bottom: 0 !important;
}
