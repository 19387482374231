.SettingsPage {
  .ant-form-item {
    display: flex;
  
    .ant-form-item-label {
      flex: 1;
      text-align: left;
    }
  
    .ant-form-item-control-wrapper {
      flex: 3;
    }
  }
}