@import (reference) "../assets/stylesheets/main";

.PublicCampaignBand {
  width: 100%;
  position: relative;

  &__Container {
    padding: @padding-md 0;
    height: 100%;
    background-color: #383D3F;
  }

  &__Container .FundkyWrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__BackIcon {
    color: white;
    font-size: 2.5em;
  }

  &__Avatar {
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 20px;
    background-color: var(--link-color);
  }

  &__Campaigns {
    max-width: 50%;

    & > .HelpText {
      color: rgba(255, 255, 255, 0.45);
      font-weight: 300;
    }


    .PublicTitleDropdown {
      h1 {
        font-size: 21px;
        color: white;
        margin: 0;
        line-height: 24px;
        font-family: 'Assistant', sans-serif;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      svg {
        margin-left: 12px;
        color: white;
      }
    }
  }

  &__AboutButton {
    color: white;
    font-size: 14px;
    margin-left: auto;
    background-color: var(--link-color);
    border-color: var(--link-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    &:focus {
      background-color: var(--secondary-color) !important;
      border-color: var(--secondary-color) !important;
    }

    span {
      margin-right: 10px;
    }
  }

  &__EllipsisIcon {
    font-size: 24px;
    font-weight: 700;
  }

  @media screen and (max-width: 680px) {
    .PublicCampaignBand__BackIcon {
      font-size: 3rem;
    }

    .PublicCampaignBand__Avatar {
      margin: 0 12px;
    }

    &__AboutButton span {
      display: none;
    }
  }

  @media screen and (max-width: 420px) {
    .PublicCampaignBand__BackIcon {
      font-size: 3rem;
    }

    .PublicCampaignBand__Avatar {
      margin: 0 12px;
    }

    &__AboutButton span {
      display: none;
    }
  }

  @media screen and (max-width: 380px) {
    .PublicCampaignBand__Avatar {
      margin: 0 10px;
    }

    .PublicCampaignBand__AboutButton {
      padding: 0 12px;
    }
  }
}

.PublicCampaignBand__WaveBorder {
  height: 7px;
  &:after {
    content: "";
    display: block;
    height: 8px;
    background-repeat: repeat;
    background-size: 16px 8px;
    background-image: url("../assets/images/footer_border.svg");
    transform: rotate(180deg);
  }
}
