.PagesHomepageSliderPanel .slides-wall {
  counter-reset: section;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-picture-card-container,
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-item {
  height: 147px;
  width: 210px;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-item {
  padding: 0;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-item-info {
  border-radius: 4px;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-item-info > span {
  height: 100%;
  display: flex;
  background-color: #fff;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-item-info > span .ant-upload-list-item-thumbnail {
  height: auto;
  max-height: 100%;
  margin: auto 0;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-picture-card .ant-upload-list-item-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: rgba(250, 250, 250, 0.85);
  left: inherit;
  top: inherit;
  transform: inherit;
  bottom: 0;
  width: 100%;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-picture-card .ant-upload-list-item-actions::before {
  margin-right: auto;
  display: inline-block;
  margin-left: 0.4rem;
  counter-increment: section;
  content: "# " counter(section);
}
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-picture-card .ant-upload-list-item-actions i {
  color: #EA3153;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload-list-picture-card .ant-upload-list-item-actions i:hover {
  color: #EA3153;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload.ant-upload-select-picture-card {
  border: 0;
}
.PagesHomepageSliderPanel .slides-wall .ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}
.PagesHomepageSliderPanel .add-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #fff;
  cursor: pointer;
  width: 210px;
  height: 147px;
}
.PagesHomepageSliderPanel .add-slide h3 {
  font-weight: bold;
  color: #545658;
}
.PagesHomepageSliderPanel .add-slide span {
  color: #545658;
}
.PagesHomepageSliderPanel .add-slide svg {
  font-size: 3rem;
  color: var(--secondary-color);
}
.PagesHomepageSliderPanel .form-add-slide .ant-upload-list-picture-card .ant-upload-list-item {
  width: auto;
  height: auto;
}
.PagesHomepageSliderPanel .form-add-slide .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.PagesHomepageSliderPanel .form-add-slide .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 100%;
  height: auto;
}
.PagesHomepageSliderPanel .buttons {
  margin-top: 1rem;
  clear: both;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.PagesHomepageSliderPanel .buttons .SettingsForm__Submit {
  margin: 0;
}
.PagesHomepageSliderPanel .buttons .btn-cancel {
  height: 32px;
}
.ant-modal-content .form-add-slide .ant-upload-list-picture-card .ant-upload-list-item {
  width: auto;
  height: auto;
}
.ant-modal-content .form-add-slide .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-modal-content .form-add-slide .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 100%;
  height: auto;
}
.ant-modal-content .form-add-slide svg.fa-info-circle {
  color: #AC8EC3;
}
