@import (reference) "../../../assets/stylesheets/main";

// No choice here: getFieldDecorator removes the element class and add an id
.AmountOptions {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 -3px;

  // Add a new feedback icon in the right
  // Repro Antd behavior: the pseudo-element is always here but empty
  &:after {
    position: absolute;
    top: 50%;
    left: calc(~'100% + 10px');
    margin-top: -10px;
    width: 32px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: @form-feedback-icon-size;
    animation: zoomIn .3s @ease-out-back;
  }
  > * {
    max-width:19%;
    min-width:19%;
  }
  @media only screen and (max-width: 660px) {
    & > * {
      max-width:32%;
      min-width:32%;
    }
  }
  @media only screen and (max-width: 470px) {
    & > * {
      min-width: 47.9%;
      max-width: 47.9%;
    }
  }

  > span.ant-input-group-wrapper {
    flex: 1.3;
    top: 0;
    margin: 0 3px 6px 3px;
  }

  .ant-btn {
    border-color: @input-bg;
    margin-bottom: 6px;
  }

  .ant-btn:hover, .ant-btn:focus {
    color: #fff;
  }

  .ant-input-group {
    display: flex;

    input {
      line-height: 54px;
      height: 56px;
      
    }
  }

  .ant-input-group-addon {
    position: absolute;
    top: 0;
    z-index: 9;
    border: none;
    margin: 1px;
    width: 30px;
    display: block;
    background-color: #fff;
    font-size: 24px;
    line-height: 54px;
    padding: 0 !important;
    border: none !important;
    border-radius: 4px !important;
  }

  &__Option--Selected,
  &__Option--Selected + .ant-input-group-addon {
    background-color: var(--primary-color) !important;
    color: #fff;
    border-color: var(--primary-color);
  }

  input {
    font-size:24px;
    border-radius:4px !important;
  }

  &-left {
    .ant-input-group {
      .AmountOptions__OtherAmountInput {
        padding-left: 31px !important;
        text-align: left;
      }

      input {
        text-align: right;
      }

      &-addon {
        left: 0;
      }
    }
  }

  &-right {
    .ant-input-group {
      .AmountOptions__OtherAmountInput {
        padding-right: 31px !important;
        text-align: right;
      }

      input {
        text-align: left;
      }

      &-addon {
        right: 0;
      }
    }
  }


  &__OtherAmountInput {
    height: 56px;
    box-sizing: border-box;
    font-size: 24px;
    border-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &:hover + .ant-input-group-addon,
    &:focus + .ant-input-group-addon {
      border-color: var(--primary-color) !important;
      outline: none;
    }
  }
}

.has-error .AmountOptions {
  .AmountOptions__Option {
    border: 1px solid @error-color;
  }

  &__OtherAmountInput {
    &:hover,
    &:focus {
      border-color: var(--primary-color) !important;
      + .ant-input-group-addon {
        border-color: var(--primary-color) !important;
        outline: none;
      }
    }
  }

  &-left {
    .ant-input-group {
      &-addon {
        border-right: none;
      }
    }
  }

  &-right {
    .ant-input-group {
      &-addon {
        border-left: none;
      }
    }
  }
}

.AmountOptions__Option {
  background-color: @input-bg;
  flex: 1;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 56px;
  color: #fff;
  background-color: rgba(0, 0, 0, .65);
  font-size: 24px;
  margin: 0 3px;

  i {
    font-size: 36px;
    padding-top: @padding-lg;
    color: var(--link-color);
  }

  h3 {
    margin: 0;
  }

  p {
    // Because we are inside a form control, we need to reset the Antd input line-height
    line-height: initial;
    text-align: center;
    margin-bottom: 30px;
  }
}
