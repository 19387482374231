.CreateDonationForm {
  padding-top: 24px;
  max-width: 680px;
}
.CreateDonationForm #is .ant-tooltip-content p {
  margin: 0;
}
.CreateDonationForm .CreateDonationForm__Field {
  display: flex;
}
.CreateDonationForm .CreateDonationForm__Field > div {
  flex: 1;
}
.CreateDonationForm .CreateDonationForm__Field--Short > div {
  flex: initial;
}
.CreateDonationForm .TaxReceipt__Checkbox {
  background: #d9d9d9;
  padding: 1em 2rem;
  border-radius: 4px;
}
.CreateDonationForm .CreateDonationForm__Submit {
  display: flex;
  justify-content: flex-end;
}
.CreateDonationForm textarea {
  resize: none;
}
.CreateDonationForm .TextAreaField__Counter {
  text-align: right;
  font-size: 0.7em;
  display: block;
  width: 100%;
  line-height: normal;
  opacity: 0.5;
}
.CreateDonationForm__SubmitWrapper {
  display: flex;
  justify-content: flex-start;
}
.CreateDonationForm__SubmitWrapper .ant-btn {
  height: 40px;
  font-weight: 400;
}
@media screen and (max-width: 600px) {
  .CreateDonationForm {
    padding-right: 1em;
  }
}
