.PlatformSettingsForm {
  em {
    line-height: 1em;
    display: block;
    margin-top: 10px;
  }
  .PlatformCommunicationName__Field {
    margin-bottom: 0;
  }
}
