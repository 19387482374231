@import (reference) "../../assets/stylesheets/main";

.FundkyModal {
  position: relative;

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }

  .ant-modal-body {
    background-color: #f5f7f7;
    border-radius: 4px;
  }

  h1.FundkyModal--title {
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 3px solid #e8e9e9;
    padding-bottom: 18px;
    svg {
      font-size: 1.5em;
      color: var(--secondary-color);
      margin-right: 10px;
    }
  }

  .ant-modal-close-x {
    display: none;
  }

  &--close {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.65) !important;
    font-size: 20px;

    &:hover,
    &:focus,
    svg:hover,
    svg:focus {
      color: rgba(0, 0, 0, 1) !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }

    svg {
      margin: 1rem;
    }
  }

  &--actions {
    display: flex;
    justify-content: flex-end;
    margin: 2.4rem -.7rem 0 -.7rem;

    button {
      margin: 0 .7rem;
    }
  }

  &.scrollContent {
    .FundkyModal--content {
      overflow: auto;
      max-height: ~"calc(80vh - 100px)";
    }
  }
}
