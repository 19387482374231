.CreatePersonalizationForm {
  padding-top: 24px;
  max-width: 700px;
}
.CreatePersonalizationForm__ContentLangSwitcher {
  display: flex;
  background-color: #D8FDFF;
  padding: 1.8rem;
  margin-bottom: 2.4rem;
}
.CreatePersonalizationForm__ContentLangSwitcher .fa-exclamation-circle {
  color: #5CAFB6;
  font-size: 2.4rem;
  margin-right: 1.8rem;
  margin-top: 0.8rem;
}
.CreatePersonalizationForm__ContentLangSwitcher label {
  font-weight: bold;
}
.CreatePersonalizationForm__ContentLangSwitcher .TextAreaMultiLangSelect {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.CreatePersonalizationForm__ContentLangSwitcher .TextAreaMultiLangSelect .ant-select-selection {
  background: none;
  border: none;
}
.CreatePersonalizationForm__ContentLangSwitcher .TextAreaMultiLangSelect .ant-select-arrow {
  margin-top: -1rem;
}
.CreatePersonalizationForm__Field {
  display: flex;
}
.CreatePersonalizationForm__Field > div {
  flex: 1;
}
.CreatePersonalizationForm__Submit {
  display: flex;
  justify-content: flex-start;
}
.CreatePersonalizationForm__Submit .btn-submit {
  height: 40px;
  font-weight: 400;
}
.CreatePersonalizationForm__Dropdown {
  width: auto !important;
}
.CreatePersonalizationForm__SubmitWrapper {
  display: flex;
  width: 66.66666667%;
}
.CreatePersonalizationForm__SubmitWrapper > * {
  margin-right: 10px;
}
.CreatePersonalizationForm__CancelWrapper .btn-draft {
  margin-right: 1rem;
}
