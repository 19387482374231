.DesignSettingsForm .swatches > .ant-form-item-control-wrapper {
  flex-wrap: wrap;
}
.DesignSettingsForm .swatches > .ant-form-item-control-wrapper > label {
  margin-bottom: 0.4rem;
  width: 100%;
}
.DesignSettingsForm .swatches .ColorPickerField + .ColorPickerField {
  margin-left: 1rem;
}
