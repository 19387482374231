@import (reference) "../../assets/stylesheets/main";

.HomePage {
  h2 {
    font-size: 2em;
    font-family: @font-family-title;
    font-weight: bold;
  }

  .HomePage__Carousel {

    .slider {
      visibility: inherit !important;
    }

    .slider,
    .slider-frame,
    .slider-list,
    .slider-slide {
      min-height: 1px;
      width: 100% !important;
    }

    .slider-slide > img,
    .slider-slide > a,
    .slider-slide > a > img {
      width: 100%;
      display: block;
    }

    .slider-slide img {
      min-width: 100% !important;
    }

    @media screen and (max-width: @screen-lg-max) {

      .slider,
      .slider-frame,
      .slider-list,
      .slider-slide {
      }
    }

    .slider-slide img {

      @media screen and (max-width: @screen-lg-max) {
      }
    }
  }
}
