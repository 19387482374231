.AdminTitleBar {
  padding: 3.4rem 4rem 3rem;
  background-color: #F4F7F7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.small-padding-bottom {
    padding: 3.4rem 4rem 1rem;
  }

  &--title {
    h1 {
      margin: 0;
      margin-right: 1.5em;
      line-height: 1em;
      word-break: break-word;
    }
  }
  @media only screen and (max-width: 620px) {
    &--title {
      h1 {
        font-size:1.6em;
      }
    }
  }

  &--upperTitle {
    text-transform: uppercase;
    margin-bottom: 0;
  }

  &--subTitle {
    color: #939393;
    font-weight: 300;
    font-size: 16px;
  }

  &--name {
    margin: 3rem 0 0;
    font-weight: 500;
    font-size: 24px;
  }

  &--buttons {
    display: flex;
    >span {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    a{white-space: nowrap !important;}
    svg {
      margin-left: 1rem;
    }
  }
}
