.CampaignSettingsPageForm {
  padding: 1.5rem;
  background: #fff;

  .ant-form-item {
    display: flex;

    .ant-form-item-label {
      flex: 1;
      text-align: left;

      label {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .ant-form-item-control-wrapper {
      flex: 3;
    }
  }

  .participants-limit-suffix {
    margin-left: 1rem;
  }
}
