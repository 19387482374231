// Import the common less main file
@import (reference) "../../assets/stylesheets/main.less";

.FundkyCard {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1.8rem;
  position: relative;
  max-width: 400px;
  width: 100%;

  &.no-overflow > .FundkyCard__Body {
      max-width: 80%;
      overflow: hidden;
  }

  &.hasIcon {
    padding-left: 6.5rem;
  }

  &.hasAction {
    .FundkyCard__Action {
      padding-left: 1.8rem;

      > *:not(:last-of-type) {
        margin-right: .6rem;
      }

      svg {
        font-size: 1.6rem;
      }
    }
  }

  &.hasCardAction {
    cursor: pointer;
  }

  &__Icon {
    position: absolute;
  }

  &__Icon {
    color: var(--secondary-color);
    font-size: 3rem;
    top: 0.7rem; left: 1.8rem;
    line-height: normal;
  }

  &__Body {
    line-height: normal;

    p.title {
      font-weight: bold;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}