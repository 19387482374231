.TeamPublicPage .TeamPublicPage__Menu .TeamPublicPage__MenuLink {
  width: 90% !important;max-width: 1200px;margin: auto;
}
@media screen and (max-width: 600px) {
  .TeamPublicPage .TeamPublicPage__Menu .TeamPublicPage__MenuLink {
    width: 95% !important;
  }
}
.TeamPublicPage .TeamPublicPage__Container {
  padding-bottom: 24px;
  display: flex;
  position: relative;
  width: 100%;
}
.TeamPublicPage .TeamPublicPage__Menu {
  flex: 1 0 320px;
  max-width: 392px;
  padding-right: 24px;
}
.TeamPublicPage .TeamPublicPage__Menu .TeamPublicPage__Thermometer {
  margin: 24px;
}
.TeamPublicPage .TeamPublicPage__Menu .PublicPageClosed__Thermo {
  margin-bottom: 2.4rem;
}
.TeamPublicPage .TeamPublicPage__Menu .TeamPublicPage__MenuLink {
  line-height: 60px;
  border-radius: 4px;
  border-left: 5px solid transparent;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 5px;
}
.TeamPublicPage .TeamPublicPage__Menu .TeamPublicPage__MenuLink svg {
  font-size: 28px;
  vertical-align: middle;
  margin-right: 24px;
}
.TeamPublicPage .TeamPublicPage__Menu .TeamPublicPage__MenuLink:hover {
  background-color: #FFFFFF;
}
.TeamPublicPage .TeamPublicPage__Menu .TeamPublicPage__MenuLink--Active {
  background-color: #FFFFFF;
  border-left: 5px solid var(--link-color);
}
.TeamPublicPage .TeamPublicPage__Content {
  flex: 1 1 auto;
  min-width: 0;
}
.TeamPublicPage .TeamPublicPage__Content > div:first-of-type {
  margin-top: 0;
}
.TeamPublicPage__Breadcrumbs {
  font-size: 12px;
  color: #AEADAE;
}
.TeamPublicPage__Breadcrumbs ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 12px;
  padding-left: 0;
}
.TeamPublicPage__Breadcrumbs li:last-of-type {
  color: #424749;
}
.TeamPublicPage__Breadcrumbs a {
  color: #AEADAE;
  cursor: pointer;
}
.TeamPublicPage__Breadcrumbs a:hover {
  color: var(--primary-color);
}
.TeamPublicPage__Breadcrumbs svg {
  font-size: 10px;
  color: var(--secondary-color);
  margin: 0 6px;
}
@media screen and (max-width: 980px) {
  .TeamPublicPage .PublicPanel__Footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
