@import (reference) "../../assets/stylesheets/main";

.ParticipantCreatePage {
  background-color: @hq-admin-background-bar;

  .ParticipantCreatePage__TitleDivider {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: @padding-lg * 2 0;

    h1,
    h2 {
      margin-bottom: 0;
    }
  }

  .ant-tabs-tab {
    padding-left: 40px;
    padding-right: 40px;
  }

  .ant-tabs {
    background-color: @body-background;
  }

  .ant-tabs-bar {
    background-color: @hq-admin-background-bar;

    i,
    svg {
      color: var(--link-color);
    }
  }

  &__TitleIcon {
    font-size: 56px;
    color: var(--primary-color);
    margin-right: 40px;
  }
}
