@import (reference) "../assets/stylesheets/main";

@stroke-width: 5%;

.PublicThermometer {
  text-align: center;

  &__CircularWrapper {
    position: relative;

    svg {
      // Needed, otherwise the div will take few pixels more in height, don't really know why...
      // @see http://jsfiddle.net/65h133wh/
      display: block;
    }
  }

  &__CircularParticipant {
    max-width: 258px;
    max-height: 258px;
    margin: 0 auto;
  }

  .CircularProgressbar-trail {
    stroke: #EEEEEE;
    stroke-width: @stroke-width;
  }

  .CircularProgressbar-path {
    stroke: var(--primary-color);
    stroke-width: @stroke-width;
  }

  &__InsideContent {
    position: absolute;
    top: @stroke-width * 2;
    left: @stroke-width * 2;
    right: @stroke-width * 2;
    bottom: @stroke-width * 2;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.hasEditButton:hover {
      .edit-profile {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .edit-profile {
      opacity: 0;
      pointer-events: none;
      transition: opacity .2s ease-in-out;
    }

    img {
      width: 100%;
    }
  }

  &__OutsideContent {
    margin-top: @padding-lg;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-family: @font-family-title;
      overflow-wrap: break-word;
      word-break: break-word;
      word-wrap: break-word;
      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }
  }

  &__Percentage {
    color: var(--primary-color);
    font-size: 48px;
  }

  &__Detail {
    font-size: @font-size-lg;
    color: @heading-color;

    b {
      font-weight: 600;
    }
  }

  &__DonateBtn {
    margin-top: 20px;
  }
}

.PublicThermometer--Compact {
  .PublicThermometer__Percentage {
    font-size: 7.4rem;
    line-height: 7.2rem;
  }

  .PublicThermometer__Detail {
    font-size: 2.4rem;
    line-height: normal;
  }
}

.horizontal-thermometer{
  background:#fff;
  padding:0;
  .numbers-wrapper, .horizontal-progress-bar{
   width:95%;
   margin:auto;
  }
  .numbers-wrapper{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-top:0.5em;
    .PublicThermometer__Detail, .PublicThermometer__Percentage{
      line-height:2.4rem;
    }
    .PublicThermometer__Detail{
      font-size:1.6rem;
    }
    .PublicThermometer__Percentage{
      font-weight:bold;
      font-size:2.4rem;
    }
  }
  .horizontal-progress-bar, .horizontal-progress-bar div{
    height:18px;
    border-radius:3px;
  }
  .horizontal-progress-bar{
    background:#E9EAEA;
    div{
      background:var(--primary-color);
      max-width:100%;
    }
  }
}

.PublicThermometer--Hide {
  &.PublicThermometer--Compact {
    .PublicThermometer__InsideContent {
      position: relative;
      border-radius: 0;
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
    }
  }
  &:not(.PublicThermometer--Compact):not(.PublicThermometer--Horizontal) {
    .PublicThermometer__CircularParticipant {
      height: 258px;
    }
  }
  &.CampaignSection__Thermometer:not(.PublicThermometer--Compact) {
    .PublicThermometer__CircularWrapper {
      height: 315px;
    }
  }
}
