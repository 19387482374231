@import (reference) "../../assets/stylesheets/main";

@resultThumbnailLiMargin: 4.5px;

.SearchPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @padding-lg * 2 0;

  .ant-tabs {
    width: 100%;

    .ant-tabs-bar {
      display: flex;
      text-align:center;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  > h1,
  > p {
    text-align: center;
  }

  .SearchBar {
    width: 75%;

    & input {
      height: 57px;
      font-size: 24px;
      padding-left:38px !important;
    }
  }

  .PaginationTop,
  .ResultThumbnailList {
    width: 75%;
    margin : 0 auto;
  }

  .ResultThumbnailList {
    li {
      margin: @resultThumbnailLiMargin;
    }

    .ant-card {
      border: 2px solid #E5E5E5;
      &:hover {
        background-color: #fff;
        border: 2px solid var(--primary-color);
        color: rgba(0, 0, 0, 0.65);
      }

      .ant-card-body {
        padding: 12px;
      }

      .AvatarThumbnail__Avatar {
        height: 46px;
        width: 46px;
        flex: 0 0 46px;
      }

      .AvatarThumbnail__Content {
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }

      .AvatarThumbnail__PrimaryContent {
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .card-disabled {
    border: 2px solid #E5E5E5;
    background-color: #fff;
    opacity: .5;
    &:hover {
      cursor: not-allowed;
      border: 2px solid #E5E5E5 !important;
      background-color: #fff !important;;
    }
  }

  @media screen and (max-width: 706px) {
    .PaginationTop,
    .ResultThumbnailList {
      width: 100%;
    }

    .ResultThumbnailList li {
      width: calc(~'50% - @{resultThumbnailLiMargin}*2');
    }
  }

  @media screen and (max-width: 500px) {
    .PaginationTop,
    .ResultThumbnailList {
      width: 100%;
    }

    .ResultThumbnailList {
      margin: 0 -@resultThumbnailLiMargin;
    }

    .ResultThumbnailList li {
      width: 100%;
      flex: 1 0 auto;
    }
  }

  @media screen and (max-width: 380px) {
    .PaginationTop {
      .ant-pagination {
        flex-wrap: wrap;

        .ant-pagination-total-text {
          flex: initial;
          margin-right: 0;
          width: 100%;
        }

        .ant-pagination-prev {
          margin-right: auto;
        }

        .ant-pagination-next {
          margin-left: auto;
        }
      }
    }
  }

}
