.SocialMediaField {
  &--facebook {

  }
  &--instagram {

  }
  &--linkedin {

  }
  &--pinterest {

  }
  &--twitter {

  }
  &.empty svg {
    color: #B8B8B8;
    path {
      fill: #B8B8B8;
    }
  }

  .ant-form-item-label {
    flex: none !important;
    width: 50px !important;
    text-align: center !important;

    svg {
      font-size: 24px;
      vertical-align: middle;
    }
  }
}
