.TransactionsPageForm__Field textarea,
.AddTransactionsPageForm__Field textarea {
  height: 6rem !important;
}
.TransactionsPageForm h3,
.AddTransactionsPageForm h3 {
  margin-top: 40px;
}
.TransactionsPageForm h3:first-of-type,
.AddTransactionsPageForm h3:first-of-type {
  margin-top: 0;
}
.TransactionsPageForm .ant-form-item,
.AddTransactionsPageForm .ant-form-item {
  margin-bottom: 9px;
}
.TransactionsPageForm .transaction-recipient-edit-icon,
.AddTransactionsPageForm .transaction-recipient-edit-icon {
  margin-left: 10px;
}
.TransactionsPageForm .transaction-recipient-edit-icon svg,
.AddTransactionsPageForm .transaction-recipient-edit-icon svg {
  font-size: 20px;
}
.TransactionsPageForm .request-update-button,
.AddTransactionsPageForm .request-update-button {
  margin-top: 1rem;
  margin-right: auto;
}
.TransactionsPageForm a#recipient,
.AddTransactionsPageForm a#recipient,
.TransactionsPageForm span#recipient,
.AddTransactionsPageForm span#recipient {
  margin-top: 1rem;
  display: inline-block;
}
.TransactionsPageForm .ant-calendar-picker-input,
.AddTransactionsPageForm .ant-calendar-picker-input {
  width: 200px;
}
.TransactionsPageForm .buttons-wrapper,
.AddTransactionsPageForm .buttons-wrapper {
  display: flex;
}
.TransactionsPageForm .CancelAndSaveButtons__wrap,
.AddTransactionsPageForm .CancelAndSaveButtons__wrap {
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .TransactionsPageForm .buttons-wrapper,
  .AddTransactionsPageForm .buttons-wrapper {
    flex-direction: column;
  }
}
