.AdminBackButtonBar {
  padding: 3.4rem 4rem 0;
  background-color: #F4F7F7;
}
.AdminBackButtonBar .lnk-back {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  color: #828281;
  margin-bottom: 0;
}
.AdminBackButtonBar .lnk-back svg {
  color: #828281;
}
.AdminBackButtonBar .lnk-back:hover {
  color: #5CAFB5;
}
.AdminBackButtonBar .lnk-back:hover svg {
  color: #5CAFB5;
}
