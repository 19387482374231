.PagesHomepagePage {
  .ant-tabs {
    clear: both;
  }

  .PagesHomepageSliderPanel,
  .PagesHomepageIntroTextPanel,
  .PagesHomepageFeaturedCampaignPanel {
    .panel-title {
      float: left;
    }

    .panel-content {
      clear: both;
    }
  }
}
