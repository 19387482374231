.SettingsPage .ant-form-item {
  display: flex;
}
.SettingsPage .ant-form-item .ant-form-item-label {
  flex: 1;
  text-align: left;
}
.SettingsPage .ant-form-item .ant-form-item-control-wrapper {
  flex: 3;
}
