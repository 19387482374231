.FundkyModal {
  position: relative;
}
.FundkyModal .ant-form-item-with-help {
  margin-bottom: 24px;
}
.FundkyModal .ant-modal-body {
  background-color: #f5f7f7;
  border-radius: 4px;
}
.FundkyModal h1.FundkyModal--title {
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #e8e9e9;
  padding-bottom: 18px;
}
.FundkyModal h1.FundkyModal--title svg {
  font-size: 1.5em;
  color: var(--secondary-color);
  margin-right: 10px;
}
.FundkyModal .ant-modal-close-x {
  display: none;
}
.FundkyModal--close {
  position: absolute;
  top: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 20px;
}
.FundkyModal--close:hover,
.FundkyModal--close:focus,
.FundkyModal--close svg:hover,
.FundkyModal--close svg:focus {
  color: #000000 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
.FundkyModal--close svg {
  margin: 1rem;
}
.FundkyModal--actions {
  display: flex;
  justify-content: flex-end;
  margin: 2.4rem -0.7rem 0 -0.7rem;
}
.FundkyModal--actions button {
  margin: 0 0.7rem;
}
.FundkyModal.scrollContent .FundkyModal--content {
  overflow: auto;
  max-height: calc(80vh - 100px);
}
