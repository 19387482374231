@import (reference) "../../assets/stylesheets/main";

.CreateInformationForm {
  max-width: 680px;

  .CreateInformationForm__Field {
    display: flex;

    >div {
      flex: 1;
    }

    input {
      font-size: 18px;
    }
  }

  .CreateInformationForm__Field--Short>div {
    // For non full-screen fields: reset the flex: 1;
    flex: initial;
  }

  .CreateInformationForm__Submit {
    display: flex;
    justify-content: flex-end;
  }

  .CreateInformationForm__NameField {
    margin: 20px 0 40px;
  }

  .SubmitWrapper > * {
    margin-right: 10px;
  }
}

.CreateInformationForm__SubmitWrapper {
  display: flex;
  justify-content: flex-end;

  button:disabled {
    background-color: #F5F7F7 !important;
    border-color: #F5F7F7 !important;

    &:hover,
    &:focus {
      background-color: #F5F7F7 !important;
      border-color: #F5F7F7 !important;
    }
  }
}

.CreateInformationForm__CancelWrapper {
  .btn-draft {
    margin-right: 1rem;
  }
}

.CampaignTypeDiagramModal {
  max-width: 800px;
  width: 90% !important;
}
