.PaginationTop {
  display: flex;
  padding-top: 16px;
  padding-bottom: 10px;
}
.PaginationTop > *,
.PaginationTop .ant-pagination-total-text {
  margin-right: 20px;
}
.PaginationTop > *:last-child,
.PaginationTop .ant-pagination-total-text:last-child {
  margin-right: 0;
}
.PaginationTop .ant-pagination {
  display: flex;
  flex: 1;
}
.PaginationTop .ant-pagination > :first-child {
  display: flex;
  flex: 1;
}
.PaginationTop .ant-pagination .ant-pagination-item {
  display: none;
}
.PaginationTop .ant-pagination .ant-pagination-prev,
.PaginationTop .ant-pagination .ant-pagination-next {
  font-size: 14px;
  font-weight: bold;
}
.PaginationTop .ant-pagination .ant-pagination-prev svg,
.PaginationTop .ant-pagination .ant-pagination-next svg {
  width: 0.5em;
}
.PaginationTop .ant-select-selection .ant-select-arrow:before {
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.8em;
}
.PaginationTop__SelectOption {
  text-align: center;
}
.PaginationTop__TotalInfo {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.PaginationTop__TotalInfo > :first-child {
  font-weight: bold;
  font-size: 18px;
}
.PaginationTop__TotalInfo > :last-child {
  font-size: 14px;
}
