.ConfirmationPanel input,
.ConnectionForm__Field input,
.ConnectionForm__CompactField input {
  height: 55px;
}
.ConfirmationPanel .ant-btn,
.ConnectionForm__Field .ant-btn,
.ConnectionForm__CompactField .ant-btn {
  height: 50px;
  line-height: 50px;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}
.ConnectionForm__Divider {
  display: flex;
}
.ConnectionForm__Divider .ConnectionForm__Field {
  flex-grow: 1;
}
.ConnectionForm__Divider .ConnectionForm__Field:first-child {
  margin-right: 12px;
}
.ConnectionForm__Divider .ConnectionForm__Field:last-child {
  margin-left: 12px;
}
.ConnectionForm__HiddenField {
  display: none;
}
.ConnectionForm__CompactField .ant-form-item-control {
  line-height: 1;
}
.ConnectionForm__EndNote {
  text-align: center;
}
