.PublicPageMobileScripts .BuyTicketBtn.templateButton {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  flex: 1 1 100%;
}
.PublicPageMobileScripts .BuyTicketBtn.templateButton:hover {
  background-color: var(--secondary-color) !important;
}
@media screen and (max-width: 800px) {
  #anchorMenu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #d5dbdb;
    padding: 0 2.5%;
  }
  #anchorMenu .ant-anchor {
    display: flex;
    border-top: 1px solid #F5F7F7;
  }
  #anchorMenu .ant-anchor-ink {
    display: none;
  }
  #anchorMenu .ant-anchor-link {
    padding: 0;
  }
  #anchorMenu .ant-anchor-link-title {
    padding: 0.75em 0.5em;
  }
  #anchorMenu .ant-anchor-link-active a {
    color: #333;
    border-bottom: 4px solid var(--link-color);
  }
  #anchorMenu .SocialSharing__Mobile button {
    background-color: #fff;
    color: var(--secondary-color);
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
  }
  #anchorMenu .SocialSharing__Mobile button span {
    font-size: 10px;
  }
  #anchorMenu .SocialSharing {
    margin: 0;
  }
  #callToActions {
    width: 100%;
  }
  #callToActions .buttonWrapper {
    background: #fff;
    display: flex;
    padding: 1.25%;
    justify-content: space-between;
  }
  #callToActions .buttonWrapper a {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #callToActions .buttonWrapper > a,
  #callToActions .buttonWrapper > div {
    width: 100%;
    margin: 1.25%;
    padding: 0;
  }
  #callToActions .buttonWrapper > a button,
  #callToActions .buttonWrapper > div button {
    width: 100%;
  }
  .PublicCampaignBand__WaveBorder {
    background: #fff;
  }
  #titleDropdown {
    max-width: inherit;
    background: #fff;
    width: 100%;
    flex: auto;
    z-index: 10;
    padding-right: 0;
    position: relative;
    min-height: 67px;
  }
  #titleDropdown h1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 0.5em;
  }
  #titleDropdown .ant-dropdown {
    width: 95%;
  }
  #titleDropdown .ant-dropdown .PublicTitleDropdown__Menu {
    max-width: none;
  }
  #titleDropdown .PublicTitleDropdown h1 {
    font-size: 2.4rem;
  }
  #titleDropdown [class*="__MenuNavSocialSharing"],
  #titleDropdown [class*="__TitleThermo"],
  #titleDropdown [class*="__MenuNav"] {
    width: 100%;
  }
  #titleDropdown [class*="__TitleThermo"] {
    min-width: 300px;
    width: 95%;
    margin: auto;
    padding: 0.5em 0;
  }
  #titleDropdown [class*="__MenuNav"] [class*="__MenuLink"] {
    padding-right: 5px;
  }
  main section[class*="PublicPage"] {
    flex-direction: column;
    width: 100% !important;
    padding-top: 0;
  }
  main section[class*="PublicPage"] [class*="__Container"] {
    width: 100% !important;
    display: block;
  }
  main section[class*="PublicPage"] .PublicPanel {
    width: 95%;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-right: 2.5%;
    padding-left: 2.5%;
  }
  main section[class*="PublicPage"] #titleDropdown .PublicPageClosed__Thermo {
    display: none;
  }
  main section[class*="PublicPage"] .CampaignPublicPage__Banner {
    width: 100%;
    height: auto;
  }
  .ParticipantPublicPage__Menu {
    max-width: none !important;
  }
  .ParticipantPublicPage__Menu .PublicThermometer__OutsideContent,
  .ParticipantPublicPage__Menu .ParticipantPublicPage__Thermometer {
    padding: 0 !important;
    margin: 0 !important;
  }
  .ParticipantPublicPage__Menu .ParticipantPublicPage__Thermometer {
    padding: 0.6em 2.5% !important;
    text-align: left;
  }
  .ParticipantPublicPage__Thermometer {
    display: flex;
  }
  .ParticipantPublicPage__Thermometer .edit-profile,
  .ParticipantPublicPage__Thermometer .PublicThermometer__OutsideContent > *:not(.ParticipantPublicPage__Participant--dropdown) {
    display: none;
  }
  .ParticipantPublicPage__Thermometer .PublicThermometer__CircularWrapper {
    margin: 0;
    width: 70px;
    height: auto !important;
  }
  .ParticipantPublicPage__Thermometer .PublicThermometer__CircularWrapper .PublicThermometer__InsideContent {
    height: 50px;
    width: 50px;
  }
  .ParticipantPublicPage__Thermometer .PublicThermometer__CircularWrapper svg {
    display: none;
  }
  .ParticipantPublicPage__Thermometer .ParticipantPublicPage__Participant--dropdown {
    margin-top: 4px;
  }
  .ParticipantPublicPage__Thermometer .PublicThermometer__OutsideContent {
    width: 100%;
  }
  #main #header,
  #main #anchorMenu,
  #main #titleDropdown {
    transform: translateY(0px);
    transition: transform 0.2s;
  }
  #main.stickDropdownAndCallToActions #callToActions,
  #main.stickDropdownAndCallToActions #anchorMenu,
  #main.stickDropdownAndCallToActions #titleDropdown {
    position: fixed;
    z-index: 111;
  }
  #main.stickDropdownAndCallToActions #callToActions {
    bottom: 0;
  }
  #main.stickDropdownAndCallToActions #titleDropdown {
    top: 76px;
    z-index: 112;
  }
  #main.stickDropdownAndCallToActions #anchorMenu {
    top: 143px;
  }
  #main.stickDropdownAndCallToActions #anchorMenu.position-eventWithoutDate {
    top: 148px;
  }
  #main.stickDropdownAndCallToActions #anchorMenu.position-eventWithDate {
    top: 184px;
  }
  #main.stickDropdownAndCallToActions #footer .PublicFooter__FundkySection {
    padding-bottom: 85px !important;
  }
  #main.stickDropdownAndCallToActions #dropdownTitle_ref {
    height: 160px;
  }
  #main.stickDropdownAndCallToActions.hideHeader #dropdownTitle_ref {
    height: 90px;
  }
  #main.stickDropdownAndCallToActions.hideHeader #header,
  #main.stickDropdownAndCallToActions.hideHeader #anchorMenu,
  #main.stickDropdownAndCallToActions.hideHeader #titleDropdown {
    transform: translateY(-76px);
  }
  #main.stickDropdownAndCallToActions .mobileThermo {
    display: none;
  }
}
