@import (reference) "../assets/stylesheets/main";

.PublicPageMobileScripts {
  .BuyTicketBtn.templateButton {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
    //margin: 1.25%;
    flex: 1 1 100%;
    &:hover {
      background-color: var(--secondary-color) !important;
    }
  }
}

@media screen and (max-width: 800px) {
  #anchorMenu {
    width: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #d5dbdb;
    padding: 0 2.5%;

    .ant-anchor {
      display: flex;
      border-top: 1px solid #F5F7F7;
    }

    .ant-anchor-ink {
      display: none;
    }

    .ant-anchor-link {
      padding: 0;
    }

    .ant-anchor-link-title {
      padding: 0.75em 0.5em;
    }

    .ant-anchor-link-active a {
      color: #333;
      border-bottom: 4px solid var(--link-color);
      //font-size: 20px;
    }

    .SocialSharing__Mobile {
      button {
        background-color: #fff;
        color: var(--secondary-color);
        border: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        span {
          font-size: 10px;
        }
      }
    }

    .SocialSharing {
      margin: 0;
    }
  }

  #callToActions {
    width: 100%;

    .buttonWrapper {
      background: #fff;
      display: flex;
      padding: 1.25%;
      justify-content: space-between;

      a {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &>a,
      &>div {
        width: 100%;
        margin: 1.25%;
        padding: 0;

        button {
          width: 100%;
        }
      }
    }
  }

  .PublicCampaignBand__WaveBorder {
    background: #fff;
  }

  #titleDropdown {
    max-width: inherit;
    background: #fff;
    width: 100%;
    flex: auto;
    z-index: 10;
    padding-right: 0;
    position: relative;
    min-height: 67px; //prevent wierdness when resizing between desktop and mobile

    h1 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 0.5em;
    }

    .ant-dropdown {
      width: 95%;

      .PublicTitleDropdown__Menu {
        max-width: none;
      }
    }

    .PublicTitleDropdown h1 {
      font-size: 2.4rem;
    }

    [class*="__MenuNavSocialSharing"],
    [class*="__TitleThermo"],
    [class*="__MenuNav"] {
      width: 100%;
    }

    [class*="__TitleThermo"] {
      min-width: 300px;
      width: 95%;
      margin: auto;
      padding: 0.5em 0;
    }

    [class*="__MenuNav"] {
      [class*="__MenuLink"] {
        padding-right: 5px;
      }
    }
  }

  main section[class*="PublicPage"] {
    //fix campaign layout
    flex-direction: column;
    width: 100% !important;
    padding-top: 0;

    //fix participant + team layout
    [class*="__Container"] {
      width: 100% !important;
      display: block;
    }

    .PublicPanel {
      width: 95%;
      margin-right: auto !important;
      margin-left: auto !important;
      padding-right: 2.5%;
      padding-left: 2.5%;
    }

    #titleDropdown .PublicPageClosed__Thermo {
      display: none;
    }

    .CampaignPublicPage__Banner {
      width: 100%;
      height: auto;
    }
  }

  //Fix participant dropdownTitle
  .ParticipantPublicPage__Menu {
    max-width: none !important;

    .PublicThermometer__OutsideContent,
    .ParticipantPublicPage__Thermometer {
      padding: 0 !important;
      margin: 0 !important;
    }

    .ParticipantPublicPage__Thermometer {
      padding: 0.6em 2.5% !important;
      text-align: left;
    }

  }

  .ParticipantPublicPage__Thermometer {
    display: flex;

    .edit-profile,
    .PublicThermometer__OutsideContent>*:not(.ParticipantPublicPage__Participant--dropdown) {
      display: none;
    }

    .PublicThermometer__CircularWrapper {
      margin: 0;
      width: 70px;
      height: auto !important;

      .PublicThermometer__InsideContent {
        height: 50px;
        width: 50px;
      }

      svg {
        display: none;
      }
    }

    .ParticipantPublicPage__Participant--dropdown {
      margin-top: 4px;
    }

    .PublicThermometer__OutsideContent {
      width: 100%;
    }
  }

  #main {

    #header,
    #anchorMenu,
    #titleDropdown {
      transform: translateY(0px);
      transition: transform 0.2s;
    }

    &.stickDropdownAndCallToActions {

      #callToActions,
      #anchorMenu,
      #titleDropdown {
        position: fixed;
        z-index: 111;
      }

      #callToActions {
        bottom: 0;
      }

      #titleDropdown {
        top: 76px; //header height
        z-index: 112;
      }

      #anchorMenu {
        top: 143px; //header height + dropdown height
        &.position-eventWithoutDate {
          top: 148px;
        }
        &.position-eventWithDate {
          top: 184px;
        }
      }

      #footer .PublicFooter__FundkySection {
        padding-bottom: 85px !important; //calltoactions height
      }

      #dropdownTitle_ref {
        height: 160px; //calltoactions + dropdown + anchors
      }

      &.hideHeader {
        #dropdownTitle_ref {
          height: 90px; //dropdown + anchors
        }

        #header,
        #anchorMenu,
        #titleDropdown {
          transform: translateY(-76px); //header height
        }
      }

      .mobileThermo {
        display: none;
      }
    }
  }
}
