.responsiveTabs .ant-dropdown-trigger {
    display: none;
}

@mobile-ruleset: {
    .responsiveTabs {
        position: relative;

        div.ant-tabs-tab:not(.ant-tabs-tab-active),
        .ant-tabs-ink-bar {
            display: none !important;
        }

        .ant-tabs-tab-active:hover {
            color: #000;
            cursor: default;
        }

        .ant-tabs-tab-active {
            border-bottom: 4px solid var(--secondary-color);
        }

        .ant-dropdown-trigger {
            display: block;
            position: absolute;
            top: 5px;
            right: 2rem;
            z-index: 2;
            font-size: 24px;
            color: #000;
            padding: 0 0.3em;
            margin-right: -2px;

            &.ant-dropdown-open {
                background: white;
                box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
                color: #000;
                border-radius: 4px 4px 0 0;

                &:after {
                    //hide dropshadow between trigger and menu
                    content: "";
                    width: 118%;
                    height: 10px;
                    background: #fff;
                    bottom: 0;
                    transform: translateY(100%);
                    right: 0;
                    position: absolute;
                }
            }

        }
    }

    div.mobileTabsSubmenu {
        width: 100%;
        margin-top: -10px;
        z-index: 1 !important;

        .ant-dropdown-menu {
            margin: 0 2rem 0 1.8rem;
            top: -5px;
            right: -2px;
        }

        .ant-dropdown-menu-item,
        .ant-dropdown-menu-submenu-title {
            padding: 1.5rem 2rem;
            cursor: default;

            a {
                display: inline;
            }

            &:hover {
                background: none !important;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    @mobile-ruleset();
}

//When the content area is smaller than 700 (700 + 290)
@media only screen and (max-width: 990px) {
    aside:not(.ant-layout-sider-collapsed)+i+section.ant-layout {
        @mobile-ruleset();

        .mobileTabsSubmenu ul {
            margin: 0 0.1em 0 2.2em !important;
        }
    }
}

@media screen and (max-width: 400px) {
    div.mobileTabsSubmenu .ant-dropdown-menu {
        margin: 0 2rem 0 0.7rem;
    }
}