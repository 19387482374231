// Import the common less main file
@import "~Parts/assets/stylesheets/main";

// Some CLIENT-specific rules can be defined here
@padding-page: @padding-lg * 8;
@padding-tablet: 6rem;
@padding-mobile-lg: @padding-xs * 8;
@padding-mobile-sm: 2rem;

@layout-body-background: @hq-admin-background-bar;

@gold-color: #EDCE48;
@silver-color: #BCC1CA;
@bronze-color: #9A7D48;

@one-third: 100% / 3 * 1;

.FundkyWrap {
  width: 90% !important;
  max-width: 1200px;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .FundkyWrap {
    width: 95% !important;
  }
}

.PublicLayout {
  .ant-tabs-bar .ant-tabs-nav-container {
    &:extend(.FundkyWrap);
  }
}

.ant-btn:not(.ant-btn-link) {
  color: #fff;
  background-color: var(--default-color);
  border-color: var(--default-color);

  &:hover,
  &:focus {
    background-color: var(--default-hover-color);
    border-color: var(--default-hover-color);
  }

  &.ant-btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);

    &:hover,
    &:focus {
      background-color: var(--primary-hover-color);
      border-color: var(--primary-hover-color);
    }
  }

  &[disabled],
  &[disabled]:focus,
  &[disabled]:hover {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }
}

a {
  color: var(--link-color);

  &:hover {
    color: var(--link-hover-color);
  }
}

.ant-radio-checked::after {
  border: 1px solid var(--primary-color);
}

.ant-radio-inner::after,
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.ant-checkbox+span,
span.ant-form-item-children {
  line-height: 1.2em;
}

.ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.ant-checkbox-wrapper:hover .ant-checkbox .ant-checkbox-inner,
.ant-radio-checked .ant-radio-inner,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: var(--primary-color);
}

label.ant-checkbox-wrapper {
  display: inline-flex;
  align-items: baseline;

  .ant-checkbox {
    top: 2px !important;
  }
}

.ant-form-item[class*="__CompactField"] {
  margin-bottom: 1rem !important;

  .ant-form-item-children {
    line-height: normal !important;
  }
}

.ant-form-item-control.has-feedback {
  label.ant-checkbox-wrapper {
    >span:not(.ant-checkbox) {
      padding-right: 30px;
    }
  }
}

.ant-select {
  .ant-select-arrow {
    font-size: 18px;
    margin-top: -8px;
  }

  .fa-caret-down {
    transition: transform .2s ease-in-out;
  }

  &.ant-select-open {
    .fa-caret-down {
      transform: rotate(180deg);
    }
  }
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-input:focus {
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px var(--secondary-shadow-color);
}

.ant-input:hover {
  border-color: var(--secondary-color);
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu-horizontal>.ant-menu-item>a:hover {
  color: var(--primary-color);
}

.ant-tabs.FillActive {
  .ant-tabs-tab-active {
    background-color: @body-background;
  }

  .ant-tabs-ink-bar {
    border-radius: 4px 4px 0 0;
    height: 5px;
    top: 0;
  }
}

.ant-tabs-tab-active {
  span {
    z-index: 1;
    position: relative;
  }
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--secondary-color);
}

.ant-tabs-ink-bar {
  background-color: var(--link-color);
}

.ant-btn-clicked::after {
  background-color: var(--primary-shadow-color);
}

.ant-menu-horizontal {

  >.ant-menu-item:hover,
  >.ant-menu-submenu:hover,
  >.ant-menu-item-active,
  >.ant-menu-submenu-active,
  >.ant-menu-item-open,
  >.ant-menu-submenu-open,
  >.ant-menu-item-selected,
  >.ant-menu-submenu-selected {
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
  }
}

.ant-btn:hover:not(.ant-btn-link):not(.ant-btn-danger),
.ant-btn:focus:not(.ant-btn-link):not(.ant-btn-danger) {
  color: #fff;
}

.ant-btn.btn-cancel,
.ant-btn.copy-url {
  color: rgba(0, 0, 0, 0.65);
  border: none;
  background-color: #FFF !important;

  &:hover {
    background-color: #F5F7F7 !important;
  }
}

.ant-btn.Back-btn,
.ant-btn.CreateConfirmationPanel__DarkButton {
  background-color: #333 !important;
  border-color: #333 !important;
  color: #fff !important;

  &:hover {
    background-color: #000 !important;
    border-color: #000 !important;
    color: #fff !important;
  }
}

button[disabled] {
  opacity: .5;
}

.buorg {
  background-color: #fff !important;
}

#buorgul,
#buorgpermanent {
  background: @primary-color  !important;
  background-color: @primary-color  !important;
  background: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

#buorgig {
  background: #fff !important;
  background-color: #fff !important;
  color: @primary-color  !important;
  color: var(--primary-color) !important;
}

.buorg-icon {
  display: none !important;
}


@media screen and (min-width: 801px) {
  .globalMobileOnly {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .globalDesktopOnly {
    display: none;
  }
}


@media screen and (max-width: 580px) {
  .CreateConfirmationPanel {
    svg.CreateConfirmationPanel__SuccessIcon {
      display: none;
    }
  }
}

::selection {
  background: var(--primary-color);
}
