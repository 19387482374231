.PublicCampaignBandMenu {
  margin-top: -8px;
  border-radius: 4px 0 4px 4px;
  box-shadow: none;
  background-color: var(--secondary-color);
  border: 0 !important;
}
.PublicCampaignBandMenu li {
  padding-right: 100px !important;
  height: 68px !important;
  line-height: 68px !important;
  margin: 0 !important;
}
.PublicCampaignBandMenu li a {
  color: white;
  font-size: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.PublicCampaignBandMenu__Icon {
  font-size: 30px;
  margin-right: 24px !important;
  width: 50px !important;
}
.PublicCampaignBandMenu .ant-menu-item-selected {
  background-color: var(--secondary-color-lighten) !important;
}
.PublicCampaignBandMenu .ant-menu-item a[disabled] {
  opacity: 0.5;
}
.PublicCampaignBandMenu .ant-menu-item:first-of-type {
  border-radius: 4px 0 0 0;
}
.PublicCampaignBandMenu .ant-menu-item:last-of-type {
  border-radius: 0 0 4px 4px;
}
.PublicCampaignBandMenu .ant-menu-item:hover,
.PublicCampaignBandMenu .ant-menu-item:focus {
  background-color: var(--secondary-color-lighten) !important;
}
.PublicCampaignBandMenu .ant-menu-item:hover a,
.PublicCampaignBandMenu .ant-menu-item:focus a {
  color: white;
}
