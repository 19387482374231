.PagesHomepagePage .ant-tabs {
  clear: both;
}
.PagesHomepagePage .PagesHomepageSliderPanel .panel-title,
.PagesHomepagePage .PagesHomepageIntroTextPanel .panel-title,
.PagesHomepagePage .PagesHomepageFeaturedCampaignPanel .panel-title {
  float: left;
}
.PagesHomepagePage .PagesHomepageSliderPanel .panel-content,
.PagesHomepagePage .PagesHomepageIntroTextPanel .panel-content,
.PagesHomepagePage .PagesHomepageFeaturedCampaignPanel .panel-content {
  clear: both;
}
