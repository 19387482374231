.AdminContentLangSwitcher__wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  margin-bottom: 0.75em;
  z-index: 1;
}
.AdminContentLangSwitcher__wrap label {
  margin: 1rem 0;
  font-weight: normal;
}
.AdminContentLangSwitcher__wrap .ant-select {
  width: 100%;
}
.AdminContentLangSwitcher__wrap .ant-select-selection__rendered {
  margin-right: 30px;
}
.AdminContentLangSwitcher__wrap .fa-caret-down {
  color: #333333;
}
.SocialMediasSettingsForm .AdminContentLangSwitcher__wrap,
.DesignSettingsForm .AdminContentLangSwitcher__wrap,
.EventAdminCampaignTicketForm .AdminContentLangSwitcher__wrap {
  text-align: right;
  flex-direction: initial;
  display: flex;
  justify-content: flex-end;
}
.SocialMediasSettingsForm .AdminContentLangSwitcher__wrap .ant-select,
.DesignSettingsForm .AdminContentLangSwitcher__wrap .ant-select,
.EventAdminCampaignTicketForm .AdminContentLangSwitcher__wrap .ant-select {
  display: inline;
  width: auto !important;
}
.SocialMediasSettingsForm .AdminContentLangSwitcher__wrap .ant-select .ant-select-selection,
.DesignSettingsForm .AdminContentLangSwitcher__wrap .ant-select .ant-select-selection,
.EventAdminCampaignTicketForm .AdminContentLangSwitcher__wrap .ant-select .ant-select-selection {
  font-weight: bold;
  border: 0 !important;
  position: relative;
  top: 1px;
  box-shadow: none;
  background: none;
}
.SocialMediasSettingsForm .AdminContentLangSwitcher__wrap .ant-select .ant-select-selection,
.DesignSettingsForm .AdminContentLangSwitcher__wrap .ant-select .ant-select-selection,
.EventAdminCampaignTicketForm .AdminContentLangSwitcher__wrap .ant-select .ant-select-selection,
.SocialMediasSettingsForm .AdminContentLangSwitcher__wrap .ant-select .ant-select-selection-selected-value,
.DesignSettingsForm .AdminContentLangSwitcher__wrap .ant-select .ant-select-selection-selected-value,
.EventAdminCampaignTicketForm .AdminContentLangSwitcher__wrap .ant-select .ant-select-selection-selected-value {
  display: inline-block;
}
