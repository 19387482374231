.ResultThumbnailList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.ResultThumbnailList__List {
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.ResultThumbnailList__List li {
  margin: 10px;
  border: 0;
  cursor: pointer;
  width: 250px;
}
@media (min-width: 706px) and (max-width: 1005px) {
  .ResultThumbnailList__List li {
    width: calc( (100% / 2) - 9px );
  }
}
@media (min-width: 1006px) and (max-width: 1305px) {
  .ResultThumbnailList__List li {
    width: calc( (100% / 2) - 9px );
  }
}
@media (min-width: 1306px) and (max-width: 1605px) {
  .ResultThumbnailList__List li {
    width: calc( (100% / 3) - 9px );
  }
}
@media (min-width: 1606px) and (max-width: 1905px) {
  .ResultThumbnailList__List li {
    width: calc( (100% / 4) - 9px );
  }
}
@media (min-width: 1906px) and (max-width: 2205px) {
  .ResultThumbnailList__List li {
    width: calc( (100% / 5) - 9px );
  }
}
@media (min-width: 2206px) and (max-width: 2505px) {
  .ResultThumbnailList__List li {
    width: calc( (100% / 6) - 9px );
  }
}
@media (min-width: 2506px) {
  .ResultThumbnailList__List li {
    width: 297px;
  }
}
.ResultThumbnailList__List .ant-card {
  transition: all 0.3s;
}
.ResultThumbnailList__List .ant-card:hover {
  background-color: #333333;
  color: #FFFFFF;
}
.ResultThumbnailList__List .ant-card:hover .ant-card-head-title {
  color: #FFFFFF;
}
.ResultThumbnailList__List .ant-card-head {
  background-color: transparent;
}
.ResultThumbnailList__List .ant-card-head-title {
  transition: color 0.3s;
}
.ResultThumbnailList__List .ant-card-head-title i,
.ResultThumbnailList__List .ant-card-head-title svg {
  color: var(--primary-color);
  margin-right: 20px;
  font-size: 27px;
}
.ResultThumbnailList__List .ant-card-head-title > span {
  display: flex;
}
.ResultThumbnailList__Pagination {
  align-self: center;
}
.ResultThumbnailList__Pagination li {
  background-color: transparent;
}
.ResultThumbnailList__Pagination li.ant-pagination-item-active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.ResultThumbnailList__Pagination li.ant-pagination-item-active a {
  color: #fff;
}
.ResultThumbnailList__Pagination li:hover:not(.ant-pagination-item-active):not(.ant-pagination-disabled) {
  border-color: var(--primary-color);
}
.ResultThumbnailList__Pagination li:hover:not(.ant-pagination-item-active):not(.ant-pagination-disabled) a {
  color: var(--primary-color);
}
.ResultThumbnailList__Pagination li.ant-pagination-prev a,
.ResultThumbnailList__Pagination li.ant-pagination-next a {
  background-color: transparent;
}
.ResultThumbnailList__Pagination li.ant-pagination-prev:hover:not(.ant-pagination-disabled) a,
.ResultThumbnailList__Pagination li.ant-pagination-next:hover:not(.ant-pagination-disabled) a,
.ResultThumbnailList__Pagination li.ant-pagination-prev:focus:not(.ant-pagination-disabled) a,
.ResultThumbnailList__Pagination li.ant-pagination-next:focus:not(.ant-pagination-disabled) a {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
