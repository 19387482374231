@import (reference) "../../../assets/stylesheets/main";

.TextAreaField {
  &__CounterBarContainer {
    width: 100%;
    height: 5px;
    border-radius:0 0 4px 4px;
    overflow: hidden;
  }

  &__CounterBar {
    height: 100%;
    background-color: var(--primary-color);
  }

  textarea.ant-input {
    margin-bottom: 0 !important;
    max-height:200px !important;
    &.radius2 { border-radius: 4px 4px 0 0; }
    &.radius3 { border-radius: 4px 4px 4px 0; }
    &.radius4 { border-radius: 4px 4px 4px 4px; }
  }
}
