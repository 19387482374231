.ant-tooltip {
  z-index: 10000;
}
.Fundky__Tooltip {
  color: #AC8EC3;
  margin-left: 0.6rem;
  cursor: pointer;
}
.Fundky__Tooltip__Overlay .ant-tooltip-arrow {
  border-top-color: rgba(171, 141, 194, 0.95);
}
.Fundky__Tooltip__Overlay .ant-tooltip-arrow:before {
  background-color: rgba(171, 141, 194, 0.95);
}
.Fundky__Tooltip__Overlay .ant-tooltip-inner {
  background-color: rgba(171, 141, 194, 0.95);
  word-break: inherit;
}
