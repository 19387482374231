@import "../abstracts/_mixins";

.ant-tabs-bar {
  border-bottom: 0;

  .button-font();
  font-weight: bold;
  color: #000000;
}

.ant-tabs-nav .ant-tabs-tab {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: inherit;
  font-weight: inherit;
}

.ant-tabs-ink-bar {
  height: 3px;
}
