.AmountOptions {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0 -3px;
}
.AmountOptions:after {
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  margin-top: -10px;
  width: 32px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 16px;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.AmountOptions > * {
  max-width: 19%;
  min-width: 19%;
}
@media only screen and (max-width: 660px) {
  .AmountOptions > * {
    max-width: 32%;
    min-width: 32%;
  }
}
@media only screen and (max-width: 470px) {
  .AmountOptions > * {
    min-width: 47.9%;
    max-width: 47.9%;
  }
}
.AmountOptions > span.ant-input-group-wrapper {
  flex: 1.3;
  top: 0;
  margin: 0 3px 6px 3px;
}
.AmountOptions .ant-btn {
  border-color: #fff;
  margin-bottom: 6px;
}
.AmountOptions .ant-btn:hover,
.AmountOptions .ant-btn:focus {
  color: #fff;
}
.AmountOptions .ant-input-group {
  display: flex;
}
.AmountOptions .ant-input-group input {
  line-height: 54px;
  height: 56px;
}
.AmountOptions .ant-input-group-addon {
  position: absolute;
  top: 0;
  z-index: 9;
  border: none;
  margin: 1px;
  width: 30px;
  display: block;
  background-color: #fff;
  font-size: 24px;
  line-height: 54px;
  padding: 0 !important;
  border: none !important;
  border-radius: 4px !important;
}
.AmountOptions__Option--Selected,
.AmountOptions__Option--Selected + .ant-input-group-addon {
  background-color: var(--primary-color) !important;
  color: #fff;
  border-color: var(--primary-color);
}
.AmountOptions input {
  font-size: 24px;
  border-radius: 4px !important;
}
.AmountOptions-left .ant-input-group .AmountOptions__OtherAmountInput {
  padding-left: 31px !important;
  text-align: left;
}
.AmountOptions-left .ant-input-group input {
  text-align: right;
}
.AmountOptions-left .ant-input-group-addon {
  left: 0;
}
.AmountOptions-right .ant-input-group .AmountOptions__OtherAmountInput {
  padding-right: 31px !important;
  text-align: right;
}
.AmountOptions-right .ant-input-group input {
  text-align: left;
}
.AmountOptions-right .ant-input-group-addon {
  right: 0;
}
.AmountOptions__OtherAmountInput {
  height: 56px;
  box-sizing: border-box;
  font-size: 24px;
  border-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.AmountOptions__OtherAmountInput:focus {
  outline: none !important;
  box-shadow: none !important;
}
.AmountOptions__OtherAmountInput:hover + .ant-input-group-addon,
.AmountOptions__OtherAmountInput:focus + .ant-input-group-addon {
  border-color: var(--primary-color) !important;
  outline: none;
}
.has-error .AmountOptions .AmountOptions__Option {
  border: 1px solid #F3704A;
}
.has-error .AmountOptions__OtherAmountInput:hover,
.has-error .AmountOptions__OtherAmountInput:focus {
  border-color: var(--primary-color) !important;
}
.has-error .AmountOptions__OtherAmountInput:hover + .ant-input-group-addon,
.has-error .AmountOptions__OtherAmountInput:focus + .ant-input-group-addon {
  border-color: var(--primary-color) !important;
  outline: none;
}
.has-error .AmountOptions-left .ant-input-group-addon {
  border-right: none;
}
.has-error .AmountOptions-right .ant-input-group-addon {
  border-left: none;
}
.AmountOptions__Option {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 56px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.65);
  font-size: 24px;
  margin: 0 3px;
}
.AmountOptions__Option i {
  font-size: 36px;
  padding-top: 24px;
  color: var(--link-color);
}
.AmountOptions__Option h3 {
  margin: 0;
}
.AmountOptions__Option p {
  line-height: initial;
  text-align: center;
  margin-bottom: 30px;
}
