.SurveyInfoFormWrap > form {
  width: 100%;
}
.SurveyInfoFormWrap .ant-form-item {
  flex-direction: column;
}
.SurveyInfoFormWrap .ant-form-item .ant-form-item-label {
  margin: 1rem 0;
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox {
  background-color: #fff;
  padding: 12px;
  margin: 20px 0px 0 40px;
  border-radius: 4px;
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox:first-of-type {
  margin-top: 0;
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox h3 {
  text-transform: capitalize !important;
  border-bottom: 3px solid #f5f7f7;
  min-height: 35px;
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox__footer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox__footer button:last-of-type {
  margin-left: auto;
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox__footer {
  justify-content: flex-end;
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox__footer .apply {
  margin-left: 35px !important;
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox__footer .save-draft {
  background-color: transparent;
  color: #000000;
  border: none;
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox__footer .ArchiveSurvey {
  background-color: transparent;
  border-color: transparent;
  color: var(--primary-hover-color);
}
.SurveyInfoFormWrap .SettingsColumn .SettingsBox__footer .ArchiveSurvey:hover,
.SurveyInfoFormWrap .SettingsColumn .SettingsBox__footer .ArchiveSurvey:focus {
  color: var(--primary-hover-color);
  background-color: transparent;
  border-color: transparent;
}
.SurveyInfoFormWrap .adminResultDetails .adminResultCollapseTitle.required::after {
  content: '*';
  color: #eb3052;
  margin-left: 1rem;
}
.SurveyInfoFormWrap .adminResultDetails .adminResultAnswerCount {
  margin-bottom: 0px;
  font-weight: 300;
}
.SurveyInfoFormWrap .adminResultDetails .adminResultBirthdayList .adminResultBirthdayItem {
  padding: 9px 10px;
  border-radius: 4px;
  background-color: #f3f3f3;
}
.SurveyInfoFormWrap .adminResultDetails .adminResultBirthdayList .adminResultBirthdayItem:not(:last-child) {
  margin-bottom: 8px;
}
.SurveyInfoFormWrap .adminResultDetails .adminResultBirthdayList .adminResultBirthdayItem p {
  margin-bottom: 0px;
}
.SurveyInfoInputs .SurveyInfoInputsItem {
  display: flex;
  flex-wrap: wrap;
}
.SurveyInfoInputs .SurveyInfoInputsItem .dragSpace {
  width: 20px;
  background-color: #52a6ac;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
.SurveyInfoInputs .SurveyInfoInputsItem .contentSpace {
  flex: 1;
  padding: 16px 20px;
  background-color: #e5e5e5;
  border: 2px solid #e5e5e5;
}
.SurveyInfoInputs .SurveyInfoInputsItem .contentSpace label {
  display: block;
}
.SurveyInfoInputs .SurveyInfoInputsItem .contentSpace .gear-icon {
  color: #4d9a9f;
}
.SurveyInfoInputs .SurveyInfoInputsItem .contentSpace .react-datepicker-wrapper {
  width: 100%;
}
.SurveyInfoInputs .SurveyInfoInputsItem .contentSpace .react-datepicker-wrapper .react-datepicker__calendar-icon {
  z-index: 1;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.SurveyInfoInputs .SurveyInfoInputsItem .contentSpace .ant-select {
  width: 100%;
}
.SurveyInfoInputs .SurveyInfoInputsItem .ant-checkbox-wrapper .ant-checkbox {
  top: 0px !important;
}
.SurveyInfoInputs .SurveyInfoInputsItem:not(:last-of-type) {
  margin-bottom: 1rem;
}
.SurveyInfoInputs .SurveyInfoInputsItem.active .contentSpace {
  border-color: #52a6ac;
}
.dataFieldCard {
  background-color: #f5f7f7;
  border: 2px solid transparent;
}
.dataFieldCard .ant-card-body {
  padding: 10px;
}
.dataFieldCard .ant-card-body .dataFieldCardTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
.dataFieldCard .ant-card-body .dataFieldCardText {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0px;
}
.dataFieldCard.active {
  border-color: #52a6ac;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.error-message {
  color: red;
}
@media screen and (max-width: 767px) {
  .SurveyInfoFormWrap {
    flex-direction: column-reverse;
  }
  .SurveyInfoFormWrap .SettingsColumn .SettingsBox {
    margin: 0 !important;
  }
  .SurveyInfoFormWrap .SettingsColumn {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
  .SurveyInfoFormWrap .SettingsColumn .SettingsBox {
    margin-bottom: 20px !important;
  }
  .SurveyInfoFormWrap .AdminContentLangSwitcher__wrap {
    flex-direction: row;
    margin: 0.75em 0 0 0;
  }
  .SurveyInfoFormWrap .AdminContentLangSwitcher__wrap label {
    margin-right: 1em;
  }
  .SurveyInfoFormWrap .AccessibilityBoxForm .ant-row {
    display: flex !important;
    flex-direction: row !important;
  }
}
@media only screen and (max-width: 1057px) {
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .SurveyInfoFormWrap {
    flex-direction: column-reverse;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .SurveyInfoFormWrap .SettingsColumn .SettingsBox {
    margin: 0 !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .SurveyInfoFormWrap .SettingsColumn {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .SurveyInfoFormWrap .SettingsColumn .SettingsBox {
    margin-bottom: 20px !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .SurveyInfoFormWrap .AdminContentLangSwitcher__wrap {
    flex-direction: row;
    margin: 0.75em 0 0 0;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .SurveyInfoFormWrap .AdminContentLangSwitcher__wrap label {
    margin-right: 1em;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .SurveyInfoFormWrap .AccessibilityBoxForm .ant-row {
    display: flex !important;
    flex-direction: row !important;
  }
}
@media screen and (max-width: 460px) {
  .SurveyInfoFormWrap .AdminContentLangSwitcher__wrap {
    flex-direction: column;
  }
}
@media screen and (min-width: 1057px) {
  #sticky-wrapper.enabled {
    position: fixed;
    top: 5em;
    overflow: auto;
    width: 270px;
    max-height: calc(100vh - 100px);
  }
}
@media only screen and (min-width: 767px) {
  aside.ant-layout-sider-collapsed + i + section.ant-layout #sticky-wrapper.enabled {
    position: fixed;
    top: 5em;
    overflow: auto;
    width: 270px;
    max-height: calc(100vh - 100px);
  }
}
