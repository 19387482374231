@import '~react-quill/dist/quill.core.css';
@import '~react-quill/dist/quill.snow.css';
.Wysiwyg .quill .ql-toolbar,
.Wysiwyg .quill.ql-toolbar {
  background-color: #fff;
  border-radius: 4px 4px 0 0;
}
.Wysiwyg .quill .ql-tooltip {
  left: 0 !important;
}
.Wysiwyg .quill .ql-header {
  color: rgba(0, 0, 0, 0.85);
}
.Wysiwyg .quill .ql-container {
  background-color: #fff;
  border-radius: 0 0 4px 4px;
}
.Wysiwyg .quill .ql-editor {
  font-family: 'Assistant', sans-serif;
  font-size: 1.6rem;
  min-height: 275px;
  max-height: 80vh;
  padding: 8px 12px 28px 12px;
}
.Wysiwyg .quill .ql-editor h1 {
  font-family: 'Domine', serif;
}
.Wysiwyg .quill .ql-editor h2 {
  font-weight: bold;
}
.Wysiwyg .quill .ql-editor h3 {
  text-transform: inherit;
}
.Wysiwyg .quill .ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1em;
}
.Wysiwyg .quill.disabled {
  opacity: 0.5;
}
.Wysiwyg .quill.disabled,
.Wysiwyg .quill.disabled * {
  cursor: not-allowed !important;
}
.Wysiwyg .quill.disabled .ql-toolbar {
  display: none;
}
.Wysiwyg .quill.disabled .ql-container {
  border-radius: 4px;
  border-top: 1px solid #ccc;
}
.Wysiwyg .quill .ql-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.Wysiwyg .quill iframe.ql-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Wysiwyg .quill .ql-video-invalid {
  text-align: center;
  background: #f3704a;
  color: #fff;
  font-weight: bold;
  padding: 10px;
}
.Wysiwyg .radius2 {
  border-radius: 4px 4px 0 0;
}
.Wysiwyg .radius3 {
  border-radius: 4px 4px 4px 0;
}
.Wysiwyg .radius4 {
  border-radius: 4px 4px 4px 4px;
}
.Wysiwyg__CounterBarContainer {
  width: 100%;
  height: 5px;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  margin-top: -1px;
  margin-left: 1px;
}
.Wysiwyg__CounterBar {
  height: 100%;
  background-color: var(--primary-color);
}
.Wysiwyg .ant-form-item-children-icon {
  top: 22px !important;
}
.Wysiwyg .ant-form-item-control:not(.has-success):not(.has-error):not(.has-feedback) .ant-form-item-children-icon {
  display: none;
}
.ant-message .ant-message-notice-content {
  padding: 0;
}
.ant-message .ant-message-error {
  border: 1px solid #FFD4C8;
  background-color: #FFEFEB;
}
.ant-message .ant-message-custom-content {
  text-align: left;
  border-radius: 4px;
  padding: 21px;
  color: #333333;
  font-family: Assistant;
  font-size: 18px;
  letter-spacing: 0;
  display: flex;
}
.ant-message .ant-message-custom-content .anticon {
  font-size: 24px;
  margin-right: 19px;
  display: inline-block;
}
.ant-message .ant-message-custom-content > span {
  display: inline-block;
  line-height: 20px;
}
.ant-message .ant-message-custom-content > span div {
  margin-top: 5px;
  max-width: 500px;
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
}
.ant-message .ant-message-custom-content .ant-message-error {
  border: 1px solid #FFD4C8;
  background-color: #FFEFEB;
}
.ant-message .ant-message-custom-content .an-message-success {
  border: 1px solid #CFFCD8;
  background-color: #E9FFED;
}
.ant-message .ant-message-custom-content .an-message-info {
  border: 1px solid #CFFCD8;
  background-color: #E9FFED;
}
.ant-message .ant-message-custom-content .an-message-warning {
  border: 1px solid #FAEC8F;
  background-color: #FFF9CE;
}
