.CampaignTypeDiagram {
  font-size: 10px;
}
.CampaignTypeDiagram .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.CampaignTypeDiagram .row p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  margin: 0 0 1.2em;
}
.CampaignTypeDiagram .column {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0.2em;
}
.CampaignTypeDiagram .w-33 {
  width: 33%;
}
.CampaignTypeDiagram .w-50 {
  width: 50%;
}
.CampaignTypeDiagram .py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.CampaignTypeDiagram .align-center {
  align-items: center;
}
.CampaignTypeDiagram .justify-center {
  justify-content: center;
}
.CampaignTypeDiagram .text-center {
  text-align: center;
}
.CampaignTypeDiagram h3 {
  font-size: 1.3em;
  font-weight: bold;
  margin: 1.3em 0;
  text-transform: uppercase;
  min-height: 3em;
  display: flex;
  align-items: center;
}
.CampaignTypeDiagram h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 1.33em 0 0.8em;
  text-transform: uppercase;
}
.CampaignTypeDiagram .or {
  color: #AB8DC2;
  font-size: 1.3em;
  font-weight: bold;
  padding: 2em 0;
  width: 4em;
  text-transform: uppercase;
}
.CampaignTypeDiagram .h4Icon {
  font-size: 4em;
  color: #AB8DC2;
}
.CampaignTypeDiagram .dollarIcon {
  margin: 0.1em 0 0 0.2em;
  font-size: 1.3em;
}
.CampaignTypeDiagram .arrow {
  font-size: 4em;
  color: #cccaca;
}
.CampaignTypeDiagram .arrow.left {
  transform: rotate(25deg);
}
.CampaignTypeDiagram .arrow.right {
  transform: rotate(-25deg);
}
.CampaignTypeDiagram .level-1,
.CampaignTypeDiagram .level-2,
.CampaignTypeDiagram .level-3 {
  transition-property: opacity;
  transition-duration: 0.8s;
}
.CampaignTypeDiagram .level-1.show,
.CampaignTypeDiagram .level-2.show,
.CampaignTypeDiagram .level-3.show {
  visibility: visible;
  opacity: 1;
}
.CampaignTypeDiagram > p {
  font-size: 16px;
  margin: 16px 0 0 0;
}
.CampaignTypeDiagram > p em {
  font-size: 14px;
}
@media screen and (max-width: 529px) {
  .CampaignTypeDiagram {
    font-size: 16px;
  }
  .CampaignTypeDiagram > .row {
    flex-direction: column;
    align-items: center;
  }
  .CampaignTypeDiagram .level-1.w-33 {
    width: 100%;
  }
  .CampaignTypeDiagram .or {
    padding: 0;
  }
}
