.CampaignsParticipantsPage {
  .participant-name {
    float: left;
  }
  
  .btn-view-page {
    float: right;
  }

  .ant-tabs {
    clear: both;
  }

  .with-margin-right {
    margin-right: 25px;
  }
}