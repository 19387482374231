.CampaignsTeamsRegistrationPageTable {
  .table-title {
    float: left;
  }

  .actions {
    float: right;

    button:first-of-type {
      margin-right: 1rem;
    }
  }

  .PaginationTop {
    clear: both;
  }

  .ResultTable--ClickableRow tbody>tr:hover {
    background-color: #fff;
    color: rgba(0, 0, 0, .65);
  }

  .Column {
    .SelectField {
      margin: 0;
      max-height: 40px;

      &:before,
      .ant-form-item-control:before {
        content: none;
      }
    }
  }

  @mobile-ruleset: {
    .ant-select-selection {
      font-size: 14px;

      .ant-select-arrow {
        right: 5px;
      }

      .ant-select-selection__rendered {
        margin: 0 5px !important;
      }
    }
  }

  @media screen and (max-width: 700px) {
    @mobile-ruleset();
  }

  //When the content area is smaller than 700 (700 + 290)
  @media only screen and (max-width: 990px) {
    aside:not(.ant-layout-sider-collapsed)+i+section.ant-layout {
      @mobile-ruleset();
    }
  }
}