.DonationSection {
  padding: 48px 0;
  background-color: #FFFFFF;
}
.DonationSection__Information {
  display: flex;
  justify-content: space-between;
}
.DonationSection__DonationQuote {
  padding: 12px;
}
.DonationSection .slider-control-centerleft,
.DonationSection .slider-control-centerright {
  top: calc(50% - 55px) !important;
}
@media screen and (max-width: 720px) {
  .DonationSection__Information {
    flex-direction: column;
    justify-content: inherit;
    align-items: center;
    text-align: center;
    margin-bottom: 6.4rem;
  }
  .DonationSection__Information > div {
    text-align: center;
  }
  .DonationSection__Information > div .ant-divider-horizontal {
    margin-left: auto;
    margin-right: auto;
  }
}
.DonationSection .DonationSection__Carousel {
  height: 350px;
}
