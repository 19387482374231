.responsiveTabs .ant-dropdown-trigger {
  display: none;
}
@media screen and (max-width: 700px) {
  .responsiveTabs {
    position: relative;
  }
  .responsiveTabs div.ant-tabs-tab:not(.ant-tabs-tab-active),
  .responsiveTabs .ant-tabs-ink-bar {
    display: none !important;
  }
  .responsiveTabs .ant-tabs-tab-active:hover {
    color: #000;
    cursor: default;
  }
  .responsiveTabs .ant-tabs-tab-active {
    border-bottom: 4px solid var(--secondary-color);
  }
  .responsiveTabs .ant-dropdown-trigger {
    display: block;
    position: absolute;
    top: 5px;
    right: 2rem;
    z-index: 2;
    font-size: 24px;
    color: #000;
    padding: 0 0.3em;
    margin-right: -2px;
  }
  .responsiveTabs .ant-dropdown-trigger.ant-dropdown-open {
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    color: #000;
    border-radius: 4px 4px 0 0;
  }
  .responsiveTabs .ant-dropdown-trigger.ant-dropdown-open:after {
    content: "";
    width: 118%;
    height: 10px;
    background: #fff;
    bottom: 0;
    transform: translateY(100%);
    right: 0;
    position: absolute;
  }
  div.mobileTabsSubmenu {
    width: 100%;
    margin-top: -10px;
    z-index: 1 !important;
  }
  div.mobileTabsSubmenu .ant-dropdown-menu {
    margin: 0 2rem 0 1.8rem;
    top: -5px;
    right: -2px;
  }
  div.mobileTabsSubmenu .ant-dropdown-menu-item,
  div.mobileTabsSubmenu .ant-dropdown-menu-submenu-title {
    padding: 1.5rem 2rem;
    cursor: default;
  }
  div.mobileTabsSubmenu .ant-dropdown-menu-item a,
  div.mobileTabsSubmenu .ant-dropdown-menu-submenu-title a {
    display: inline;
  }
  div.mobileTabsSubmenu .ant-dropdown-menu-item:hover,
  div.mobileTabsSubmenu .ant-dropdown-menu-submenu-title:hover {
    background: none !important;
  }
}
@media only screen and (max-width: 990px) {
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .responsiveTabs {
    position: relative;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .responsiveTabs div.ant-tabs-tab:not(.ant-tabs-tab-active),
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .responsiveTabs .ant-tabs-ink-bar {
    display: none !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .responsiveTabs .ant-tabs-tab-active:hover {
    color: #000;
    cursor: default;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .responsiveTabs .ant-tabs-tab-active {
    border-bottom: 4px solid var(--secondary-color);
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .responsiveTabs .ant-dropdown-trigger {
    display: block;
    position: absolute;
    top: 5px;
    right: 2rem;
    z-index: 2;
    font-size: 24px;
    color: #000;
    padding: 0 0.3em;
    margin-right: -2px;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .responsiveTabs .ant-dropdown-trigger.ant-dropdown-open {
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    color: #000;
    border-radius: 4px 4px 0 0;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .responsiveTabs .ant-dropdown-trigger.ant-dropdown-open:after {
    content: "";
    width: 118%;
    height: 10px;
    background: #fff;
    bottom: 0;
    transform: translateY(100%);
    right: 0;
    position: absolute;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout div.mobileTabsSubmenu {
    width: 100%;
    margin-top: -10px;
    z-index: 1 !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout div.mobileTabsSubmenu .ant-dropdown-menu {
    margin: 0 2rem 0 1.8rem;
    top: -5px;
    right: -2px;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout div.mobileTabsSubmenu .ant-dropdown-menu-item,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout div.mobileTabsSubmenu .ant-dropdown-menu-submenu-title {
    padding: 1.5rem 2rem;
    cursor: default;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout div.mobileTabsSubmenu .ant-dropdown-menu-item a,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout div.mobileTabsSubmenu .ant-dropdown-menu-submenu-title a {
    display: inline;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout div.mobileTabsSubmenu .ant-dropdown-menu-item:hover,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout div.mobileTabsSubmenu .ant-dropdown-menu-submenu-title:hover {
    background: none !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .mobileTabsSubmenu ul {
    margin: 0 0.1em 0 2.2em !important;
  }
}
@media screen and (max-width: 400px) {
  div.mobileTabsSubmenu .ant-dropdown-menu {
    margin: 0 2rem 0 0.7rem;
  }
}
