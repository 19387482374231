.DonationChoicePage {
  padding-top: 48px;
  padding-bottom: 48px;
}
.DonationChoicePage .FundkyWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DonationChoicePage > h1,
.DonationChoicePage > p {
  text-align: center;
}
.DonationChoicePage > p {
  margin-bottom: 48px;
}
.DonationChoicePage__CallToActions {
  display: flex;
  justify-content: center;
  width: 100%;
}
.DonationChoicePage__CallToActions > span {
  align-self: center;
  font-size: 18px;
  margin: 24px;
  text-transform: uppercase;
}
.DonationChoicePage__CallToAction {
  border: 1px solid #DDDFDF;
}
.DonationChoicePage__CallToAction .FundkyCTA__Icon {
  color: var(--link-color);
}
.DonationChoicePage__CallToAction:not(.DonationChoicePage__CallToAction--Primary) a {
  border-color: var(--link-color);
  background-color: var(--link-color);
  color: #FFFFFF;
}
.DonationChoicePage__CallToAction:not(.DonationChoicePage__CallToAction--Primary) a:hover {
  border-color: var(--link-hover-color);
  background-color: var(--link-hover-color);
  color: #FFFFFF;
}
.DonationChoicePage__CallToAction--Primary .FundkyCTA__Icon {
  color: var(--primary-color);
}
@media screen and (max-width: 460px) {
  .DonationChoicePage__CallToActions {
    flex-direction: column;
    align-items: center;
  }
}
