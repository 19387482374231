@import (reference) "../../assets/stylesheets/main";
@import (reference) "../../common/PublicCarousel";

@thermometer-width: 363px;
@thermometer-margin: @padding-sm;

.CampaignSection {
  padding: @padding-lg * 2 0;
  background-color: @body-background;
  height: 1000px;
  .FundkyWrap{
    display: flex;
  }


  &__Information button.ant-btn {
    .secondary-btn();
  }

  &__Thermometer {
    width: @thermometer-width;
    padding: @padding-lg;
    background-color: @input-bg;
    border-radius: @border-radius-base;
  }

  &__Carousel {

    &.OneCampaign {
      min-width: 500px !important;
    }

    ul {
      display: flex !important;
      align-items: stretch;
      cursor: grab !important;

      > li {
        display: flex !important;
        justify-content: center;
        align-items: stretch !important;
        cursor: grab !important;
        min-height: 100%;

        > a {
          display: flex !important;
          align-items: stretch;
          width: @thermometer-width;
          margin: 0 @padding-sm;

          > .CampaignSection__Thermometer {
            display: flex;
            flex-direction: column;
            margin: 0;

            > .PublicThermometer__OutsideContent {
              flex: 1;

              > h3 {
                margin: 0 0 auto;
                font-size: 24px;
                min-height: 108px;
              }

              > .PublicThermometer__Percentage {
                font-size: 82px;
              }

              > .PublicThermometer__Detail {
                font-size: 26px;
              }
            }
          }
        }
      }
    }

    flex: 1 0;

    @media screen and (max-width: 1080px) {
      min-width: @thermometer-width + 1 * @thermometer-margin + 1 * @public-carousel-side-controls-width;
    }

    @media screen and (min-width: 1081px) and (max-width: 1393px) {
      min-width: @thermometer-width + 2 * @thermometer-margin + 2 * @public-carousel-side-controls-width;
    }

    @media screen and (min-width: 1394px) and (max-width: 1780px) {
      min-width: 2 * @thermometer-width + 4 * @thermometer-margin + 2 * @public-carousel-side-controls-width;
    }

    @media screen and (min-width: 1781px) and (max-width: 2167px) {
      min-width: 3 * @thermometer-width + 6 * @thermometer-margin + 2 * @public-carousel-side-controls-width;
    }

    @media screen and (min-width: 2168px) {
      min-width: 4 * @thermometer-width + 8 * @thermometer-margin + 2 * @public-carousel-side-controls-width;
    }
  }


  @media screen and (max-width: 720px) {
    flex-direction: column;

    &__Information {
      margin-bottom: 3rem;
      text-align: center;

      .ant-divider-horizontal {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__Carousel {
      ul > li > a { margin: 0 auto; }
    }
  }

  @media screen and (max-width: 600px) {
    .FundkyWrap{
      flex-direction: column;
    }
  }

  @media screen and (max-width: 520px) {
    &__Carousel {
      min-width: inherit;
    }
  }

  @media screen and (max-width: 475px) {
    .PublicCarousel--SideControls {
      .slider-control-centerright a {
        right:-1em;
      }
      .slider-control-centerleft a {
        left:-1em;
      }
    }
    .PublicThermometer__CircularWrapper{
        width: 226px;
        margin: auto;
    }
    .CampaignSection__Carousel ul>li>a{
      width:111%;
    }
  }

}

.menu-contribute {
  li:hover {
    background-color: #ECEDED !important;

    a {
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}

@media screen and (min-width: 700px) {
  .CampaignSection{
    height: 750px;
  }
}
