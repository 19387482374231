@import (reference) "~Parts/assets/stylesheets/themes/_fundky.less";

.CampaignTypeDiagram {
  font-size: 10px;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    p {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 1.2em;
      margin: 0 0 1.2em;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 .2em;
  }

  .w-33 {width: 33%}
  .w-50 {width: 50%}
  .py-1 { padding-top: 1em; padding-bottom: 1em }
  .align-center {align-items: center}
  .justify-center {justify-content: center}
  .text-center {text-align: center}

  h3 {
    font-size: 1.3em;
    font-weight: bold;
    margin: 1.3em 0;
    text-transform: uppercase;
    min-height: 3em;
    display: flex;
    align-items: center;
  }

  h4 {
    font-size: 1em;
    font-weight: bold;
    margin: 1.33em 0 .8em;
    text-transform: uppercase;
  }

  .or {
    color: @helper-color;
    font-size: 1.3em;
    font-weight: bold;
    padding: 2em 0;
    width: 4em;
    text-transform: uppercase;
  }

  .h4Icon {
    font-size: 4em;
    color: @helper-color;
  }

  .dollarIcon {
    margin: .1em 0 0 .2em;
    font-size: 1.3em;
  }

  .arrow {
    font-size: 4em;
    color: #cccaca;

    &.left {transform: rotate(25deg)}
    &.right {transform: rotate(-25deg)}
  }

  .level-1, .level-2, .level-3 {
    transition-property: opacity;
    transition-duration: .8s;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  > p {
    font-size: 16px;
    margin: 16px 0 0 0;
    em {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 529px) {
    font-size: 16px;
    > .row {
      flex-direction: column;
      align-items: center;
    }
    .level-1.w-33 {
      width: 100%;
    }
    .or {
      padding: 0;
    }
  }
}
