body {
  min-height: 100%;
  font-size: 1.6rem;
  overflow-x: hidden;
}
.PublicLayout {
  width: 100%;
  min-height: 100%;
  position: relative;
}
.PublicLayout .ant-form .ant-checkbox-wrapper p {
  line-height: normal;
  margin-bottom: 0;
}
.PublicLayout .ant-form .SubmitWrapper {
  margin-top: 7.8rem;
}
@media screen and (min-width: 1081px) {
  .PublicLayout .PublicHeaderRef + .ant-layout-content {
    margin-top: 90px;
    transition: margin-top 0.2s ease-in-out;
  }
}
.PublicLayout form .has-feedback .ant-input {
  padding: 4px 11px;
}
.PublicLayout .ant-form-item-children {
  display: block;
}
.PublicLayout .ant-form-item-children .ant-form-item-children-icon {
  right: -2.6rem !important;
}
@media screen and (max-width: 1080px) {
  .PublicLayout .PublicHeaderRef + .ant-layout-content {
    margin-top: 76px;
  }
}
.PublicLayout .info-notice-language {
  color: #AC8EC3;
}
