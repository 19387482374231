@import (reference) "../../../assets/stylesheets/main";

.ParticipationTypeOptions {
  display: flex;
  position: relative;
  margin-bottom: 1rem;

  // Add a new feedback icon in the right
  // Repro Antd behavior: the pseudo-element is always here but empty
  &:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    width: 32px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: @form-feedback-icon-size;
    animation: zoomIn .3s @ease-out-back;
  }

  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
}

span.ParticipationTypeOptions__Option,
button.ParticipationTypeOptions__Option {
  background-color: @input-bg;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

span.ParticipationTypeOptions__Option {
  >button {
    border: none;
    width: 100%;

    &:hover {
      color: var(--link-hover-color)
    }

    &:focus {
      color: var(--link-hover-color)
    }
  }
}

button.ParticipationTypeOptions__Option {
  outline: none;

  &:hover {
    border-color: var(--link-hover-color)
  }

  &:focus {
    border-color: var(--link-hover-color)
  }
}

.ParticipationTypeOptions__Option {
  cursor: pointer;

  // Repro Antd input styles (thicker border thought)
  border: 2px @border-style-base @input-border-color;
  border-radius: @border-radius-base;
  transition: all .3s;

  &.ParticipationTypeOptions__Option--Selected {
    border: 2px var(--primary-color) solid;
  }

  margin-right: 6px;

  &:last-child {
    margin-right: 0;
    opacity: 0;
  }

  .ParticipationTypeOptions__Icon {
    font-size: 3.6rem;
    margin-top: @padding-lg;
    margin-bottom: 1rem;
    color: var(--link-color);
  }

  h3 {
    margin: 0;
  }

  p {
    // Because we are inside a form control, we need to reset the Antd input line-height
    line-height: initial;
    text-align: center;
    margin-bottom: 30px;
  }
}

.ParticipationTypeOptions__Empty {
  flex: 1;
}
