.transactions-suggestions-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-start;
}
.transactions-suggestions-wrap .ant-form-item {
  margin-right: 1rem;
}
.transactions-suggestions-wrap .MoneyField .has-error {
  padding-bottom: 1em;
}
.transactions-suggestions-wrap .MoneyField .ant-form-item-control .ant-form-explain {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 0 !important;
  background: white;
  width: 100%;
}
.transactions-suggestions-wrap .MoneyField,
.transactions-suggestions-wrap .MoneyField .ant-form-item-control,
.transactions-suggestions-wrap .MoneyField .ant-form-item-control-wrapper {
  position: static;
}
@media screen and (max-width: 540px) {
  .transactions-suggestions-wrap {
    padding-bottom: 2.5em;
  }
  .transactions-suggestions-wrap .MoneyField__Input {
    width: 148px !important;
  }
  .transactions-suggestions-wrap .MoneyField .has-error {
    padding-bottom: 0 !important;
  }
  .transactions-suggestions-wrap .MoneyField .ant-form-item-control .ant-form-explain {
    bottom: auto;
    top: 6em;
    line-height: 1.3em;
    height: 2.5em;
  }
}
@media screen and (max-width: 370px) {
  .transactions-suggestions-wrap .MoneyField__Input {
    width: 124px !important;
  }
}
