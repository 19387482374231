.CampaignsTeamsRegistrationPageTable .table-title {
  float: left;
}
.CampaignsTeamsRegistrationPageTable .actions {
  float: right;
}
.CampaignsTeamsRegistrationPageTable .actions button:first-of-type {
  margin-right: 1rem;
}
.CampaignsTeamsRegistrationPageTable .PaginationTop {
  clear: both;
}
.CampaignsTeamsRegistrationPageTable .ResultTable--ClickableRow tbody > tr:hover {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65);
}
.CampaignsTeamsRegistrationPageTable .Column .SelectField {
  margin: 0;
  max-height: 40px;
}
.CampaignsTeamsRegistrationPageTable .Column .SelectField:before,
.CampaignsTeamsRegistrationPageTable .Column .SelectField .ant-form-item-control:before {
  content: none;
}
@media screen and (max-width: 700px) {
  .CampaignsTeamsRegistrationPageTable .ant-select-selection {
    font-size: 14px;
  }
  .CampaignsTeamsRegistrationPageTable .ant-select-selection .ant-select-arrow {
    right: 5px;
  }
  .CampaignsTeamsRegistrationPageTable .ant-select-selection .ant-select-selection__rendered {
    margin: 0 5px !important;
  }
}
@media only screen and (max-width: 990px) {
  .CampaignsTeamsRegistrationPageTable aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ant-select-selection {
    font-size: 14px;
  }
  .CampaignsTeamsRegistrationPageTable aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ant-select-selection .ant-select-arrow {
    right: 5px;
  }
  .CampaignsTeamsRegistrationPageTable aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ant-select-selection .ant-select-selection__rendered {
    margin: 0 5px !important;
  }
}
