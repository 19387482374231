.SocialSharing {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0 2.4rem 0;

  &__Title {
    margin-bottom: .8rem;
    text-transform: uppercase;
  }

  &__Wrap {
    display: flex;
    justify-content: center;
  }

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
    color: #fff;
    width: 40px;
    height: 40px;
  }

  a:first-of-type {
    border-radius: 4px 0 0 4px;
  }

  a:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  &__Copy {
    background-color: #63C1C6;
    transition: background-color .2s ease-in-out;

    &:hover {
      background-color: darken(#63C1C6, 6%);
    }
  }

  .hiddenInput {
    position: absolute;
    left: -99999px;
  }
}

.ant-message{
  z-index:1060;
}

.WinkNotification .SocialSharing{
  align-items: flex-start;
}