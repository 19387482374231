@import (reference) "../assets/stylesheets/main";

.ResultThumbnailList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__List {
    display: flex;
    list-style-type: none;
    padding: 0;
    flex-wrap: wrap;
    justify-content: flex-start;

    li {
      margin: 10px;
      border: 0;
      cursor: pointer;

      width: 250px;

      @media (min-width: 706px) and (max-width: 1005px) {
        width: ~"calc( (100% / 2) - 9px )";
      }

      @media (min-width: 1006px) and (max-width: 1305px) {
        width: ~"calc( (100% / 2) - 9px )";
      }

      @media (min-width: 1306px) and (max-width: 1605px) {
        width: ~"calc( (100% / 3) - 9px )";
      }

      @media (min-width: 1606px) and (max-width: 1905px) {
        width: ~"calc( (100% / 4) - 9px )";
      }

      @media (min-width: 1906px) and (max-width: 2205px) {
        width: ~"calc( (100% / 5) - 9px )";
      }

      @media (min-width: 2206px) and (max-width: 2505px) {
        width: ~"calc( (100% / 6) - 9px )";
      }

      @media (min-width: 2506px) {
        width: 297px;
      }
    }

    .ant-card {
      transition: all @animation-duration-slow;

      &:hover {
        background-color: @admin-cell-hover;
        color: #FFFFFF;

        .ant-card-head-title {
          color: #FFFFFF;
        }
      }
    }

    .ant-card-head {
      background-color: transparent;
    }

    .ant-card-head-title {
      transition: color @animation-duration-slow;

      i,
      svg {
        color: var(--primary-color);
        margin-right: 20px;
        font-size: 27px;
      }

      >span {
        display: flex;
      }
    }
  }

  &__Pagination {
    align-self: center;

    li {
      background-color: transparent;

      &.ant-pagination-item-active {
        background-color: var(--primary-color);
        border-color: var(--primary-color);

        a {
          color: #fff;
        }
      }

      &:hover:not(.ant-pagination-item-active):not(.ant-pagination-disabled) {
        border-color: var(--primary-color);

        a {
          color: var(--primary-color);
        }
      }

      &.ant-pagination-prev,
      &.ant-pagination-next {
        a {
          background-color: transparent;
        }

        &:hover:not(.ant-pagination-disabled),
        &:focus:not(.ant-pagination-disabled) {
          a {
            border-color: var(--primary-color);
            color: var(--primary-color);
          }
        }
      }
    }
  }
}