.featuredCampaignItem .FeatureCampaign__Tip {
  margin: 3rem 0 0.3rem 0;
}
.featuredCampaignItem .Draggable_Wrap {
  display: flex;
  flex-direction: column;
}
.featuredCampaignItem .Draggable_Item {
  display: flex;
  align-items: center;
  height: 70px;
  background-color: #fff;
  margin: 0.5rem 0;
  padding: 1rem;
  border: 2px solid transparent;
  transition: border 0.2s ease-in-out;
}
.featuredCampaignItem .Draggable_Item:hover,
.featuredCampaignItem .Draggable_Item:active {
  border: 2px solid var(--link-color);
}
.featuredCampaignItem .Draggable_Item--Order {
  font-weight: bold;
  margin-right: 1rem;
}
.featuredCampaignItem .Draggable_Item--Banner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
}
.featuredCampaignItem .Draggable_Item--Title {
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
}
.featuredCampaignItem .featureCampaigns_disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.featuredCampaignItem .featureCampaigns_disabled:after {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
