@import (reference) "../../assets/stylesheets/main";
@import (reference) "../../common/PublicThermometer";

@slider-padding: @padding-lg;

.DonationSection {
  padding: @padding-lg * 2 0;
  background-color: #FFFFFF;

  &__Information {
    display: flex;
    justify-content: space-between;
  }

  &__DonationQuote {
    padding: @padding-sm;
  }

  .slider-control-centerleft,
  .slider-control-centerright {
    top: ~"calc(50% - 55px)" !important;
  }

  @media screen and (max-width: 720px) {
    &__Information {
      flex-direction: column;
      justify-content: inherit;
      align-items: center;
      text-align: center;
      margin-bottom: 6.4rem;

      > div {
        text-align: center;

        .ant-divider-horizontal {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  .DonationSection__Carousel{
    height: 350px;
  }
}
