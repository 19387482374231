.ConnectionModal {
  overflow: hidden;
  width: 986px !important;
}
.ConnectionModal__Form {
  opacity: 0;
  transition: opacity 1s;
}
.ConnectionModal__Form.active {
  opacity: 1;
}
.ConnectionModal__Form--container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ConnectionModal .ant-modal-close-x {
  display: none;
}
.ConnectionModal .ant-modal-body,
.ConnectionModal .CssVariableApplicator {
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: flex-end;
  position: relative;
}
.ConnectionModal__Panel {
  display: flex;
  flex-direction: column;
  width: 493px;
  padding: 50px;
  z-index: 1;
  position: relative;
}
.ConnectionModal__Panel.top {
  z-index: 2;
  transition: right 0.5s;
}
.ConnectionModal .ant-modal-body {
  background-color: #ECEDED;
  border-radius: 4px;
  overflow: hidden;
  width: 986px !important;
}
.ConnectionModal .ant-btn[disabled] {
  background-color: #F85572;
  color: #fff;
}
.ConnectionModal .ant-btn[disabled] svg {
  margin-right: 10px;
}
.ConnectionModal__Logo {
  max-width: 100%;
  height: auto;
  align-self: center;
}
.ConnectionModal__Logo--container {
  position: relative;
  margin-bottom: 24px;
  align-self: center;
  flex: 0;
}
.ConnectionModal--question {
  color: #ab8dc2;
  font-size: 30px;
  position: absolute;
  top: 10px;
  right: -40px;
  cursor: pointer;
  display: none;
}
.ConnectionModal .ant-alert-description-link {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}
.ConnectionModal .ant-btn:hover,
.ConnectionModal .ant-btn:focus {
  color: #fff;
}
.ConnectionModal .InfoPanel--back {
  display: none;
}
@media screen and (max-width: 986px) {
  .ConnectionModal {
    width: 493px !important;
  }
  .ConnectionModal .ant-modal-body {
    width: 493px !important;
  }
  .ConnectionModal .InfoPanel {
    overflow: auto;
  }
  .ConnectionModal .InfoPanel--back {
    display: block;
  }
  .ConnectionModal__Panel.top {
    position: absolute;
    right: 493px;
  }
  .ConnectionModal__Panel.display {
    right: 0;
  }
  .ConnectionModal--question {
    display: block;
    top: 16px;
    right: -35px;
    overflow: scroll;
  }
}
@media screen and (max-width: 508px) {
  .ConnectionModal {
    width: 300px !important;
  }
  .ConnectionModal .ant-modal-body {
    width: 300px !important;
  }
  .ConnectionModal .InfoPanel {
    overflow: auto;
  }
  .ConnectionModal .InfoPanel--back {
    display: block;
    top: 10px;
    left: 10px;
    font-size: 30px;
  }
  .ConnectionModal--question {
    top: 20px;
    right: -10px;
  }
  .ConnectionModal__Logo {
    width: 90%;
  }
  .ConnectionModal__Panel {
    width: 300px !important;
    padding: 20px;
  }
  .ConnectionModal__Panel.top {
    right: 300px;
  }
  .ConnectionModal__Panel.display {
    right: 0;
  }
}
