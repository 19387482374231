.PublicParticipantTeamMembersPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.PublicParticipantTeamMembersPanel > * {
  margin-bottom: 0;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Information {
  display: flex;
  align-items: flex-start;
  flex: 0 0 auto;
  max-width: 33%;
  margin: 0.6rem 2rem 1.2rem 0;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Information .iconWrap {
  background: #64C1C6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Information .iconWrap .fa-users {
  font-size: 2rem;
  color: #fff;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Information p,
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Information h4 {
  line-height: normal;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Information p {
  margin-bottom: 0;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Information h4 {
  font-size: 2rem;
  margin-bottom: 0;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Information h4 a {
  color: inherit;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Carousel {
  flex: 1 1 63%;
  min-width: 63%;
  max-width: 100%;
  padding: 0 2.8rem;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Carousel div[class^="slider-control-"] svg {
  font-size: 2.2rem;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Carousel div[class^="slider-control-"] .disabled {
  opacity: 0.5;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Carousel .slider-slide a {
  margin: 0 auto;
}
.PublicParticipantTeamMembersPanel .PublicParticipantTeamMembersPanel_Carousel--avatar {
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: var(--secondary-color);
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
  line-height: 50px;
  margin: 0 auto;
}
