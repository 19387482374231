.PublicThermometer {
  text-align: center;
}
.PublicThermometer__CircularWrapper {
  position: relative;
}
.PublicThermometer__CircularWrapper svg {
  display: block;
}
.PublicThermometer__CircularParticipant {
  max-width: 258px;
  max-height: 258px;
  margin: 0 auto;
}
.PublicThermometer .CircularProgressbar-trail {
  stroke: #EEEEEE;
  stroke-width: 5%;
}
.PublicThermometer .CircularProgressbar-path {
  stroke: var(--primary-color);
  stroke-width: 5%;
}
.PublicThermometer__InsideContent {
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PublicThermometer__InsideContent.hasEditButton:hover .edit-profile {
  opacity: 1;
  pointer-events: auto;
}
.PublicThermometer__InsideContent .edit-profile {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}
.PublicThermometer__InsideContent img {
  width: 100%;
}
.PublicThermometer__OutsideContent {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PublicThermometer__OutsideContent h3 {
  font-family: 'Domine', serif;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.PublicThermometer__Percentage {
  color: var(--primary-color);
  font-size: 48px;
}
.PublicThermometer__Detail {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
}
.PublicThermometer__Detail b {
  font-weight: 600;
}
.PublicThermometer__DonateBtn {
  margin-top: 20px;
}
.PublicThermometer--Compact .PublicThermometer__Percentage {
  font-size: 7.4rem;
  line-height: 7.2rem;
}
.PublicThermometer--Compact .PublicThermometer__Detail {
  font-size: 2.4rem;
  line-height: normal;
}
.horizontal-thermometer {
  background: #fff;
  padding: 0;
}
.horizontal-thermometer .numbers-wrapper,
.horizontal-thermometer .horizontal-progress-bar {
  width: 95%;
  margin: auto;
}
.horizontal-thermometer .numbers-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
}
.horizontal-thermometer .numbers-wrapper .PublicThermometer__Detail,
.horizontal-thermometer .numbers-wrapper .PublicThermometer__Percentage {
  line-height: 2.4rem;
}
.horizontal-thermometer .numbers-wrapper .PublicThermometer__Detail {
  font-size: 1.6rem;
}
.horizontal-thermometer .numbers-wrapper .PublicThermometer__Percentage {
  font-weight: bold;
  font-size: 2.4rem;
}
.horizontal-thermometer .horizontal-progress-bar,
.horizontal-thermometer .horizontal-progress-bar div {
  height: 18px;
  border-radius: 3px;
}
.horizontal-thermometer .horizontal-progress-bar {
  background: #E9EAEA;
}
.horizontal-thermometer .horizontal-progress-bar div {
  background: var(--primary-color);
  max-width: 100%;
}
.PublicThermometer--Hide.PublicThermometer--Compact .PublicThermometer__InsideContent {
  position: relative;
  border-radius: 0;
  top: unset;
  bottom: unset;
  left: unset;
  right: unset;
}
.PublicThermometer--Hide:not(.PublicThermometer--Compact):not(.PublicThermometer--Horizontal) .PublicThermometer__CircularParticipant {
  height: 258px;
}
.PublicThermometer--Hide.CampaignSection__Thermometer:not(.PublicThermometer--Compact) .PublicThermometer__CircularWrapper {
  height: 315px;
}
