.NameField input.counterOn {
  padding-right: 30px;
}
.NameField .NameField_Counter {
  position: absolute;
  color: #BFBFBF;
  right: 0;
  top: 50%;
  line-height: 0;
  text-align: center;
  width: 35px;
}
.NameField input.counterOn {
  padding-right: 50px;
}
.NameField .has-feedback.has-error .NameField_Counter,
.NameField .has-feedback.has-success .NameField_Counter {
  right: 20px;
}
