.MonthlyField .ant-form-item-label {
  margin-bottom: 1em;
}
.MonthlyField .ant-form-item-control-wrapper {
  margin-bottom: 24px;
}
.MonthlyField label {
  color: rgba(0, 0, 0, 0.65) !important;
}
.MonthlyField .CreateDonationForm__Field {
  display: block !important;
}
.MonthlyField__Message {
  background: #d9d9d9;
  padding: 1em 2rem;
  border-radius: 4px;
  margin: 1em 1em 0 1em;
}
.MonthlyField .ant-form-item-children-icon {
  top: 1em !important;
}
