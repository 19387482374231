@import (reference) "../../assets/stylesheets/main";


.StaticBreadcrumbs {
  ul {
    margin: 0 0 20px;
    padding: 0;
    list-style-type: none;

  }

  li {
    display: inline;
    font-size: 12px;

    a {
      color: #AEADAE;
    }

    svg {
      color: var(--link-color);
      margin: 0 7px;
    }

    &:last-of-type a {
      color: #424749;
    }
  }
}
