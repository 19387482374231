.transactions-suggestions-wrap {
  display: flex;
  flex-wrap: wrap;
  position:relative;
  align-items: flex-start;

  .ant-form-item {
    margin-right: 1rem;
  }
  .MoneyField {
    .has-error{padding-bottom:1em;}
    .ant-form-item-control{
      .ant-form-explain{
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 0 !important;
        background: white;
        width: 100%;
      }
    }
    &, .ant-form-item-control, .ant-form-item-control-wrapper{
      position:static;
    }
  }
  @media screen and (max-width: 540px) {
    &{
      padding-bottom:2.5em;
    }
    
    .MoneyField__Input{
      width:148px !important;
    }
    .MoneyField .has-error{
      padding-bottom:0 !important;
    }

    .MoneyField .ant-form-item-control{
      .ant-form-explain{
        bottom:auto;
        top:6em;
        line-height:1.3em;
        height:2.5em;
      }
    }
  }
  @media screen and (max-width: 370px) {
    .MoneyField__Input{
      width:124px !important;
    }
  }
}