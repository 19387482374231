.DonationPage .DonationPage__Header {
  padding: 24px 0;
  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
}
.DonationPage .ant-tabs {
  background-color: #ECEDED;
}
.DonationPage .ant-tabs-bar {
  background-color: #F5F7F7;
  margin-bottom: 0;
}
.DonationPage .ant-tabs-bar i,
.DonationPage .ant-tabs-bar svg {
  color: var(--link-color);
}
.DonationPage .ant-tabs-tab {
  padding-left: 40px;
  padding-right: 40px;
}
.DonationPage .ant-tabs-tabpane {
  width: 100vw;
}
.DonationPage__ChangeRecipientBtn {
  font-size: 30px;
  color: var(--primary-color);
  margin-left: 30px;
  margin-top: 9px;
}
.DonationPage__ChangeRecipientBtn svg {
  margin: 0;
}
.DonationPage__ChangeRecipientBtn:hover {
  color: #D62D4C;
}
.DonationPage [class*="CancelWrapper"] {
  margin-bottom: 80px;
}
.DonationPage [class*="CancelWrapper"] a,
.DonationPage [class*="CancelWrapper"] > span {
  color: var(--primary-color);
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
  cursor: pointer;
}
.DonationPage [class*="CancelWrapper"] a .ant-popover-message-title,
.DonationPage [class*="CancelWrapper"] > span .ant-popover-message-title {
  text-transform: initial;
}
@media screen and (max-width: 360px) {
  .DonationPage .TypeField > div > div > span {
    display: block;
    position: relative;
  }
  .DonationPage .ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  .DonationPage .ant-radio-group > label {
    margin-right: 0;
  }
}
