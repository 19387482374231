.StaticEmbedMap {
  &__Container {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__Iframe {
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
  }
}
