//Closed public page message
.PublicPageClosed__Banner,
.PublicPageClosed__Thermo {
  display: flex;

  h3 {
    font-size: 1.8rem;
    line-height: normal;
  }

  .PublicPageClosed__Description {
    margin-bottom: 0;
    font-size: 1.6rem;
    line-height: normal;
  }
}

.PublicPageClosed__Banner {
  background-color: #D8FDFF;
  margin-bottom: 2.4rem;
  padding: 2.4rem;

  .fa-award {
    color: #2BDAFF;
    font-size: 4rem;
    margin-right: 2.4rem;
  }
}

.PublicPageClosed__Thermo {
  background-color: #D8FDFF;
  border: 1px solid #B5FAFF;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.8rem;
}

.PublicPageClosed__Panel {
  text-align: right;
  
  .PublicPageClosed__Title {
    font-weight: 600;
    margin-bottom: 0;
  }

  .PublicPageClosed__Description {
    font-weight: 300;
    margin-bottom: 0;
  }
}