@import (reference) "../assets/stylesheets/main";

@public-carousel-side-controls-width: 28px;

.PublicCarousel {
  &--SideControls {
    padding: 0 @public-carousel-side-controls-width;

    .slider-control-centerleft a,
    .slider-control-centerright a {
      position: absolute;
      transform: translateY(-50%);
      // top: -(@public-carousel-side-controls-width / 2);
      font-size: @public-carousel-side-controls-width;
      color: @heading-color;
      cursor: pointer;

      &.disabled {
        color: #999999 !important;
        cursor: initial;
      }
    }

    .slider-control-centerleft a {
      left: -( @public-carousel-side-controls-width );
    }
    .slider-control-centerright a {
      right: -( @public-carousel-side-controls-width );
    }
  }

  .slider-list {
    display: flex !important;
  }
  .slider {
    outline: none;
  }
}

.spinner-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem;
}
