@import (reference) "../assets/stylesheets/main";

@table-border-spacing: 6px;

.ResultTable {

  table {
    border-collapse: separate;
    border-spacing: 0 @table-border-spacing;

    span.ant-table-column-title {
      display: block;
      line-height: 1.1em;
    }
  }

  .ant-table-body {
    overflow-x: auto !important;
  }

  tbody>tr {
    background-color: @admin-cell-bg;
  }

  .ant-table-thead>tr>th {
    line-height: 1.1em;
    word-break: normal !important;
  }

  tbody>tr>td {
    border-bottom: 0;
    line-height: 1.1em;

    // Remove the Antd default transition on all properties of the td
    // because we want the tr to be responsible of the transition (see below)
    transition: unset;

    i,
    svg {
      color: var(--primary-color);
      font-size: 20px;
    }

    .ant-btn[disabled] {
      text-shadow: none;
      box-shadow: none;
      opacity: .5;

      i,
      svg {
        color: #C8D5D5;
      }
    }

    &.Column--Emphasis {
      font-weight: bold;
    }

    // Make the button looks like a simple icon
    &.Column--ButtonIcon,
    &.Column--ButtonsIcon {

      .ant-btn-circle,
      a {
        border: none;
        background: none;
        padding: 0;
        letter-spacing: normal;
        line-height: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 !important;
        overflow: visible;
        padding-top: 2px; //fix iphone glitch

        &.ant-btn-circle:before {
          background: none;
        }
      }
    }

    &.Column--ButtonsIcon {

      .buttons {
        display: flex;
        flex-wrap: wrap;
        margin: -.4rem;
      }

      button {
        margin: .4rem;
      }
    }
  }

  td {
    &:first-child {
      border-radius: @border-radius-sm 0 0 @border-radius-sm;
    }

    &:last-child {
      border-radius: 0 @border-radius-sm @border-radius-sm 0;
    }
  }

  .ant-table-placeholder {
    border-radius: @border-radius-sm;
  }

  .ant-table-pagination {
    text-align: center;
    float: none;

    li {
      background-color: transparent;
      margin: 0 4px 8px 4px;

      &.ant-pagination-item-active {
        background-color: var(--primary-color);
        border-color: var(--primary-color);

        a {
          color: #fff;
        }
      }

      &:hover:not(.ant-pagination-item-active):not(.ant-pagination-disabled) {
        border-color: var(--primary-color);

        a {
          color: var(--primary-color);
        }
      }

      &.ant-pagination-prev,
      &.ant-pagination-next {
        a {
          background-color: transparent;
        }

        &:hover:not(.ant-pagination-disabled),
        &:focus:not(.ant-pagination-disabled) {
          a {
            border-color: var(--primary-color);
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

.TransactionsListPage,
.CampaignsTransactionsListPage,
.CampaignsTeamsRegistrationPageTable {

  td.donation-target-name {
    //text-overflow: ellipsis;
    width: 14em;
    //white-space: nowrap;
    //display: block;
    //overflow: hidden;
  }
}

.ResultTable--ClickableRow tbody>tr {
  cursor: pointer;

  // We use the Antd default transition on all the properties of the tr
  &:hover {
    background-color: @admin-cell-hover;
    color: #FFFFFF;

    td {
      // The hover background is set on the td
      background-color: inherit;
    }
  }
}


@mobile-ruleset: {
  .ResultTable table {

    td,
    th {
      padding: 4px;
      font-size: 0.875em;

      &.Column--ButtonIcon {
        text-align: center;
      }

      a,
      button {
        display: inline !important;
      }

      &:first-child {
        padding-left: 0.5em;
      }

      &.referenceId {
        font-weight: normal !important;
      }
    }
  }

  .TransactionsListPage,
  .CampaignsTransactionsListPage,
  .CampaignsTeamsRegistrationPageTable {

    .donation-email,
    .donation-target-name,
    .amount,
    .collected {
      display: none;
    }
  }

  .ParticipantsListPage,
  .CampaignsParticipantsListPage {
    .memberEmail {
      display: none;
    }
  }

  .ant-pagination-total-text {
    width: 100%;
  }

  .PaginationTop {
    position: relative;
    padding-top: 4em;
    padding-bottom: 0;

    ul {
      border-top: 1px solid #d9d9d9;
    }

    .PaginationTop__TotalInfo span+span {
      display: block;
      position: absolute;
      top: 1.3em;
    }

    .ant-pagination {
      margin-right: 0;
      justify-content: space-between;

      .ant-pagination-total-text {
        position: absolute;
        top: 0.5em;
        left: 0;
      }

      &+.ant-select {
        position: absolute;
        top: 1.2em;
        right: 0;
        font-size: 14px;
      }
    }
  }

  .ant-pagination:after {
    display: none !important;
  }
}

@media screen and (max-width: 700px) {
  @mobile-ruleset();
}

@media only screen and (max-width: 990px) {

  //When the content area is smaller than 700 (700 + 290)
  aside:not(.ant-layout-sider-collapsed)+i+section.ant-layout {
    @mobile-ruleset();
  }
}

@media only screen and (max-width: 900px) {

  .ant-table-tbody>tr>td,
  .ant-table-thead>tr>th {
    padding: 8px;
  }
}

@media only screen and (max-width: 400px) {

  .ant-table-tbody>tr>td,
  .ant-table-thead>tr>th {
    padding: 4px;
  }
}
