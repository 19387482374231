@import url('https://fonts.googleapis.com/css?family=Galada');
.PublicFooter {
  padding: 0;
  background-color: #F5F7F7;
}
.PublicFooter .PublicFooter--instagram {
  top: 3px;
}
.PublicFooter-home .PublicFooter__PlatformSection {
  margin-top: 0 !important;
  border-top: 0 !important;
}
.PublicFooter__PlatformSection {
  border-top: 3px solid #E8E9E9;
  padding: 48px 0;
}
.PublicFooter .PlatformSocialMedia__PlatformSocialLinks ul {
  display: flex;
}
.PublicFooter__PlatformSection .ant-collapse {
  border: 0;
  background: none;
}
.PublicFooter__PlatformSection,
.PublicFooter__PlatformSection .ant-collapse {
  display: flex;
}
.PublicFooter__PlatformSection .ant-collapse-header,
.PublicFooter__PlatformSection .ant-collapse .ant-collapse-header {
  padding: 0;
  margin-bottom: 0.5em;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  line-height: 1.2em;
  padding-right: 0;
  margin-right: 0;
}
.PublicFooter__PlatformSection .ant-collapse-header .ant-collapse-arrow,
.PublicFooter__PlatformSection .ant-collapse .ant-collapse-header .ant-collapse-arrow {
  position: static;
  transform: none;
  margin-left: 0.8em;
}
.PublicFooter__PlatformSection .ant-collapse-content,
.PublicFooter__PlatformSection .ant-collapse .ant-collapse-content,
.PublicFooter__PlatformSection .ant-collapse-item,
.PublicFooter__PlatformSection .ant-collapse .ant-collapse-item {
  background: none;
  border: 0;
}
.PublicFooter__PlatformSection .ant-collapse-content-box,
.PublicFooter__PlatformSection .ant-collapse .ant-collapse-content-box {
  padding: 0;
}
.PublicFooter__PlatformSection div,
.PublicFooter__PlatformSection .ant-collapse div,
.PublicFooter__PlatformSection .ant-collapse-item,
.PublicFooter__PlatformSection .ant-collapse .ant-collapse-item {
  flex: 1;
  margin-right: 24px;
}
.PublicFooter__PlatformSection div:last-child,
.PublicFooter__PlatformSection .ant-collapse div:last-child,
.PublicFooter__PlatformSection .ant-collapse-item:last-child,
.PublicFooter__PlatformSection .ant-collapse .ant-collapse-item:last-child {
  margin-right: 0;
}
.PublicFooter__PlatformSection h4,
.PublicFooter__PlatformSection .ant-collapse h4,
.PublicFooter__PlatformSection .ant-collapse-header,
.PublicFooter__PlatformSection .ant-collapse .ant-collapse-header {
  text-transform: uppercase;
  font-weight: bold;
}
.PublicFooter__PlatformSection ul,
.PublicFooter__PlatformSection .ant-collapse ul {
  list-style: none;
  padding: 0;
}
.PublicFooter__PlatformSection a,
.PublicFooter__PlatformSection .ant-collapse a {
  color: rgba(0, 0, 0, 0.45);
}
.PublicFooter__PlatformSection a:hover,
.PublicFooter__PlatformSection .ant-collapse a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.PublicFooter .PlatformSocialMedia__PlatformSocialLinks li {
  display: inline;
  margin-right: 12px;
}
.PublicFooter .PlatformSocialMedia__PlatformSocialLinks li:last-child {
  margin-right: 0;
}
.PublicFooter .PlatformSocialMedia__PlatformSocialLinks svg {
  font-size: 2.8rem;
}
.PublicFooter__WaveBorder {
  height: 7px;
}
.PublicFooter__WaveBorder:after {
  content: "";
  display: block;
  height: 8px;
  background-repeat: repeat;
  background-size: 16px 8px;
  background-image: url("../../assets/images/footer_border.svg");
}
.PublicFooter__FundkySection {
  display: flex;
  flex-direction: column;
  background-color: #383D3F;
  color: rgba(255, 255, 255, 0.65);
  padding: 24px 0;
}
.PublicFooter__GetFundky {
  font-family: 'Galada';
  font-size: 36px;
  color: #d1d2d2;
  margin-left: 5px;
  line-height: 30px;
}
.PublicFooter__Divider {
  display: flex;
  flex: 1 0 auto;
}
.PublicFooter__Divider:first-child {
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.PublicFooter__Divider:last-child {
  flex-wrap: wrap;
  padding-top: 24px;
}
.PublicFooter__Newsletter {
  display: flex;
  flex: 1;
  align-items: center;
}
.PublicFooter__Newsletter > * {
  margin-right: 24px;
}
.PublicFooter__Newsletter p {
  flex-basis: 300px;
  margin-bottom: 0;
}
.PublicFooter__Newsletter form {
  flex-basis: 300px;
}
.PublicFooter__Newsletter input {
  border: 0;
}
.PublicFooter__Newsletter input:hover,
.PublicFooter__Newsletter input:focus {
  box-shadow: none;
}
.PublicFooter__Newsletter svg {
  font-size: 24px;
  color: var(--link-color);
  cursor: pointer;
}
.PublicFooter__FundkySocialLinks {
  display: flex;
  align-items: center;
}
.PublicFooter__FundkySocialLinks a {
  margin-right: 12px;
  border: 2px solid rgba(255, 255, 255, 0.65);
  border-radius: 100%;
  background-color: transparent;
  transition: all 0.3s;
  width: 30px !important;
  height: 30px !important;
  max-width: 30px !important;
  max-height: 30px !important;
}
.PublicFooter__FundkySocialLinks a:last-child {
  margin-right: 0;
}
.PublicFooter__FundkySocialLinks i {
  padding: 6px;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.65);
  transition: all 0.3s;
}
.PublicFooter__FundkySocialLinks svg {
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.65);
  transition: all 0.3s;
  width: 26px !important;
  height: 26px;
}
.PublicFooter__FundkySocialLinks a:hover {
  background-color: rgba(255, 255, 255, 0.65);
  border-color: transparent;
}
.PublicFooter__FundkySocialLinks a:hover i {
  color: #383D3F;
}
.PublicFooter__FundkySocialLinks a:hover svg {
  color: #383D3F;
}
.PublicFooter__LegalLinks,
.PublicFooter__CopyrightContainer {
  display: flex;
  align-items: center;
}
.PublicFooter__LegalLinks {
  margin-right: 24px;
}
.PublicFooter__LegalLinks:last-child {
  margin-right: 0;
}
.PublicFooter__LegalLinks > * {
  font-size: 14px;
  margin-right: 24px;
}
.PublicFooter__LegalLinks > *:last-child {
  margin-right: 0;
}
.PublicFooter__CopyrightContainer {
  margin-right: 24px;
}
.PublicFooter__CopyrightContainer:last-child {
  margin-right: 0;
}
.PublicFooter__PoweredContainer {
  margin-left: auto;
  position: relative;
}
.PublicFooter__PoweredTxt {
  position: absolute;
  display: block;
  top: 30px;
  left: 48px;
  font-size: 15px;
}
@media screen and (max-width: 992px) {
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__LegalLinks,
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__CopyrightContainer {
    margin-bottom: 2rem;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__CopyrightContainer {
    margin-right: 0;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__PoweredContainer {
    width: 100%;
  }
}
@media screen and (max-width: 820px) {
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__LegalLinks {
    width: 100%;
    margin-right: 0;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__CopyrightContainer {
    margin-bottom: 0;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__PoweredContainer {
    width: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 720px) {
  .PublicFooter__PlatformSection .ant-collapse {
    width: 100% !important;
  }
  .PublicFooter__PlatformSection,
  .PublicFooter__PlatformSection .ant-collapse {
    flex-wrap: wrap;
  }
  .PublicFooter__PlatformSection > div,
  .PublicFooter__PlatformSection .ant-collapse > div,
  .PublicFooter__PlatformSection .ant-collapse-item,
  .PublicFooter__PlatformSection .ant-collapse .ant-collapse-item {
    flex: inherit;
    width: calc(50% - 24px);
  }
}
@media screen and (max-width: 624px) {
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__LegalLinks {
    flex-wrap: wrap;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__LegalLinks > a {
    width: calc(50% - 24px);
  }
}
@media screen and (min-width: 567px) {
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse-header .ant-collapse-arrow {
    display: none;
  }
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse-content {
    display: block !important;
  }
}
@media screen and (max-width: 566px) {
  .PublicFooter .PublicFooter__PlatformSection,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse {
    flex-direction: column;
    text-align: center;
    padding-bottom: 0;
  }
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse-header,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse .ant-collapse-header,
  .PublicFooter .PublicFooter__PlatformSection h4,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse h4 {
    text-align: center;
    font-size: 1.125em;
    margin-bottom: 0;
  }
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse-header,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse .ant-collapse-header {
    margin-left: 1.3em;
  }
  .PublicFooter .PublicFooter__PlatformSection h4,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse h4 {
    margin-bottom: 0.25em;
  }
  .PublicFooter .PublicFooter__PlatformSection .PlatformSocialMedia__PlatformSocialLinks svg,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse .PlatformSocialMedia__PlatformSocialLinks svg {
    font-size: 3rem;
  }
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse-content-box,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse .ant-collapse-content-box {
    margin-top: -0.5em;
  }
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse-content-box ul li,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse .ant-collapse-content-box ul li {
    margin: 0.5em 0;
  }
  .PublicFooter .PublicFooter__PlatformSection > div,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse > div,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse-item,
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse .ant-collapse-item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1em !important;
  }
  .PublicFooter .PublicFooter__PlatformSection > div:not(:last-of-type),
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse > div:not(:last-of-type),
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse-item:not(:last-of-type),
  .PublicFooter .PublicFooter__PlatformSection .ant-collapse .ant-collapse-item:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  .PublicFooter .PlatformSocialMedia__PlatformSocialLinks ul {
    justify-content: center;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:first-of-type {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:first-of-type .PublicFooter__Newsletter {
    margin-bottom: 2rem;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:first-of-type .PublicFooter__Newsletter span {
    margin-right: 0;
  }
  .PublicFooter .PublicFooter__FundkySocialLinks a {
    width: 40px !important;
    height: 40px !important;
    max-width: 40px !important;
    max-height: 40px !important;
  }
  .PublicFooter .PublicFooter__FundkySocialLinks svg {
    height: 37px !important;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type {
    flex-direction: column;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__LegalLinks {
    justify-content: center;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__LegalLinks > a {
    width: auto !important;
    margin: 0 0.5em;
    white-space: nowrap;
    font-size: 12px;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__LegalLinks span {
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__CopyrightContainer {
    justify-content: center;
    margin-bottom: 1rem;
  }
  .PublicFooter .PublicFooter__FundkySection .PublicFooter__Divider:last-of-type .PublicFooter__PoweredContainer {
    margin-left: auto;
    margin-right: auto;
  }
  .PublicFooter .PublicFooter__CopyrightContainer {
    font-size: 12px;
  }
}
