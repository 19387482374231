.CampaignsTeamsPage {
  .team-name {
    float: left;
  }
  
  .btn-view-page {
    float: right;
  }

  .ant-tabs {
    clear: both;
  }

  .page-title {
    text-transform: uppercase;
    margin-bottom: 0;
  }

  h1 svg {
    margin-left: 1rem;
  }
}