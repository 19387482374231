.PublicCampaignBand {
  width: 100%;
  position: relative;
}
.PublicCampaignBand__Container {
  padding: 16px 0;
  height: 100%;
  background-color: #383D3F;
}
.PublicCampaignBand__Container .FundkyWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.PublicCampaignBand__BackIcon {
  color: white;
  font-size: 2.5em;
}
.PublicCampaignBand__Avatar {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 20px;
  background-color: var(--link-color);
}
.PublicCampaignBand__Campaigns {
  max-width: 50%;
}
.PublicCampaignBand__Campaigns > .HelpText {
  color: rgba(255, 255, 255, 0.45);
  font-weight: 300;
}
.PublicCampaignBand__Campaigns .PublicTitleDropdown h1 {
  font-size: 21px;
  color: white;
  margin: 0;
  line-height: 24px;
  font-family: 'Assistant', sans-serif;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.PublicCampaignBand__Campaigns .PublicTitleDropdown svg {
  margin-left: 12px;
  color: white;
}
.PublicCampaignBand__AboutButton {
  color: white;
  font-size: 14px;
  margin-left: auto;
  background-color: var(--link-color);
  border-color: var(--link-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.PublicCampaignBand__AboutButton:focus {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}
.PublicCampaignBand__AboutButton span {
  margin-right: 10px;
}
.PublicCampaignBand__EllipsisIcon {
  font-size: 24px;
  font-weight: 700;
}
@media screen and (max-width: 680px) {
  .PublicCampaignBand .PublicCampaignBand__BackIcon {
    font-size: 3rem;
  }
  .PublicCampaignBand .PublicCampaignBand__Avatar {
    margin: 0 12px;
  }
  .PublicCampaignBand__AboutButton span {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .PublicCampaignBand .PublicCampaignBand__BackIcon {
    font-size: 3rem;
  }
  .PublicCampaignBand .PublicCampaignBand__Avatar {
    margin: 0 12px;
  }
  .PublicCampaignBand__AboutButton span {
    display: none;
  }
}
@media screen and (max-width: 380px) {
  .PublicCampaignBand .PublicCampaignBand__Avatar {
    margin: 0 10px;
  }
  .PublicCampaignBand .PublicCampaignBand__AboutButton {
    padding: 0 12px;
  }
}
.PublicCampaignBand__WaveBorder {
  height: 7px;
}
.PublicCampaignBand__WaveBorder:after {
  content: "";
  display: block;
  height: 8px;
  background-repeat: repeat;
  background-size: 16px 8px;
  background-image: url("../assets/images/footer_border.svg");
  transform: rotate(180deg);
}
