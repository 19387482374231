.WinkNotification {
  margin-bottom: 2em;
}
.WinkNotification .WinkNotification__Inner {
  background: #fff;
  border: 1px solid #AB8DC2;
  padding: 2rem;
  position: relative;
}
.WinkNotification .logo-wink {
  max-width: 34px;
  position: absolute;
  top: 1.4rem;
  left: 1.4rem;
}
.WinkNotification .WinkNotification__Inner_Content.hasWink {
  margin-left: 4.5rem;
}
.WinkNotification .WinkNotification__Inner_Content.closable {
  margin-right: 2.4rem;
}
.WinkNotification .WinkNotification__Inner_Content h2,
.WinkNotification .WinkNotification__Inner_Content p {
  line-height: normal;
}
.WinkNotification .WinkNotification__Inner_Content h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
.WinkNotification .WinkNotification__Buttons {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
}
.WinkNotification .WinkNotification__Buttons.align-center {
  justify-content: center;
}
.WinkNotification .WinkNotification__Buttons.align-right {
  justify-content: flex-end;
}
.WinkNotification .WinkNotification__Buttons > span {
  display: block;
  margin: 1rem;
}
.WinkNotification .WinkNotification__Buttons button {
  background-color: #AB8DC2;
  border-color: #AB8DC2;
}
.WinkNotification .WinkNotification__Buttons button:hover {
  background-color: #9A7EAF !important;
  border-color: #9A7EAF !important;
}
.WinkNotification .WinkNotification__CloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  color: #AB8DC2;
  font-size: 2.6rem;
  line-height: normal;
}
.WinkNotification .WinkNotification__CloseButton:hover {
  color: #9A7EAF;
}
.WinkNotification button.ant-btn {
  background: #AB8DC2;
  border-color: #9A7EAF;
}
.WinkNotification button.ant-btn:hover {
  background: #9A7EAF;
}
