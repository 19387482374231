@import (reference) "../../assets/stylesheets/main";

.SelectCampaignPanel {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  h2 {
    margin-top: 60px;
    font-family: Domine;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }

  p {
    font-size: 16px;
    text-align: center;
  }

  .PaginationTop,
  .SearchBar {
    width: 75%;
    align-self: center;
  }

  .SearchBar input {
    height: 57px;
    font-size: 24px;
    padding-left:38px !important;
  }

  .AvatarThumbnail__Avatar {
    height: 58px;
    width: 58px;
    flex: 0 0 58px;
  }

  .AvatarThumbnail__PrimaryContent {
    font-weight: 600;
  }

  .ResultThumbnailList .ant-card {
    border: 2px solid #E5E5E5;
    &:hover {
      background-color: #fff;
      border: 2px solid var(--primary-color);
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .card-disabled {
    border: 2px solid #E5E5E5;
    background-color: #fff;
    opacity: .5;
    &:hover {
      cursor: not-allowed;
      border: 2px solid #E5E5E5 !important;
      background-color: #fff !important;;
    }
  }
}
