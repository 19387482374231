.CreateConfirmationPanel {
  position: relative;
  padding-top: 24px;
  max-width: 680px;
}
.CreateConfirmationPanel svg.CreateConfirmationPanel__SuccessIcon {
  position: absolute;
  left: -80px;
  font-size: 56px;
  color: var(--link-color);
  margin-right: 14px;
}
.CreateConfirmationPanel svg.CreateConfirmationPanel__ErrorIcon {
  position: absolute;
  left: -80px;
  font-size: 56px;
  color: #F3704A;
  margin-right: 14px;
}
.CreateConfirmationPanel svg.CreateConfirmationPanel__CcIcon {
  font-size: 33px;
  margin-right: 4px;
}
.CreateConfirmationPanel .TaxReceipt__ConfirmationMessage {
  display: block;
  margin-top: 2rem;
}
.CreateConfirmationPanel__InformationBlocks {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}
.CreateConfirmationPanel__InformationBlock {
  min-width: 50px;
  flex: auto;
  margin: 0 1rem;
}
.CreateConfirmationPanel__InformationBlock.TransactionInformation {
  width: 100%;
}
.CreateConfirmationPanel__InformationBlock.TransactionInformation tbody.ticket tr:first-of-type td {
  padding-top: 10px;
}
.CreateConfirmationPanel__InformationBlock h3 {
  font-weight: 600;
  font-size: 18px;
}
.CreateConfirmationPanel__InformationBlock ul {
  padding: 0;
  list-style-type: none;
}
.CreateConfirmationPanel__InformationBlock li {
  display: flex;
  align-items: center;
}
.CreateConfirmationPanel__InformationBlock label {
  display: block;
  font-weight: 600;
  min-width: 100px;
  padding-right: 1rem;
}
.CreateConfirmationPanel__NoTaxes {
  margin: 20px 0;
}
.CreateConfirmationPanel .WinkNotification .wink-create-account {
  padding-bottom: 2.4rem;
}
.CreateConfirmationPanel__Divider {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
}
.CreateConfirmationPanel__Divider--CTA {
  justify-content: flex-start !important;
  margin-top: 78px;
}
.CreateConfirmationPanel__Divider--CTA > a,
.CreateConfirmationPanel__Divider--CTA button {
  margin-right: 10px;
}
.CreateConfirmationPanel__DarkButton {
  background-color: #333333;
  color: #FFFFFF;
}
.CreateConfirmationPanel__DarkButton:hover {
  border-color: #333333;
  background-color: #333333;
  color: #FFFFFF;
}
.WinkTooltip .SocialSharing {
  margin: 0 auto 1rem;
}
.WinkTooltip .wink-create-account {
  padding-bottom: 2.4rem;
}
.WinkTooltip .wink-create-account button {
  margin: 0 auto;
  display: block;
}
@media print {
  .PublicHeader__Divider,
  .DonationPage__Header,
  .ant-tabs-bar,
  .CreateConfirmationPanel__Divider--CTA,
  .WinkTooltip,
  .PublicFooter__PlatformSection,
  .PublicFooter__WaveBorder,
  .PublicFooter__FundkySection > .PublicFooter__Divider:first-of-type,
  .PublicFooter__PoweredContainer {
    display: none !important;
  }
  .PublicHeaderRef {
    position: static !important;
  }
  .PublicLayout {
    min-height: inherit !important;
  }
  .PublicFooter__FundkySection,
  .PublicFooter__FundkySection > .PublicFooter__Divider:last-of-type,
  .PublicFooter__LegalLinks {
    flex-direction: column;
    align-items: flex-start;
  }
  .PublicFooter__Divider:last-of-type {
    display: block;
    flex: inherit;
    flex-direction: inherit !important;
    align-items: inherit !important;
    flex-wrap: inherit;
    padding-top: 0;
  }
  .PublicFooter__LegalLinks {
    display: block;
    margin: 0;
  }
  .PublicFooter__LegalLinks a {
    display: block;
    margin: 0;
  }
  .PublicFooter__LegalLinks a:link:after {
    content: " (" attr(href) ") ";
    font-size: 90%;
  }
}
