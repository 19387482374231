.CreateInformationForm {
  padding-top: 24px;
  max-width: 680px;
}
.CreateInformationForm .TitleField {
  margin: 0;
}
.CreateInformationForm__Divider {
  margin-left: -10px;
  margin-right: -10px;
}
.CreateInformationForm__Divider .CreateInformationForm__Field {
  flex: 1;
  margin: 0 10px;
}
.CreateInformationForm__Field {
  display: flex;
  margin: 0;
}
.CreateInformationForm__Field > div {
  flex: 1;
}
.CreateInformationForm__Field .ant-select-selection {
  height: 56px;
}
.CreateInformationForm__Field .ant-select-selection__rendered {
  height: 56px;
}
.CreateInformationForm__Field .ant-select-selection-selected-value {
  line-height: 56px;
}
.CreateInformationForm__Field .ant-form-item-children {
  display: block;
}
.CreateInformationForm__Field input {
  height: 56px;
}
.CreateInformationForm .FundkyCard {
  margin-top: 2.7rem;
}
.CreateInformationForm .WinkNotification__Inner_Content p > p {
  margin-bottom: 0;
}
.CreateInformationForm .WinkNotification__Inner_Content .WinkTooltip__CTA {
  margin-right: 1em;
  margin-top: 1em;
}
.CreateInformationForm__Checkboxes .ant-form-item-control.has-feedback label > span:not(.ant-checkbox) {
  padding-right: 30px;
}
.CreateInformationForm__Checkboxes span.ant-form-item-children {
  display: block;
}
.CreateInformationForm__SubmitWrapper {
  display: flex;
  justify-content: flex-start;
  padding-top: 72px;
}
.CreateInformationForm__SubmitWrapper .ant-btn {
  height: 40px;
  font-weight: 400;
}
.CreateInformationForm__SubmitWrapper .Back-btn {
  color: #fff;
  background-color: #5f697a;
  height: 40.5px;
  margin-right: 10px;
  transition: background-color 1s;
}
.CreateInformationForm__SubmitWrapper .Back-btn:hover {
  border-color: unset;
  background-color: rgba(0, 0, 0, 0.65);
  transition: background-color 1s;
}
.FundkyModal--content .AddressUnit,
.FundkyModal--content .CityProvince,
.FundkyModal--content .OrgNameTitle {
  display: block;
  margin-bottom: 0;
}
.FundkyModal--content .AddressUnit > div,
.FundkyModal--content .CityProvince > div,
.FundkyModal--content .OrgNameTitle > div {
  margin-bottom: 2.4rem;
}
.FundkyModal--content .ant-form-explain {
  position: absolute;
}
@media screen and (max-width: 600px) {
  .CreateInformationForm {
    padding-right: 1em;
  }
}
@media screen and (max-width: 360px) {
  .CreateInformationForm__Divider {
    margin-right: 0;
    margin-left: 0;
  }
  .CreateInformationForm .OrgNameTitle,
  .CreateInformationForm .FirstNameLastName,
  .CreateInformationForm .ZipCodeCountry,
  .CreateInformationForm .EmailLanguage {
    flex-direction: column;
    margin-bottom: 0;
  }
  .CreateInformationForm .OrgNameTitle > div,
  .CreateInformationForm .FirstNameLastName > div,
  .CreateInformationForm .ZipCodeCountry > div,
  .CreateInformationForm .EmailLanguage > div {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 2.4rem;
  }
}
