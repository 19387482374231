.InformationFormContainer {
  .AdditionalInformation{margin-top:3em;}
  .ProfileTypeCards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    .FundkyCard {
      margin: 1rem;
      min-width: 300px;
      width: 100%;

      &__Body p {
        line-height: normal;
        margin-bottom: 0;
      }
    }

    .empty-corporate-card {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      p {
        margin-bottom: 0;
      }
    }
  }
}