.AdminTitleBar {
  padding: 3.4rem 4rem 3rem;
  background-color: #F4F7F7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.AdminTitleBar.small-padding-bottom {
  padding: 3.4rem 4rem 1rem;
}
.AdminTitleBar--title h1 {
  margin: 0;
  margin-right: 1.5em;
  line-height: 1em;
  word-break: break-word;
}
@media only screen and (max-width: 620px) {
  .AdminTitleBar--title h1 {
    font-size: 1.6em;
  }
}
.AdminTitleBar--upperTitle {
  text-transform: uppercase;
  margin-bottom: 0;
}
.AdminTitleBar--subTitle {
  color: #939393;
  font-weight: 300;
  font-size: 16px;
}
.AdminTitleBar--name {
  margin: 3rem 0 0;
  font-weight: 500;
  font-size: 24px;
}
.AdminTitleBar--buttons {
  display: flex;
}
.AdminTitleBar--buttons > span {
  margin-right: 10px;
}
.AdminTitleBar--buttons > span:last-of-type {
  margin-right: 0;
}
.AdminTitleBar--buttons a {
  white-space: nowrap !important;
}
.AdminTitleBar--buttons svg {
  margin-left: 1rem;
}
