.ResultTable table {
  border-collapse: separate;
  border-spacing: 0 6px;
}
.ResultTable table span.ant-table-column-title {
  display: block;
  line-height: 1.1em;
}
.ResultTable .ant-table-body {
  overflow-x: auto !important;
}
.ResultTable tbody > tr {
  background-color: #FFFFFF;
}
.ResultTable .ant-table-thead > tr > th {
  line-height: 1.1em;
  word-break: normal !important;
}
.ResultTable tbody > tr > td {
  border-bottom: 0;
  line-height: 1.1em;
  transition: unset;
}
.ResultTable tbody > tr > td i,
.ResultTable tbody > tr > td svg {
  color: var(--primary-color);
  font-size: 20px;
}
.ResultTable tbody > tr > td .ant-btn[disabled] {
  text-shadow: none;
  box-shadow: none;
  opacity: 0.5;
}
.ResultTable tbody > tr > td .ant-btn[disabled] i,
.ResultTable tbody > tr > td .ant-btn[disabled] svg {
  color: #C8D5D5;
}
.ResultTable tbody > tr > td.Column--Emphasis {
  font-weight: bold;
}
.ResultTable tbody > tr > td.Column--ButtonIcon .ant-btn-circle,
.ResultTable tbody > tr > td.Column--ButtonsIcon .ant-btn-circle,
.ResultTable tbody > tr > td.Column--ButtonIcon a,
.ResultTable tbody > tr > td.Column--ButtonsIcon a {
  border: none;
  background: none;
  padding: 0;
  letter-spacing: normal;
  line-height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  overflow: visible;
  padding-top: 2px;
}
.ResultTable tbody > tr > td.Column--ButtonIcon .ant-btn-circle.ant-btn-circle:before,
.ResultTable tbody > tr > td.Column--ButtonsIcon .ant-btn-circle.ant-btn-circle:before,
.ResultTable tbody > tr > td.Column--ButtonIcon a.ant-btn-circle:before,
.ResultTable tbody > tr > td.Column--ButtonsIcon a.ant-btn-circle:before {
  background: none;
}
.ResultTable tbody > tr > td.Column--ButtonsIcon .buttons {
  display: flex;
  flex-wrap: wrap;
  margin: -0.4rem;
}
.ResultTable tbody > tr > td.Column--ButtonsIcon button {
  margin: 0.4rem;
}
.ResultTable td:first-child {
  border-radius: 2px 0 0 2px;
}
.ResultTable td:last-child {
  border-radius: 0 2px 2px 0;
}
.ResultTable .ant-table-placeholder {
  border-radius: 2px;
}
.ResultTable .ant-table-pagination {
  text-align: center;
  float: none;
}
.ResultTable .ant-table-pagination li {
  background-color: transparent;
  margin: 0 4px 8px 4px;
}
.ResultTable .ant-table-pagination li.ant-pagination-item-active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.ResultTable .ant-table-pagination li.ant-pagination-item-active a {
  color: #fff;
}
.ResultTable .ant-table-pagination li:hover:not(.ant-pagination-item-active):not(.ant-pagination-disabled) {
  border-color: var(--primary-color);
}
.ResultTable .ant-table-pagination li:hover:not(.ant-pagination-item-active):not(.ant-pagination-disabled) a {
  color: var(--primary-color);
}
.ResultTable .ant-table-pagination li.ant-pagination-prev a,
.ResultTable .ant-table-pagination li.ant-pagination-next a {
  background-color: transparent;
}
.ResultTable .ant-table-pagination li.ant-pagination-prev:hover:not(.ant-pagination-disabled) a,
.ResultTable .ant-table-pagination li.ant-pagination-next:hover:not(.ant-pagination-disabled) a,
.ResultTable .ant-table-pagination li.ant-pagination-prev:focus:not(.ant-pagination-disabled) a,
.ResultTable .ant-table-pagination li.ant-pagination-next:focus:not(.ant-pagination-disabled) a {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.TransactionsListPage td.donation-target-name,
.CampaignsTransactionsListPage td.donation-target-name,
.CampaignsTeamsRegistrationPageTable td.donation-target-name {
  width: 14em;
}
.ResultTable--ClickableRow tbody > tr {
  cursor: pointer;
}
.ResultTable--ClickableRow tbody > tr:hover {
  background-color: #333333;
  color: #FFFFFF;
}
.ResultTable--ClickableRow tbody > tr:hover td {
  background-color: inherit;
}
@media screen and (max-width: 700px) {
  .ResultTable table td,
  .ResultTable table th {
    padding: 4px;
    font-size: 0.875em;
  }
  .ResultTable table td.Column--ButtonIcon,
  .ResultTable table th.Column--ButtonIcon {
    text-align: center;
  }
  .ResultTable table td a,
  .ResultTable table th a,
  .ResultTable table td button,
  .ResultTable table th button {
    display: inline !important;
  }
  .ResultTable table td:first-child,
  .ResultTable table th:first-child {
    padding-left: 0.5em;
  }
  .ResultTable table td.referenceId,
  .ResultTable table th.referenceId {
    font-weight: normal !important;
  }
  .TransactionsListPage .donation-email,
  .CampaignsTransactionsListPage .donation-email,
  .CampaignsTeamsRegistrationPageTable .donation-email,
  .TransactionsListPage .donation-target-name,
  .CampaignsTransactionsListPage .donation-target-name,
  .CampaignsTeamsRegistrationPageTable .donation-target-name,
  .TransactionsListPage .amount,
  .CampaignsTransactionsListPage .amount,
  .CampaignsTeamsRegistrationPageTable .amount,
  .TransactionsListPage .collected,
  .CampaignsTransactionsListPage .collected,
  .CampaignsTeamsRegistrationPageTable .collected {
    display: none;
  }
  .ParticipantsListPage .memberEmail,
  .CampaignsParticipantsListPage .memberEmail {
    display: none;
  }
  .ant-pagination-total-text {
    width: 100%;
  }
  .PaginationTop {
    position: relative;
    padding-top: 4em;
    padding-bottom: 0;
  }
  .PaginationTop ul {
    border-top: 1px solid #d9d9d9;
  }
  .PaginationTop .PaginationTop__TotalInfo span + span {
    display: block;
    position: absolute;
    top: 1.3em;
  }
  .PaginationTop .ant-pagination {
    margin-right: 0;
    justify-content: space-between;
  }
  .PaginationTop .ant-pagination .ant-pagination-total-text {
    position: absolute;
    top: 0.5em;
    left: 0;
  }
  .PaginationTop .ant-pagination + .ant-select {
    position: absolute;
    top: 1.2em;
    right: 0;
    font-size: 14px;
  }
  .ant-pagination:after {
    display: none !important;
  }
}
@media only screen and (max-width: 990px) {
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table td,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table th {
    padding: 4px;
    font-size: 0.875em;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table td.Column--ButtonIcon,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table th.Column--ButtonIcon {
    text-align: center;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table td a,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table th a,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table td button,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table th button {
    display: inline !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table td:first-child,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table th:first-child {
    padding-left: 0.5em;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table td.referenceId,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ResultTable table th.referenceId {
    font-weight: normal !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .TransactionsListPage .donation-email,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CampaignsTransactionsListPage .donation-email,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CampaignsTeamsRegistrationPageTable .donation-email,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .TransactionsListPage .donation-target-name,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CampaignsTransactionsListPage .donation-target-name,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CampaignsTeamsRegistrationPageTable .donation-target-name,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .TransactionsListPage .amount,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CampaignsTransactionsListPage .amount,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CampaignsTeamsRegistrationPageTable .amount,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .TransactionsListPage .collected,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CampaignsTransactionsListPage .collected,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CampaignsTeamsRegistrationPageTable .collected {
    display: none;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ParticipantsListPage .memberEmail,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .CampaignsParticipantsListPage .memberEmail {
    display: none;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ant-pagination-total-text {
    width: 100%;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .PaginationTop {
    position: relative;
    padding-top: 4em;
    padding-bottom: 0;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .PaginationTop ul {
    border-top: 1px solid #d9d9d9;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .PaginationTop .PaginationTop__TotalInfo span + span {
    display: block;
    position: absolute;
    top: 1.3em;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .PaginationTop .ant-pagination {
    margin-right: 0;
    justify-content: space-between;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .PaginationTop .ant-pagination .ant-pagination-total-text {
    position: absolute;
    top: 0.5em;
    left: 0;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .PaginationTop .ant-pagination + .ant-select {
    position: absolute;
    top: 1.2em;
    right: 0;
    font-size: 14px;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .ant-pagination:after {
    display: none !important;
  }
}
@media only screen and (max-width: 900px) {
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 8px;
  }
}
@media only screen and (max-width: 400px) {
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding: 4px;
  }
}
