.CurrentCampaignDropdown {
  display: flex !important;
  align-items: center;
  cursor: pointer;
  color: #fff;
}
.CurrentCampaignDropdown .ant-skeleton {
  opacity: 0.1;
}
.CurrentCampaignDropdown .ant-skeleton-paragraph {
  margin-bottom: 1px;
}
.CurrentCampaignDropdown .current-campaign-avatar-wrap {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  margin-right: 1rem !important;
}
.CurrentCampaignDropdown .current-campaign-avatar {
  margin: 0 !important;
  max-width: 100%;
  width: 50px;
  min-width: 50px;
  height: auto;
}
.CurrentCampaignDropdown .current-campaign-text {
  overflow: hidden;
}
.CurrentCampaignDropdown .current-campaign-text strong {
  font-size: 1.8rem;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.CurrentCampaignDropdown .current-campaign-text .current-campaign-subtitle {
  color: #fff;
  text-transform: initial;
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 0;
}
.CurrentCampaignDropdown .fa-caret-down {
  margin-right: 0;
  margin-left: auto;
}
.CurrentCampaignDropdown__Menu {
  max-width: 375px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #DDDFDF;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 12px;
}
.CurrentCampaignDropdown__Menu a {
  color: rgba(0, 0, 0, 0.85);
  padding: 8px;
}
.CurrentCampaignDropdown__Menu .CurrentCampaignDropdown__EntityLinks {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;
}
.CurrentCampaignDropdown__Menu .CurrentCampaignDropdown__EntityLinks a {
  transition: background-color 0.2s;
}
.CurrentCampaignDropdown__Menu .CurrentCampaignDropdown__EntityLinks a:hover {
  background-color: #DDDDDD;
}
.CurrentCampaignDropdown__Menu .CurrentCampaignDropdown__AdditionalLinks {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  border-top: 3px solid #F5F7F7;
}
.CurrentCampaignDropdown__Menu .CurrentCampaignDropdown__AdditionalLinks a {
  padding-left: 12px;
  line-height: 40px;
  transition: color 0.3s;
}
.CurrentCampaignDropdown__Menu .CurrentCampaignDropdown__AdditionalLinks a:hover {
  color: #eb3052;
}
.CurrentCampaignDropdown__Menu .CurrentCampaignDropdown__AdditionalLinks svg {
  margin-right: 10px;
}
