.MoneyField {
  &__Input {
    position: relative;
    width: 200;

    .ant-input-number-input {
      text-align: right
    }

    &.before {
      .ant-input-number-input {
        padding: 0 24px 0 30px;
      }
      .ant-input-number-input-wrap:before {
        content: "$";
        position: absolute;
        line-height: 4.2rem;
        margin: 0 15px;
      }
    }
    &.after {
      .ant-input-number-input {
        padding: 0 30px 0 15px;
      }
      .ant-input-number-input-wrap:after {
        content: "$";
        position: absolute;
        line-height: 4.2rem;
        margin: 0 -15px;
      }
    }
  }

  .has-success,
  .has-error {
    .MoneyField__Input.inside {
      &.before {
        .ant-input-number-input {
          padding: 0 30px 0 30px;
        }
      }
      &.after {
        .ant-input-number-input {
          padding: 0 40px 0 15px;
        }
        .ant-input-number-input-wrap:after {
          margin: 0 -35px;
        }
      }
    }
  }
}
