.FundkyCard {
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1.8rem;
  position: relative;
  max-width: 400px;
  width: 100%;
}
.FundkyCard.no-overflow > .FundkyCard__Body {
  max-width: 80%;
  overflow: hidden;
}
.FundkyCard.hasIcon {
  padding-left: 6.5rem;
}
.FundkyCard.hasAction .FundkyCard__Action {
  padding-left: 1.8rem;
}
.FundkyCard.hasAction .FundkyCard__Action > *:not(:last-of-type) {
  margin-right: 0.6rem;
}
.FundkyCard.hasAction .FundkyCard__Action svg {
  font-size: 1.6rem;
}
.FundkyCard.hasCardAction {
  cursor: pointer;
}
.FundkyCard__Icon {
  position: absolute;
}
.FundkyCard__Icon {
  color: var(--secondary-color);
  font-size: 3rem;
  top: 0.7rem;
  left: 1.8rem;
  line-height: normal;
}
.FundkyCard__Body {
  line-height: normal;
}
.FundkyCard__Body p.title {
  font-weight: bold;
}
.FundkyCard__Body p:last-of-type {
  margin-bottom: 0;
}
