.CampaignSettingsAdvancedPageForm {
  padding: 1.5rem;
  background: #fff;
}
.CampaignSettingsAdvancedPageForm .ant-form-item {
  display: flex;
}
.CampaignSettingsAdvancedPageForm .ant-form-item .ant-form-item-label {
  flex: 1;
  text-align: left;
}
.CampaignSettingsAdvancedPageForm .ant-form-item .ant-form-item-label label {
  color: rgba(0, 0, 0, 0.85);
}
.CampaignSettingsAdvancedPageForm .ant-form-item .ant-form-item-control-wrapper {
  flex: 3;
}
.CampaignSettingsAdvancedPageForm .participants-limit-suffix {
  margin-left: 1rem;
}
