.PlatformSwitcher {
  flex: 1;
  min-width: 0;
  &__Label {
    color: rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
  }

  &__Platform {
    color: #000 !important;
    font-size: 2rem;
  }

  .HelpText {
    display: block;
    font-size: 14px;
  }

  &__Dropdown {
    display: inline-flex;
    
    h1 {
      font-family: "Assistant";
      font-size: 20px;
      font-weight: 300;
      line-height: 1em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 0.1em;
    }
    
    svg {
      font-size: 16px;
      margin-left: 10px;
      color: #EB3052;
      line-height: 1em;
    }
  }
  @media only screen and (max-width: 585px) {
    .PlatformSwitcher__Dropdown h1{
      font-size:16px;
    }
    .HelpText{
      font-size:12px;
    }
  }
}


