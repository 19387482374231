.PublicHeaderRef {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1051;
}
.PublicHeader {
  border-bottom: 2px solid #F5F6F7;
  padding: 8px 0;
  height: auto;
  line-height: normal;
  position: relative;
  height: 90px;
  transition: all 0.2s ease-in-out;
}
.PublicHeader .FundkyWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PublicHeader__Logo {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  max-width: 320px;
}
.PublicHeader__Logo img {
  max-width: 100%;
}
.PublicHeader__UserMenu,
.PublicHeader__PlatformMenu {
  text-align: right;
}
.PublicHeader__UserMenu {
  font-size: 14px;
  border-color: transparent;
  margin-top: 4px;
  line-height: 24px;
}
.PublicHeader__UserMenu .ant-menu-item,
.PublicHeader__UserMenu .ant-menu-submenu {
  margin: 0 0 0 24px;
  padding: 0;
  border-bottom: none !important;
  color: rgba(0, 0, 0, 0.85);
}
.PublicHeader__UserMenu .ant-menu-item:hover,
.PublicHeader__UserMenu .ant-menu-submenu:hover {
  border-bottom: none !important;
}
.PublicHeader__UserMenu .ant-menu-item > a,
.PublicHeader__UserMenu .ant-menu-submenu > a {
  color: rgba(0, 0, 0, 0.85);
}
.PublicHeader__UserMenu .ant-menu-submenu-title {
  padding: 0;
}
.PublicHeader__UserMenu .ant-menu-submenu-title svg {
  margin-left: 2px;
  margin-right: 0;
  color: var(--primary-color);
}
.PublicHeader__PlatformMenu {
  float: left;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
}
.PublicHeader__PlatformMenu .ant-menu-item,
.PublicHeader__PlatformMenu .ant-menu-submenu {
  border-bottom: none !important;
}
.PublicHeader__PlatformMenu .ant-menu-item:hover,
.PublicHeader__PlatformMenu .ant-menu-submenu:hover {
  border-bottom: none !important;
}
.PublicHeader__PlatformMenu .ant-menu-item > a,
.PublicHeader__PlatformMenu .ant-menu-submenu > a {
  color: rgba(0, 0, 0, 0.85);
}
.PublicHeader__PlatformMenuIcon {
  margin-right: 7px;
}
.PublicHeader__PlatformSubMenu.ant-menu-submenu-popup,
.PublicHeader__UserSubMenu.ant-menu-submenu-popup {
  z-index: 1052;
}
.PublicHeader__PlatformSubMenu .ant-menu-sub li,
.PublicHeader__UserSubMenu .ant-menu-sub li {
  font-weight: 600;
  margin: 0 !important;
  line-height: 50px;
  height: 50px;
}
.PublicHeader__PlatformSubMenu .ant-menu-sub li:hover,
.PublicHeader__UserSubMenu .ant-menu-sub li:hover {
  background-color: #ECEDED;
  color: rgba(0, 0, 0, 0.85);
}
.PublicHeader__PlatformSubMenu .ant-menu-sub li:hover a,
.PublicHeader__UserSubMenu .ant-menu-sub li:hover a {
  color: rgba(0, 0, 0, 0.85);
}
.PublicHeader__PlatformSubMenu .ant-menu-sub li:first-of-type,
.PublicHeader__UserSubMenu .ant-menu-sub li:first-of-type {
  border-radius: 4px 4px 0 0;
}
.PublicHeader__PlatformSubMenu .ant-menu-sub li:last-of-type,
.PublicHeader__UserSubMenu .ant-menu-sub li:last-of-type {
  border-radius: 0 0 4px 4px;
}
.PublicHeader__PlatformSubMenu .ant-menu-sub li:only-of-type,
.PublicHeader__UserSubMenu .ant-menu-sub li:only-of-type {
  border-radius: 4px;
}
.PublicHeader__PlatformSubMenuIcon {
  color: var(--secondary-color);
  margin-right: 16px;
}
.PublicHeader .PlatformSocialMedia {
  float: right;
}
.PublicHeader .PlatformSocialMedia ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
@media screen and (min-width: 1081px) {
  .PublicHeader .PlatformSocialMedia ul li {
    line-height: 47px;
  }
}
.PublicHeader__ShareIcon,
.PublicHeader .PlatformSocialMedia li {
  padding: 0 0 0 10px;
}
.PublicHeader__ShareIcon:hover,
.PublicHeader .PlatformSocialMedia li:hover {
  color: var(--primary-color) !important;
}
.PublicHeader__ShareIcon:hover .instagram-color--square path,
.PublicHeader .PlatformSocialMedia li:hover .instagram-color--square path {
  fill: var(--primary-color) !important;
}
.PublicHeader__ShareIcon:hover a,
.PublicHeader .PlatformSocialMedia li:hover a,
.PublicHeader__ShareIcon:hover svg,
.PublicHeader .PlatformSocialMedia li:hover svg {
  color: var(--primary-color) !important;
}
@media screen and (min-width: 1081px) {
  .PublicHeader__UserMenuMobile,
  .PublicHeader__PlatformMenuMobile,
  .PublicHeader__ToggleMenu,
  .PublicHeader .login-icon {
    display: none !important;
  }
}
.PublicHeader .hide-desktop {
  display: none;
}
.PublicHeader__UserMenuMobile {
  border-color: transparent;
}
.PublicHeader__ToggleMenu {
  background-color: inherit;
  border: none;
  font-size: 2.4rem;
  padding: 0;
  cursor: pointer;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.PublicHeader__PlatformMenuMobile {
  background: #fff;
  padding: 8px 10vw;
  position: absolute;
  top: calc(100% + 1px);
  right: -110vw;
  width: 100vw;
  transition: right 0.3s cubic-bezier(0, 0.52, 0, 1);
  z-index: 10;
}
.PublicHeader__PlatformMenuMobile .PublicHeader__UserSubMenu .fa-caret-down {
  display: none;
}
.PublicHeader__PlatformMenuMobile .PublicHeader__ShareIcon {
  padding-left: 24px;
  width: auto;
  float: left;
  line-height: normal;
  height: auto;
  margin-top: 0;
  padding-right: 0;
}
.PublicHeader__PlatformMenuMobile .PlatformSocialMedia {
  float: left;
}
.PublicHeader__PlatformMenuMobile.isVisible {
  right: 0;
}
@media screen and (max-width: 1080px) {
  .PublicHeader {
    height: 76px;
  }
  .PublicHeader__UserMenu,
  .PublicHeader__PlatformMenu {
    display: none;
  }
  .PublicHeader > .FundkyWrap > .PublicHeader__Divider > .PlatformSocialMedia {
    display: none !important;
  }
  .PublicHeader__Divider {
    display: flex;
    align-items: center;
  }
  .PublicHeader__ToggleMenu {
    position: relative;
  }
  .PublicHeader__ToggleMenu .fa-bars,
  .PublicHeader__ToggleMenu .fa-times-circle {
    transition: 0.2s opacity ease-in-out;
  }
  .PublicHeader__ToggleMenu .fa-bars {
    opacity: 1;
  }
  .PublicHeader__ToggleMenu .fa-times-circle {
    opacity: 0;
    position: absolute;
  }
  .PublicHeader__ToggleMenu.isVisible .fa-bars {
    opacity: 0;
  }
  .PublicHeader__ToggleMenu.isVisible .fa-times-circle {
    opacity: 1;
  }
  .PublicHeader__PlatformMenuMobile {
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0 4px 5px 0.2px rgba(52, 50, 47, 0.29) !important;
  }
  .PublicHeader__PlatformMenuMobile .ant-menu-item:not(.PublicHeader__ShareIcon),
  .PublicHeader__PlatformMenuMobile .ant-menu-submenu-title {
    font-size: 1.8rem;
    margin: 0 !important;
    border-bottom: 2px solid #F5F6F7;
    height: 45px !important;
    line-height: 45px !important;
    padding: 0 !important;
  }
  .PublicHeader__PlatformMenuMobile .ant-menu-sub {
    border-bottom: 2px solid #F5F6F7;
    padding-left: 4rem;
  }
  .PublicHeader__PlatformMenuMobile .ant-menu-submenu .ant-menu-item {
    border-bottom: none;
  }
  .PublicHeader__PlatformMenuMobile .PublicHeader__ShareIcon,
  .PublicHeader__PlatformMenuMobile .PlatformSocialMedia {
    margin: 1.5rem 0;
  }
  .PublicHeader__PlatformMenuMobile .PublicHeader__ShareIcon svg,
  .PublicHeader__PlatformMenuMobile .PlatformSocialMedia svg {
    font-size: 3.5rem;
  }
  .PublicHeader__PlatformMenuMobile .PublicHeader__ShareIcon {
    padding-left: 0 !important;
  }
}
.ant-menu-sub .ant-menu-item > a:hover,
.ant-menu-sub .ant-menu-item:hover {
  color: var(--secondary-color) !important;
}
