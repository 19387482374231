.CampaignPublicPage {
  padding: 24px 0;
  flex-direction: row;
}
.CampaignPublicPage__Menu {
  flex: 1 0 320px;
  max-width: 392px;
  padding-right: 24px;
}
.CampaignPublicPage__Menu .CampaignPublicPage__Menu {
  margin: 0 -1rem;
}
.CampaignPublicPage__Menu .CampaignPublicPage__TitleThermo,
.CampaignPublicPage__Menu .CampaignPublicPage__MenuNavSocialSharing {
  margin: 0 1rem;
}
.CampaignPublicPage__Menu .CampaignPublicPage__TitleThermo {
  margin-bottom: 2.4rem;
}
.CampaignPublicPage__Menu .CampaignPublicPage__TitleThermo > div {
  top: auto !important;
}
.CampaignPublicPage__Menu .CampaignPublicPage__Thermometer {
  margin-top: 1.4rem;
}
.CampaignPublicPage__Menu .CampaignPublicPage__MenuNav {
  display: flex;
  flex-direction: column;
}
.CampaignPublicPage__Menu .CampaignPublicPage__MenuLink {
  display: flex;
  align-items: center;
  padding-right: 24px;
  line-height: normal;
  height: 60px;
  border-radius: 4px;
  border-left: 5px solid transparent;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 5px;
}
.CampaignPublicPage__Menu .CampaignPublicPage__MenuLink:hover {
  background-color: #FFFFFF;
}
.CampaignPublicPage__Menu .CampaignPublicPage__MenuLink[disabled] {
  opacity: 0.5;
}
.CampaignPublicPage__Menu .CampaignPublicPage__MenuLinkIcon {
  font-size: 2.8rem;
  width: 38%;
  max-width: 84px;
  text-align: center;
}
.CampaignPublicPage__Menu .CampaignPublicPage__MenuLink--Active {
  background-color: #FFFFFF;
  border-left: 5px solid var(--link-color);
}
.CampaignPublicPage .CampaignPublicPage__Content {
  flex: 1 1 auto;
  min-width: 0;
}
.CampaignPublicPage .CampaignPublicPage__Content .CampaignPublicPage__BannerHolder {
  position: relative;
}
.CampaignPublicPage .CampaignPublicPage__Content .CampaignPublicPage__BannerHolder:hover .Btn__EditBanner {
  opacity: 1;
  pointer-events: auto;
}
.CampaignPublicPage .CampaignPublicPage__Content .CampaignPublicPage__BannerHolder .CampaignPublicPage__Banner {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 377px;
}
.CampaignPublicPage .CampaignPublicPage__Content .CampaignPublicPage__BannerHolder .Btn__EditBanner {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 980px) {
  .CampaignPublicPage .CampaignPublicPage__Content .PublicTeamPanel .PublicPanel__Footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
