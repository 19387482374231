@import (reference) "../../assets/stylesheets/main";

.PublicParticipantPanel {
  background-color: #FFFFFF;
  border: 1px solid @input-border-color;
  border-radius: @border-radius-base;
  padding: @padding-sm @padding-lg;
  margin-top: @padding-lg;

  > * {
    margin-bottom: @padding-sm;
  }

  &__Divider {
    border-bottom: 3px solid @layout-body-background;
    padding-bottom: @padding-sm;
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: @font-size-lg;
      line-height: @font-size-lg;
      font-weight: 600;
      margin-bottom: 0;
    }

    .edit-profile {
      background: none;
      color: var(--primary-color);
      transition: color .2s ease-in-out;

      &:hover {
        color: var(--primary-hover-color)
      }
    }

    .ant-input-affix-wrapper {
      width: auto;
      line-height: 1;
    }
    .ant-input {
      height: @font-size-lg;
      line-height: @font-size-lg;
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
      text-align: right;

      &:focus {
        box-shadow: none;
      }
    }
    .ant-input-suffix {
      cursor: pointer;
      right: 0;
    }
  }

  &__Footer {
    border-top: 3px solid @layout-body-background;
    padding-top: @padding-sm;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__CTA {
    margin: 0 -5px;
    > * {
      margin: 5px;
    }

    &--contact {
      background-color: #333;
      color: #fff;

      &:hover {
        background-color: #000;
        color: #fff;
        border-color: #000;
      }
    }
  }

}
