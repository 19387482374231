.DesignSettingsForm {
  .swatches {
    > .ant-form-item-control-wrapper {
      flex-wrap: wrap;

      > label {
        margin-bottom: .4rem;
        width: 100%;
      }
    }

    .ColorPickerField + .ColorPickerField {
      margin-left: 1rem;
    }
  }
}