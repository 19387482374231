.CustomRefinementList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0 -.2rem;

  .CustomRefinementList__item {
    border-radius: 20px;
    padding: 0 0.6rem;
    margin: .2rem;

    a {
      display: inline-block;
    }

    &.selected {
      background-color: var(--primary-color);

      a {
        color: #fff;
      }
    }
  }
}
