@import (reference) "../../assets/stylesheets/main";

.TeamPublicPage {
  .TeamPublicPage__Container {
    padding-bottom: @padding-lg;
    display: flex;
    position: relative;
    width: 100%;
  }

  .TeamPublicPage__Menu {
    flex: 1 0 320px;
    max-width: 392px;
    padding-right: @padding-lg;

    .TeamPublicPage__Thermometer {
      margin: @padding-lg;
    }

    .PublicPageClosed__Thermo {
      margin-bottom: 2.4rem;
    }

    .TeamPublicPage__MenuLink {
      &:extend(.FundkyWrap);
      line-height: 60px;
      border-radius: @border-radius-base;
      border-left: 5px solid transparent;
      color: @heading-color;
      margin-bottom: 5px;

      svg {
        font-size: 28px;
        vertical-align: middle;
        margin-right: @padding-lg;
      }

      &:hover {
        background-color: #FFFFFF;
      }
    }

    .TeamPublicPage__MenuLink--Active {
      background-color: #FFFFFF;
      border-left: 5px solid var(--link-color);
    }
  }

  .TeamPublicPage__Content {
    flex: 1 1 auto;
    min-width: 0;

    >div:first-of-type {
      margin-top: 0;
    }
  }

  &__Breadcrumbs {
    font-size: 12px;
    color: #AEADAE;

    ul {
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      margin-bottom: 12px;
      padding-left: 0;
    }

    li:last-of-type {
      color: #424749;
    }

    a {
      color: #AEADAE;
      cursor: pointer;

      &:hover {
        color: var(--primary-color);
      }
    }

    svg {
      font-size: 10px;
      color: var(--secondary-color);
      margin: 0 6px;
    }
  }

  @media screen and (max-width: 980px) {
    .PublicPanel__Footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
