@import (reference) "../assets/stylesheets/main";

.HistoryTable {
  background-color: #ECEDED;

  p {
    margin: 0;
  }

  .ant-table {
    background-color: #fff;
    overflow: hidden;
  }

  .ant-table-body {
    margin: 0 !important;
  }

  tbody>tr {
    background-color: @admin-cell-bg;
    cursor: pointer;
    border-bottom: 0;
    border-top: @border-width-base @border-style-base @border-color-split;

    &:first-of-type {
      border: none;
    }
  }

  tbody>tr.ant-table-expanded-row {
    cursor: inherit;
  }

  tbody>tr.ant-table-expanded-row>td {
    border-top: 0;
    background-color: @hq-admin-background-bar;
  }

  .diff-boxes {
    display: flex;
    width: 100%;
    padding-right: 50px;

    div {
      flex: 1;
      margin: 5px;
      background-color: #fff;
    }
  }

  .json-markup {
    white-space: pre-wrap !important;
  }

  .Column.addedOn {
    min-width: 100px;
    word-break: normal !important;
  }
}

@mobile-ruleset: {
  .HistoryTable {
    .ant-table-tbody>tr>td {
      border: 0 !important;
    }

    &,
    .ant-table-tbody {
      display: block;
      max-width: 100%;

      div,
      tr,
      td {
        display: block;
      }

      tr {
        position: relative;
      }
    }

    .ant-table-row-expand-icon-cell {
      position: absolute;
      right: 0;
      border-top: 0;
      top: 0em;
    }

    .Column.event {
      border-top: 0;
      font-size: 14px;
      line-height: 1.3em;
      padding-top: 0 !important;
      padding-bottom: 12px !important;
      margin-bottom: 6px;

      span {
        word-break: break-word;
      }
    }

    .json-markup {
      word-break: break-word;
    }

    .diff-boxes {
      padding-right: 0;

      h5 {
        font-size: 15px;
        font-weight: bold;
        padding: 0.5em;
      }

      div+div {
        margin-top: 3em;
      }
    }

  }
}

@media only screen and (max-width: 600px) {
  // When smaller then 600
  @mobile-ruleset();
}

@media only screen and (max-width: 890px) {

  //When the content area is smaller than 600 (menu open)
  aside:not(.ant-layout-sider-collapsed)+i+section.ant-layout {
    @mobile-ruleset();
  }
}