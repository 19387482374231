@import (reference) "../../assets/stylesheets/main";
@import "_ConnectionForm";

.ConfirmationPanel {
  p {
    font-size: 20px;
    text-align: center;
    margin-left: 60px;
    margin-right: 60px;
  }

  .closeWindow {
    color: #fff;
    background-color: rgba(0,0,0,.85);
    text-align: center;
    height: 50px;
    line-height: 50px;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    &:hover {
      background-color: #000;
    }
  }
}

.ConfirmationPanel__TitleWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: @padding-lg;

  i {
    font-size: 48px;
    color: @success-color;
    margin-right: 14px;
  }

  h1 {
    display: inline;
    margin-bottom: 0;
  }
}
