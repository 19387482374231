.Error404Page {
  &__Content {
    padding: 23px 13% 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-image: url('https://cdn.fundky.com/general/404.png');
    background-color: #FFF;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
  }
  &__En {
    padding: 10px 75px 10px 0;
    border-right: 1px solid #ACACAC;
    text-align: right;
  }
  &__Fr {
    padding: 10px 0 10px 75px;
    border-left: 1px solid #ACACAC;
    text-align: left;
  }
  &__Title {
    color: #EC2D51;
    font-family: 'Galada', serif;
    font-size: 46px;
    margin: 0 0 12px 0;
    line-height: 1;
    font-weight: 400;
  }
  &__Subtitle {
    color: #333;
    font-family: 'Domine', serif;
    font-size: 26px;
    margin: 0 0 19px 0;
    line-height: 1;
  }
  &__Text {
    color: #333;
    font-family: 'Assistant', sans-serif;
    font-size: 18px;
    margin: 0;
    line-height: 1;
  }
}

/* MEDIAQUERIES >>> */
@media screen and (max-width: 768px) {
  .Error404Page {
    &__En {
      padding-right: 40px;
    }
    &__Fr {
      padding-left: 40px;
    }
    &__Subtitle {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 414px) {
  .Error404Page {
    &__Content {
      padding: 10px 13% 40%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &__En {
      padding: 10px 0 15px;
      border-right: none;
      border-bottom: 1px solid #ACACAC;
      text-align: left;
    }
    &__Fr {
      padding: 15px 0;
      border-left: none;
      border-top: 1px solid #ACACAC;
      text-align: right;
    }
    &__Title {
      font-size: 30px;
      margin: 0 0 10px 0;
    }
    &__Subtitle {
      font-size: 16px;
      margin: 0 0 15px 0;
    }
    &__Text {
      font-size: 14px;
    }
  }
}
/* <<< MEDIAQUERIES */
