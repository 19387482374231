.QuickLinks {
  margin-top: 2rem;
}
.QuickLinks .QuickLinks__title,
.QuickLinks .QuickLinks__GroupItem {
  display: block;
  color: #FFFFFF;
  padding-left: 16px;
}
.QuickLinks .QuickLinks__title {
  margin-top: 48px;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.QuickLinks .QuickLinks__GroupItem {
  display: flex;
  font-size: 1.6rem;
}
.QuickLinks i,
.QuickLinks svg {
  font-size: 2rem;
  margin-right: 1rem;
}
