.PublicCaptainQuote {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.PublicCaptainQuote__Message {
  background-color: #ECEDED;
  border-radius: 12px;
  border: 0;
  padding: 0 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PublicCaptainQuote__Message:before,
.PublicCaptainQuote__Message:after {
  color: #888888;
  font-size: 100px;
  font-family: Arial, serif;
  font-style: normal;
  line-height: normal;
}
.PublicCaptainQuote__Message:before {
  content: '\201C';
  align-self: flex-start;
  margin-top: -10px;
}
.PublicCaptainQuote__Message:after {
  content: '\201E';
  align-self: flex-end;
}
.PublicCaptainQuote p {
  line-height: 1.2em;
  margin: 0;
  font-family: 'Domine', serif;
  text-align: center;
}
.PublicCaptainQuote__Info {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PublicCaptainQuote__Info .no-style {
  color: inherit;
  cursor: pointer;
}
.PublicCaptainQuote__Arrow {
  position: absolute;
  left: 18px;
  top: 0;
  height: 33%;
  fill: #ECEDED;
}
.PublicCaptainQuote .AvatarThumbnail {
  padding: 8px 0;
}
.PublicCaptainQuote .AvatarThumbnail__PrimaryContent {
  font-size: 0.8em;
}
.PublicCaptainQuote .AvatarThumbnail__PrimaryContent svg {
  color: var(--primary-color);
}
.PublicCaptainQuote .AvatarThumbnail__SecondaryContent {
  font-size: 14px;
  line-height: 15px;
  font-weight: bold;
}
.PublicCaptainQuote .AvatarThumbnail {
  justify-content: center;
}
.PublicCaptainQuote__Popover .ant-popover-inner,
.PublicCaptainQuote__Popover .ant-popover-arrow {
  background-color: #F5F7F7;
}
.PublicCaptainQuote__Popover .ant-popover-inner-content {
  padding: 2rem;
}
.PublicCaptainQuote__Popover__Content {
  display: flex;
  align-items: center;
}
.PublicCaptainQuote__Popover__Content__Avatar {
  border-radius: 100%;
  margin-right: 2rem;
}
.PublicCaptainQuote__Popover__Content__Avatar,
.PublicCaptainQuote__Popover__Content__Avatar img {
  height: 100px;
  width: 100px;
  flex: 0 0 100px;
}
.PublicCaptainQuote__Popover__Content__Name {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.6rem;
}
.PublicCaptainQuote__Popover__Content__Name > span {
  margin-right: 0.4rem;
}
.PublicCaptainQuote__Popover__Content__Name svg {
  color: var(--primary-color);
  font-size: 1.2rem;
}
.PublicCaptainQuote__Popover__Content__Actions {
  margin: 0 -0.5rem;
}
.PublicCaptainQuote__Popover__Content__Actions button {
  margin: 0 0.5rem;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle {
  color: rgba(0, 0, 0, 0.65);
  font-family: Domine,serif;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle.smallQuote {
  font-size: 20px;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle.mediumQuote {
  font-size: 15px;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle.largeQuote {
  font-size: 10px;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle p {
  font-size: 1.6em;
  margin: 0.5em 0;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle strong {
  font-weight: 700;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle em {
  font-style: italic;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle u {
  text-decoration: underline;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle sub {
  font-size: 0.5em;
}
.PublicCaptainQuote .PublicCaptainQuote__Message.wysiwygStyle sup {
  font-size: 0.5em;
}
