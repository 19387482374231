@import (reference) "../assets/stylesheets/main";

.PublicTitleDropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  h1 {
    line-height: normal;
    margin-bottom: 0;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  i {
    font-size: 16px;
    vertical-align: middle;
  }
}

.PublicTitleDropdown__Menu {
  display:flex;
  max-width:375px;
  flex-direction: column;
  background-color: @component-background;
  border: 1px solid @input-border-color;
  border-radius: @border-radius-base;
  box-shadow: @box-shadow-base;
  padding: @padding-sm;

  .HelpText {
    margin: @padding-sm;
  }

  a {
    color: @heading-color;
    padding: @padding-xs;
  }
}

.PublicTitleDropdown__EntityLinks {
  display:flex;
  flex-direction: column;
  max-height: 300px;
  overflow: auto;

  .AvatarThumbnail__Content {
    font-family: @font-family-title;
    font-weight: bold;
  }

  a:hover {
    background-color: #DDDDDD;
  }

  img {
    border-radius: 100%;
    margin: 0 @padding-sm;
    height: 100%;
  }
}

.PublicTitleDropdown__AdditionalLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: @padding-xs;
  border-top: 3px solid @layout-body-background;

  a {
    padding-left: @padding-sm;
    line-height: 40px;
    transition: color .3s;
  }

  a:hover {
    color: var(--primary-color);
  }

  svg {
    color: var(--link-color);
    margin-right: 10px;
  }
}
