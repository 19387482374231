@import (reference) "../../../assets/stylesheets/main";

.fake-field span.ant-form-item-children {
    margin-top:0.5em;
}

@mobile-ruleset: {
    .fake-field {
        .ant-form-item-label{
            margin-bottom:0 !important;
            padding-bottom: 0 !important;
        }
    }
}

// When smaller then 600
@media only screen and (max-width: 600px) {
    @mobile-ruleset();
}

//When the content area is smaller than 600 (menu open)
@media only screen and (max-width: 890px) {
    aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout{
        @mobile-ruleset();
    }
}