.PagesHomepageSliderPanel {
  .slides-wall {
    counter-reset: section;

    .ant-upload-list-picture-card-container,
    .ant-upload-list-item {
      height: 147px;
      width: 210px;
    }

    .ant-upload-list-item {
      padding: 0;
    }

    .ant-upload-list-item-info {
      border-radius: 4px;
    }

    .ant-upload-list-item-info>span {
      height: 100%;
      display: flex;
      background-color: #fff;

      .ant-upload-list-item-thumbnail {
        height: auto;
        max-height: 100%;
        margin: auto 0;
      }
    }

    .ant-upload-list-picture-card .ant-upload-list-item-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: rgba(250, 250, 250, .85);
      left: inherit;
      top: inherit;
      transform: inherit;
      bottom: 0;
      width: 100%;

      &::before {
        margin-right: auto;
        display: inline-block;
        margin-left: 0.4rem;
        counter-increment: section;
        content: "# "counter(section);
      }

      i {
        color: #EA3153;

        &:hover {
          color: #EA3153;
        }
      }
    }

    .ant-upload.ant-upload-select-picture-card {
      border: 0;
    }

    .ant-upload.ant-upload-select-picture-card>.ant-upload {
      padding: 0;
    }
  }

  .add-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    color: #fff;
    cursor: pointer;
    width: 210px;
    height: 147px;

    h3 {
      font-weight: bold;
      color: #545658;
    }

    span {
      color: #545658;
    }

    svg {
      font-size: 3rem;
      color: var(--secondary-color);
    }
  }

  .form-add-slide {
    .ant-upload-list-picture-card .ant-upload-list-item {
      width: auto;
      height: auto;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
      width: 100%;
      height: auto;
    }
  }

  .buttons {
    margin-top: 1rem;
    clear: both;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .SettingsForm__Submit {
      margin: 0;
    }

    .btn-cancel {
      height: 32px
    }
  }
}

.ant-modal-content {
  .form-add-slide {
    .ant-upload-list-picture-card .ant-upload-list-item {
      width: auto;
      height: auto;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
      width: 100%;
      height: auto;
    }

    svg.fa-info-circle {
      color: #AC8EC3;
    }
  }
}
