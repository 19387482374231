@import (reference) "../../assets/stylesheets/main";

.FundkyCTA {
  display: flex;
  flex: 1;
  max-width: 300px;
  flex-direction: column;
  align-items: center;
  background-color: @input-bg;
  border-radius: @border-radius-base;
  margin: .6rem;
  padding: @padding-lg;
  box-sizing: border-box;

  &__Title {
    margin: @padding-sm 0;
    text-align: center;
  }

  &__Description {
    text-align: center;
    margin-bottom: @padding-lg;
  }
}