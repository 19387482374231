@import (reference) "../../../assets/stylesheets/main";

//Need to do this for firefox
.PublicLayout .ant-form-item-children .CardCvvTooltip{
    position:absolute;
    left: 100%;
    right:auto !important;
    & + span.ant-form-item-children-icon{
        left: 100%;
        right:auto !important;
        margin-left: 1.1em;
    }
}