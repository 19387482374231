.TransactionsPageForm,
.AddTransactionsPageForm {
  &__Field {

    textarea {
      height: 6rem !important;
    }
  }

  h3 {
    margin-top: 40px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .ant-form-item {
    margin-bottom: 9px;
  }

  .transaction-recipient-edit-icon {
    margin-left: 10px;
    svg {
      font-size: 20px;
    }
  }

  .request-update-button {
    margin-top: 1rem;
    margin-right: auto;
  }

  a#recipient,
  span#recipient {
    margin-top: 1rem;
    display: inline-block;
  }

  .ant-calendar-picker-input {
    width: 200px;
  }

  .buttons-wrapper {
    display: flex;

  }

  .CancelAndSaveButtons__wrap {
    margin-left: auto;
  }

  @media screen and (max-width: 840px) {
    .buttons-wrapper {
      flex-direction: column;
    }
  }
}
