.ContactPage {
  padding: 80px 0;

  &__Content {
    display: flex;
    flex-wrap: wrap;
    margin: -20px -30px;
  }

  &__Map,
  &__Infos {
    margin: 20px 30px;
  }

  &__Map {
    flex: 1 1 52%;
    background-color: white;
    height: 345px;
  }

  &__Infos {
    flex: 1 0 33%;
    font-size: 18px;
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;

    h4 {
      font-weight: 600;
      margin-bottom: 4px;
    }

    p {
      line-height: 20px;
    }

    .postal-code {
      text-transform: uppercase;
    }

    ul {
      padding-left: 0;
      list-style-type: none;
    }
  }

  &__Phones,
  &__Webs {
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom:1em;
    }

    svg {
      margin-right: 10px;
      font-size: 24px;
    }

    a {
      font-weight: 600;
      line-height: normal;
    }
  }

  .PlatformSocialMedia ul {
    display: flex;
    margin: 0;

    li {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      display: flex;
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }

    svg {
      color: #fff;
      margin: auto;
    }
  }
}
