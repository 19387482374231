@import (reference) "../../assets/stylesheets/main";

@header-height-desktop: 90px;
@header-height-mobile: 76px;

.PublicHeaderRef {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1051;
}

.PublicHeader {
  border-bottom: 2px solid #F5F6F7;
  padding: @padding-xs 0;
  height: auto;
  line-height: normal;
  position: relative;
  height: @header-height-desktop;
  transition: all .2s ease-in-out;

  .FundkyWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__Logo {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    max-width: 320px;

    img {
      max-width: 100%;
    }
  }

  &__UserMenu,
  &__PlatformMenu {
    text-align: right;
  }

  &__UserMenu {
    font-size: @font-size-sm;
    border-color: transparent;
    margin-top: 4px;
    line-height: @padding-lg;

    .ant-menu-item,
    .ant-menu-submenu {
      margin: 0 0 0 @padding-lg;
      padding: 0;
      border-bottom: none !important;
      color: rgba(0, 0, 0, 0.85);

      &:hover {
        border-bottom: none !important;
      }

      >a {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .ant-menu-submenu-title {
      padding: 0;

      svg {
        margin-left: 2px;
        margin-right: 0;
        color: var(--primary-color);
      }
    }
  }

  &__PlatformMenu {
    float: left;
    font-weight: bold;
    color: @heading-color;
    border-color: transparent;

    .ant-menu-item,
    .ant-menu-submenu {
      border-bottom: none !important;

      &:hover {
        border-bottom: none !important;
      }

      >a {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  &__PlatformMenuIcon {
    margin-right: 7px;
  }

  &__PlatformSubMenu,
  &__UserSubMenu {

    &.ant-menu-submenu-popup {
      z-index: 1052;
    }

    .ant-menu-sub li {
      font-weight: 600;
      margin: 0 !important;
      line-height: 50px;
      height: 50px;

      &:hover {
        background-color: #ECEDED;
        color: rgba(0, 0, 0, 0.85);

        a {
          color: rgba(0, 0, 0, 0.85);
        }
      }

      &:first-of-type {
        border-radius: @border-radius-base @border-radius-base 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 @border-radius-base @border-radius-base;
      }

      &:only-of-type {
        border-radius: @border-radius-base;
      }
    }
  }

  &__PlatformSubMenuIcon {
    color: var(--secondary-color);
    margin-right: 16px;
  }

  .PlatformSocialMedia {
    float: right;

    ul {
      display: flex;
      align-items: center;
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;

      @media screen and (min-width: 1081px) {
        li {
          line-height: 47px;
        }
      }
    }
  }

  &__ShareIcon,
  .PlatformSocialMedia li {
    padding: 0 0 0 10px;

    &:hover {
      color: var(--primary-color) !important;

      .instagram-color--square path {
        fill: var(--primary-color) !important;
      }

      a,
      svg {
        color: var(--primary-color) !important;
      }
    }
  }

  @media screen and (min-width: 1081px) {

    &__UserMenuMobile,
    &__PlatformMenuMobile,
    &__ToggleMenu,
    .login-icon {
      display: none !important;
    }
  }

  .hide-desktop {
    display: none;
  }

  // MOBILE
  &__UserMenuMobile {
    border-color: transparent;
  }

  &__ToggleMenu {
    background-color: inherit;
    border: none;
    font-size: 2.4rem;
    padding: 0;
    cursor: pointer;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }

  &__PlatformMenuMobile {
    background: #fff;
    padding: @padding-xs 10vw;
    position: absolute;
    top: calc(~'100% + 1px');
    right: -110vw;
    width: 100vw;
    transition: right .3s cubic-bezier(0, .52, 0, 1);
    z-index: 10;

    .PublicHeader__UserSubMenu .fa-caret-down {
      display: none;
    }

    .PublicHeader__ShareIcon {
      padding-left: 24px;
      width: auto;
      float: left;
      line-height: normal;
      height: auto;
      margin-top: 0;
      padding-right: 0;
    }

    .PlatformSocialMedia {
      float: left;
    }

    &.isVisible {
      right: 0;
    }
  }

  @media screen and (max-width: 1080px) {
    height: @header-height-mobile;

    &__UserMenu,
    &__PlatformMenu {
      display: none;
    }


    >.FundkyWrap>.PublicHeader__Divider>.PlatformSocialMedia {
      display: none !important;
    }

    &__Divider {
      display: flex;
      align-items: center;
    }

    &__ToggleMenu {
      position: relative;

      .fa-bars,
      .fa-times-circle {
        transition: .2s opacity ease-in-out;
      }

      .fa-bars {
        opacity: 1;
      }

      .fa-times-circle {
        opacity: 0;
        position: absolute;
      }

      &.isVisible {
        .fa-bars {
          opacity: 0;
        }

        .fa-times-circle {
          opacity: 1;
        }
      }
    }

    &__PlatformMenuMobile {
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: 0 4px 5px .2px rgba(52, 50, 47, 0.29) !important;

      .ant-menu-item:not(.PublicHeader__ShareIcon),
      .ant-menu-submenu-title {
        font-size: 1.8rem;
        margin: 0 !important;
        border-bottom: 2px solid #F5F6F7;
        height: 45px !important;
        line-height: 45px !important;
        padding: 0 !important;
      }

      .ant-menu-sub {
        border-bottom: 2px solid #F5F6F7;
        padding-left: 4rem;
      }

      .ant-menu-submenu .ant-menu-item {
        border-bottom: none;
      }

      .PublicHeader__ShareIcon,
      .PlatformSocialMedia {
        margin: 1.5rem 0;

        svg {
          font-size: 3.5rem;
        }
      }

      .PublicHeader__ShareIcon {
        padding-left: 0 !important;
      }
    }
  }
}

// Sub-menus aren't in the main div
.ant-menu-sub .ant-menu-item>a:hover, .ant-menu-sub .ant-menu-item:hover {
  color:var(--secondary-color) !important;
}
