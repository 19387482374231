@import (reference) "../assets/stylesheets/main";

.PublicCampaignBandMenu {
  margin-top: -8px;
  border-radius: @border-radius-base 0 @border-radius-base @border-radius-base;
  box-shadow: none;
  background-color: var(--secondary-color);
  border: 0 !important;

  li {
    padding-right: 100px !important;
    height: 68px !important;
    line-height: 68px !important;
    margin: 0 !important;

    a {
      color: white;
      font-size: 18px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__Icon {
    font-size: 30px;
    margin-right: 24px !important;
    width: 50px !important;
  }
  .ant-menu-item-selected {
    background-color: var(--secondary-color-lighten) !important;
  }
  .ant-menu-item a[disabled] {
    opacity: .5;
  }
  .ant-menu-item {
    &:first-of-type {
      border-radius: @border-radius-base 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 @border-radius-base @border-radius-base;
    }

    &:hover,
    &:focus {
      background-color: var(--secondary-color-lighten) !important;
      a {
        color: white;
      }
    }
  }
}
