@import (reference) "../../../assets/stylesheets/main";

// No choice here: getFieldDecorator removes the element class and add an id
.TypeOptions {
  display: flex;
  position: relative;

  // Add a new feedback icon in the right
  // Repro Antd behavior: the pseudo-element is always here but empty
  &:after {
    position: absolute;
    top: 50%;
    right: -32px;
    margin-top: -10px;
    width: 32px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: @form-feedback-icon-size;
    animation: zoomIn .3s @ease-out-back;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;

    &__Option {
      margin-right: 0 !important;
      margin-bottom: 6px;
      padding: 0 1rem;
    }

    &:after {
      right: -24px;
    }
  }
}

.TypeOptions__Option {
  background-color: @input-bg;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  // Repro Antd input styles (thicker border thought)
  border: 2px @border-style-base @input-border-color;
  border-radius: @border-radius-base;
  transition: all .3s;

  &:hover, &:focus {
    outline:none;
    border: 2px solid var(--primary-color) !important;
  }

  &.TypeOptions__Option--Selected {
    border: 2px var(--primary-color) solid;
  }

  margin-right: 6px;

  &:last-child {
    margin-right: 0;
  }

  .TypeOptions__Icon {
    font-size: 36px;
    margin-top: @padding-lg;
    color: var(--link-color);
  }

  h3 {
    margin: 10px 0;
    line-height: normal;
  }

  p {
    // Because we are inside a form control, we need to reset the Antd input line-height
    line-height: initial;
    text-align: center;
    flex: 1 1 33%;
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
}
