@import (reference) "../assets/stylesheets/main";

.HelpText() {
  color: @text-color-secondary;
  text-transform: uppercase;
  font-size: @font-size-xs;
}

.HelpText {
  .HelpText()
}
