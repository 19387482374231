.CampaignsParticipantsPage .participant-name {
  float: left;
}
.CampaignsParticipantsPage .btn-view-page {
  float: right;
}
.CampaignsParticipantsPage .ant-tabs {
  clear: both;
}
.CampaignsParticipantsPage .with-margin-right {
  margin-right: 25px;
}
