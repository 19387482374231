@import (reference) "../../assets/stylesheets/main";

.CampaignCreatePage {
  background-color: @hq-admin-background-bar;

  .CampaignCreatePage__TitleDivider {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: @padding-lg * 2 0;

    i {
      font-size: 56px;
      color: var(--primary-color);
      margin-right: @padding-lg;
    }

    h1, h2 {
      margin-bottom: 0;
    }
  }

  .ant-tabs-tab {
    padding-left: 40px;
    padding-right: 40px;
  }

  .ant-tabs {
    background-color: @body-background;
  }

  .ant-tabs-bar {
    background-color: @hq-admin-background-bar;

    i, svg {
      color: var(--link-color);
    }
  }

  &__TitleIcon {
    font-size: 56px;
    color: var(--primary-color);
    margin-right: 40px;
  }

  .Back-btn {
    color: #fff;
    background-color: #5f697a;
    height: 40.5px;
    margin-right: 10px;
    transition: background-color 1s;
  }

  [class*="CancelWrapper"] {
    margin-bottom: 80px;

    a, > span {
      color: var(--primary-color);
      font-size: .8em;
      font-weight: 600;
      letter-spacing: .12rem;
      text-transform: uppercase;
      cursor: pointer;

      .ant-popover-message-title {
        text-transform: initial;
      }
    }
  }
}
