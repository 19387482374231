.TeamCreatePage {
  background-color: #F5F7F7;
}
.TeamCreatePage .TeamCreatePage__TitleDivider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 48px 0;
}
.TeamCreatePage .TeamCreatePage__TitleDivider h1,
.TeamCreatePage .TeamCreatePage__TitleDivider h2 {
  margin-bottom: 0;
}
.TeamCreatePage .ant-tabs-tab {
  padding-left: 40px;
  padding-right: 40px;
}
.TeamCreatePage .ant-tabs {
  background-color: #ECEDED;
}
.TeamCreatePage .ant-tabs-bar {
  background-color: #F5F7F7;
}
.TeamCreatePage .ant-tabs-bar i,
.TeamCreatePage .ant-tabs-bar svg {
  color: var(--link-color);
}
.TeamCreatePage__TitleIcon {
  font-size: 56px;
  color: var(--primary-color);
  margin-right: 40px;
}
