.anticon-facebook {
  color: #3b5998;
}

.anticon-twitter {
  color: #1da1f2;
}

.anticon-linkedin {
  color: #0077b5;
}
