.RecipientModalContent {
  &--clickableAvatar {
    cursor: pointer;
  }
  &__AvatarThumbnail {
    background-color: #fff;
    padding: 12px;
    border-radius: 4px;

    h1 {
      font-size: 24px;
      line-height: 30px;
    }

    .AvatarThumbnail__Avatar, .AvatarThumbnail__Avatar img {
      height: 80px;
      width: 80px;
      flex: 0 0 80px;
      font-size: 40px;
    }

    .AvatarThumbnail__Content {
      flex-direction: column-reverse;
      margin-left: 20px;
    }
  }

  &__Or {
    display: block;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 25px;
    margin: 30px 0 30px 0;
    font-weight: 700;
    text-align: center;
  }

  &--label {
    display: block;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 25px;
    margin: 38px 0 20px 0;
    font-weight: 700;
  }

  &--select {
    .ant-select-selection {
      height: 58px;
    }
    .ant-select-selection__rendered{
      height: 100%;
    }
    .ant-select-selection-selected-value {
      height: 100%;
      display: flex !important;
    }
  }

  &__SearchNewButton {
    width: 100%;
    min-height: 60px;
    font-size: 18px;
  }

  &--radio {
    .ant-radio-button-wrapper {
      border-radius: 4px !important;
      width: 100%;
      height: auto;
      padding: 0;
      border: 4px solid #fff;

      &:before {
        content: none;
      }

      &-checked {
        border-color: var(--secondary-color);
        box-shadow: none;
      }

      &:not(.organization-option) {
        margin-bottom: 12px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      > span:not(.ant-radio-button) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .FundkyButton {
          font-size: 18px;
          svg {
            margin: 16px;
          }
        }
      }
    }

    .not-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: default;

      h1 {
        margin: 0;
      }

      .FundkyButton {
        font-size: 18px;
        svg {
          margin: 16px;
        }
      }
    }
  }
}
