@import (reference) "../assets/stylesheets/main";

.PublicPanel {
  background-color: #FFFFFF;
  border: 1px solid @input-border-color;
  border-radius: @border-radius-base;
  padding: @padding-sm @padding-lg;
  margin-top: @padding-lg;

  > * {
    margin-bottom: @padding-sm;
  }

  &__Divider {
    border-bottom: 3px solid @layout-body-background;
    padding-bottom: @padding-sm;
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: @font-size-lg;
      line-height: @font-size-lg;
      font-weight: 600;
      margin-bottom: 0;
    }

    .ant-input-affix-wrapper {
      width: auto;
      line-height: 1;
    }
    .ant-input {
      height: @font-size-lg;
      line-height: @font-size-lg;
      padding-top: 0;
      padding-bottom: 0;
      border: 0;
      text-align: right;

      &:focus {
        box-shadow: none;
      }
    }
    .ant-input-suffix {
      cursor: pointer;
      right: 0;
    }
  }

  &__Content {
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  &__Footer {
    border-top: 3px solid @layout-body-background;
    padding-top: @padding-sm;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--right {
      justify-content: flex-end;
    }
  }

  &__AvailablePlacesCounter {
    > span {
      font-size: 16px;
      line-height: 10px;
    }
  }

  &__Counter {
    font-size: 30px;
    font-weight: bold;
    margin-top: -12px;
    color: #adadad;
    > span {
      &:first-of-type {
        padding-right: 5px;
        color: var(--link-color);
      }

      &:last-of-type {
        padding-left: 5px;
      }
    }
  }

  &__CTA {
    margin: 0 -5px;
    > * {
      margin: 5px;
    }

    &--invite {
      background-color: #333;
      color: #fff;

      &:hover:not(:disabled) {
        background-color: #000;
        color: #fff;
        border-color: #000;
      }
    }
  }

  // To fixe Nuka-Carousel height glitch
  // https://stackoverflow.com/questions/41468628/nuka-carousel-heightmode-dont-work-in-react
  .slider-list {
    min-height: 64px;
  }

  .slider:focus{
    outline: none;
  }

  @media screen and (max-width: 480px) {
    padding: @padding-sm @padding-lg/2;
  }

}
