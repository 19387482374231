.CampaignSection {
  padding: 48px 0;
  background-color: #ECEDED;
  height: 1000px;
}
.CampaignSection .FundkyWrap {
  display: flex;
}
.CampaignSection__Information button.ant-btn {
  border-color: var(--link-color);
  background-color: var(--link-color);
  color: #FFFFFF;
}
.CampaignSection__Information button.ant-btn:hover {
  border-color: var(--link-hover-color);
  background-color: var(--link-hover-color);
  color: #FFFFFF;
}
.CampaignSection__Thermometer {
  width: 363px;
  padding: 24px;
  background-color: #fff;
  border-radius: 4px;
}
.CampaignSection__Carousel {
  flex: 1 0;
}
.CampaignSection__Carousel.OneCampaign {
  min-width: 500px !important;
}
.CampaignSection__Carousel ul {
  display: flex !important;
  align-items: stretch;
  cursor: grab !important;
}
.CampaignSection__Carousel ul > li {
  display: flex !important;
  justify-content: center;
  align-items: stretch !important;
  cursor: grab !important;
  min-height: 100%;
}
.CampaignSection__Carousel ul > li > a {
  display: flex !important;
  align-items: stretch;
  width: 363px;
  margin: 0 12px;
}
.CampaignSection__Carousel ul > li > a > .CampaignSection__Thermometer {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.CampaignSection__Carousel ul > li > a > .CampaignSection__Thermometer > .PublicThermometer__OutsideContent {
  flex: 1;
}
.CampaignSection__Carousel ul > li > a > .CampaignSection__Thermometer > .PublicThermometer__OutsideContent > h3 {
  margin: 0 0 auto;
  font-size: 24px;
  min-height: 108px;
}
.CampaignSection__Carousel ul > li > a > .CampaignSection__Thermometer > .PublicThermometer__OutsideContent > .PublicThermometer__Percentage {
  font-size: 82px;
}
.CampaignSection__Carousel ul > li > a > .CampaignSection__Thermometer > .PublicThermometer__OutsideContent > .PublicThermometer__Detail {
  font-size: 26px;
}
@media screen and (max-width: 1080px) {
  .CampaignSection__Carousel {
    min-width: 403px;
  }
}
@media screen and (min-width: 1081px) and (max-width: 1393px) {
  .CampaignSection__Carousel {
    min-width: 443px;
  }
}
@media screen and (min-width: 1394px) and (max-width: 1780px) {
  .CampaignSection__Carousel {
    min-width: 830px;
  }
}
@media screen and (min-width: 1781px) and (max-width: 2167px) {
  .CampaignSection__Carousel {
    min-width: 1217px;
  }
}
@media screen and (min-width: 2168px) {
  .CampaignSection__Carousel {
    min-width: 1604px;
  }
}
@media screen and (max-width: 720px) {
  .CampaignSection {
    flex-direction: column;
  }
  .CampaignSection__Information {
    margin-bottom: 3rem;
    text-align: center;
  }
  .CampaignSection__Information .ant-divider-horizontal {
    margin-left: auto;
    margin-right: auto;
  }
  .CampaignSection__Carousel ul > li > a {
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .CampaignSection .FundkyWrap {
    flex-direction: column;
  }
}
@media screen and (max-width: 520px) {
  .CampaignSection__Carousel {
    min-width: inherit;
  }
}
@media screen and (max-width: 475px) {
  .CampaignSection .PublicCarousel--SideControls .slider-control-centerright a {
    right: -1em;
  }
  .CampaignSection .PublicCarousel--SideControls .slider-control-centerleft a {
    left: -1em;
  }
  .CampaignSection .PublicThermometer__CircularWrapper {
    width: 226px;
    margin: auto;
  }
  .CampaignSection .CampaignSection__Carousel ul > li > a {
    width: 111%;
  }
}
.menu-contribute li:hover {
  background-color: #ECEDED !important;
}
.menu-contribute li:hover a {
  color: rgba(0, 0, 0, 0.85) !important;
}
@media screen and (min-width: 700px) {
  .CampaignSection {
    height: 750px;
  }
}
