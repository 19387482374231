@import (reference) "../../assets/stylesheets/main";

.CreateConfirmationPanel {
  position: relative;
  padding-top: @padding-lg;
  max-width: 680px;

  svg.CreateConfirmationPanel__SuccessIcon {
    position: absolute;
    left: -56px - @padding-lg;
    font-size: 56px;
    color: var(--link-color);
    margin-right: 14px;
  }

}

.CreateConfirmationPanel__Divider {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  margin-bottom: @padding-lg;

  &--CTA {
    justify-content: flex-start !important;
    margin-top: 78px;

    > a, button {
      margin-right: 10px;
    }
  }
}

.CreateConfirmationPanel__DarkButton {
  .dark-btn();
}
