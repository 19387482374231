.MultiLang .MultiLangSelect {
  float: right;
  margin-bottom: 1rem;
}
.MultiLang .MultiLangField {
  clear: both;
}
.MultiLang .MultiLangField.isInvisible {
  display: none !important;
}
