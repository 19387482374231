// Useful mixins will be there
@import "../themes/_fundky";
@import "../abstracts/_variables";

.button-font() {
  text-transform: uppercase;
  letter-spacing: .12rem;
}

// 2 types of button in Antd:
// - Primary (got the primary color)
// - Default (white)
// This mixin is for the 3rd type: secondary, with the link color
.secondary-btn() {
  border-color: var(--link-color);
  background-color: var(--link-color);
  color: #FFFFFF;

  &:hover {
    border-color: var(--link-hover-color);
    background-color: var(--link-hover-color);
    color: #FFFFFF;
  }
}

// Other type of button: dark
.dark-btn() {
  background-color: @admin-cell-hover;
  color: #FFFFFF;

  &:hover {
    border-color: @admin-cell-hover;
    background-color: @admin-cell-hover;
    color: #FFFFFF;
  }
}

// With flexbox, we often need to specify a margin between siblings having flex-grow
.inner-margin(@value) {
  margin-right: @value;

  &:last-child {
    margin-right: 0;
  }
}
