.CampaignAdminPage > h1 {
  float: left;
}
.CampaignAdminPage .btn-view-page {
  float: right;
}
.CampaignAdminPage .ant-tabs {
  clear: both;
}
.CampaignAdminPage .ant-upload-list-picture-card .ant-upload-list-item,
.CampaignAdminPage .ant-upload-select-picture-card {
  width: calc(260px + 12px) !important;
  height: calc(108px + 20px) !important;
}
.CampaignAdminPage .ant-upload-list-picture-card .ant-upload-list-item .ant-upload-list-item-thumbnail img,
.CampaignAdminPage .ant-upload-select-picture-card .ant-upload-list-item-thumbnail img {
  width: 260px;
  height: 108px;
  object-fit: cover;
}
.CampaignAdminPage--banner .UploadField__Upload .ant-upload-list-picture-card .ant-upload-list-item,
.CampaignAdminPage--banner .UploadField__Upload .ant-upload-select-picture-card {
  width: calc(260px + 12px);
  height: calc(108px + 20px);
}
