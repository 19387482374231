.ColorPickerField .ColorPickerField__Input {
  height: 32px !important;
  width: 62px;
  padding: 0;
}
.ColorPickerField .trigger {
  width: 62px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  outline: none;
  cursor: pointer;
}
.ColorPickerField .colorPickerWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background: #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
  padding: 10px;
}
.ColorPickerField .colorPickerWrapper button {
  width: 100%;
}
.ColorPickerField .colorPickerWrapper .sketch-picker {
  padding: 0 !important;
  box-shadow: none !important;
}
.ColorPickerField .colorPickerWrapper .flexbox-fix:nth-child(2) > div:nth-child(2) {
  display: none;
}
.ColorPickerField .colorPickerWrapper .swatch {
  height: 50px;
  width: 100%;
  border-bottom: 3px solid #fff;
}
.ColorPickerField .cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
}
