.PublicParticipantPanel {
  background-color: #FFFFFF;
  border: 1px solid #DDDFDF;
  border-radius: 4px;
  padding: 12px 24px;
  margin-top: 24px;
}
.PublicParticipantPanel > * {
  margin-bottom: 12px;
}
.PublicParticipantPanel__Divider {
  border-bottom: 3px solid #F5F7F7;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.PublicParticipantPanel__Divider h2 {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.PublicParticipantPanel__Divider .edit-profile {
  background: none;
  color: var(--primary-color);
  transition: color 0.2s ease-in-out;
}
.PublicParticipantPanel__Divider .edit-profile:hover {
  color: var(--primary-hover-color);
}
.PublicParticipantPanel__Divider .ant-input-affix-wrapper {
  width: auto;
  line-height: 1;
}
.PublicParticipantPanel__Divider .ant-input {
  height: 18px;
  line-height: 18px;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  text-align: right;
}
.PublicParticipantPanel__Divider .ant-input:focus {
  box-shadow: none;
}
.PublicParticipantPanel__Divider .ant-input-suffix {
  cursor: pointer;
  right: 0;
}
.PublicParticipantPanel__Footer {
  border-top: 3px solid #F5F7F7;
  padding-top: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.PublicParticipantPanel__CTA {
  margin: 0 -5px;
}
.PublicParticipantPanel__CTA > * {
  margin: 5px;
}
.PublicParticipantPanel__CTA--contact {
  background-color: #333;
  color: #fff;
}
.PublicParticipantPanel__CTA--contact:hover {
  background-color: #000;
  color: #fff;
  border-color: #000;
}
