@import '~react-image-crop/dist/ReactCrop.css';
@import (reference) "../../../assets/stylesheets/main.less";

.UploadCrop__Field {
  display: flex;
  position: relative;

  .ant-upload-select-picture-card {
    float: left;
    box-sizing: border-box;
    width: 272px;
    height: 128px;
    background-color: #fff;
    border: 2px solid #dddfdf;
    border-radius: 4px;
    transition: all .3s;
  }

  .UploadButton__Icon {
    font-size: 36px;
    color: var(--link-color);
  }

  .ant-upload-disabled {
    .Upload__Preview {
      opacity: .5;
    }
  }
}

.ant-modal .UploadCrop__Field .ant-upload {
  width: 100%;
}

.Upload__Preview {
  width: 100%;
  height: auto;
}

.Upload__Error {
  color: @error-color;
  margin-bottom: 0;
  line-height: 20px;
}

// CROP
.ReactCrop {
  max-width: inherit;
  background-color: inherit;
}