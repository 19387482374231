.HistoryTable {
  background-color: #ECEDED;
}
.HistoryTable p {
  margin: 0;
}
.HistoryTable .ant-table {
  background-color: #fff;
  overflow: hidden;
}
.HistoryTable .ant-table-body {
  margin: 0 !important;
}
.HistoryTable tbody > tr {
  background-color: #FFFFFF;
  cursor: pointer;
  border-bottom: 0;
  border-top: 1px solid #e8e8e8;
}
.HistoryTable tbody > tr:first-of-type {
  border: none;
}
.HistoryTable tbody > tr.ant-table-expanded-row {
  cursor: inherit;
}
.HistoryTable tbody > tr.ant-table-expanded-row > td {
  border-top: 0;
  background-color: #F5F7F7;
}
.HistoryTable .diff-boxes {
  display: flex;
  width: 100%;
  padding-right: 50px;
}
.HistoryTable .diff-boxes div {
  flex: 1;
  margin: 5px;
  background-color: #fff;
}
.HistoryTable .json-markup {
  white-space: pre-wrap !important;
}
.HistoryTable .Column.addedOn {
  min-width: 100px;
  word-break: normal !important;
}
@media only screen and (max-width: 600px) {
  .HistoryTable .ant-table-tbody > tr > td {
    border: 0 !important;
  }
  .HistoryTable,
  .HistoryTable .ant-table-tbody {
    display: block;
    max-width: 100%;
  }
  .HistoryTable div,
  .HistoryTable .ant-table-tbody div,
  .HistoryTable tr,
  .HistoryTable .ant-table-tbody tr,
  .HistoryTable td,
  .HistoryTable .ant-table-tbody td {
    display: block;
  }
  .HistoryTable tr,
  .HistoryTable .ant-table-tbody tr {
    position: relative;
  }
  .HistoryTable .ant-table-row-expand-icon-cell {
    position: absolute;
    right: 0;
    border-top: 0;
    top: 0em;
  }
  .HistoryTable .Column.event {
    border-top: 0;
    font-size: 14px;
    line-height: 1.3em;
    padding-top: 0 !important;
    padding-bottom: 12px !important;
    margin-bottom: 6px;
  }
  .HistoryTable .Column.event span {
    word-break: break-word;
  }
  .HistoryTable .json-markup {
    word-break: break-word;
  }
  .HistoryTable .diff-boxes {
    padding-right: 0;
  }
  .HistoryTable .diff-boxes h5 {
    font-size: 15px;
    font-weight: bold;
    padding: 0.5em;
  }
  .HistoryTable .diff-boxes div + div {
    margin-top: 3em;
  }
}
@media only screen and (max-width: 890px) {
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .ant-table-tbody > tr > td {
    border: 0 !important;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .ant-table-tbody {
    display: block;
    max-width: 100%;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable div,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .ant-table-tbody div,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable tr,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .ant-table-tbody tr,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable td,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .ant-table-tbody td {
    display: block;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable tr,
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .ant-table-tbody tr {
    position: relative;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .ant-table-row-expand-icon-cell {
    position: absolute;
    right: 0;
    border-top: 0;
    top: 0em;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .Column.event {
    border-top: 0;
    font-size: 14px;
    line-height: 1.3em;
    padding-top: 0 !important;
    padding-bottom: 12px !important;
    margin-bottom: 6px;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .Column.event span {
    word-break: break-word;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .json-markup {
    word-break: break-word;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .diff-boxes {
    padding-right: 0;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .diff-boxes h5 {
    font-size: 15px;
    font-weight: bold;
    padding: 0.5em;
  }
  aside:not(.ant-layout-sider-collapsed) + i + section.ant-layout .HistoryTable .diff-boxes div + div {
    margin-top: 3em;
  }
}
