.ant-tooltip{
  z-index:10000;
}
.Fundky__Tooltip {
  color: #AC8EC3;
  margin-left: .6rem;
  cursor:pointer;


  &__Overlay {
    @color: rgba(171, 141, 194, .95);
    .ant-tooltip-arrow {
      border-top-color: @color;
      &:before {
        background-color: @color;
      }
    }

    .ant-tooltip-inner {
      background-color: @color;
      word-break: inherit;
    }
  }
}
