.CampaignCreatePage {
  background-color: #F5F7F7;
}
.CampaignCreatePage .CampaignCreatePage__TitleDivider {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 48px 0;
}
.CampaignCreatePage .CampaignCreatePage__TitleDivider i {
  font-size: 56px;
  color: var(--primary-color);
  margin-right: 24px;
}
.CampaignCreatePage .CampaignCreatePage__TitleDivider h1,
.CampaignCreatePage .CampaignCreatePage__TitleDivider h2 {
  margin-bottom: 0;
}
.CampaignCreatePage .ant-tabs-tab {
  padding-left: 40px;
  padding-right: 40px;
}
.CampaignCreatePage .ant-tabs {
  background-color: #ECEDED;
}
.CampaignCreatePage .ant-tabs-bar {
  background-color: #F5F7F7;
}
.CampaignCreatePage .ant-tabs-bar i,
.CampaignCreatePage .ant-tabs-bar svg {
  color: var(--link-color);
}
.CampaignCreatePage__TitleIcon {
  font-size: 56px;
  color: var(--primary-color);
  margin-right: 40px;
}
.CampaignCreatePage .Back-btn {
  color: #fff;
  background-color: #5f697a;
  height: 40.5px;
  margin-right: 10px;
  transition: background-color 1s;
}
.CampaignCreatePage [class*="CancelWrapper"] {
  margin-bottom: 80px;
}
.CampaignCreatePage [class*="CancelWrapper"] a,
.CampaignCreatePage [class*="CancelWrapper"] > span {
  color: var(--primary-color);
  font-size: 0.8em;
  font-weight: 600;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
  cursor: pointer;
}
.CampaignCreatePage [class*="CancelWrapper"] a .ant-popover-message-title,
.CampaignCreatePage [class*="CancelWrapper"] > span .ant-popover-message-title {
  text-transform: initial;
}
