@import (reference) "~Parts/assets/stylesheets/themes/_fundky.less";

.WinkNotification {
  margin-bottom:2em;

  .WinkNotification__Inner {
    background: #fff;
    border: 1px solid @helper-color;
    padding: 2rem;
    position: relative;
  }

  .logo-wink {
    max-width: 34px;
    position: absolute;
    top: 1.4rem; left: 1.4rem;
  }

  .WinkNotification__Inner_Content {

    &.hasWink {
      margin-left: 4.5rem;
    }

    &.closable {
      margin-right: 2.4rem;
    }

    h2, p {
      line-height: normal;
    }

    h2 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }

  .WinkNotification__Buttons {
    display: flex;
    flex-wrap: wrap;
    margin: -1rem;

    &.align-center {
      justify-content: center;
    }

    &.align-right {
      justify-content: flex-end;
    }

    > span {
      display: block;
      margin: 1rem;
    }

    button {
      background-color: @helper-color;
      border-color: @helper-color;

      &:hover {
        background-color: @helper-hover-color !important;
        border-color: @helper-hover-color !important;
      }
    }
  }

  .WinkNotification__CloseButton {
    color: @helper-color;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    position: absolute;
    top: 1.4rem; right: 1.4rem;
    color: @helper-color;
    font-size: 2.6rem;
    line-height: normal;

    &:hover {
      color: @helper-hover-color;
    }
  }

  button.ant-btn{
    background:#AB8DC2;
    border-color:#9A7EAF;
    &:hover{
      background:#9A7EAF;
    }
  }
}