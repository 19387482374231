@import (reference) "../../assets/stylesheets/main";

.PublicParticipantTeamMembersPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    margin-bottom: 0;
  }

  .PublicParticipantTeamMembersPanel_Information {
    display: flex;
    align-items: flex-start;
    flex: 0 0 auto;
    max-width: 33%;
    margin: .6rem 2rem 1.2rem 0;

    .iconWrap {
      background: #64C1C6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.2rem;
      margin-right: 1rem;
      width: 50px;
      height: 50px;

      .fa-users {
        font-size: 2rem;
        color: #fff;
      }
    }

    p, h4 {
      line-height: normal;
    }

    p {
      margin-bottom: 0;
    }

    h4 {
      font-size: 2rem;
      margin-bottom: 0;

      a {
        color: inherit;
      }
    }
  }

  .PublicParticipantTeamMembersPanel_Carousel {
    flex: 1 1 63%;
    min-width: 63%;
    max-width: 100%;
    padding: 0 2.8rem;

    div[class^="slider-control-"] {
      //top: 35% !important;

      svg {
        font-size: 2.2rem;
      }

      .disabled {
        opacity: .5;
      }
    }

    .slider-slide a {
      margin: 0 auto;
    }

    &--avatar {
      display: block;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      background-color: var(--secondary-color);
      font-size: @font-size-lg;
      color: #FFFFFF;
      text-align: center;
      line-height: 50px;
      margin: 0 auto;
    }
  }
}
