form .labelCheckbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
form .labelCheckbox input {
    margin: 0;
    width: 20px;
    height: 20px;
    opacity: 1;
    accent-color: #eb3052}
form .labelCheckbox a {
    color: #6441A5;
}
form .labelCheckbox .ant-checkbox {
    margin: 0 1em 0 0;
}

form .ppfRow .ant-form-item-children {
    background-color: white;
    .ant-form-item {
        padding: 16px;
        margin-bottom: 0;
    }
}

form .donationRow {
    background-color: white;
    margin-bottom: 24px;
    font-size: 1.1em;
    .rowContent {
        padding: 16px;
    }
}

form .labelCheckbox span.ppfDonation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: normal;
    text-align: left;
}
form .labelCheckbox .ant-form-item-label {
    line-height: normal;
}
form .labelCheckbox .ant-form-item-no-colon::after {
    content: none;
}
form div.ppfDonation {
    font-size: 1.25em;
}