body {
  min-height: 100%;
  font-size: 1.6rem;
  overflow-x: hidden;
}

.PublicLayout {
  width: 100%;
  min-height: 100%;
  position: relative;

  .ant-form {

    //CHECKBOXES
    .ant-checkbox-wrapper {
      p {
        line-height: normal;
        margin-bottom: 0;
      }
    }

    .SubmitWrapper {
      margin-top: 7.8rem;
    }
  }

  @media screen and (min-width: 1081px) {
    .PublicHeaderRef+.ant-layout-content {
      margin-top: 90px;
      transition: margin-top .2s ease-in-out;
    }
  }

  form .has-feedback .ant-input {
    padding: 4px 11px;
  }

  .ant-form-item-children {
    display: block;

    .ant-form-item-children-icon {
      right: -2.6rem !important;
    }
  }

  @media screen and (max-width: 1080px) {
    .PublicHeaderRef+.ant-layout-content {
      margin-top: 76px;
    }
  }

  // Notice on public pages with the description only in other language
  .info-notice-language {
    color: #AC8EC3;
  }
}
