.MultiLang {
  .MultiLangSelect {
    float: right;
    margin-bottom: 1rem;
  }

  .MultiLangField {
    clear: both;
  
    &.isInvisible {
      display: none !important;
    }
  }
}