.PublicPanel {
  background-color: #FFFFFF;
  border: 1px solid #DDDFDF;
  border-radius: 4px;
  padding: 12px 24px;
  margin-top: 24px;
}
.PublicPanel > * {
  margin-bottom: 12px;
}
.PublicPanel__Divider {
  border-bottom: 3px solid #F5F7F7;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.PublicPanel__Divider h2 {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.PublicPanel__Divider .ant-input-affix-wrapper {
  width: auto;
  line-height: 1;
}
.PublicPanel__Divider .ant-input {
  height: 18px;
  line-height: 18px;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  text-align: right;
}
.PublicPanel__Divider .ant-input:focus {
  box-shadow: none;
}
.PublicPanel__Divider .ant-input-suffix {
  cursor: pointer;
  right: 0;
}
.PublicPanel__Content {
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.PublicPanel__Footer {
  border-top: 3px solid #F5F7F7;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PublicPanel__Footer--right {
  justify-content: flex-end;
}
.PublicPanel__AvailablePlacesCounter > span {
  font-size: 16px;
  line-height: 10px;
}
.PublicPanel__Counter {
  font-size: 30px;
  font-weight: bold;
  margin-top: -12px;
  color: #adadad;
}
.PublicPanel__Counter > span:first-of-type {
  padding-right: 5px;
  color: var(--link-color);
}
.PublicPanel__Counter > span:last-of-type {
  padding-left: 5px;
}
.PublicPanel__CTA {
  margin: 0 -5px;
}
.PublicPanel__CTA > * {
  margin: 5px;
}
.PublicPanel__CTA--invite {
  background-color: #333;
  color: #fff;
}
.PublicPanel__CTA--invite:hover:not(:disabled) {
  background-color: #000;
  color: #fff;
  border-color: #000;
}
.PublicPanel .slider-list {
  min-height: 64px;
}
.PublicPanel .slider:focus {
  outline: none;
}
@media screen and (max-width: 480px) {
  .PublicPanel {
    padding: 12px 12px;
  }
}
