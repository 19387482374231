.SurevyAttemptMain {
  width: 90%;
  max-width: 800px;
  padding-bottom: 2rem;
}
.SurevyAttemptMain .SurevyAttemptRadio,
.SurevyAttemptMain .SurevyAttemptDate,
.SurevyAttemptMain .SurevyAttemptDropdown,
.SurevyAttemptMain .SurevyAttemptText,
.SurevyAttemptMain .SurevyAttemptCheckbox,
.SurevyAttemptMain .SurevyAttemptTextarea,
.SurevyAttemptMain .SurevyAttemptBtn {
  padding: 15px 0px;
}
.SurevyAttemptMain .SurevyAttemptCancleBtn {
  padding: 0px 15px;
  margin: 0 10px;
}
.surveyDropdown {
  white-space: normal;
  word-wrap: break-word;
}
