.InfoPanel {
  background-image: url(/assets/Fundky_Logotype_2016._BGGeometric.jpg);
  background-size: cover;
  background-position: bottom;
  position: relative;

  h1 {
    margin-top: 40px;
    color: #fff;
    font-size: 28px;
    line-height: 1em;

    span {
      font-family: 'Galada';
      font-weight: normal;
    }
  }

  img {
    width: 237px;
    margin: 0 auto;
  }

  p {
    color: #fff;
    font-size: 18px;
    line-height: 1.33em;
  }

  a {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    svg {
      margin-right: 10px;
    }
  }

  &--back {
    position: absolute;
    top: 28px;
    left: 28px;
    color: #fff;
    font-size: 36px;
    cursor: pointer;
  }

  @media screen and (max-width: 986px) {
    height: 100%;
  }

  @media screen and (max-width: 508px) {
    img {
      width: 50%;
    }

    h1 {
      font-size: 20px;
      margin-top: 20px;
    }

    p, a {
      font-size: 16px;
    }
  }
}
