.AdminContentLangSwitcher__wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  margin-bottom: 0.75em;
  z-index: 1;

  label {
    margin: 1rem 0;
    font-weight: normal;
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-selection__rendered {
    margin-right: 30px;
  }

  // .ant-select-selection {
  //   background-color: inherit;
  //   border: none;
  //   height: auto !important;

  //   &__rendered {
  //     line-height: normal !important;
  //   }
  // }

  // .ant-select-focused .ant-select-selection,
  // .ant-select-selection:focus,
  // .ant-select-selection:active {
  //   border: none;
  //   box-shadow: none;
  // }

  .fa-caret-down {
    color: #333333;
  }
}

.SocialMediasSettingsForm,
.DesignSettingsForm,
.EventAdminCampaignTicketForm {
  .AdminContentLangSwitcher__wrap{
    text-align: right;
    flex-direction: initial;
    display: flex;
    justify-content: flex-end;
    .ant-select{
      display:inline;
      width:auto !important;
      .ant-select-selection{
        font-weight:bold;
        border:0 !important;
        position:relative;
        top:1px;
        box-shadow: none;
        background:none;
      }
      .ant-select-selection, .ant-select-selection-selected-value{
        display:inline-block;
      }
    }
  }
}
