@import (reference) "../../assets/stylesheets/main";


.ConnectionModal {
  overflow: hidden;
  width: 986px !important;

  &__Form {
    opacity: 0;
    transition: opacity 1s;
    &.active {
      opacity: 1;
    }
    &--container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-body,
  .CssVariableApplicator {
    display: flex;
    flex-direction: row;
    padding: 0;
    justify-content: flex-end;
    position: relative;
  }

  &__Panel {
    display: flex;
    flex-direction: column;
    width: 493px;
    padding: 50px;
    z-index: 1;
    position: relative;
    &.top{
      z-index: 2;
      transition: right .5s;
    }
  }

  .ant-modal-body {
    background-color: @body-background;
    border-radius: 4px;
    overflow: hidden;
    width: 986px !important;
  }

  .ant-btn[disabled] {
    background-color: #F85572;
    color: #fff;

    svg {
      margin-right: 10px;
    }
  }

  &__Logo {
    max-width: 100%;
    height: auto;
    align-self: center;

    &--container{
      position: relative;
      margin-bottom: @padding-lg;
      align-self: center;
      flex: 0;
    }
  }

  &--question {
    color: #ab8dc2;
    font-size: 30px;
    position: absolute;
    top: 10px;
    right: -40px;
    cursor: pointer;
    display: none;
  }

  .ant-alert-description-link {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-btn:hover, .ant-btn:focus {
    color: #fff;
  }

  .InfoPanel--back {
   display: none;
  }

  @media screen and (max-width: 986px) {
    width: 493px !important;

    .ant-modal-body {
      width: 493px !important;
    }

    .InfoPanel {
      overflow: auto;
      &--back {
        display: block;
      }
    }

    &__Panel {
      &.top {
        position: absolute;
        right: 493px;
      }
      &.display {
        right: 0;
      }
    }

    &--question {
      display: block;
      top: 16px;
      right: -35px;
      overflow: scroll;
    }
  }

  @media screen and (max-width: 508px) {
    width: 300px !important;

    .ant-modal-body {
      width: 300px !important;
    }

    .InfoPanel {
      overflow: auto;
      &--back {
        display: block;
        top: 10px;
        left: 10px;
        font-size: 30px;
      }
    }

    &--question {
      top: 20px;
      right: -10px;
    }

    &__Logo {
      width: 90%;
    }

    &__Panel {
      width: 300px !important;
      padding: 20px;
      &.top {
        right: 300px;
      }
      &.display {
        right: 0;
      }
    }
  }
}
