.SurevyAttemptMain {
    width: 90%;
    max-width: 800px;
    padding-bottom: 2rem;
    .SurevyAttemptRadio,
    .SurevyAttemptDate,
    .SurevyAttemptDropdown,
    .SurevyAttemptText,
    .SurevyAttemptCheckbox,
    .SurevyAttemptTextarea,
    .SurevyAttemptBtn {
        padding: 15px 0px;
    }
    .SurevyAttemptCancleBtn {
        padding: 0px 15px;
        margin: 0 10px;
    }
}

.surveyDropdown{
    white-space: normal;
    word-wrap: break-word;
}