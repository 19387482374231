.algPageInfo{
	.cta a{
		display: inline-block;
		color:#000;
		font-weight:300;
		font-size: 0.9em;
		padding:0 1em;
		font-weight: 600;
		i, svg{
			font-size: 0.8em;
		}
	}
}
.wherenow{
	margin-right: 2em;
	font-size: 0.93em;
	font-weight: 300;
}