.MoneyField__Input {
  position: relative;
  width: 200;
}
.MoneyField__Input .ant-input-number-input {
  text-align: right;
}
.MoneyField__Input.before .ant-input-number-input {
  padding: 0 24px 0 30px;
}
.MoneyField__Input.before .ant-input-number-input-wrap:before {
  content: "$";
  position: absolute;
  line-height: 4.2rem;
  margin: 0 15px;
}
.MoneyField__Input.after .ant-input-number-input {
  padding: 0 30px 0 15px;
}
.MoneyField__Input.after .ant-input-number-input-wrap:after {
  content: "$";
  position: absolute;
  line-height: 4.2rem;
  margin: 0 -15px;
}
.MoneyField .has-success .MoneyField__Input.inside.before .ant-input-number-input,
.MoneyField .has-error .MoneyField__Input.inside.before .ant-input-number-input {
  padding: 0 30px 0 30px;
}
.MoneyField .has-success .MoneyField__Input.inside.after .ant-input-number-input,
.MoneyField .has-error .MoneyField__Input.inside.after .ant-input-number-input {
  padding: 0 40px 0 15px;
}
.MoneyField .has-success .MoneyField__Input.inside.after .ant-input-number-input-wrap:after,
.MoneyField .has-error .MoneyField__Input.inside.after .ant-input-number-input-wrap:after {
  margin: 0 -35px;
}
