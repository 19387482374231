@import (reference) "../../assets/stylesheets/main";

.PresentationSection {
  padding: @padding-lg * 2 0;
  background-color: @layout-body-background;

  .bg-amount{
    // background-color: #03BFD6;
    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: center;
    padding: 20px 0px;
    font-weight: 600;
    font-size: 23px;
    .title{
      margin-right: 20px;
      color: #fff;
      max-width: 100px;
      line-height: 1.1;
    }
    .bg-white{
      color: #fff;
      font-weight: 600;
      margin: 0px 5px 0px 0px !important;
    }
  }
  .bg-white-gradiant{
    background-color: #fff;
    position: relative;
    margin-bottom: 0px;
    border-radius: 5px;
    width: 30px;
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    &::after{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 12.24%);
    }
  }

  .ant-divider {
    margin-top: @padding-lg;
    margin-bottom: @padding-lg;
  }

  h1 {
    min-width: 200px;
    word-wrap: break-word;
    hyphens: auto;
  }

  h2 {
    text-align: center;
  }

  &__Information {
    display: flex;


    h2{
      text-align:left;
      font-size:25px !important;
    }
    h3{
      font-size:20px;
      font-weight:bold;
      font-family:Domine, serif;
    }
    h4, h5, h6{
      text-transform:uppercase;
      font-weight:bold;
      font-size: 16px;
    }


    h1 {
      flex: 1;
    }

    div {
      flex: 3;
      padding-left: @padding-lg;
      padding-top: 8px;
    }
  }

  &__CallToActions {
    display:flex;
    padding-top: @padding-lg * 2;
    justify-content: center;
  }

  &__CallToAction.FundkyCTA {
    justify-content: flex-start;
    width: 300px;
    min-height: 300px;
    padding: 40px;

    .ant-btn {
      margin-top: auto;
    }

    h3, p {
      width: 100%;
    }

    &:not(.PresentationSection__CallToAction--Primary) > a,
    .RegisterDropdown {
      .secondary-btn();
    }

    svg {
      color: var(--link-color);
    }

    &.PresentationSection__CallToAction--Primary svg {
      color: var(--primary-color);
    }
  }

  @media screen and (max-width: 720px) {
    &__Information {
      flex-direction: column;
      align-items: center;
      overflow-wrap: break-word;

      > h1 {
        min-width: inherit;
      }

      p {
        padding-left: 0;
      }

      > div {
        width: 100%;
        padding: 0;
      }
    }

    .ant-divider-horizontal {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 6.4rem;
    }

    &__CallToActions {
      flex-direction: column;
      align-items: center;
    }

    &__CallToAction {
      width: 100%;
      min-height: 240px;
    }
  }

}
