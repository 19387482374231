@import "_ConnectionForm";

.ant-alert {
  margin-bottom: @form-item-margin-bottom;
}

.ConnectionForm {
  h1 {
    font-size: 24px;
  }

  &__Field--password {
    margin-bottom: 0;
  }

  &__ForgotLink {
    text-align: right;
  }

  &__EndNote {
    margin: 0;
  }

  .twitch-btn {
    display: block;
    background-color: #9146FF;
    color: #fff;
    height: 50px;
    line-height: 50px;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .12rem;
    font-size: 14px;
    // text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    // box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    border-radius: 4px;
    padding: 0 15px;
    outline: 0;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    &:hover {
      background-color: darken(#9146FF, 10%);
    }
    img {
      margin-left: 1em;
    }
  }
}
