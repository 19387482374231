.CampaignsTeamsPageForm {
  width: 100%;
}
.CampaignsTeamsPageForm .AvatarThumbnail__PrimaryContent {
  font-size: 0.8em;
  text-transform: uppercase;
}
.CampaignsTeamsPageForm .AvatarThumbnail__SecondaryContent {
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
}
.CampaignsTeamsPageForm .Wysiwyg.smallQuote .quill.wysiwygStyle .ql-snow .ql-editor {
  font-size: 20px;
}
.CampaignsTeamsPageForm .Wysiwyg.mediumQuote .quill.wysiwygStyle .ql-snow .ql-editor {
  font-size: 15px;
}
.CampaignsTeamsPageForm .Wysiwyg.largeQuote .quill.wysiwygStyle .ql-snow .ql-editor {
  font-size: 10px;
}
.CampaignsTeamsPageForm .quill.wysiwygStyle .ql-snow .ql-editor {
  color: rgba(0, 0, 0, 0.65);
  font-family: Domine,serif;
}
.CampaignsTeamsPageForm .quill.wysiwygStyle .ql-snow .ql-editor p {
  font-size: 1.6em;
  margin: 0.5em 0;
}
.CampaignsTeamsPageForm .quill.wysiwygStyle .ql-snow .ql-editor strong {
  font-weight: 700;
}
.CampaignsTeamsPageForm .quill.wysiwygStyle .ql-snow .ql-editor em {
  font-style: italic;
}
.CampaignsTeamsPageForm .quill.wysiwygStyle .ql-snow .ql-editor u {
  text-decoration: underline;
}
.CampaignsTeamsPageForm .quill.wysiwygStyle .ql-snow .ql-editor sub {
  font-size: 0.5em;
}
.CampaignsTeamsPageForm .quill.wysiwygStyle .ql-snow .ql-editor sup {
  font-size: 0.5em;
}
