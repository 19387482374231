.InfoPanel {
  background-image: url(/assets/Fundky_Logotype_2016._BGGeometric.jpg);
  background-size: cover;
  background-position: bottom;
  position: relative;
}
.InfoPanel h1 {
  margin-top: 40px;
  color: #fff;
  font-size: 28px;
  line-height: 1em;
}
.InfoPanel h1 span {
  font-family: 'Galada';
  font-weight: normal;
}
.InfoPanel img {
  width: 237px;
  margin: 0 auto;
}
.InfoPanel p {
  color: #fff;
  font-size: 18px;
  line-height: 1.33em;
}
.InfoPanel a {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.InfoPanel a svg {
  margin-right: 10px;
}
.InfoPanel--back {
  position: absolute;
  top: 28px;
  left: 28px;
  color: #fff;
  font-size: 36px;
  cursor: pointer;
}
@media screen and (max-width: 986px) {
  .InfoPanel {
    height: 100%;
  }
}
@media screen and (max-width: 508px) {
  .InfoPanel img {
    width: 50%;
  }
  .InfoPanel h1 {
    font-size: 20px;
    margin-top: 20px;
  }
  .InfoPanel p,
  .InfoPanel a {
    font-size: 16px;
  }
}
